import { browserHistory } from 'browserHistory'
import Moment from 'moment'

import alt from '../../core/services/alt'
import appConfig from 'config'

import * as ContentData from '../../apis/content'

import { handleRequestFailed } from '../../core/services/errorhandling'

import Const from '../../core/constants'
import { addChannel, removeChannel } from './shared/utils'
import { saveBlobToDisc } from '../../utils/save'

class Actions {

	// UI ACTIONS
	navNext(date, selectedChannels) {
		return (dispatch) => {
			const newDate = Moment(date).add(1, "day").format(Const.DATE_FORMAT);
			dispatch(newDate);
			browserHistory.push(`/schedule/${newDate}/${selectedChannels}`);
		};
	}

	navPrev(date, selectedChannels) {
		return (dispatch) => {
			const newDate = Moment(date).subtract(1, "day").format(Const.DATE_FORMAT);
			dispatch(newDate);
			browserHistory.push(`/schedule/${newDate}/${selectedChannels}`);
		};
	}

	navToday(selectedChannels) {
		return (dispatch) => {
			dispatch();
			browserHistory.push(`/schedule/today/${selectedChannels}`);
		};
	}

	navDate(date, selectedChannels) {
		return (dispatch) => {
			const navDate = Moment(date).format(Const.DATE_FORMAT);
			dispatch(navDate);
			browserHistory.push(`/schedule/${navDate}/${selectedChannels}`);
		};
	}

	selectChannel(channels, selectedChannels, date) {
		return (dispatch) => {
			dispatch(channels);
			browserHistory.push(`/schedule/${date}/${addChannel(channels, selectedChannels)}`);
		}
	}

	unselectChannel(channels, selectedChannels, date) {
		return (dispatch) => {
			dispatch(channels);
			browserHistory.push(`/schedule/${date}/${removeChannel(channels, selectedChannels)}`);
		}
	}

	// EPG ACTIONS
	fetchEpgDay(channels, epgPayload, tv4EpgPayload, vodPayload, tv4PlayPayload, tv4PlayPlusPayload, hideLoading) {
		return (dispatch) => {
			dispatch({channels, hideLoading});
			const requestTime = Date.now();

			// #TempHackForTv4InternalSchedule
			const epgRequests = [];
			if (tv4EpgPayload) {
				epgRequests.push(ContentData.fetchEpgSchedulesTv4(tv4EpgPayload));
			}
			if (epgPayload) {
				epgRequests.push(ContentData.fetchEpgSchedules(epgPayload));
			}
			Promise.all(epgRequests)
				.then((responses) => {
					let items = [];
					responses.forEach((response) => {
						const { data } = response;
						items.push(...data);
					});
					items = items.filter((item, index, self) => self.findIndex(item2 => item2.channel.name === item.channel.name) === index);
					this.itemsUpdated(items, requestTime);
				});

			if (appConfig.features.internalScheduleVodViewsEnabled && vodPayload) {
				ContentData.fetchPackageSchedules(vodPayload)
					.then(response => {
						const { data } = response;
						this.vodPackagesUpdated(data);
					}, this.requestFailed);
			}

			// #TempHackForTv4InternalSchedule
			if (appConfig.features.internalScheduleVodViewsEnabled && tv4PlayPayload) {
				ContentData.fetchPackageSchedulesTv4(tv4PlayPayload)
					.then(response => {
						const { data } = response;
						this.tv4PlayPackagesUpdated(data);
					}, this.requestFailed);
			}

			if (appConfig.features.internalScheduleVodViewsEnabled && tv4PlayPlusPayload) {
				ContentData.fetchPackageSchedulesTv4(tv4PlayPlusPayload)
					.then(response => {
						const { data } = response;
						this.tv4PlayPlusPackagesUpdated(data);
					}, this.requestFailed);
			}
		};
	}
	itemsUpdated(data, requestTime) {
		return {
			data,
			requestTime,
		};
	}
	vodPackagesUpdated(data) {
		return { data };
	}
	tv4PlayPackagesUpdated(data) {
		return { data };
	}
	tv4PlayPlusPackagesUpdated(data) {
		return { data };
	}
	
	// EPG-BROADCAST ACTIONS
	fetchEpgProgramBroadcasts(payload) {
		return (dispatch) => {
			dispatch(payload);

			// #TempHackForTv4InternalSchedule
			const isTv4Play = window.location.search.includes("site=tv4play");
			const apiMethod = isTv4Play ? ContentData.fetchEpgBroadcastsTv4 : ContentData.fetchEpgBroadcasts;
			apiMethod(payload)
				.then(response => {
					const { data, meta } = response;
					this.epgProgramBroadcastsUpdated(data);
				}, this.requestFailed);
		};
	}
	epgProgramBroadcastsUpdated(data) {
		return data;
	}
	fetchEpgSeasonBroadcasts(payload) {
		return (dispatch) => {
			dispatch(payload);

			// #TempHackForTv4InternalSchedule
			const isTv4Play = window.location.search.includes("site=tv4play");
			const apiMethod = isTv4Play ? ContentData.fetchEpgBroadcastsTv4 : ContentData.fetchEpgBroadcasts;
			apiMethod(payload)
				.then(response => {
					const { data, meta } = response;
					this.epgSeasonBroadcastsUpdated(data);
				}, this.requestFailed);
		};
	}
	epgSeasonBroadcastsUpdated(data) {
		return data;
	}

	// METADATA ACTIONS
	fetchSeriesAndLatestSeasonWithEpisodes(id, filters, fetchSeasonBroadcasts) {
		return (dispatch) => {
			dispatch(id);

			// #TempHackForTv4InternalSchedule
			const isTv4Play = window.location.search.includes("site=tv4play");
			const apiMethod = isTv4Play ? ContentData.fetchMetadataSeasonsTv4 : ContentData.fetchMetadataSeasons;
			apiMethod(id, filters)
				.then(response => {
					const { data, meta } = response;
					this.seasonsUpdated(data);
					const latestSeason = data.length ? data[data.length - 1] : null;
					if (latestSeason) {
						this.fetchSeason(latestSeason.id, filters);
						this.fetchEpisodes(latestSeason.id, filters);

						fetchSeasonBroadcasts(latestSeason.id);
					}
				}, this.requestFailed);
		}
	}

	fetchProgram(id, filters) {
		return (dispatch) => {
			dispatch(id);

			// #TempHackForTv4InternalSchedule
			const isTv4Play = window.location.search.includes("site=tv4play");
			const apiMethod = isTv4Play ? ContentData.fetchMetadataProgramTv4 : ContentData.fetchMetadataProgram;
			apiMethod(id, filters)
				.then(response => {
					const { data, meta } = response;
					this.programUpdated(data);
				}, this.requestFailed);
		};
	}
	fetchSeason(id, filters) {
		return (dispatch) => {
			dispatch(id);

			// #TempHackForTv4InternalSchedule
			const isTv4Play = window.location.search.includes("site=tv4play");
			const apiMethod = isTv4Play ? ContentData.fetchMetadataProgramTv4 : ContentData.fetchMetadataProgram;
			apiMethod(id, filters)
				.then(response => {
					const { data, meta } = response;
					this.programUpdated(data);
				}, this.requestFailed);
		};
	}
	programUpdated(data) {
		return data;
	}

	fetchSeasons(seriesId, filters) {
		return (dispatch) => {
			dispatch(seriesId);

			// #TempHackForTv4InternalSchedule
			const isTv4Play = window.location.search.includes("site=tv4play");
			const apiMethod = isTv4Play ? ContentData.fetchMetadataSeasonsTv4 : ContentData.fetchMetadataSeasons;
			apiMethod(seriesId, filters)
				.then(response => {
					const { data, meta } = response;
					this.seasonsUpdated(data);
				}, this.requestFailed);
		};
	}
	seasonsUpdated(data) {
		return data;
	}

	fetchEpisodes(seasonId, filters) {
		return (dispatch) => {
			dispatch(seasonId);

			// #TempHackForTv4InternalSchedule
			const isTv4Play = window.location.search.includes("site=tv4play");
			const apiMethod = isTv4Play ? ContentData.fetchMetadataEpisodesTv4 : ContentData.fetchMetadataEpisodes;
			apiMethod(seasonId, filters)
				.then(response => {
					const { data, meta } = response;
					this.episodesUpdated(data);
				}, this.requestFailed);
		};
	}
	episodesUpdated(data) {
		return data;
	}

	fetchFamily(id, filters) {
		return (dispatch) => {
			dispatch(id);

			// #TempHackForTv4InternalSchedule
			const isTv4Play = window.location.search.includes("site=tv4play");
			const apiMethod = isTv4Play ? ContentData.fetchMetadataFamilyTv4 : ContentData.fetchMetadataFamily;
			apiMethod(id, filters)
				.then(response => {
					const { data, included, meta } = response;
					this.familyUpdated({ data, included });
				}, this.requestFailed);
		};
	}
	familyUpdated(data) {
		return data;
	}

	/* **************** Misc **************** */
	unmount() { return true; }

	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}

	/* **************** Excel export **************** */
	exportVodPackageExcel(payload, isTv4Play) {
		return dispatch => {
			dispatch();

			// #TempHackForTv4InternalSchedule
			const apiMethod = isTv4Play ? ContentData.fetchPackageSchedulesExcelTv4 : ContentData.fetchPackageSchedulesExcel;
			apiMethod(payload)
				.then(response => {
					saveBlobToDisc(response, `VodView_${payload.channel}_${Moment().format('YYYY-MM-DD')}`);
					this.exportVodPackageExcelFinish()
				})
		}
	}

	exportVodPackageExcelFinish(excelLoading) {
		return !excelLoading
	}
}

export default alt.createActions(Actions);