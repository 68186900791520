import React from 'react'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll'

import { getRouteName } from '../../../components/comet'
import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'
import Button from '../../../components/ui/controls/button'

import Header from './header'
import List from './list'

import Actions from './actions'
import Store from './store'

import { getSearchPayload } from '../shared/utils'

import './app.css'

const MODULE = "metadata"
const DATASTORE = "tags"
const TEXT_HEADING = "Tags"
const TEXT_EMPTY = "No tags? There must be a problem."
const TEXT_CREATE = "Create tag"
const ICON_CREATE = "add"

export default class TagsApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.debouncedFilter = debounce(this.handleFilter, 300);
		this.loadMore = this.loadMore.bind(this);

		this.scroll = null;

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		Actions.fetchItems(DATASTORE, getSearchPayload(this.state));
	}

	// componentWillReceiveProps({ keydown }) {
	// 	if (keydown.event) {
	// 		console.log( keydown.event.which );
	// 		this.onSearch(keydown.event);
	// 	}
	// }

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	onFilter(type, e) {
		if (type === "search") {
			e.persist();
			this.debouncedFilter(type, e);
		} else {
			this.handleFilter(type, e);
		}
	}

	handleFilter(type, event) {
		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions[type](value, name);
		Actions.fetchItems(DATASTORE, getSearchPayload(this.state));

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	handleCreateNew() {
		Actions.create({});
	}

	loadMore() {
		const list = this.state.list[DATASTORE];

		if (list.nextPageUrl) {
			Actions.pageItems(DATASTORE, list.nextPageUrl);
		}
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const { isLoading, searchText: componentSearchText, filters, unselectedFilter, list } = this.state;
		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

		const tags = list[DATASTORE];

		return (
			<App
				name={`c6-${MODULE}-${DATASTORE} c6-list`}
				layout="grid"
				isLoading={isLoading}>
				<Header
					title={TEXT_HEADING}
					searchText={searchText}
					filters={filters}
					unselectedFilters={unselectedFilter || !!searchText.length}
					handleFilter={this.onFilter}
					handleCreateNew={this.handleCreateNew}
				/>
				<ScrollContainer scrollKey={`c6-${MODULE}-${DATASTORE}`} shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el} padding={true}>
						<List
							items={tags.items}
							isLoading={this.state.isLoading}
							searchText={searchText}
							filters={filters}
							hasMore={!!tags.nextPageUrl}
							loadMore={this.loadMore}
						/>
					</Main>
				</ScrollContainer>
				<Info>{getInfo(this.props.location, tags)}</Info>
			</App>
		);
	}
}

// HELPERS
function getInfo(location, list) {
	const items = list.items.length ? `- displaying ${list.items.length} of ${list.numberOfItems}` : "";

	return `${getRouteName(location)} ${items}`;
}