import React from 'react'
import Moment from 'moment'
import keydown from 'react-keydown'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll'

import Const from '../../../core/constants'

import { getRouteName } from '../../../components/comet'
import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'
import Button from '../../../components/ui/controls/button'

import Header from './header'
import Schedule from '../../internal-schedule/schedule/schedule'
import Broadcast from './broadcast'

import Actions from '../actions'
import Store from '../store'

// import { getSearchPayload, swapImageAsset } from '../shared/utils'
import OriginalStyles from '../../internal-schedule/schedule/app.css'
import ChannelStyles from '../../internal-schedule/shared/channels.css'

import appConfig from 'config'

const LS_KEY = "metadata-internalschedule-selectedchannels";
const DATASTORE =  "schedules"
const ENTITY =  "program"

@keydown("left", "right", "ctrl+t")
export default class ScheduleApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onFetchSchedule = this.onFetchSchedule.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.debouncedFilter = debounce(this.handleFilter, 500);

		this.scroll = null;
		this.date = this.props.params.date || Moment().format(Const.DATE_FORMAT);
		this._prevDate = null;
		this.channels = this.props.params.channels || localStorage.getItem(LS_KEY) || null;

		this.refreshTimerId = null;

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);

		Actions.fetchChannelGroups();

		if(!this.state.list.schedules.items.length) {
			this.onFetchSchedule();
		}

		this.refreshTimerId = window.setInterval(() => {
			const hideLoading = true;
			this.onFetchSchedule(hideLoading);
		}, 5 * 60 * 1000); // Refresh every five minutes
	}

	componentDidUpdate() {
		const { keydown, params } = this.props;
		const newDate = params.date || Moment().format(Const.DATE_FORMAT);
		const newChannels = params.channels || this.state.filters.filter.channel;
		if (!keydown.event && (newDate !== this.date || newChannels !== this.channels)) {
			this._prevDate = this.date;
			this.date = newDate;
			this.channels = newChannels;
			localStorage.setItem(LS_KEY, this.channels);

			this.onFetchSchedule();
		}

		// if (keydown.event) {
		// 	console.log( keydown.event.which );
		// 	// this.onSearch(keydown.event);
		// }
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
		window.clearInterval(this.refreshTimerId);
	}

	onChange(state) {
		this.setState(state);
	}

	onFetchSchedule(hideLoading) {
		Actions.fetchScheduleDay(DATASTORE, getSearchPayload(this.state, this.date, this.channels), hideLoading, this._prevDate);
	}

	onFilter(type, e) {
		if(type === "search") {
    		e.persist();
    		this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
  	}

	handleFilter(type, event) {
		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions.scheduleFilter(value, name);
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const { isLoading, fallbackChannels, useChannelGroupsFallback, list } = this.state;
		const schedules = list[DATASTORE];
		const channels = getChannels(list.channelGroups.items, fallbackChannels, useChannelGroupsFallback);
		const channelGroups = getChannelGroups(list.channelGroups.items, useChannelGroupsFallback);
		const { _approvalStatus, _type, _channelGroup } = schedules.filters;
		const channelGroup = !_channelGroup.length && channelGroups?.length ? channelGroups[0].key : _channelGroup;

		const filteredSchedules = getSchedules(schedules.items, _approvalStatus, _type, channels, _channelGroup);
		const date = this.date && Moment(this.date) || null;

		const { keydown, location } = this.props;

		const noBroadcastsText = _approvalStatus === "" && _type === "" ? "No broadcasts." : "No broadcasts matching the selected filters.";

		return (
			<App name={`c6-internal-schedule c6-list`} layout="grid" isLoading={isLoading}>
				<Header
					keydown={keydown}
					date={date && date.format(Const.DATE_FORMAT)}
					selectedChannels={schedules.filters.channels}
					approvalStatus={_approvalStatus}
					type={_type}
					channelGroup={channelGroup}
					handleFilter={this.handleFilter}
					channels={channels}
					channelGroups={channelGroups}
					useChannelGroupsFallback={useChannelGroupsFallback}
				/>
				<ScrollContainer scrollKey="c6-metadata-schedule-day" shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el}>
						<Schedule
							schedules={filteredSchedules}
							channels={channels}
							noBroadcastsText={noBroadcastsText}
						>
							<Broadcast
								location={location}
								selectedGroup={channelGroup}
								contentOwner={getContentOwnerForChannelGroup(channelGroup, channelGroups)}
							/>
						</Schedule>
					</Main>
				</ScrollContainer>
				<Info>{getInfo(location, this.state)}</Info>
			</App>
		);
	}
}

// HELPERS
function getInfo(location, { numberOfItems, ...rest }) {
	const ds = rest[DATASTORE];
	const items = ""; // ds.length ? `- displaying ${ds.length} of ${numberOfItems}` : "";

	return `${getRouteName(location)} ${items}`;
}

function getSearchPayload({ list }, day, channels) {
	const payload = {
		...list.schedules.filters,
	};
	day && (payload.day = day);
	if(!appConfig.features.metadataScheduleSkipChannels) {
		channels && ( payload.channels = channels);
	}
	else {
		delete payload.channels;
	}
	return payload;
}

function getSchedules(schedules, approval, type, channels, channelGroupFilter) {
	if (approval !== "images" && approval !== "missing" && type !== "sports" && channelGroupFilter === "") {
		return schedules;
	}

	// Filter on channel group
	const channelsInSelectedGroups = channels.filter(c => (channelGroupFilter ?? "").includes(c.group));
	const schedulesToRender = schedules.filter(i => channelsInSelectedGroups.some(c => c.key.toLowerCase() === i.channel.name.toLowerCase()));

	return schedulesToRender
		// Use same channel order as in store
		.sort((a, b) => channels.findIndex(c => c.key === a.channel.name) - channels.findIndex(c => c.key === b.channel.name))
		.map(channel => {
			const currentChannel = channels.filter(c => c.key === channel.channel.name);
			const group = currentChannel.length && currentChannel[0].group;

			const { broadcasts, ...rest } = channel;
			return {
				...rest,
				broadcasts: filterBroadcasts(broadcasts, group, approval, type), // Filter on program approval and sports
			}
		});
}

function filterBroadcasts(broadcasts, group, approval, type) {
	return broadcasts.filter(b =>
		programNeedsAttention(b.program, group, approval) &&
		sportProgram(b.program, type) &&
		programNeedsImageApproval(b.program, approval))
}

const TV4_VERSION_ID = 1;
function programNeedsAttention(program, group, approvalStatus) {
	if(!approvalStatus || approvalStatus !== "missing") {
		return true;
	}

	const { approval, imageApproval, tagsApproval, seasonApproval, seriesApproval, tagsSeriesApproval, versions } = program;
	const activeVersions = group !== "tv4" ? versions : versions.filter(v => v.versionId === TV4_VERSION_ID);
	const approvalVersions = activeVersions.map(v => ({
		status: v.approval && v.approval.status,
	}));

	const approvals = program.type === "Episode"
		? [approval, imageApproval, seasonApproval, seriesApproval, tagsSeriesApproval, ...approvalVersions]
		: [approval, imageApproval, tagsApproval, ...approvalVersions];

	return approvals.some(a => !a || !a.status || a.status === "unapproved");
}

function sportProgram(program, type) {
	return !type || program.class === "Sport";
}

function programNeedsImageApproval(program, approvalStatus) {
	if(!approvalStatus || approvalStatus !== "images") {
		return true;
	}

	const { imageApproval, seasonApproval } = program;

	const approvals = program.type === "Episode"
		? [imageApproval, seasonApproval]
		: [imageApproval];

	return approvals.some(a => !a || !a.status || a.status === "unapproved");
}

function getChannels(channelGroups, fallbackChannels, useChannelGroupsFallback) {
	if (!useChannelGroupsFallback && (appConfig.features?.metadataC70 || appConfig.features?.metadataC80)) {
		return channelGroups.reduce((channels, currentGroup) => {
			const currentGroupChannels = currentGroup.channels.map(c => ({ group: currentGroup.name, key: c.name, text: c.displayName }));
			return [...channels, ...currentGroupChannels];
		}, []);
	}

	return fallbackChannels;
}

function getChannelGroups(channelGroups, useChannelGroupsFallback) {
	if (!useChannelGroupsFallback && (appConfig.features?.metadataC70 || appConfig.features?.metadataC80)) {
		return channelGroups.map(cg => ({ key: cg.name, text: cg.displayName, contentOwner: cg.contentOwner }));
	}

	return null;
}

function getContentOwnerForChannelGroup(channelGroup, channelGroups) {
	const group = channelGroups?.find(g => g.key === channelGroup);
	return group?.contentOwner;
}