import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'

import ExistingFamilies from './existingFamilies'
import NewFamily from './newFamily'

import appConfig from 'config'

import '../dialogs.css'
import './index.css'

const ProgramFamilyDialog = (props) => {

	const [tab, setTab] = React.useState("existing");

	if (!props.data?.id) {
		return null;
	}

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			className="c6-modal c6-program-family-dialog"
			// maxWidth={false}
			classes={{
				container: "c6-modal-content",
				paper: "c6-modal-body"
			}}
		>
			<DialogContent sx={{ padding: 0 }}>
				<Tabs className="c6-tabs" value={tab} onChange={(_, val) => setTab(val)}>
					<Tab label="Existing family" value="existing" />
					<Tab label="New family" value="new" />
				</Tabs>

				{tab === "existing" && (
					<ExistingFamilies data={props.data} />
				)}
				{tab === "new" && (
					<NewFamily data={props.data} />
				)}
			</DialogContent>
			<DialogActions>
				<Button
					variant="standard"
					onClick={props.onClose}
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ProgramFamilyDialog;
