import React from 'react'
import Moment from 'moment'
import { Link } from 'react-router'
import appConfig from 'config'

import { GUID_REGEXP, Regions, AllRegions } from '../../../core/constants'
import MetadataModule from '../module'

export function getItemTemplateModification({ type, parentId, masterId, isMasterTemplate }, masterTemplateModifications) {
	if(type !== "Episode") {
		return null;
	}

	const masterTemplateModification = masterTemplateModifications && masterTemplateModifications.get(parentId) || {};
	const { event, masterId: modifiedMasterId } = masterTemplateModification;

	const masterTemplateRemoved = masterId && event === "removed";
	const masterTemplateAdded = !masterId && event === "added";

	if ((masterTemplateRemoved || masterTemplateAdded) && !isMasterTemplate) {
		return { event, modifiedMasterId };
	}

	return null;
}

export function getSearchPayload({ filters = {} }, _catalogue, isTranslationList, translationLanguage, _rightVersion) {
	const { filter, searchText, ...rest } = filters;

	const translationFilters = isTranslationList ? getTranslationFilters(filter, searchText, translationLanguage, _catalogue) : null;
	
	const specialFilters = {};
	if (filter.approval == "#missingseries") {
		specialFilters["programTypes"] = "season";
		specialFilters["missingParent"] = true;
		specialFilters["approval"] = "";
	}

	let catalogue = _catalogue;
	if (_catalogue === "all") {
		catalogue = filter.catalogue === "all" ? null : filter.catalogue;
	}
	
	return {
		...rest,
		searchText,
		...filter,
		catalogue: appConfig.features.metadataUseCatalogues ? catalogue : null,
		sportsMetadataIsUsed: searchText ? null : filters.sportsMetadataIsUsed, // Don't search with sportsMetadataIsUsed
		approval: filter?.approval === "version" ? filter?.versionApproval : filter?.approval,
		versionApproval: null,
		...translationFilters,
		...specialFilters,
		contentOwner: appConfig.features.metadataUseCatalogues ? null : catalogue,
	};
}

export function getApproval(skipTags = false, catalogue) {
	const approvals = [
		{
			key: "",
			text: "Any",
			description: "Programs which either are approved or are missing approval.",
		},
		{
			key: "missing",
			text: "Needs Attention",
			description: "Program is not fully approved.",
		},
		{
			key: "#missingseries",
			text: "Missing Series",
			description: "List seasons that is missing series connection.",
		},
	];

	if (catalogue === "mtvfi") {
		approvals.push({
			key: "finnishrating",
			text: "Age rating",
			description: "Program does not have an age rating."
		});
	}

	approvals.push({
		key: "images",
		text: "Images",
		description: "Program needs approved images."
	});


	if (catalogue === "britbox" || catalogue === "hayu") {
		approvals.push(
			{
				key: "videos",
				text: "Video",
				description: "Program needs approved video."
			},
			{
				key: "finnishrating",
				text: "Age",
				description: "Program needs Finnish age rating."
			},
			{
				key: "version",
				text: "Version",
				description: "Program needs approved version."
			},
		);
	}
	
	if (!skipTags) {
		approvals.push({
			key: "tags",
			text: "Tags",
			description: "Program needs approved tags.",
		});
	}

	return approvals;
}

export function getVersionApprovals() {
	const versions = [
		{
			key: "generic",
			text: "Generic",
			description: "Program is missing generic approval."
		},
		{
			key: "english",
			text: "English",
			description: "Program is missing English approval."
		},
		{
			key: "swedish",
			text: "Swedish",
			description: "Program is missing Swedish approval."
		},
		{
			key: "norwegian",
			text: "Norwegian",
			description: "Program is missing Norwegian approval."
		},
		{
			key: "danish",
			text: "Danish",
			description: "Program is missing Danish approval."
		},
		{
			key: "finnish",
			text: "Finnish",
			description: "Program is missing Finnish approval."
		}
	];

	return versions;
}

export function getRight() {
	return [
		{
			key: "",
			text: "Any",
			description: "Either a VOD or a linear program.",
		},
		{
			key: "svod|catchup",
			text: "SVOD",
			description: "A SVOD program.",
		},
		{
			key: "avod",
			text: "AVOD",
			description: "A AVOD program.",
		},
		{
			key: "linear",
			text: "Linear",
			description: "A linear program.",
		}
	]
}

export function getClass() {
	return [
		{
			key: "",
			text: "Any",
			description: "Any class.",
		},
		{
			key: "a",
			text: "A",
			description: "Program with an A class license.",
		},
		{
			key: "b",
			text: "B",
			description: "Program with an B class license.",
		},
		{
			key: "c",
			text: "C",
			description: "Program with an C class license.",
		},
		{
			key: "d",
			text: "D",
			description: "Program with an D class license.",
		},
	]
}

export function getCatalogues(language) {
	let cataloguesRes = MetadataModule.catalogues;

	if(language) {
		const languageVersionId = Regions.find(r => r.languageDisplayName.toLowerCase() === language.toLowerCase()).id;
		cataloguesRes = cataloguesRes.filter(c => c.uiData.localeEditorVersions.some(v => v.targetVersionId === languageVersionId));
	}
	const catalogues = cataloguesRes
		.map(c => ({
			key: c.name,
			text: c.displayName,
		}))
		.sort((a, b) => a.text.localeCompare(b.text));

	language && catalogues.unshift({ key: "", text: "Any" });
	return catalogues;
}

export function getPremiere() {
	const premieres = [
		{
			key: "previouslyandupcoming",
			text: "Previous & Upcoming",
			description: "Program had/has a premiere in the past or future.",
		},
		{
			key: "upcoming",
			text: "Upcoming",
			description: "Program has an upcoming premiere sometime in the future.",
		}
	];

	let month = Moment().startOf("month");
	let prevMonth = Moment().startOf("month").add(-1, "months");

	// add the previous month
	premieres.push({
		key: prevMonth.format("YYYY-MM-DD"),
		text: prevMonth.format("MMMM"),
		description: "Program premieres in " + prevMonth.format("MMMM"),
	});

	// add 6 upcoming months including the current month
	for (let i = 0; i < 6; i++) {
		premieres.push({
			key: month.format("YYYY-MM-DD"),
			text: month.format("MMMM"),
			description: "Program premieres in " + month.format("MMMM"),
		})
		month = month.add(1, "months").startOf("month");
	}
	return premieres;
}

export function getTranslation() {
	return [
		{
			key: "",
			text: "Any",
			description: "Tag is either fully translated or missing some translation."
		},
		{
			key: "missing",
			text: "Missing",
			description: "Tag is not fully translated."
		},
		{
			key: "sv",
			text: "Swedish",
			description: "Tag needs Swedish translation."
		},
		{
			key: "no",
			text: "Norwegian",
			description: "Tag needs Norwegian translation."
		},
		{
			key: "da",
			text: "Danish",
			description: "Tag needs Danish translation."
		},
		{
			key: "fi",
			text: "Finnish",
			description: "Tag needs Finnish translation."
		},
	];
}

export function getTypes() {
	const types = [
		{
			key: "",
			text: "All",
			description: ""
		},
		{
			key: "movies",
			text: "Singles",
			description: "Display single programs (no sport)."
		},
	];
	if (appConfig.features.metadataSeriesAndEpisodesSeparateFilters) {
		types.push({
			key: "episodes",
			text: "Episodes",
			description: "Display episodes."
		});
	}
	types.push(
		{
			key: "series",
			text: "Series",
			description: appConfig.features.metadataSeriesAndEpisodesSeparateFilters ? "Display series." : "Display series episodes."
		},
		{
			key: "sport",
			text: "Sport",
			description: "Display sport programs."
		},
		{
			key: "sportseries",
			text: "Sport series",
			description: "Display sport series."
		}
	);

	if (appConfig.features.metadataEventPlannerFilter) {
		types.push({
			key: "_eventplanner",
			text: "From Eventpl.",
			description: "Display sport programs that receive metadata from Event planner."
		});
	}

	return types;
}

export function getTranslationStatuses(translationLanguage) {
	const statuses = [
		{
			key: "",
			text: "Any",
			description: ""
		}
	];

	if (appConfig.features.metadataVodHubProgramListFilter && translationLanguage === "Finnish") {
		statuses.push(
			{
				key: "finnishrating",
				text: "Age rate",
				description: "Display programs that need finnish age rating.",
			}
		);
	}
	
	statuses.push(
		{
			key: "translate",
			text: "Translate",
			description: "Display programs that need translation."
		},
		{
			key: "done",
			text: "Done",
			description: "Display programs that have been translated."
		}
	);

	return statuses;
}

export function getVersions() {
	return [
		{
			key: "",
			text: "Any",
			description: "",
		},
		...AllRegions.map(region => ({
			key: region.languageDisplayName.toLowerCase(),
			text: region.countryDisplayName,
			description: "",
		})),
	];
}

export function getSeasons(seriesSeasons) {
	const now = Moment();
	return seriesSeasons.map(season => {
		const hasActiveSeason = season.rights && season.rights.some(r => r.start && r.end && now.isBetween(Moment(r.start).subtract(14, 'days'), r.end, "day", "[]"));

		return {
			key: season.id,
			text: season.seasonNumber || season.productionYear || "?",
			description: `Display season ${season.seasonNumber || season.productionYear }`,
			className: !hasActiveSeason ? "inactive" : null,
		};
	});
}

const SWEDISH = 1;
export function getSwedishTitle(versions, originalTitle) {
	const swedish = versions ? versions.filter(v => v.versionId === SWEDISH) : [];
	return swedish[0] && swedish[0].title
		? swedish[0].title
		: originalTitle || '';
}


export function swapImageAsset(reference, assetGuid) {
	const re = /(?:\/\/.*?)\/(.*)\//;
	const imagesToRefresh = document.querySelectorAll(`img[data-reference='${reference}']`);

	imagesToRefresh.forEach(i => {
		let { src, srcset } = i;

		// HACK? Since we clear the src when lazyloaded images fail to load.
		if (!src || !src.length) {
			src = `${appConfig.media.image}program/00000000-1000-1000-8000-000000000000/main/24.jpg`;
		}

		// Replace any GUIDs in src and srcset with new GUID
		let newSrc = src.replace(GUID_REGEXP, (match, p1) => match.replace(p1, assetGuid));
		let newSrcset = srcset.split(",").map(si => si.replace(GUID_REGEXP, (match, p1) => match.replace(p1, assetGuid))).join();

		// Replace program reference with GUID
		newSrc = newSrc.replace(re, (match, p1) => match.replace(p1, assetGuid));
		newSrcset = newSrcset.split(",").map(si => si.replace(re, (match, p1) => match.replace(p1, assetGuid))).join();

		i.src = newSrc;
		i.srcset = newSrcset;
	});
}

export function getMergeActions(datastore, { selected }, onMergeClick, onClearClick) {
	const dsSelected = selected[datastore];
	const selectedText = dsSelected.length ? `${dsSelected.length} item(s) selected ` : "";
	const actions = [];
	if (dsSelected.length) {
		actions.push(<Link key="merge" className="c6-link" onClick={onMergeClick} disabled={dsSelected.length < 2}>Merge</Link>);
		actions.push(<Link key="clear" className="c6-link" onClick={onClearClick}>Clear</Link>);
	}

	return (
		<div className="merge-actions">
			{selectedText}{actions}
		</div>
	);
}

export const LinkRelationTypes = [
	{ id: "Main", name: "Main" },
	{ id: "Pre", name: "Pre-shows" },
	{ id: "Post", name: "Post-shows" },
	{ id: "PreEpisode", name: "Pre-episode show" },
	{ id: "PostEpisode", name: "Post-episode show" },
	{ id: "SpinOff", name: "Spin-off shows" },
	{ id: "Related", name: "Related shows" },
]

export function programHasRatings(item) {
	return appConfig.features.metadataFinnishRatings && ["Single", "Episode"].includes(item?.type);
}

export function listSupportsRatings(isTranslationList, translationLanguage, catalogue) {
	return isTranslationList && translationLanguage === "Finnish"
		|| catalogue === "mtvfi"
		|| catalogue === "cmore"/* && hasFinnishRight*/
		|| catalogue === "britbox"/* && hasFinnishRight*/
		|| catalogue === "hayu"/* && hasFinnishRight*/;
}

// HELPERS
function getTranslationFilters(filter, searchText, translationLanguage, catalogue) {
	const sourceLanguage = appConfig.features.metadataTranslationSourceLang;
	let hasApproval = sourceLanguage;
	let needsApproval = translationLanguage?.toLowerCase() || "norwegian,danish,finnish";

	switch(filter._translationStatus) {
		case "":
			needsApproval = null;
			break;
		case "done":
			hasApproval = needsApproval;
			needsApproval = null;
			break;
		case "finnishrating":
			needsApproval = "finnishrating";
			break;
	}

	let programTypes = "single,episode,season,series";
	switch(filter._type) {
		case "movies":
			programTypes = "single";
			break;
		case "episodes":
			programTypes = "episode";
			break;
		case "series":
			programTypes = appConfig.features.metadataSeriesAndEpisodesSeparateFilters
				? "series"
				: "episode,season,series";
			break;
		case "sport":
			programTypes = "single,episode";
			break;
		case "_eventplanner":
			programTypes = "single,episode";
			break;
	}

	// When free text searching, we return everything, no matter translation approval status
	if (searchText) {
		hasApproval = sourceLanguage;
		needsApproval = null;
		programTypes = "single,season,series";
	}

	// Special case for mtv since we only want to show series & episodes that need Finnish translation
	if (catalogue === "mtv") {
		hasApproval = null;
		programTypes = "series,episode";
	}

	const translationFilters = {
		hasApproval,
 		needsApproval,
		approval: null, // approval === needsApproval
		hideWithParent: null, // Since we don't (currently) have the season view activated in the translation list we can't hide anything.
		programTypes,
		rightVersion: translationLanguage?.toLowerCase(),
		catalogue: filter.catalogue,
	};

	if (appConfig.features.metadataTranslationNewFilters) {
		translationFilters.premiere = null;
	}
	
	return translationFilters;
}
