import React from 'react'
import { Link } from 'react-router'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'

import Textbox from '../../../components/cms/components/textbox_new'
import DropDown from '../../../components/ui/controls/dropdown'

import * as CMSAPI from '../../../apis/cms'

import './broadcast.css'
import { localeCompareSortNumeric } from '../../../core/services/sorting'

export default ({
	broadcast,
    onChange,
	channelGroup,
}) => {
    const {
		id,
		start,
		end,
		title,
		seasonNumber,
		episodeNumber,
		classification,
		broadcastText,
		broadcastTitle,
		broadcastRights,
		_originalItem,
	} = broadcast;

    const now = moment();
	const broadcastEnd = moment(end);
	const past = broadcastEnd.isBefore(now) ? "dim" : "";
	const time = start ? moment(start).format("HH:mm") : "????";

	const seasonEpisode = (seasonNumber || episodeNumber) && (classification !== "Sport" || classification === "Sport")
		? <span>{`${seasonNumber ? ` S${seasonNumber}` : ""}${episodeNumber ? `\u00A0E${episodeNumber}` : ""}`}</span>
		: null;

	const [expand, setExpand] = React.useState(false);
    const [isEditingBroadcastText, setIsEditingBroadcastText] = React.useState(false);
	const [isEditingBroadcastTitle, setIsEditingBroadcastTitle] = React.useState(false);
	const [isAddingRights, setIsAddingRights] = React.useState(false);

    const onBroadcastTextChange = React.useCallback(
		async (newValue) => {
			await onChange({
				id,
				data: newValue,
				path: ["descriptions", "se_sv"],
				originalItem: _originalItem,
			});
		},
		[_originalItem]
	);

	const onBroadcastTitleChange = React.useCallback(
		async (newValue) => {
			await onChange({
				id,
				data: newValue,
				path: ["title"],
				originalItem: _originalItem,
			});
		},
		[_originalItem]
	);			

	const removeRight = React.useCallback(
		async (e, rightId) => {
			e.stopPropagation();

			const updatedRights = [...broadcastRights];
			const index = updatedRights.findIndex(r => r.right?.id === rightId);
			if (index === -1) {
				return;
			}

			const right = { ...updatedRights[index] };
			if (right.inputState === "system") {
				right.inputState = "hidden";
				updatedRights[index] = right;
			} else {
				updatedRights.splice(index, 1);
			}

			await onChange({
				id,
				data: updatedRights,
				path: ["broadcastRights"],
				originalItem: _originalItem,
			});
		},
		[broadcastRights, _originalItem]
	);

	const onBroadcastTextClick = React.useCallback(
		(e) => {
			if (expand && isEditingBroadcastText) {
				e.stopPropagation();
				return;
			}

			setExpand(true);
			setIsEditingBroadcastText(true);
		},
		[expand, setExpand, isEditingBroadcastText, setIsEditingBroadcastText]
	);

	const onBroadcastTitleClick = React.useCallback(
		(e) => {
			if (expand && isEditingBroadcastTitle) {
				e.stopPropagation();
				return;
			}

			setExpand(true);
			setIsEditingBroadcastTitle(true);
		},
		[expand, setExpand, isEditingBroadcastTitle, setIsEditingBroadcastTitle]
	);

	const rightsFilters = { _entity: "rights", _module: "broadcast", pageSize: 1000 };
	const { data: availableRights } = useQuery(
		["available-broadcast-rights", rightsFilters],
		() => CMSAPI.fetchCMSEntities(rightsFilters),
	);

	const addRight = React.useCallback(
		async (e) => {
			const newRightId = e.target.value;
			if (!newRightId) {
				return;
			}

			const updatedRights = [...broadcastRights];
			const newRight = {
				inputState: "user",
				right: {
					id: newRightId,
					name: availableRights?.items?.find(r => r.id === newRightId)?.name ?? "Loading...",
				},
			};
			updatedRights.push(newRight);

			await onChange({
				id,
				data: updatedRights,
				path: ["broadcastRights"],
				originalItem: _originalItem,
			});
		},
		[availableRights, _originalItem]
	);

	const visibleRights = (broadcastRights ?? [])
		.filter(br => !!br.right?.id && br.inputState !== "hidden")
		.sort(sortRights);

	const rightOptions = getRightOptions(availableRights, visibleRights, channelGroup);
	// Stop adding rights when there are no more rights to add
	React.useEffect(
		() => {
			if (rightOptions.length === 1) {
				setIsAddingRights(false);
			}
		},
		[rightOptions?.length]
	);

	return (
		<div
			className={`c6-eventplanner-broadcast ${past} ${expand ? "expand" : ""}`}
            onClick={(e) => {
                if (expand) {
                    e.stopPropagation();
                    return;
                }

                setExpand(true);
            }}
			title="Click to edit broadcast title & text"
		>
			<time dateTime={start}>{time}</time>
			<h1 onClick={() => setExpand(!expand)}>
				<span>
					{!!broadcastTitle?.length && (
						<strong>{broadcastTitle}</strong>
					)}
					{!broadcastTitle?.length && (
						<span>
							{title}
							{seasonEpisode}
						</span>
					)}
				</span>
				{!expand && visibleRights?.length > 0 && (
					<div className="inline-rights">
						{visibleRights.map(right => (
							<div key={right.right.id} className="inline-right">{getIcon(right.right)}</div>
						))}
					</div>
				)}
				{expand && <span className="expand icon-expand_less"></span>}
			</h1>
			{expand && (
				<>
					<div className="broadcast-header">
						Original title:
					</div>
					<p className="no-indent">
						{title}
						{seasonEpisode}
					</p>
				</>
			)}
			{expand && (
				<div className="broadcast-header">
					Broadcast title:
				</div>
			)}
			{expand && !isEditingBroadcastTitle && broadcastTitle?.length > 0 && (
				<p className="no-indent">
					<span className="broadcast-title" onClick={onBroadcastTitleClick}>{broadcastTitle}</span>
					<span
						className="remove c6-link"
						onClick={() => setIsEditingBroadcastTitle(true)}
						title="Click to edit/remove broadcast title"
					>
						Edit
					</span>
				</p>
			)}
			{expand && isEditingBroadcastTitle && (
				<Textbox
					onChange={onBroadcastTitleChange}
					value={broadcastTitle}
					options={{
						maxLength: 1000
					}}
					onBlur={() => setIsEditingBroadcastTitle(false)}
					autoFocus={true}
				/>
			)}
			{expand && !isEditingBroadcastTitle && !broadcastTitle?.length && (
				<p className="no-indent">
					<span
						className="add c6-link"
						onClick={() => setIsEditingBroadcastTitle(true)}
						title="Click to enter a broadcast title"
					>
						Set broadcast title
					</span>
				</p>
			)}
			{expand && (
				<div className="broadcast-header">
					Broadcast text:
				</div>
			)}
			{!isEditingBroadcastText && broadcastText?.length > 0 && (
				<p className="no-indent">
					<span className="broadcast-text" onClick={onBroadcastTextClick}>{broadcastText}</span>
					<span
						className="remove c6-link"
						onClick={() => {
							setIsEditingBroadcastText(true);
							setIsEditingBroadcastTitle(false);
						}}
						title="Click to edit broadcast text"
					>
						Edit
					</span>
				</p>
			)}
            {expand && isEditingBroadcastText && (
                <Textbox
                    onChange={onBroadcastTextChange}
                    value={broadcastText}
                    options={{
                        maxLength: 1000
                    }}
                    onBlur={() => setIsEditingBroadcastText(false)}
					autoFocus={true}
                />
            )}
			{expand && !isEditingBroadcastText && !broadcastText?.length && (
				<p className="no-indent">
					<span
						className="add c6-link"
						onClick={() => setIsEditingBroadcastText(true)}
						title="Click to enter a broadcast text"
					>
						Set broadcast text
					</span>
				</p>
			)}
			{expand && (
				<div className="broadcast-header">
					Broadcast rights:
				</div>
			)}
			{expand && visibleRights.map(br => (
				<div key={br.right.id} className="broadcast-right">
					<span>{getIcon(br.right)}{br.right.name ?? "[Unknown]"}</span>
					<a className="c6-link" onClick={(e) => removeRight(e, br.right.id)}>Remove</a>
				</div>
			))}
			{expand && !isAddingRights && rightOptions.length > 1 && (
				<div className="add-rights">
					<a className="c6-link" style={{ marginBottom: "3px" }} onClick={() => setIsAddingRights(true)}>Add right</a>
				</div>
			)}
			{expand && isAddingRights && (
				<div className="broadcast-right add-rights">
					<DropDown
						states={rightOptions}
						currentState=""
						onChange={addRight}
						title=""
					/>
					<a className="c6-link" onClick={() => setIsAddingRights(false)}>Cancel</a>
				</div>
			)}
		</div>
	);
}

function getRightOptions(rights, visibleRights = [], channelGroup) {
	const rightOptions = (rights?.items ?? [])
		.filter(r =>
			!visibleRights?.some(br => br.right?.id === r.id) // Don't show already selected rights
			&& r.owner === channelGroup
		)
		.map(r => ({
			key: r.id,
			text: r.name,
			icon: getIcon(r, true),
		}));

	return [
		{ key: "", text: "Pick a right to add" },
		...rightOptions,
	];
}

const getIcon = (right, isDropdown) => {
	if (right.rightType === "startOver") {
		return (
			<svg style={isDropdown ? fixDropdownSVGStyle : {}} xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="3 10 105 110" preserveAspectRatio="xMidYMid meet">
				<g transform="translate(0.000000,121.000000) scale(0.100000,-0.100000)"> {/* Created by JOL, hence the weird scale and viewbox*/}
					<path d="M332 1044 c-62 -42 -112 -82 -112 -90 0 -16 219 -166 232 -158 4 3 8 23 8 44 0 35 3 40 27 46 15 4 55 3 90 -3 160 -28 268 -137 296 -297 39 -226 -173 -438 -399 -399 -166 29 -269 135 -299 310 -14 81 -29 103 -72 103 -35 0 -73 -43 -73 -82 0 -52 27 -154 56 -211 34 -67 144 -177 211 -211 143 -72 313 -72 456 0 67 34 177 144 211 211 72 143 72 313 0 456 -34 67 -144 177 -211 211 -66 33 -161 56 -235 56 l-58 0 0 39 c0 22 -4 42 -8 45 -5 3 -59 -29 -120 -70z"/>
					<path d="M466 703 c-9 -10 -7 -311 2 -317 13 -8 232 143 232 160 0 7 -46 44 -102 82 -128 85 -124 83 -132 75z"/>
				</g>
			</svg>
		);
	}

	if (right.rightType === "catchUp"/* && right.days === 7*/) {
		return (
			<svg style={isDropdown ? fixDropdownSVGStyle : {}} version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="2 7 105.000000 110" preserveAspectRatio="xMidYMid meet">
				<g transform="translate(0.000000,121.000000) scale(0.100000,-0.100000)"> {/* Created by JOL, hence the weird scale and viewbox*/}
					<path d="M76 1113 c-9 -10 -7 -311 2 -317 13 -8 232 143 232 160 0 7 -46 44 
						-102 82 -128 85 -124 83 -132 75z"/>
					<path d="M446 1009 c-57 -45 -17 -119 65 -119 167 0 313 -110 354 -266 19 -73
						19 -105 0 -178 -45 -173 -220 -288 -391 -259 -166 29 -269 135 -299 310 -14
						81 -29 103 -72 103 -35 0 -73 -43 -73 -82 0 -52 27 -154 56 -211 34 -67 144
						-177 211 -211 143 -72 313 -72 456 0 67 34 177 144 211 211 72 143 72 313 0
						456 -34 67 -144 177 -211 211 -110 55 -259 73 -307 35z"/>
					<path d="M380 715 l0 -25 105 0 c58 0 105 -1 105 -3 0 -2 -36 -86 -80 -186
						-44 -101 -80 -188 -80 -192 0 -5 12 -9 28 -9 26 0 31 10 110 195 45 106 82
						205 82 219 l0 26 -135 0 -135 0 0 -25z"/>
				</g>
			</svg>
		);
	}

	return isDropdown ? null : <span className="placeholder-for-icon"></span>;
}

const fixDropdownSVGStyle = { width: "20px", marginRight: "15px" };
const sortRights = localeCompareSortNumeric(right => right.right.name);