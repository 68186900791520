import React from 'react'
import debounce from 'lodash/debounce'

import withDnDContext from '../../../core/services/withDnDContext'

import App from '../../../components/app'
import Section from '../../../components/ui/section'
import Main from '../../../components/ui/main'

import Store from './store'
import Actions from './actions'
import { getSearchPayload } from '../shared/utils'

import Header from './header'
import Members from './members'
import ProgramSearch from './programSearch'

import './app.css'

const MODULE = "metadata";
// const DATASTORE = "families";
const ENTITY = "family";

class FamilyApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.debouncedFilter = debounce(this.handleFilter, 500);
		this.loadMore = this.loadMore.bind(this);
		this.handleOutsideDrop = this.handleOutsideDrop.bind(this);

		this.scroll = null;

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
		};
	}

	// Lifecycle
	componentDidMount() {
		Store.listen(this.onChange);

		const familyId = this.props.params.id;
		const programSearchPayload = getSearchPayload(this.state);

		Actions.fetchFamily(familyId, programSearchPayload);
		Actions.fetchFamilyMembers(familyId);
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	onFilter(type, e) {
		if (type === "search") {
			e.persist();
			this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
	}

	handleFilter(type, event) {
		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions[type](value, name);
		if (type !== "search" || this.state.filters.searchText.length > 0) {
			Actions.fetchPrograms(getSearchPayload(this.state));
		} else {
			Actions.clearSearch();
		}

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	loadMore() {
		if (this.state.nextPageUrl) {
			Actions.pagePrograms(this.state.nextPageUrl);
		}
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	handleOutsideDrop(id, originalIndex) {
		this.membersRef.handleOutsideDrop(id, originalIndex);
	}

	render() {
		const {
			programsLoading,
			familyLoading,
			familyMembersLoading,
			searchText: componentSearchText,
			filters,
			nextPageUrl,
			unselectedFilter,
			family,
			familyMembers,
			programs,
		} = this.state;
		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

		const isLoading = programsLoading || familyLoading || familyMembersLoading;
	    return (
			<App name={`c6-metadata-programs c6-${MODULE}-${ENTITY}`} isLoading={isLoading}>
				<Section flex="2">
					<Header title={family.name} isLoading={familyLoading} />
					<Main padding={true}>
						<Members
							ref={r => { if (r) this.membersRef = r }}
							family={family}
							items={familyMembers}
							isLoading={familyMembersLoading}
						/>
					</Main>
				</Section>
				<ProgramSearch
					items={programs}
					isLoading={programsLoading}
					hasMore={!!nextPageUrl}
					loadMore={this.loadMore}
					inputRef={el => this.scroll = el}

					searchText={searchText}
					filters={filters}
					unselectedFilters={unselectedFilter || !!searchText.length}
					handleFilter={this.onFilter}
					familyMembers={familyMembers}

					onItemDropOutside={this.handleOutsideDrop}
					onSearch={this.handleSearch}
					onTypeChange={this.handleTypeChange}
				/>
			</App>
		);
	}
}

export default withDnDContext(FamilyApp);