import React from 'react'
import PropTypes from 'prop-types'

import LazyLoad from '../lazyload'

import appConfig from 'config'
import './responsiveImage.css'

const BASE_URL = appConfig.media.star;
const LAZYLOAD_OFFSET_VERTICAL = 300;

const ResponsiveImage = ({
	id = null,
	programId = null,
	sizes = null,
	format = "16x9",
	extension,
}) => {
	if (!id && !programId) {
		return null;
	}

	let { src, srcSet } = getSources(id, programId, format, extension);
	// HACK?
	// URLs look different in Azure blobstorage so we need to swap the format & image id
	// If we use a CDN or somehow end up with a different URL than cometstorage.z6.web.core.windows.net, we should move this to appConfig
	// if (BASE_URL.includes("core.windows.net")) {
	// 	src = modifyUrlsForBlobStorage(src, id, extension);
	// 	srcSet = modifyUrlsForBlobStorage(srcSet, id, extension);
	// }
	return (
		<LazyLoad offsetVertical={LAZYLOAD_OFFSET_VERTICAL} debounce={false}>
			<img
				src={src}
				srcSet={srcSet}
				sizes={sizes}
				alt=""
				className="c6-image-responsive"
				decoding="async"
			/>
		</LazyLoad>
	);
};

ResponsiveImage.propTypes = {
	id: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]),
	programId: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]),
	sizes: PropTypes.string,
}
export default ResponsiveImage;

// HELPERS
function getSources(id, programId, format, extension) {
	extension = extension && extension.toLowerCase() || "jpg";
	let urlStart = getURL(id, programId, "Star_None_");
	switch(format) {
		case "freeheight":
			return {
				src: `${urlStart}300x.${extension}`,
				srcSet: `${urlStart}200x.${extension} 200w, ${urlStart}300x.${extension} 300w, ${urlStart}400x.${extension} 400w, ${urlStart}600x.${extension} 600w`,
			}
		case "fit":
			return {
				src: `${urlStart}1200x1200.${extension}`,
				srcSet: `${urlStart}1200x1200.${extension} 1200w, ${urlStart}2400x2400.${extension} 2400w`,
			}
		case "16x9":
			urlStart = getURL(id, programId, "Comet_16x9_");
			const TEMP_URL_START = programId ? `${BASE_URL}program/${programId}/Main/` : `${BASE_URL}${id}/`;

			return {
				src: `${TEMP_URL_START}22.${extension}`,
				srcSet: `${TEMP_URL_START}23.${extension} 200w, ${TEMP_URL_START}22.${extension} 400w, ${urlStart}800x450.${extension} 800w, ${urlStart}1600x900.${extension} 1600w`,
			}

		default:
			console.error("ResponsiveImage.jsx: No formatKey provided for image: " + (id || programId));
	}
}

function getURL(id, programId, format) {
	return programId ? `${BASE_URL}program/${programId}/Main/${format}` : `${BASE_URL}${id}/${format}`;
}

function modifyUrlsForBlobStorage(urls, id, extension) {
	extension = extension && extension.toLowerCase() || "jpg";
	const formatRegex = new RegExp(`\/([^\/]*)\.${extension}`);
	return urls.split(",").map(url => {
		const format = url.match(formatRegex)[1];
		return url.replace(format, id).replace(id, format);
	});
}