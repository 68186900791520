import React from 'react'

import { Filter, Left, Right } from '../../../components/filter'
import Switch from '../../../components/ui/controls/switch'
import Search from '../../../components/ui/controls/search'
import Button from '../../../components/ui/controls/button'
import CircularProgress from '@mui/material/CircularProgress'

import { getStatus, getPublishFilters, getSearchPayload } from '../shared/utils'
import ServicePicker from './servicePicker'
import Actions from '../actions'

export default ({
	searchText,
	filters,
	unselectedFilters,
	handleFilter,
	services = [],
	serviceId,
	isLoading,
}) => {
	const service = serviceId && services.find(s => s.id == serviceId);

	const disableShowFilter = false; //filters.filter.status && filters.filter.status === "new"; //filters.filter.status.startsWith("queued")

	return (
		<Filter>
			<Left>
				<h1>
					{service?.displayName ?? service?.name}
				</h1>
				<Search
					onChange={handleFilter.bind(this, "search")}
					placeholder="Search"
					searchText={searchText}
					title='The search will find packages based on original title or local title. You can also limit your search to specific seasons or episodes (for example "S1" or "E1")'
				/>
				<div>
					<Switch
						name="filter"
						title=""
						unselected={unselectedFilters || disableShowFilter}
						onChange={handleFilter.bind(this, "filter")}
						states={getPublishFilters()}
						currentState={filters.filter.filter}
						disabled={disableShowFilter}
					/>
					<Switch
						name="status"
						title="Status"
						unselected={unselectedFilters}
						onChange={handleFilter.bind(this, "filter")}
						states={getStatus()}
						currentState={filters.filter.status}
					/>
				</div>
				<ServicePicker services={services} />
			</Left>
			<Right>
				{isLoading && <span><CircularProgress size={25} thickness={3.6} /></span>}
				{!isLoading && (
					<Button
						title="Export"
						type="file-excel"
						hoverTitle="Feature not yet available"
						disabled={false}
						onClick={() => {
							Actions.ExportExcel(getSearchPayload({ filters }, serviceId), service.displayName)
						}}
					/>
				)}
			</Right>
		</Filter>
	);
}