import { browserHistory } from 'browserHistory'
import upperFirst from 'lodash/upperFirst'

import * as apis from '../../apis/'

import * as Alert from '../../core/services/alert'
import { handleRequestFailed } from '../../core/services/errorhandling'
import alt from '../../core/services/alt'

class Actions {
	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}

	// API
	fetchItem(id, api, entity, transformer, commands = {}) {
		return (dispatch) => {
			dispatch();

			const command = commands && commands.fetchItem || getCommand("fetch", api, entity);
			command({id})
				.then(response => {
					if(typeof(transformer) === "function") {
						response = transformer(response);
					}
					this.modelUpdated(response);
				}, this.requestFailed);
		};
	}

	saveItem({location, formData, patchData, saveAsCopy = false, ...rest}) {
		return (dispatch) => {
			dispatch();
			if(patchData) {
				this.updateItem({
					...location.query,
					itemId: formData.id,
					body: patchData,
					returnTo: location.state?.returnTo,
					wideModal: location.state?.wideModal,
					targetStore: location.state?.targetStore,
					...rest,
				}, "patch");
			}
			else if(formData.id && !saveAsCopy) {
				this.updateItem({
					...location.query,
					itemId: formData.id,
					body: formData,
					returnTo: location.state?.returnTo,
					wideModal: location.state?.wideModal,
					targetStore: location.state?.targetStore,
					...rest,
				});
			}
			else {
				this.createItem({
					...location.query,
					payload: formData,
					returnTo: location.state?.returnTo,
					wideModal: location.state?.wideModal,
					targetStore: location.state?.targetStore,
					saveAsCopy,
					...rest,
				});
			}
		}
	}

	updateItem({
		itemId: id,
		body,
		returnTo,
		api,
		routes,
		params = {},
		entity,
		queryString = "",
		transformer,
		wideModal,
		targetStore,
		commands = {},
		...rest
	}, type = "update") {
		return (dispatch) => {
			dispatch();

			const command = commands && commands.updateItem || getCommand(type, api, entity);
			command({id}, body)
				.then(response => {
					// Update editor (apply loadPayloadTransform if needed)
					if (typeof(transformer) === "function") {
						this.modelUpdated(transformer(response));
					}
					else {
						this.modelUpdated(response);
					}

					// Also refresh the item in the list since we can get a new ID from updating items
					this.modelSaved({
						targetStore,
						model: response,
						body,
						originalId: id,
						entity,
						...rest,
					});

					// Update route, since we might have gotten a new id after updating the item
					const route = {
						pathname: getPathname(routes, params, response.id),
						search: queryString,
						state: {
							modal: true,
							wideModal,
							targetStore,
							returnTo,
						}
					};

					browserHistory.replace(route);

					Alert.displayAlert("success", `Successfully saved ${entity}.`);

				}, this.requestFailed);
		}
	}

	createItem({
		payload,
		returnTo,
		api,
		routes,
		params = {},
		entity,
		queryString = "",
		transformer,
		wideModal,
		targetStore,
		saveAsCopy,
		commands = {},
		getCreateAlertAction,
		...rest
	}) {
		return (dispatch) => {
			dispatch(); // we dispatch an event here so we can have "loading" state.

			// HACK: Can we find a better way to handle duplicating an item or is this it?
			const type = saveAsCopy ? "copy" : "create";
			const command = commands && commands.createItem || getCommand(type, api, entity);

			command(payload)
				.then(response => {
					// Update editor (apply loadPayloadTransform if needed)
					if (typeof(transformer) === "function") {
						this.modelUpdated(transformer(response));
					}
					else {
						this.modelUpdated(response);
					}

					// Also refresh/add the item in the list since we can get a new ID from updating items
					this.modelSaved({
						targetStore,
						model: response,
						originalId: response.id,
						entity,
						...rest,
					});

					// Update route, since we've switched to edit mode after creating the item
					const route = {
						pathname: getPathname(routes, params, response.id),
						search: queryString,
						state: {
							modal: true,
							returnTo,
							wideModal,
							targetStore,
						}
					};

					browserHistory.replace(route);

					const createAlertAction = getCreateAlertAction ? getCreateAlertAction({ ...rest, model: response, params }) : null;
					Alert.displayAlert("success", `Successfully created ${entity}.`, null, createAlertAction);

				}, this.requestFailed);
		}
	}

	modelChanged(model, changeFieldId) { return { model, changeFieldId }; }
	mergeModel(newModel) { return newModel; }
	modelUpdated(model) { return model; }
	modelSaved(payload) { return payload; }
	setDirty() { return true; }

	unmount() { return true; }
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, api, entity, multiple = false) {
	let cmdPart = command;

	if(typeof(entity) === "object") {
		const extra = multiple ? "s" : "";

		if(entity.parentEntity) {
			cmdPart += upperFirst(entity.parentEntity) + upperFirst(entity.entity) + extra;
		}
		else {
			cmdPart += upperFirst(entity.entity) + extra;
		}
	}
	else {
		cmdPart += upperFirst(entity);
	}

	if(typeof(apis[api][cmdPart]) !== "function") {
		console.error(`components/editor/actions.js > getCommand: Make sure ${cmdPart} exists in the api: ${api}.js`);
		return false;
	}

	return apis[api][cmdPart];
}

// TODO!!!!: This is quickly becoming hacky
// Will make sure we have an edit instead of a create as the last part of the path
// as well as replacing the item id and version id with real values.
function getPathname(routes, params, id) {
	return routes.reduce((completeRoute = "", routePart) => (
		routePart.path
			? completeRoute + "/" + routePart.path
				.replace("createcopy", "edit")
				.replace("create", `${id}/edit`)
				.replace("(:type)/", "")
				.replace(/\(:([^\/\)]+)\)/gm, (match, key) => "/" + params[key]) // replace (:param) with params[param]
				.replace(/\(\/:([^\/\)]+)\)/gm, (match, key) => "/" + params[key]) // replace (/:param) with params[param]
				.replace(/:([^\/]+)/gm, (match, key) => params[key]) // replace :param with params[param]
			: completeRoute
	), "").replace(/\/{2,}/gi, "/");
}