import React from 'react'

import Button from '../../../components/ui/controls/button'

import {
    getOriginalFieldsFromTranslateModel,
    getSourceLanguageFromTranslateModel,
    getTargetLanguageFromTranslateModel,
    getTranslateFieldsFromTranslateModel,
} from './translateUtils'
import { translateTexts } from '../../../apis/metadata'
import { displayAlert } from '../../../core/services/alert'
import EditorActions from '../../../components/editor/actions'

import './translationAPIController.css'

const TranslationAPIController = ({
    formContext,
    onChange,
    readonly,
}) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const translate = React.useCallback(
        async (e) => {
            try {
                e.preventDefault();
                const originalFields = getOriginalFieldsFromTranslateModel(formContext.model);
                const translateFields = getTranslateFieldsFromTranslateModel(formContext.model);

                const targetLanguage = getTargetLanguageFromTranslateModel(formContext.model);
                const sourceLanguage = getSourceLanguageFromTranslateModel(formContext.model);

                // Only translate fields that have a value in the original version (left) and don't have a value in the target version (right)
                const fieldsToTranslateWithAPI = originalFields.filter(({ key, value }) => {
                    return typeof value === "string"
                        && value.length > 0
                        && !translateFields.find(tf => tf.key === key && tf.value?.length > 0);
                });
                if (!fieldsToTranslateWithAPI.length) {
                    displayAlert("info", "All texts are already translated.");
                    return;
                }

                // API expects an array of strings
                const textsToTranslate = fieldsToTranslateWithAPI.map(f => f.value);
                setIsLoading(true);
                const translations = await translateTexts({
                    textsToTranslate,
                    targetLanguage,
                    sourceLanguage,
                });

                // Update fields in the target version (right)
                const fieldsToUpdate = fieldsToTranslateWithAPI.map((field, i) => {
                    return {
                        key: `${field.key}-1`,
                        value: translations.items[i],
                    };
                });

                onChange({ fieldsToUpdate });
                setIsLoading(false);
                EditorActions.setDirty();
            } catch (e) {
                setIsLoading(false);
                displayAlert("error", "Something went wrong when translating texts. Please contact support@junefirst.tv if the problem persists.");
                console.error(e);
            }
        },
        [formContext.model, onChange]
    );

    return (
        <div>
            <Button
                noBackground
                title="Translate"
                hoverTitle="Fill empty fields with AI-translated text."
                type="translate"
                className="translate"
                onClick={translate}
                disabled={isLoading || readonly}
            />
        </div>
    );
}

export default TranslationAPIController;