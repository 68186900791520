import { browserHistory } from 'browserHistory'
import appConfig from 'config'

import alt from '../../core/services/alt'
import * as APIS from '../../apis/'

import * as Alert from '../../core/services/alert'

import { isAssetEditorType, getAssetOrContainerId } from './shared/utils'


const DEFAULT_ALBUM_CREATION_AUDIENCE = "Private";

const API = "star"

class Actions {
    create({ id, pathname, returnTo, query }) {

		const route = {
			pathname,
			state: {
				modal: true,
                returnTo,
			},
			query,
		};

		browserHistory.push(route);

		return route;
    }

    edit({ subType, type, reference, id }, sourceProps, location, params, itemProps, e, list) {
        let entity = "container";
        if (isAssetEditorType(type, subType)) {
            entity = "asset";
        }

		const route = {
			pathname: `/star/${entity}/${reference || id}/edit`,
			// search,
			state: {
				modal: true,
                returnTo: `${location.pathname}${location.search}`,
				list,
			}
		};

		browserHistory.push(route);

		return route;
    }
 
    select(item)                        { return { item } }
    unselect(item)                      { return { item } }
    toggleSelect(item)                  { return { item } }
    toggleMultipleSelect(payload)       { return payload }
    selectInStore(item, selectedStore)                  { return { item, selectedStore } }
    unselectInStore(item, selectedStore)                { return { item, selectedStore } }
    toggleSelectInStore(item, selectedStore)            { return { item, selectedStore } }

    toggleShowSelected(selectedIsVisible, location, e) {

        if(selectedIsVisible) {
            // We need to go back instead of doing a normal navigation to allow for
	        // react-router-scroll to restore the scroll position of the library list.
			e.preventDefault();
			browserHistory.goBack();
        }
        else {
            const route = {
                pathname: "/star/search/selected",
                state: {
                    returnTo: `${location.pathname}${location.search}`,
                }
            }
            browserHistory.push(route);
        }

        return true;
    }


    clearSelectedInStore(selectedStore) { return selectedStore }
    clearSelected() { return true }

    /* **************** Albums **************** */
    openAlbum(album, location) {
        const route = {
			pathname: `/star/albums/${album.id}`,
			// search,
			state: {
                returnTo: `${location.pathname}${location.search}`,
			}
		};

		browserHistory.push(route);

		return route;
    }
    openDialog(type, state) {
        const route = {
			pathname: `/star/albums/${type}`,
			// search,
			state: {
				modal: true,
				...state,
                // returnTo,
			}
		};

		browserHistory.push(route);

		return route;
    }
    addSelectedToAlbum(album, selectedItems, location) {
        return (dispatch) => {
            dispatch(album);

            const { id: albumId } = album;
            const assetIds = selectedItems.filter(i => i.type !== "Container").map(i => getAssetOrContainerId(i));
            const containerIds = selectedItems.filter(i => i.type === "Container").map(i => getAssetOrContainerId(i));

            const payload = {
                albumId,
                assetIds,
                containerIds,
            }

            APIS[API].addAssetsToAlbum(albumId, payload)
                .then(response => {
                    this.addSelectedToAlbumDone(response || album, payload, location);
                }, this.requestFailed);
        }
	}
    addSelectedToAlbumDone(album, { assetIds, containerIds }, location) {

        // Show success alert
        const numberOfAssets = assetIds.length;
        const assetText = numberOfAssets ? `${numberOfAssets} individual asset${numberOfAssets > 1 ? "s" : ""}` : "";
        const numberOfContainers = containerIds.length;
        const containerText = numberOfContainers ? `assets from ${numberOfContainers} container${numberOfContainers > 1 ? "s" : ""}` : "";
        const alertText = `Successfully added ${assetText}${numberOfAssets > 0 && numberOfContainers > 0 ? " and " : " "}${containerText} to album "${album.displayName || album.name}".`;
        const alertAction = { title: "Take me there!", onClick: () => browserHistory.push({ pathname: `/star/albums/${album.id}` }) };

		// Close dialog
		if (location.state && location.state.navigateToAlbumOnAdd) {
			Alert.displayAlert("success", alertText);
			browserHistory.push({ pathname: `/star/albums/${album.id}` });
		} else if (location.state && location.state.returnTo) {
			Alert.displayAlert("success", alertText, null, alertAction);
            const [pathname, search] = location.state.returnTo.split("?") || ["/star"];
            const route = {
                pathname,
                search: search ? `?${search}`: undefined,
            }

            browserHistory.push(route);
        } else if (location.state && location.state.modal) {
			Alert.displayAlert("success", alertText, null, alertAction);
			browserHistory.goBack();
		}

        return album;
    }
    createAlbum(displayName) {
        return (dispatch) => {
            dispatch(displayName);

            APIS[API].createAlbum({ displayName, name: displayName, audience: DEFAULT_ALBUM_CREATION_AUDIENCE })
                .then(response => {
                    this.createAlbumDone(response);
                }, this.requestFailed);
        }
    }
    createAlbumDone(album) {
        Alert.displayAlert("success", `Successfully created album "${album.displayName || album.name}".`);
        return album;
    }
    createAlbumAndAddAssets(displayName, selectedItems, location) {
        return (dispatch) => {
            dispatch(displayName);

            APIS[API].createAlbum({ displayName, name: displayName, audience: DEFAULT_ALBUM_CREATION_AUDIENCE })
                .then(response => {
                    this.addSelectedToAlbum(response, selectedItems, location);
                }, this.requestFailed);
        }
    }
    removeAssetsFromAlbum(albumId, payload) {
		return (dispatch) => {
			dispatch(albumId);

			APIS[API].deleteAssetsFromAlbum(albumId, payload)
				.then(model => {
                    this.itemLoaded({ entity: "album", model });
				}, this.requestFailed);
		};
    }

    updateAlbum(albumId, payload) {
        return dispatch => {
            dispatch();

            APIS[API].updateAlbum(albumId, payload)
                .then(model => {
                    this.itemLoaded({ entity: "album", model });
                }, this.requestFailed);
        }
    }

    fetchSharedAlbum(albumGuid, password) {
        return dispatch => {
            const entity = "album";
            dispatch(entity);

            APIS[API].fetchSharedAlbum(albumGuid, { password })
                .then(response => {
                    this.itemLoaded({ entity, model: response });
                }, error => {
                    if (error.exceptionType.includes("Unauthorized")) {
                        this.itemLoaded({ entity, model: { authError: error.exceptionMessage }});
                    } else {
                        // this.requestFailed({ error });
                        this.requestFailed(error);
                        this.itemLoaded({ entity, model: {} });
                    }
                });
        };
    }

    downloadFromSharedAlbum(albumGuid, password, selectedAssets) {
        return dispatch => {
            dispatch();

            const assetIds = selectedAssets.map(a => a.id).join(",");
            document.location.href = `${appConfig.api.star}albums/shared/${albumGuid}/download?password=${password}&assetIds=${assetIds}`;
            // this.clearSelected();
        }
    }

    /* Album sharing */
	shareAlbum(album, password, expires, recipients, type) {
		return dispatch => {
            dispatch(recipients);

            const albumPayload = {
                password,
                expires,
                type,
                status: "Shared",
			};

			const sharePayload = {
                recipients,
            };

            // First update the album parts
            APIS[API].updateAlbum(album.id, albumPayload)
                .then(model => {
                    // Then update the sharing recipients
                    APIS[API].shareAlbum(album.id, sharePayload)
                        .then(model => {
                            this.itemLoaded({ entity: "album", model });
                            Alert.displayAlert("success", `Album was successfully shared until ${expires}`);
                        }, this.requestFailed);
                }, this.requestFailed);
		};
    }

    stopAlbumSharing(id) {
        return dispatch => {
            dispatch();

            APIS[API].updateAlbum(id, { status: "Undefined" })
                .then(model => {
                    this.itemLoaded({ entity: "album", model });
                }, this.requestFailed);
        };
    }

    /* **************** Items **************** */
    fetchItems(entity, payload, targetStore = null) {
        return (dispatch) => {
            const requestTime = Date.now();
            const multiple = true;
            const command = getCommand("fetch", entity, multiple);
            const store = targetStore || getStore(entity, multiple);

            dispatch({ payload, store, requestTime });

            APIS[API][command](payload)
                .then(response => {
                    const { pageIndex, numberOfItems, items, links } = response;
                    const appendToExistingItems = pageIndex > 0;

                    const nextPageUrl = getNextPageUrl(links);
                    this.itemsUpdated({
                        store,
                        items,
                        appendToExistingItems,
                        numberOfItems,
                        nextPageUrl,
                        requestTime,
                    });

                }, this.requestFailed);

        };
    }
    pageItems(entity, url, targetStore = null) {
        return (dispatch) => {
            const requestTime = Date.now();
            const multiple = true;
            const store = targetStore || getStore(entity, multiple);

            dispatch({ entity, url, store });

            APIS[API].fetchUrl(url)
                .then(response => {
                    const { pageIndex, numberOfItems, items, links } = response;
                    const appendToExistingItems = pageIndex > 0;

                    const nextPageUrl = getNextPageUrl(links);
                    this.itemsUpdated({
                        store,
                        items,
                        appendToExistingItems,
                        numberOfItems,
                        nextPageUrl,
                        requestTime,
                    });

                }, this.requestFailed);
        };
    }
    itemsUpdated(payload) {
        return payload;
    }
    clearSearch() {
        return true;
    }

    /* **************** Item **************** */
	fetchItem(entity, payload) {
		return (dispatch) => {
			dispatch(entity);

			const command = getCommand("fetch", entity);
			APIS[API][command](payload)
				.then(model => {
					this.itemLoaded({entity, model});
				}, this.requestFailed);
		};
    }

    itemLoaded(payload) { return payload; }

	updateItem(entity, data, payload, type = "update", targetStore) {
		return (dispatch) => {
			dispatch();

			const command = getCommand(type, entity);
			APIS[API][command](data, payload)
				.then(model => {
					this.itemUpdated({ entity, model, originalId: data.id, targetStore });
				}, this.requestFailed);
		}
    }
    itemUpdated(payload) { return payload; }

    removeItem(entity, data, targetStore) {
		return (dispatch) => {
			dispatch({ entity, id: data.id, targetStore });

            const command = getCommand("delete", entity);
			APIS[API][command](data)
				.then(() => {
					this.itemRemoved({ entity, id: data.id, targetStore });
				}, (error, request) => {
					// TODO!: Keep item index when rollbacking
					this.rollbackRemoveItem({ entity, id: data.id, targetStore });
                    // this.requestFailed({ error, request });
                    this.requestFailed(error);
				});
		};
    }

    itemRemoved(payload) { return payload; }
    itemRemovedFromContainer(payload) { return payload; }

    rollbackRemoveItem(payload) { return payload};

    trashItems(items, store = "searchItems", isContainerRoute) {
        return (dispatch) => {
            const containers = items.filter(i => i.type === "Container");
            const assets = items.filter(i => i.type !== "Container");
            dispatch({ containers, assets });

            const requests = [];
            items.forEach(item => {
                const id = getAssetOrContainerId(item);
                const command = item.type === "Container" ? "trashContainer" : "trashAsset";
                const request = new Promise((resolve, reject) => {
                    APIS[API][command](id)
                        .then(response => {
                            this.itemRemoved({ id: item.id, targetStore: store });
                            this.itemRemoved({ id: item.id, targetStore: "selected" });
                            if (isContainerRoute) {
                                this.itemRemovedFromContainer({ id: item.id });
                            }
                            resolve({ item });
                        }, error => {
                            resolve({ item, error });
                        });
                });
                requests.push(request);
            });

            if (requests.length) {
                Promise.all(requests).then(reqs => {
                    const successful = reqs.filter(r => !r.error);
                    const failed = reqs.filter(r => r.error);
                    if (!failed.length) {
                        const alertText = successful.length > 1
                            ? `All ${successful.length} items were successfully trashed.`
                            : "1 item was successfully trashed";
                        Alert.displayAlert("success", alertText);
                    } else if (successful.length && failed.length) {
                        Alert.displayAlert("warning", `${failed.length} item${failed.length > 1 ? "s" : ""} could not be trashed. Please expand the details for more information.`, getItemErrorMessage(reqs));
                    } else if (!successful.length) {
                        Alert.displayAlert("error", `No items could be trashed. Please expand the details for more information.`, getItemErrorMessage(reqs));
                    }
                });
            }
        };
    }

    untrashItems(items) {
        return (dispatch) => {
            const containers = items.filter(i => i.type === "Container");
            const assets = items.filter(i => i.type !== "Container");
            dispatch({ containers, assets });

            const requests = [];
            items.forEach(item => {
                const id = getAssetOrContainerId(item);
                const command = item.type === "Container" ? "untrashContainer" : "untrashAsset";
                const request = new Promise((resolve, reject) => {
                    APIS[API][command](id)
                        .then(response => {
                            this.itemRemoved({ id: item.id, targetStore: "trashed" });
                            this.itemRemoved({ id: item.id, targetStore: "selectedTrashed" });
                            resolve({ item });
                        }, error => {
                            resolve({ item, error });
                        });
                });
                requests.push(request);
            });

            if (requests.length) {
                Promise.all(requests).then(reqs => {
                    const successful = reqs.filter(r => !r.error);
                    const failed = reqs.filter(r => r.error);
                    if (!failed.length) {
                        Alert.displayAlert("success", `${successful.length > 1 ? "All " : ""}${successful.length} item${successful.length > 1 ? "s" : ""} were successfully untrashed`);
                    } else if (successful.length && failed.length) {
                        Alert.displayAlert("warning", `${failed.length} item${successful.length ? "s" : ""} could not be untrashed. Please expand the details for more information.`, getItemErrorMessage(reqs));
                    } else if (!successful.length) {
                        Alert.displayAlert("error", `No items could be untrashed. Please expand the details for more information.`, getItemErrorMessage(reqs));
                    }
                });
            }
        };
    }

    deleteItems(items, store = "trashed") {
        return (dispatch) => {
            const containers = items.filter(i => i.type === "Container");
            const assets = items.filter(i => i.type !== "Container");
            dispatch({ containers, assets });

            const requests = [];
            [{ items: containers, command: "deleteContainers"}, { items: assets, command: "deleteAssets"}].forEach(({ items, command }) => {
                if (items.length) {
                    const ids = items.map(i => getAssetOrContainerId(i));
                    const request = new Promise((resolve, reject) => {
                        APIS[API][command](ids)
                            .then(response => {
                                items.forEach(i => {
                                    this.itemRemoved({ id: i.id, targetStore: store });
                                    this.itemRemoved({ id: i.id, targetStore: "selectedTrashed" });
                                })
                                resolve({ });
                            }, error => {
                                resolve({ error });
                            });
                    });
                    requests.push(request);
                }
            });

            if (requests.length) {
                Promise.all(requests).then(reqs => {
                    const successful = reqs.filter(r => !r.error);
                    const failed = reqs.filter(r => r.error);
                    if (!failed.length) {
                        Alert.displayAlert("success", `All items were successfully deleted`);
                    } else if (successful.length && failed.length) {
                        Alert.displayAlert("warning", `Some items could not be deleted. Please expand the details for more information.`, getItemErrorMessage(reqs));
                    } else if (!successful.length) {
                        Alert.displayAlert("error", `No items could be deleted. Please expand the details for more information.`, getItemErrorMessage(reqs));
                    }
                });
            }
        };
    }

    /* ================ */
	/*  RECENT ACTIVITY */
	/* ================ */
    // fetchRecentActivity() {
    //     return dispatch => {
    //         dispatch();

    //         APIS[API].fetchContainers({ orderBy: "newAssets", pageSize: 16 })
    //             .then(response => {
    //                 this.recentActivityLoaded(response.items);
    //             }, error => this.requestFailed(error));
    //     }
    // }

    // recentActivityLoaded(items) { return items }

    /* ================ */
	/*    WORKLISTS     */
	/* ================ */
    // fetchWorklists() {
    //     return dispatch => {
    //         dispatch();

    //         const mockResponse = [
    //             {
    //                 title: "Missing 16:9 crops",
    //                 count: 132,
    //             },
    //             {
    //                 title: "Missing 4:7 crops",
    //                 count: 77
    //             },
    //             {
    //                 title: "Missing AppleTV",
    //                 count: 79
    //             }
    //         ];

    //         this.worklistsLoaded(mockResponse);
    //     }
    // }

    // worklistsLoaded(items) { return items }

    /* ================= */
    /* RECENT CONTAINERS */
    /* ================= */
    // fetchRecentContainers() {
    //     return dispatch => {
    //         dispatch();

    //         APIS[API].fetchContainers()
    //             .then(response => {
    //                 this.recentContainersLoaded(response.items);
    //             }, error => this.requestFailed(error));
    //     }
    // }

    // recentContainersLoaded(items) { return items }

    /* ================ */
	/*      GENERAL     */
	/* ================ */
    unmount() { return true }

    persist(model, store, prepend) {
        return { store, model, prepend };
	}
	
	requestFailed(error) {
		Alert.displayAlert("error", error.exceptionMessage);
		return true;
	}
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, entity, multiple = false) {
	if(typeof(entity) === "object") {
		const extra = multiple ? "s" : "";

		if(entity.parentEntity) {
			return command + entity.parentEntity.substr(0, 1).toUpperCase() + entity.parentEntity.substr(1) + entity.entity.substr(0, 1).toUpperCase() + entity.entity.substr(1) + extra;
		}
		entity = `${entity.entity}${extra}`;
	}
	return command + entity.substr(0, 1).toUpperCase() + entity.substr(1);
}

function getStore(entity, multiple = false) {
	if(typeof(entity) === "object") {
		const extra = multiple ? "s" : "";
		return `${entity.entity}${extra}`;
	}
	return entity;
}

function getNextPageUrl(links = []) {
	const nextLink = links.find(l => l.rel === "next" || l.Rel === "next");

	return nextLink
		? nextLink.href || nextLink.Href
		: null;
}

function getItemErrorMessage(requests) {
    return requests.filter(r => r.error).map(r => {
        if (r.item) {
            return `• ${r.item.type === "Container" ? "Container" : "Asset"} "${r.item.displayName}": ${r.error.exceptionMessage}`;
        }
        return r.error.exceptionMessage;
    });
}

// EXAMPLE: Recursive fetching
// async function fetch(all, start) {
//     const newData = await getData(start, 1000);
//     all = all.concat(newData);
//     return (newData.length === 1000) ? await fetch(all, all.length) : all;
// }