import debounce from 'lodash/debounce'
import { MENU } from '../../../components/actions'

import  Actions from './actions'

export default {
	targetComponent: "SortableItem",
	actions: [
		{
			title: "Remove",
			icon: "remove_circle",
			method: (data, sourceProps) => {
				Actions.removeFamilyMember({
					id: sourceProps.family.id
				}, {
					programId: data.id,
				});
			},
			type: MENU,
			confirm: true,
			dangerous: true,
		}
	]
};