import React 				 from 'react'
import { Route, IndexRoute } from 'react-router'

import Module        		 from '../app'

import Packages        	 	 from './packages/app'

export default class HorizonModule {
	static routes() {
		return (
			<Route path="horizon" component={Module} module="horizon">
				<Route path="packages">
					<IndexRoute component={Packages} />
					<Route path=":serviceId" component={Packages} />
				</Route>
			</Route>
		);
	}
}