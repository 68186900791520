export function getItems(data) {
	// For react-query's useInfiniteQuery, we receive data as an array of results
	// So we need to turn that into a "flat" list of items
	if (Array.isArray(data?.pages)) {
		return data.pages
			// .filter(d => d.items)
			.reduce((acc, cur, index) => {
				const current = Array.isArray(cur)
					? cur
					: cur.items;
				return [...acc, ...current];
			}, []);
	}

	if (data?.items) return data.items;

	return [];
}