import React from 'react'
import { Filter, Left, Right } from '../../components/filter'
import Search from '../../components/ui/controls/search'

export default ({
	title,
	showSearch,
	searchText,
	filters,
	unselectedFilters,
	handleFilter,
}) => (
	<Filter>
		<Left>
			<h1>{title}</h1>
		</Left>
		<Right>
			{showSearch && (
				<Search
					onChange={handleFilter.bind(this, "search")}
					placeholder="Search"
					searchText={searchText}
				/>
			)}
		</Right>
	</Filter>
);