import React from 'react'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll'

import { getRouteName } from '../../../components/comet'
import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'

import Header from './header'
import List from './list'

import Actions from '../actions'
import Store from '../store'

import { getSearchPayload } from '../shared/utils'
import '../library/app.css'

const MODULE = "metadata"
const DATASTORE = "families"
// const ENTITY = "family"
const TEXT_HEADING = "Program families"
const TEXT_CREATE = "Create family"
const ICON_CREATE = "add"

export default class FamiliesApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.debouncedFilter = debounce(this.handleFilter, 500);
		this.loadMore = this.loadMore.bind(this);

		this.scroll = null;

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
		};
	}

	// TODO: Needs to update families when we come from Program Library app. Use a joint store for family & families folders?
	componentDidMount() {
		Store.listen(this.onChange);
		if (!this.state.list[DATASTORE].items.length) {
			const searchPayload = {
				programType: 5, // 5 = Container
				orderBy: "title",
			};
			Actions.fetchItems(DATASTORE, searchPayload);
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	onFilter(type, e) {
		if (type === "search") {
			e.persist();
			this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
	}

	handleFilter(type, event) {
		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions[type](value, name);
		Actions.fetchItems(DATASTORE, getSearchPayload(this.state));

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	loadMore() {
		if (this.state.list[DATASTORE].nextPageUrl) {
			Actions.pageItems(DATASTORE, this.state.list[DATASTORE].nextPageUrl);
		}
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	handleCreateNew() {
		Actions.create({ pathname: "/metadata/families/create" });
	}

	render() {
		const { isLoading, searchText: componentSearchText, filters, unselectedFilter, list } = this.state;
		const families = list[DATASTORE];

		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

		return (
			<App
				name={`c6-${MODULE}-programs c6-${MODULE}-${DATASTORE} c6-list`}
				layout="grid"
				isLoading={isLoading}>
				<Header
					title={TEXT_HEADING}
					searchText={searchText}
					filters={filters}
					unselectedFilters={unselectedFilter || !!searchText.length}
					handleFilter={this.onFilter}
					textCreate={TEXT_CREATE}
					iconCreate={ICON_CREATE}
					onCreateNew={this.handleCreateNew}
				/>
				<ScrollContainer scrollKey={`c6-${MODULE}-${DATASTORE}`} shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el} padding={true}>
						<List
							items={families.items}
							isLoading={this.state.isLoading}
							searchText={searchText}
							filters={filters}
							hasMore={!!families.nextPageUrl}
							loadMore={this.loadMore}
						/>
					</Main>
				</ScrollContainer>
				<Info>
					{getInfo(this.props.location, families)}
				</Info>
			</App>
		);
	}
}

// HELPERS
function getInfo(location, list) {
	const items = list.items.length ? `- displaying ${list.items.length} of ${list.numberOfItems}` : "";

	return `${getRouteName(location)} ${items}`;
}