import { CONTENT, INLINE, CLICK_OPT, CLICK_SHIFT } from '../../../components/actions'
import Actions from '../actions'

export default {
	targetComponent: "Content",
	actions: [
		{
			type: CONTENT,
			title: "Expand item",
			method: Actions.togglePackageExpand,
		},
	]
};

// HELPERS