import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { browserHistory } from 'browserHistory'
import { DropTarget, DragSource } from 'react-dnd'

import ItemTypes from '../../../core/itemTypes'
import { CONTAINER_TYPE_OPTIONS } from '../shared/utils'
import StarConstants from '../../../core/constants/star'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'

import Spinner from '../../../components/spinner'
import EditableText from '../../../components/ui/controls/editableText'
import Button from '../../../components/ui/controls/button'

import ContainerActions from './actions'

import './containerNavigation.css'

const EPISODE = "EpisodeProgram";
const SEASON = "SeasonProgram";
const SERIES = "SeriesProgram";
const FOLDER = "Folder";

export default class ContainerNavigationItem extends React.Component {

	static propTypes = {
		items: PropTypes.array,
		loadingContainerId: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.bool,
		]),
		selectedContainerId: PropTypes.number,
		onDrop: PropTypes.func,
		hasEditAccess: PropTypes.bool,
		initiallyOpen: PropTypes.bool,
		alwaysShowEpisodesAndSeasons: PropTypes.bool,
	}

	render() {
		const { items } = this.props;
		const root = items.length ? items[0] : null;
		const children = items.filter(i => i.parentId === root.id);

		return root ? this.renderMenuItem(root, children) : null;
	}

	renderMenuItem(item, childItems = []) {
		const {
			onDrop,
			selectedContainerId,
			items,
			loadingContainerId,
			hasEditAccess,
			onContainerClick,
			initiallyOpen = true,
			alwaysShowEpisodesAndSeasons = false,
			hideAssetCountOnSelectedContainer = false,
		} = this.props;

		const containsEpisodesOrSeasons = item.type === SEASON && childItems.some(ci => ci.type === EPISODE)
			|| item.type === SERIES && childItems.some(ci => ci.id !== -1 && ci.type === SEASON);

		const children = getSortedContainers(childItems).map(ci => {
			const itemChildren = items.filter(i => i.parentId === ci.id);
			return this.renderMenuItem(ci, itemChildren);
		});

		return (
			<ContainerNavigationMenuItem
				key={`${item.id}[${item.assets ? item.assets.length : 0}]`}
				item={item}
				children={children}
				loadingContainerId={loadingContainerId}
				selectedContainerId={selectedContainerId}
				onDrop={onDrop}
				hasEditAccess={hasEditAccess}
				onContainerClick={onContainerClick}
				initiallyOpen={initiallyOpen}
				containsEpisodesOrSeasons={containsEpisodesOrSeasons}
				alwaysShowEpisodesAndSeasons={alwaysShowEpisodesAndSeasons}
				hideAssetCountOnSelectedContainer={hideAssetCountOnSelectedContainer}
			/>
		);
	}
}

// Droppable menu item
const itemTarget = {
	canDrop({ item, selectedContainerId }, monitor) {
		const draggedItem = monitor.getItem();
		// If we drag a container, we should allow dropping it on the currently selected container
		// If we drag assets, we should not allow dropping it on the currently selected container since the assets are already there
		return draggedItem.draggedItemType === "container" || selectedContainerId !== item.id;
	},

	drop(props, monitor) {
		const { onDrop, item } = props;

		if(!onDrop) {
			console.error("No onDrop prop provided to ContainerNavigationItem props: %o", props);
		}

		// If we are dropping on a nested item all parents will also get the drop event (in the order
		// dropItem > parentDropItem > grandparentDropItem etc.) so we check if we have already
		// handled the drop on the actual drop target before executing the onDrop method
		const alreadyHandledByAnotherDropTarget = monitor.didDrop();

		if(!alreadyHandledByAnotherDropTarget) {
			onDrop({
				droppedItem: monitor.getItem(),
				dropTarget: item,
			});
		}
	},
};

const itemSource = {
	beginDrag(props, monitor) {
		const { item } = props;
		return {
			id: item.id,
			displayName: item.displayName,
			draggedItemType: "container",
		};
	},

	canDrag(props, monitor) {
		return props.hasEditAccess;
	},
};

@withRouter
@DragSource(ItemTypes.ITEM, itemSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging()
}))
@DropTarget(ItemTypes.ITEM, itemTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver({ shallow: true }),
  	canDrop: monitor.canDrop()
}))
// Class wrapper so that we can use decorators
class ContainerNavigationMenuItem extends React.Component {
	render() {
		return <_ContainerNavigationMenuItem {...this.props} />
	}
}

const _ContainerNavigationMenuItem = (props) => {
	const loadSeasonsOrEpisodes = (e) => {
		e.stopPropagation();
		setShowEpisodesOrSeasons(true);
	};

	const handleCreateContainer = (item, suggestedDisplayName, displayName) => {
		const { location, routes } = props;
		const { parentId, type } = item;
		ContainerActions.createContainer({ displayName: displayName || suggestedDisplayName, parentId, type }, location.state, routes, item);
	};

	const {
		item,
		children,
		connectDropTarget,
		connectDragSource,
		loadingContainerId,
		selectedContainerId,
		hasEditAccess = false,
		onContainerClick,
		initiallyOpen = true,
		containsEpisodesOrSeasons = false,
		alwaysShowEpisodesAndSeasons,
		hideAssetCountOnSelectedContainer = false,
	} = props;

	const [showEpisodesOrSeasons, setShowEpisodesOrSeasons] = React.useState(alwaysShowEpisodesAndSeasons || false);
	const [open, setOpen] = React.useState(initiallyOpen);

	const { id, displayName, assetCount, type, _level } = item;
	const isSelected = selectedContainerId === id;
	const classNames = getFolderClassNames(props, showEpisodesOrSeasons);

	let primaryText = displayName;
	if (id === -1) {
		return (
			<div className={classNames}>
				{getNewFolder(item, handleCreateContainer)}
			</div>
		);
	}

	const onClick = () => {
		if (onContainerClick) {
			onContainerClick(item, children);
		} else {
			handleOnClick(item, children);
		}
	};

	let leftIcon = null;
	if (children?.length && open) {
		leftIcon = <span className="large-icon icon-expand_less"></span>;
	} else if (children?.length) {
		leftIcon = <span className="large-icon icon-expand_more"></span>;
	}

	let rightIcon = null;
	if (loadingContainerId === id) {
		rightIcon = <Spinner loading />;
	} else if (isSelected && hasEditAccess) {
		rightIcon = <CreateButton item={item} primaryText={primaryText} />;
	}

	const secondaryText = !(hideAssetCountOnSelectedContainer && isSelected) && getSecondaryText(assetCount);

	const hideContainerType = (containsEpisodesOrSeasons && !showEpisodesOrSeasons);
	const nestedItems = hideContainerType
		? [
			<ListItemButton
				key="loadSeasonsOrEpisodes"
				onClick={loadSeasonsOrEpisodes}
				className="folder load-children"
			>
				{`<< Click to load ${type === SEASON ? "episodes" : "seasons"} >>`}
			</ListItemButton>,
			...children
		]
		: children;

	const component = (
		<div className={classNames}>
			<ListItemButton
				className="button"
				title={primaryText}
				onClick={onClick}
				sx={{ height: "52px", paddingLeft: 0, paddingRight: 0 }}
			>
				<ListItemIcon
					sx={{ color: "inherit" }}
					onClick={() => setOpen(state => !state)}
					className="left-icon"
				>
					{leftIcon}
				</ListItemIcon>
				<ListItemText
					primary={getDisplayText(primaryText, _level, hasEditAccess)}
					secondary={secondaryText}
					className="text"
				/>
				<ListItemIcon className="right-icon">{rightIcon}</ListItemIcon>
			</ListItemButton>
		</div>
	);

	const dndComponent = connectDragSource(connectDropTarget(component));

	const collapseComponent = (
		<Collapse in={containsNewFolder(children) ? true : open} timeout={0}>
			<List component="div" disablePadding>
				{nestedItems}
			</List>
		</Collapse>
	);

	return (
		<>
			{dndComponent}
			{collapseComponent}
		</>
	);
};

ContainerNavigationMenuItem.propTypes = _ContainerNavigationMenuItem.propTypes = {
	onDrop: PropTypes.func.isRequired,
	item: PropTypes.object.isRequired,
	children: PropTypes.array,
	loadingContainerId: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.bool,
	]),
	selectedContainerId: PropTypes.number,
	hasEditAccess: PropTypes.bool,
	initiallyOpen: PropTypes.bool,
	alwaysShowEpisodesAndSeasons: PropTypes.bool,

	// DnD
	connectDropTarget: PropTypes.func,
	connectDragSource: PropTypes.func,
	id: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired
	]),
};

// HELPERS
const getDisplayText = (text, level, hasEditAccess) => {
	const MAX_CHARS = hasEditAccess ? 31 : 33;
	const removeChars = level ? level * 3 : 0;
	const ellipsis = "...";

	if (text && text.length >= MAX_CHARS - removeChars + ellipsis.length) {
		const maxLength = MAX_CHARS - removeChars;
		const firstPart = Math.round(maxLength / 2) - 1;
		const secondPart = maxLength - firstPart;
		return `${text.slice(0, firstPart)}${ellipsis}${text.slice(-secondPart)}`;
	}

	return text;
}

const getSecondaryText = (assetCount) => {
	return assetCount >= 1 ? assetCount : null;
	// if (assetCount === 1) {
	// 	return "1 asset";
	// }
	// if (assetCount >= 2) {
	// 	return `${assetCount} assets`;
	// }

	// return null;
}

const containsNewFolder = children => {
	return children.some(c => c.props.item?.id === -1);
}

const handleOnClick = (item, children) => {

	if (item.id > 0) {
		browserHistory.replace({
			pathname: `/star/container/${item.id}/edit`,
			state: {
				modal: true,
				fetchChildren: children.length === 0,
			},
		});
	}
}

const handleCreateFolder = (item, type, e) => {
	// e.preventDefault();
	e.stopPropagation();
	ContainerActions.createChildContainer(item, type);
}

const getFolderClassNames = ({ item, selectedContainerId, isOver, canDrop }, showEpisodesOrSeasons) => {

	const typeName = item.id !== -1 && StarConstants.CONTAINER_TYPES.find(ct => ct.id === item.containerTypeId)?.name.toLowerCase().replace(" ", "-") || "";
	let classNames = `folder type-${typeName} ${showEpisodesOrSeasons ? "show-episodes-seasons" : ""}`;
	if(item.id === -1) {
		classNames += " create";
	}
	if(selectedContainerId === item.id) {
		classNames += " sel";
	}
	if(isOver && canDrop) {
		classNames += " over";
	}
	else if(isOver && !canDrop) {
		classNames += " nodrop";
	}

	return classNames;
}

const getSortedContainers = (containers) => {
	return [...containers]
		// Sort by name, ignore case
		.sort((cA, cB) => (cA.displayName || cA.name || "").localeCompare(cB.displayName || cB.name || "", undefined, { sensitivity: "base", numeric: true }))
		// We want seasons or episodes listed before "regular" folders
		.sort((cA, cB) => cA.containerTypeId - cB.containerTypeId);
};

const CreateButton = ({ item, primaryText }) => {
	const buttonRef = React.useRef();
	const [menuOpen, setMenuOpen] = React.useState(false);
	const options = CONTAINER_TYPE_OPTIONS.filter(option => option.parentContainerTypeId === null || option.parentContainerTypeId === item.containerTypeId);
	if (options.length > 1) {
		return (
			<>
				<Button
					buttonRef={buttonRef}
					onClick={() => setMenuOpen(true)}
					type="c6-menuitem icon-add_circle_outline"
					noBackground
				/>
				<Menu
					anchorEl={buttonRef.current}
					open={menuOpen}
					onClose={() => setMenuOpen(false)}
				>
					{options.map(o => <MenuItem key={o.key} onClick={e => handleCreateFolder(item, o.name, e)}>{o.text}</MenuItem>)}
				</Menu>
			</>
		);
	}
	
	return (
		<Button
			hoverTitle={`Create new folder inside ${primaryText}...`}
			type="c6-menuitem icon-add_circle_outline"
			noBackground
			onClick={e => handleCreateFolder(item, options[0].name, e)}
		/>
	);
}

const getNewFolder = (item, handleCreateContainer) => {
	const { suggestedDisplayName, type } = item;
	
	if (type === SEASON) {
		return <SeasonEpisodeCreator item={item} />
	}

	const defaultName = type !== FOLDER ? suggestedDisplayName : null;
	const styles = {
		underlineStyle:	{ borderColor: "var(--accent-color)"},
			inputStyle: { color: "var(--accent-color)", fontSize: ".85rem !important" },
			 hintStyle: { color: "var(--accent-color)" },
	};

	return (
		<EditableText
			onChange={value => handleCreateContainer(item, defaultName, value)}
			onCancelChange={() => ContainerActions.removeChildContainer(item)}
			hintText="Please type a folder name"
			isEditing={true}
			disableEdit={false}
			useApproveButton
			commitOnEnter
			{...styles}
		>{defaultName}</EditableText>
	);
}

class SeasonEpisodeCreator extends React.Component {
	constructor(props) {
		super(props);
		this.state = { numberOfSeasons: 1, numberOfEpisodes: 1 };
		this.episodesField = null;
	}

	onApprove = e => {
		const { parentId, highestNumberInSiblings } = this.props.item;
		ContainerActions.createSeasonsWithEpisodes(parentId, { ...this.state, seasonNumberStart: highestNumberInSiblings + 1 });
		ContainerActions.removeChildContainer(this.props.item);
	}

	onCancel = e => ContainerActions.removeChildContainer(this.props.item);

	render() {
		const textFieldProps = {
			style: {
				width: "40px",
				textAlign: "center",
				margin: "0 10px",
			},
			InputProps: {
				style: {
					color: "var(--accent-color)",
				},
				sx: {
					":before": { borderBottomColor: "var(--accent-color) !important" },
					":after": { borderBottomColor: "var(--accent-color) !important" },
				},
			}
		};

		return (
			<div className="season-episode-creator">
				<TextField
					variant="standard"
					name="numberOfSeasons"
					value={this.state.numberOfSeasons}
					onChange={(e) => this.setState({ numberOfSeasons: e.target.value })}
					onKeyDown={e => {
						if (e.keyCode === 9 && this.episodesField) { // 9 = TAB
							this.episodesField.focus();
						} else if (e.keyCode === 13) { // 13 = ENTER
							this.onApprove(e);
						}
					}}
					min={1}
					max={12}
					type="number"
					{...textFieldProps}
				/>
				season(s)&nbsp;&nbsp;×
				<TextField
					variant="standard"
					ref={ref => this.episodesField = ref}
					name="numberOfEpisodes"
					value={this.state.numberOfEpisodes}
					onChange={(e) => this.setState({ numberOfEpisodes: e.target.value })}
					onKeyDown={e => {
						if (e.keyCode === 13) { // 13 = ENTER
							this.onApprove(e)
						}
					}}
					min={0}
					max={52}
					type="number"
					{...textFieldProps}
				/>
				episode(s)
				<Button type="approve" onClick={this.onApprove} noBackground />
				<Button type="cancel" onClick={this.onCancel} noBackground />
			</div>
		);
	}
}