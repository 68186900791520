import { hasAccessToPath } from '../../../core/services/auth'
import { MENU, CONTENT } from '../../../components/actions'
import Actions from '../actions'

const DISCONNECT_SERIES_ACCESS = [{ module: "metadata", app: "library", access: "editor" }];
const EPISODE_TEMPLATE_ACCESS  = [{ module: "metadata", app: "library", access: "editor" }];

import Store from '../store'

export default {
	targetComponent: "Content",
	actions: [
		{
			type: CONTENT,
			method: (data) => {
				data.onSelect();
			},
			hidden: (data, sourceProps) => {
				const isSeason = data?.type === "Season";
				const isSeasonList = !!sourceProps.seasonId;
				const isSelectedSeason = sourceProps?.seasonId === data?.id;
				const hasOnSelectFn = !!data?.onSelect;
				return !hasOnSelectFn || !isSeason || !isSeasonList || isSelectedSeason;
			},
		},
		{
			title: "Disconnect this series",
			confirmationText: "Disconnect this series from the season?",
			confirm: true,
			dangerous: true,
			icon: "cancel",
			method: (data, sourceProps, location, params) => {
				let seasonId = params && params.seasonId || getSeasonId(sourceProps); // HACK: Either we have seasonId from the URL or we try to get the season from our items prop
				const catalogue = sourceProps && sourceProps.catalogue;

				// EVEN MORE HACKY: If we got here through a series, we won't have access to the latest sourceProps,
				// thereby getting -2 as the seasonId we'll try to fetch it from the store instead
				if(seasonId < 0) {
					seasonId = Store.getState().list.seriesSeasonPrograms.filters._selectedSeasonId || false;
				}

				if(seasonId) {
					Actions.disconnectSeasonFromSeries(parseInt(seasonId), catalogue);
				}
			},
			type: MENU,
			hidden: (data, sourceProps, location, params) => {
				return data.type !== "Series" || !getSeasonId(sourceProps) || !hasAccessToPath(DISCONNECT_SERIES_ACCESS);
			},
		},
		{
			title: "Set as metadata template (all other episodes will get metadata from this episode)",
			confirmationText: "Are you sure you want to make this episode into a metadata template?",
			confirm: true,
			icon: "flip_to_back",
			method: Actions.setAsDefaultTemplate,
			type: MENU,
			hidden: (data, sourceProps, location, params) => {
				const mod = sourceProps.masterTemplateModifications.get(data.parentId);

				return (
					data.type !== "Episode" ||
					mod && mod.event === "added" ||
					data.masterId ||
					data.isMasterTemplate ||
					hasEpisodeTemplate(sourceProps) ||
					!hasAccessToPath(EPISODE_TEMPLATE_ACCESS)
				);
			}
		},
		{
			title: "Convert to ordinary episode (all other episodes will use their own metadata)",
			confirmationText: "Are you sure you want to turn this metadata template into an ordinary episode?",
			confirm: true,
			icon: "flip_to_front",
			method: Actions.releaseDefaultTemplate,
			type: MENU,
			hidden: (data, sourceProps, location, params) =>
				data.type !== "Episode" ||
				!data.isMasterTemplate ||
				!hasAccessToPath(EPISODE_TEMPLATE_ACCESS)
		},
		{
			title: "Use metadata template (this episode will get metadata from the metadata template)",
			icon: "filter_1",
			method: (data, sourceProps, location, params) => {
				const template = getEpisodeTemplate(sourceProps);
				if(template.length) {
					Actions.useEpisodeTemplate(data.id, template[0].id);
				}
				else {
					console.error("Could not find the episode template in the list! (Should not happen, really.)")
				}
			},
			type: MENU,
			hidden: (data, sourceProps, location, params) =>
				data.type !== "Episode" ||
				!hasEpisodeTemplate(sourceProps) ||
				data.masterId ||
				data.isMasterTemplate ||
				!hasAccessToPath(EPISODE_TEMPLATE_ACCESS)
		},
		{
			title: "Stop using metadata template (this episode will use it's own metadata)",
			icon: "filter_none",
			method: (data, sourceProps, location, params) => {
				Actions.ignoreEpisodeTemplate(data.id);
			},
			type: MENU,
			hidden: (data, sourceProps, location, params, itemProps) =>
				data.type !== "Episode" ||
				!data.masterId ||
				data.isMasterTemplate ||
				!hasAccessToPath(EPISODE_TEMPLATE_ACCESS)
		},
	]
};

// HELPERS
function getSeasonId(sourceProps) {
	const season = sourceProps && sourceProps.items && sourceProps.items.filter(i => i.type === "Season");
	return season.length ? season[0].id : null;
}

function getEpisodeTemplate(sourceProps) {
	return sourceProps && sourceProps.items && sourceProps.items.filter(i => i.isMasterTemplate);
}

function hasEpisodeTemplate(sourceProps) {
	const template = getEpisodeTemplate(sourceProps);
	return !!template.length;
}