import React from 'react'
import { Link } from 'react-router'

import { Filter, Left } from '../../../../components/filter'
import Switch from '../../../../components/ui/controls/switch'

export default ({
	title,
	searchText,
	filters,
	unselectedFilters,
	handleFilter,
}) => (
	<Filter>
		<Left>
			<h1>
				<Link to={"/metadata/tags"}>Tags</Link> {title}
			</h1>
			<div>
				<Switch
					name="filter"
					onChange={handleFilter.bind(this, "filter")}
					states={getFilterStates()}
					currentState={filters.filter.filter}
				/>
			</div>
		</Left>
	</Filter>
);

function getFilterStates() {
	return [
		{ key: "", text: "All", description: "" },
		{ key: "HasActiveRights", text: "Current", description: "" },
	];
}