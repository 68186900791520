import { useQuery } from '@tanstack/react-query'
import { fetchCometProgram } from '../../../apis/content-external'

const useHorizonProgramMetadata = (programId: string | number) => {
    return useQuery(
        ["horizon", "programmetadata", { id: programId }],
        // TODO: remove hard coded api key (temp fix)
        () => fetchCometProgram(programId, "774c0137-622d-4beb-ba2d-49028d7a412c"),
        { enabled: programId !== null && programId !== undefined }
    );
};

export default useHorizonProgramMetadata;