import React, { Component } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import Main from '../../../components/ui/main'
import Spinner from '../../../components/spinner'
import Pick from '../../../components/ui/controls/pick'
import { Filter, Left, Right } from '../../../components/filter'
import EditableText from '../../../components/ui/controls/editableText'

import Category from './category'
import ContainerActions from './actions'

import appConfig from 'config'

import './presentation.css'


export default class PresentationSection extends Component {

	state = {
		itemTypes: [],
		showPastVersions: false,
	}

	removeAsset = (asset) => {
		const { container } = this.props;
		const index = asset.index?.id;
		ContainerActions.removeAssetFromContainer(container.id, [asset.id], asset.category, index);
	}

	updateAssetPublishFrom = (asset, publishFrom) => {
		const { container } = this.props;
		const index = asset.index?.id;
		ContainerActions.updateAssetPublishFrom(container.id, asset.id, asset.categoryId, index, publishFrom);
	}

	addEmbargo = (asset) => {
		ContainerActions.addEmbargo(asset);
	}

	renderCategories = () => {
		const {
			types,
			container,
			isEditor,
			isLoading,
			openAssetDialog,
		} = this.props;

		const type = types.length && container.containerTypeId
			? types.find(t => t.id === container.containerTypeId)
			: { name: "Unknown", categories: [] };

		const categories = filterCategories(type.categories, this.state.itemTypes);

		if (!categories.length && isLoading) {
			return <Spinner loading={true} />
		}

		const categoriesWithAssets = mapAssetsToCategories(container.assets, categories);

		return (
			<div className="categories-wrapper">
				{categoriesWithAssets.map(category => (
					<Category
						key={`${category.name}-${category.id}`}
						container={container}
						category={category}
						disabled={container.approved || !isEditor}
						onAssetClick={openAssetDialog}
						onRemoveAsset={this.removeAsset}
						onEmbargoChange={this.updateAssetPublishFrom}
						onAddEmbargo={this.addEmbargo}
						showPastVersions={this.state.showPastVersions}
					/>
				))}
			</div>
		);
	}

	onItemTypesFilter = (e, value) => {
		const index = this.state.itemTypes.findIndex(t => t === value);
		const newList = [...this.state.itemTypes];
		if (index >= 0) {
			newList.splice(index, 1);
		} else {
			newList.push(value);
		}
		this.setState({ itemTypes: newList });
	}

	render() {
		const {
			container,
			isEditor,
			isLoading,
			handleTitleChange,
			starRole,
		} = this.props;

		const enableShowPastVersions = appConfig.features.metadataEnableEmbargo;

		return (
			<div className={`presentation-section ${container.approved || !isEditor ? "readonly" : ""}`}>
				<div className="section-header">
					<EditableText
						className="container-title"
						onChange={handleTitleChange}
						disableEdit={isLoading || !!container.approved || starRole !== "editor"}
						commitOnEnter
					>
						{isLoading ? "Loading..." : container.displayName || "Missing"}
					</EditableText>
					<Filter>
						<Left>
							<Pick
								states={ITEM_TYPES}
								currentState={this.state.itemTypes}
								onChange={this.onItemTypesFilter}
								name="presentation-filter"
							/>
						</Left>
						{enableShowPastVersions && (
							<Right>
								<FormControlLabel
									control={
										<Checkbox
											checked={this.state.showPastVersions}
											onChange={(_, checked) => this.setState({ showPastVersions: checked })}
										/>
									}
									label="Show past versions"
									className="past-versions-toggle"
								/>
							</Right>
						)}
					</Filter>
				</div>
				<Main modal={true}>
					{this.renderCategories()}
				</Main>
			</div>
		);
	}
}


function mapAssetsToCategories(assets, categories) {
	return categories.map(category => ({
		...category,
		assets: assets.filter(asset => asset.categoryId === category.id),
	}));
}

function filterCategories(categories, itemTypesFilter) {
	if (!itemTypesFilter.length) {
		return categories;
	}

	const isTrailerOrClip = category => category.assetType === "Trailer" || category.assetType === "Clip";
	const isAppleTV = category => category.assetType === "Image" && category.name.includes("Apple");
	const isPress = category => category.name === "Press";
	const isProgramImages = category => !isTrailerOrClip(category) && !isAppleTV(category) && !isPress(category);

	let filteredCategories = [];
	if (itemTypesFilter.includes("Image")) filteredCategories.push(...categories.filter(isProgramImages));
	if (itemTypesFilter.includes("Press")) filteredCategories.push(...categories.filter(isPress));
	if (itemTypesFilter.includes("AppleTV")) filteredCategories.push(...categories.filter(isAppleTV));
	if (itemTypesFilter.includes("Trailer,Clip")) filteredCategories.push(...categories.filter(isTrailerOrClip));
	return filteredCategories;
}

const ITEM_TYPES = [
	{ key: "Image", text: "Program images", description: "" },
	{ key: "Press", text: "Press", description: "" },
	{ key: "AppleTV", text: "Apple TV images", description: "" },
	{ key: "Trailer,Clip", text: "Trailers & Clips", description: "" },
];