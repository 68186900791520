import React from 'react'
import PropTypes from 'prop-types'

import styles from '../../../components/listItems/standard.css'
import Preview from '../../../components/assets/preview'
import { getMetadata } from '../shared/listItem'

export default class MemberContent extends React.Component {

	static propTypes = {
		title: PropTypes.string.isRequired,
		autoGenerated: PropTypes.bool,
	}

	render() {
		const {
			guid,
			title,
			type,
			isPartOfCurrentProgramFamily,
		} = this.props;

		const metadata = getMetadata(this.props);

		return (
			<div className="flex flex-dyn-1">
				{isPartOfCurrentProgramFamily && <div className="is-part-of-current-program-family icon-checkbox-marked-circle-outline"></div>}
				<Preview programId={guid} />
				<div className="c6-content">
					<h1>{title}</h1>
					<p>{type}{metadata?.length ? ". " : ""}{metadata}</p>
				</div>
			</div>
		);
	}
}