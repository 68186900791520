import { browserHistory } from 'browserHistory'

import alt from '../../../core/services/alt'

import * as TagsData from '../../../apis/tags'

import { handleRequestFailed } from '../../../core/services/errorhandling'

import Const from '../../../core/constants'

class Actions {

	/* **************** Actions **************** */
	edit({ id, pathname = `/metadata/tags/${id}/edit`, search, returnTo, wideModal, typeId, query }) {
		const route = {
			pathname,
			search,
			query,
			state: {
				modal: true,
				wideModal,
				typeId,
				returnTo,
			}
		};

		browserHistory.push(route)

		return route;
	}

	create({ pathname = "/metadata/tags/create", search, returnTo }) {

		const route = {
			pathname,
			search,
			state: {
				modal: true,
				returnTo,
			}
		};

		browserHistory.push(route);

		return route;
	}

	search(searchText) {
		return searchText;
	}

	filter(filterValue, filterName) {
		return (dispatch) => {
			dispatch({ [filterName]: filterValue });
		};
	}

	/* **************** Items **************** */
	fetchItems(entity, payload) {
		return (dispatch) => {
			const requestTime = Date.now();
			const multiple = true;
			const command = getCommand("fetch", entity, multiple);
			const store = getStore(entity, multiple);

			dispatch({ payload, store, requestTime });

			TagsData[command](payload)
				.then(response => {
					const { pageIndex, numberOfItems, items, links, pageSize } = response;
					const appendToExistingItems = pageIndex > 0;

					// const nextPageUrl = getNextPageUrl(links); // TODO!!!: Use when API is returning correct next links in https://3.basecamp.com/3091592/buckets/3735615/todos/623153686
					const nextPageUrl = getNextPageUrl(links, numberOfItems, pageIndex, pageSize);
					this.itemsUpdated(store, items, appendToExistingItems, numberOfItems, nextPageUrl);

				}, this.requestFailed);
		};
	}
	pageItems(entity, url) {
		return (dispatch) => {
			const multiple = true;
			const store = getStore(entity, multiple);

			dispatch({ entity, url, store });

			TagsData.fetchUrl(url)
				.then(response => {
					const { pageIndex, numberOfItems, items, links, pageSize } = response;
					const appendToExistingItems = pageIndex > 0;

					// const nextPageUrl = getNextPageUrl(links); // TODO!!!: Use when API is returning correct next links in https://3.basecamp.com/3091592/buckets/3735615/todos/623153686
					const nextPageUrl = getNextPageUrl(links, numberOfItems, pageIndex, pageSize);
					this.itemsUpdated(store, items, appendToExistingItems, numberOfItems, nextPageUrl);

				}, this.requestFailed);
		};
	}
	itemsUpdated(datastore, items, appendToExistingItems, numberOfItems, nextPageUrl) {
		return {
			datastore,
			items,
			appendToExistingItems,
			numberOfItems,
			nextPageUrl,
		};
	}

	/* **************** Item **************** */
	fetchItem(entity, payload) {
		return (dispatch) => {
			dispatch();

			const command = getCommand("fetch", entity);
			TagsData[command](payload)
				.then(model => {
					this.itemLoaded({ entity, model });
				}, this.requestFailed);
		};
	}
	itemLoaded(payload) { return payload; }

	updateItem(entity, data, payload, type = "update") {
		return (dispatch) => {
			dispatch();

			const command = getCommand(type, entity);
			TagsData[command](data, payload)
				.then(model => {
					this.itemUpdated({ datastore: `${entity}s`, model, originalId: data.id });
				}, this.requestFailed);
		}
	}

	enableItem(entity, data) {
		return (dispatch) => {
			dispatch(data.id);

			const command = getCommand("update", entity);
			TagsData[command](data, { enabled: true })
				.then(model => {
					this.itemUpdated({ datastore: `${entity}s`, model, originalId: data.id });
				}, this.requestFailed);
		};
	}
	disableItem(entity, data) {
		return (dispatch) => {
			dispatch(data.id);

			const command = getCommand("update", entity);
			TagsData[command](data, { enabled: false })
				.then(model => {
					this.itemUpdated({ datastore: `${entity}s`, model, originalId: data.id });
				}, this.requestFailed);
		};
	}
	itemUpdated(payload) { return payload; }

	removeItem(entity, data) {
		return (dispatch) => {
			dispatch({ entity, id: data.id });

			const command = getCommand("delete", entity);
			TagsData[command](data)
				.then(() => {
					this.itemRemoved({ entity, id: data.id });
				}, (error, request) => {
					// TODO!: Keep item index when rollbacking
					this.rollbackRemoveItem({ entity, id: data.id });
					this.requestFailed({ error, request });
				});
		};
	}
	itemRemoved(payload) { return payload; }
	rollbackRemoveItem(payload) { return payload };

	/* **************** Item entities **************** */
	addItemEntity(entities, payload) {
		return (dispatch) => {
			dispatch({ entities, payload });

			const command = getCommand("add", entities);
			const store = getStore(entities);

			TagsData[command](payload)
				.then(model => {
					this.itemLoaded({ entity: store, model });
				}, this.requestFailed);
		};
	}

	removeItemEntity(entities, payload) {
		return (dispatch) => {
			dispatch({ entities, payload });

			const command = getCommand("remove", entities);
			const store = getStore(entities);

			TagsData[command](payload)
				.then(model => {
					this.itemLoaded({ entity: store, model });
				}, this.requestFailed);
		};
	}

	setItemEntityProperty(entities, payload) {
		return (dispatch) => {
			dispatch({ entities, payload });

			const command = getCommand("update", entities);
			TagsData[command](payload)
				.then(model => {
					this.itemLoaded({ entity: entities.parentEntity, model });
				}, this.requestFailed);
		};
	}

	/* Enable / Disable */
	disableTag(tagId) {
		return dispatch => {
			dispatch();

			TagsData.disableTag(tagId)
				.then(response => {
					this.itemUpdated({ entity: "tag", model: response });
				}, this.requestFailed);
		};
	}

	enableTag(tagId) {
		return dispatch => {
			dispatch();

			TagsData.enableTag(tagId)
				.then(response => {
					this.itemUpdated({ entity: "tag", model: response });
				}, this.requestFailed);
		};
	}

	/* **************** Misc **************** */
	unmount() { return true; }

	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, entity, multiple = false) {
	if (typeof (entity) === "object") {
		const extra = multiple ? "s" : "";

		if (entity.parentEntity) {
			return command + entity.parentEntity.substr(0, 1).toUpperCase() + entity.parentEntity.substr(1) + entity.entity.substr(0, 1).toUpperCase() + entity.entity.substr(1) + extra;
		}
		entity = `${entity.entity}${extra}`;
	}
	return command + entity.substr(0, 1).toUpperCase() + entity.substr(1);
}

function getStore(entity, multiple = false) {
	if (typeof (entity) === "object") {
		const extra = multiple ? "s" : "";
		return `${entity.entity}${extra}`;
	}
	return entity;
}

function getNextPageUrl(links = [], numberOfItems, pageIndex, pageSize) {
	const nextLink = links.find(l => l.rel == "next");

	// // TODO! HACK: Compensate for buggy API in https://3.basecamp.com/3091592/buckets/3735615/todos/623153686
	// if (numberOfItems - ((pageIndex + 1) * pageSize) > 0) {
	// 	return nextLink && nextLink.href;
	// }
	// return null;

	return nextLink && nextLink.href;
}