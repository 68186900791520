import React from 'react'
import { useLocalStorage } from '../../../core/hooks/useStorage'
import { AllRegions } from '../../../core/constants'

export function addChannel(channelsToAdd, channels) {
	const channelKeys = getChannelKeys(channelsToAdd);

	let channelFilter = channels.length ? channels.split(",") : [];
	channelFilter = channelFilter.concat(channelKeys).filter((c, i, a) => i === a.indexOf(c)); // Merge and dedupes the channel arrays
	return channelFilter.join(",");
}

export function removeChannel(channelsToRemove, channels) {
	const channelKeys = getChannelKeys(channelsToRemove);

	let channelFilter = channels.length ? channels.split(",") : [];
	channelFilter = channelFilter.filter(c => !channelKeys.includes(c));
	return channelFilter.join(",");
}

export function getProgramMetadata(id, metadata) {
	return metadata.find(m => m.id === id);
}

export function getSwedishVersion(versions = []) {
	return versions.find(v => v.language === "sv") || {};
}

export function getChannelVersion(versions = [], language) {
	return versions.find(v => v.language === language) || {};
}

export function getSynopsisExternal(versions = [], contentType, reverseOrder = false, language = "sv") {
	const localVersion = versions.find(v => v.language === language);
	const synopsis = contentType !== "single" && localVersion[contentType] ? localVersion[contentType].synopsis : localVersion?.synopsis || null;

	if(!localVersion || !synopsis) {
		return null;
	}

	let { extended, long, medium, brief, short } = synopsis;
	// extended = program.type === "season" ? null : extended; // If we don't want to render the extended synopsis for seasons (like the internal schedule)

	return reverseOrder
		? <p key={language}>{ short || brief || medium || long || extended || <em>Program description missing.</em> }</p>
		: <p key={language}>{ extended || long || medium || brief || short || <em>Program description missing.</em> }</p>;
}

function getLanguageDisplayName(language) {
	return AllRegions.find(r => {
		const fixedLanguage = language === "nb" ? "no" : language; // Norwegian is "nb" (Norsk bokmål) instead of "no" in content API
		return r.language === fixedLanguage;
	})?.languageDisplayName ?? language;
}

export function useLanguage(program) {
	const languages = program?.versions?.map(v => ({ key: v.language, text: getLanguageDisplayName(v.language) })) ?? [];
	const [language, setLanguage] = useLocalStorage("external-schedule-program-language", languages[0]?.key);

	React.useEffect(
		() => {
			if (languages?.length && !languages.find(l => l.key === language)) {
				setLanguage(languages[0]?.key);
			}
		},
		[program, language]
	);

	return { languages, language, setLanguage };
}

export function getTitle(program, language) {
	if (!program) {
		return "";
	}

	const { versions, season, series, originalTitle } = program;
	const version = getChannelVersion(versions, language);

	if (program.type === "episode") {
		const seriesTitle = version.series?.title || series?.originalTitle || "Missing series title!";
		const seasonLabel = season && season.label ? ` - ${season.label}` : "";
		return `${seriesTitle}${seasonLabel}`;
	}

	return version.title || originalTitle || "Missing program title!";
}

// HELPERS
const getChannelKeys = channels => typeof(channels) === "object" ? channels.map(c => c.key) : [channels];