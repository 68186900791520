import { browserHistory } from 'browserHistory'

import alt from '../../../core/services/alt'
import * as StarData from '../../../apis/star'
import * as Alert from '../../../core/services/alert'
import { handleRequestFailed } from '../../../core/services/errorhandling'
import { GUID_REGEXP } from '../../../core/constants'

// HELPERS
const isGuid = str => (GUID_REGEXP.test(str));

class Actions {

	openAsset(data) {
		return data;
	}

	closeAsset() {
		return true;
	}

	/* Container */
	fetchContainer(id, reference, providerId, containerType) {
		return (dispatch) => {
			dispatch(id);

			if(id) {
				StarData.fetchContainer(id)
					.then((response) => {
						this.updateContainer(response);
					}, (error, api) => {
						this.requestFailed(error, api);
					});
			}
			else {
				const payload = {
					name: "Awaiting sync...",
					type: containerType,
				};

				if (isGuid(reference)) {
					payload.programGuid = reference;
					StarData.fetchContainerByGuid(reference)
						.then(response => this.handleContainerReferenceResponse(response, payload), this.handleContainerReferenceError); // TODO!!!!: We need a handleContainerReferenceError
				}
				else {
					payload.reference = reference;
					StarData.fetchContainerByReference(reference, providerId)
						.then(response => this.handleContainerReferenceResponse(response, payload), this.handleContainerReferenceError); // TODO!!!!: We need a handleContainerReferenceError
				}
			}
		};
	}

	handleContainerReferenceResponse(response, payload) {
		return (dispatch) => {
			dispatch();

			if (response) {
				this.updateContainer(response);
			} else {
				StarData.createContainer(payload).then((createResponse) => {
					this.updateContainer(createResponse);
				}, (error, api) => {
					this.requestFailed(error, api);
				});
			}
		};
	}

	createContainer(payload, locationState, routes, temporaryItem, fetchChildren = false) {
		return (dispatch) => {
			dispatch();

			StarData.createContainer(payload).then(response => {
				if(temporaryItem) {
					this.replaceChildContainer(temporaryItem.id, response);
				}

				this.updateContainer(response);

				// Update route, since we've switched to edit mode after creating the item
				const route = {
					pathname: getPathname(routes, response.id),
					state: {
						modal: locationState ? locationState.modal : undefined,
						returnTo: locationState ? locationState.returnTo : undefined,
						fetchChildren,
						// wideModal,
						// targetStore,
					}
				};

				browserHistory.replace(route);

			}, (error, api) => { this.requestFailed(error, api)});
		}
	}

	createSeasonsWithEpisodes(parentContainerId, payload) {
		return dispatch => {
			dispatch(parentContainerId);

			StarData.createSeasonsWithEpisodes(parentContainerId, payload)
				.then(response => {
					this.fetchChildContainers(parentContainerId);
					// this.addChildContainers(response.items);
				}, this.requestFailed);
		};
	}

	trashContainer(containerId) {
		return (dispatch) => {
			dispatch();

			StarData.trashContainer(containerId)
				.then(response => {
					this.removeChildContainer({ id: containerId });
					this.containerRemoved({ id: containerId });
				}, this.requestFailed);
		};
	}

	updateTitle(containerId, newTitle, container) {
		return (dispatch) => {
			dispatch();

			const payload = {
				...container,
				displayName: newTitle,
			};

			StarData.updateContainer(containerId, payload)
				.then((response) => {
					this.updateContainer(response);
					this.updateChildContainer(response);
				}, (error, api) => {
					this.requestFailed(error, api);
				});
		};
	}

	approveContainer(container, isApproved, targetStore) {
		return (dispatch) => {
			dispatch({ container, isApproved, targetStore });

			StarData.approveContainer(container.id, isApproved)
				.then((response) => {
					this.updateContainer(response);
				}, (error, api) => {
					this.requestFailed(error, api);
				});
		};
	}

	updateContainer(container) {
		return container;
	}

	uploadUpdate(file) {
		return file;
	}


	removeAssetFromContainer(containerId, assetIds, category, categoryIndex) {
		const payload = {
			assetIds,
			category,
			index: categoryIndex,
			containerId,
		};
		return (dispatch) => {
			dispatch();
			StarData.removeAssetsFromContainer(containerId, payload)
				.then((response) => {
					this.updateContainer(response);

					// Used to notify the metadata module of possible changes in the Main category
					if (category === 'Main') {
						this.mainUpdated(response);
					}

				}, (error, api) => {
					this.requestFailed(error, api);
				});
		};
	}

	addAssetsToContainer(updateType, containerId, assetIds, category, categoryIndex) {
		const index = categoryIndex || 0;
		const payload = {
			assetIds,
			category,
			index,
			containerId,
		};
		return (dispatch) => {
			dispatch();
			StarData.connectAssetsToContainer(containerId, payload)
				.then((response) => {
					if (updateType === 'parent') {
						this.updateContainer(response);

						// Used to notify the metadata module of possible changes in the Main category
						if (category === 'Main') {
							this.mainUpdated(response);
						}
					}
					else if (updateType === 'child') {
						this.fetchChildContainers(response.parentId);
						Alert.displayAlert('success', `Added asset${assetIds.length > 1 ? 's' : ''} to container (ID: ${containerId}).`);
					}
					else {
						Alert.displayAlert('error', 'No updateType defined in addAssetsToContainer');
					}
				}, (error, api) => {
					this.requestFailed(error, api);
				});
		};
	}

	// Used to notify the metadata module of possible changes in the Main category
	mainUpdated(response) {
		return response;
	}

	/* Drag and Drop */
	assetDrop(payload) 		{ return payload }
	clearAssetDrop() 		{ return true }
	containerDrop(payload) 	{ return payload }
	clearContainerDrop() 	{ return true }

	/* Move/Copy asset actions */
	moveAssetsToContainer(assetIds, targetContainer, sourceContainerId, copyAssets = true) {
		return (dispatch) => {
			dispatch({
				assetIds,
				targetContainer,
			});

			const payload = {
				assetIds,
			};

			StarData.connectAssetsToContainer(targetContainer.id, payload)
				.then(response => {
					this.updateChildContainer(response, targetContainer.id);

					if(!copyAssets) {
						this.removeAssetFromContainer2(sourceContainerId, payload);
					}
					// else {
					// 	this.clearSelected();
					// }

				}, (error, api) => {
					this.requestFailed(error, api);
				});
		};
	}

	removeAssetFromContainer2(sourceContainerId, payload) {
		return (dispatch) => {
			dispatch();
			StarData.removeAssetsFromContainer(sourceContainerId, payload)
				.then(response => {
					this.updateContainer(response);
					this.updateChildContainer(response);
					// this.clearSelected();

				}, (error, api) => {
					this.requestFailed(error, api);
				});
		};
	}

	removeAssetsFromMultipleContainers(assetsToRemove) {
		return (dispatch) => {
			dispatch();

			const requests = [];
            assetsToRemove.forEach(item => {
                const request = new Promise((resolve, reject) => {
                    StarData.removeAssetsFromContainer(item.fromContainerId, { assetIds: [item.id] })
                        .then(response => {
                            // this.itemRemoved({ id: item.id, targetStore: store });
                            // this.itemRemoved({ id: item.id, targetStore: "selected" });
                            resolve({ item });
                        }, error => {
                            resolve({ item, error });
                        });
                });
                requests.push(request);
            });

            if (requests.length) {
                Promise.all(requests).then(reqs => {
                    const successful = reqs.filter(r => !r.error);
                    const failed = reqs.filter(r => r.error);
                    if (!failed.length) {
                        // const alertText = successful.length > 1
                        //     ? `All ${successful.length} assets were successfully removed from their previous location.`
                        //     : "1 item was successfully removed from its previous location";
                        // Alert.displayAlert("success", alertText);
                    } else if (successful.length && failed.length) {
                        Alert.displayAlert("warning", `${failed.length} asset${failed.length > 1 ? "s" : ""} could not be removed from their previous location. Please expand the details for more information.`, getItemErrorMessage(reqs));
                    } else if (!successful.length) {
                        Alert.displayAlert("error", `No assets could be removed from their previous location. Please expand the details for more information.`, getItemErrorMessage(reqs));
                    }
                });
            }
		}
	}

	/* Child containers */
	fetchChildContainers(parentId, orderBy = null) {
		return (dispatch) => {
			dispatch(parentId);

			const payload = {
				parent: parentId,
				excludeAssets: true,
				orderBy,
				pageSize: -1, // pageSize -1 will make our APIS return all items 
			};

			StarData.fetchContainers(payload)
				.then((response) => {
					this.addChildContainers(response.items);
				}, (error, api) => {
					this.requestFailed(error, api);
				});
		};
	}

	moveContainersToChildContainers(containerId, containers, onRemove) {
		return (dispatch) => {
			dispatch(containerId);

			const payload = {
				parentId: containerId,
			};

			const requests = [];
			containers.forEach(c => {
				const request = new Promise((resolve, reject) => {
					StarData.updateContainer(c.reference || c.id, payload)
						.then(response => {
							this.removeChildContainer(c);
							this.addChildContainers([ response ]);
							if (onRemove) {
								onRemove(response);
							}
							resolve({ });
						}, error => {
							resolve({ item: c, error });
						});
				});
				requests.push(request);
            });

            if (requests.length) {
                Promise.all(requests).then(reqs => {
                    const successful = reqs.filter(r => !r.error);
                    const failed = reqs.filter(r => r.error);
                    if (!failed.length) {
                        Alert.displayAlert("success", `All folders were successfully moved.`);
                    } else if (successful.length && failed.length) {
                        Alert.displayAlert("warning", `Some folders could not be moved. Please expand the details for more information.`, getItemErrorMessage(reqs));
                    } else if (!successful.length) {
                        Alert.displayAlert("error", `No folders could be moved. Please expand the details for more information.`, getItemErrorMessage(reqs));
                    }
                });
            }
		};
	}

	addChildContainers(childContainers) {
		return childContainers;
	}

	updateChildContainer(container) {
		return container;
	}

	replaceChildContainer(id, container) {
		return { id, container };
	}

	createChildContainer(container, type) {
		return { parentContainer: container, type };
	}

	removeChildContainer(container) {
		return container;
	}

	containerRemoved(container) {
		return container;
	}

	/* Container Types */
	fetchTypes() {
		return dispatch => {
			dispatch();
			StarData.fetchTypes()
				.then(response => {
					const types = response.items;
					this.typesLoaded(types);
				}, this.requestFailed);
		};
	}

	typesLoaded(types) {
		return types;
	}

	/* Helpers */
	requestFailed(error) {
		// handleRequestFailed(error);
		Alert.displayAlert("error", error.exceptionMessage);
		return true;
	}
}

export default alt.createActions(Actions);

// TODO!!!!: This is quickly becoming hacky
// Will make sure we have an edit instead of a create as the last part of the path
// as well as replacing the item id and version id with real values.
function getPathname(routes, id) {
	return routes.reduce((completeRoute = "", routePart) => (
		routePart.path
			? completeRoute + "/" + routePart.path
				.replace("(:id)", id)
				.replace(":id", id)
				.replace("create", `${id}/edit`)
			: ""
	));
}

function getItemErrorMessage(requests) {
	return requests.filter(r => r.error).map(r => {
		if (r.item) {
			return `• ${r.item.type === "Container" ? "Container" : "Asset"} ${r.item.displayName}: ${r.error.exceptionMessage}`;
		}
		return r.error.exceptionMessage;
	});
}