import cloneDeep from 'lodash/cloneDeep'
import alt from '../../core/services/alt'

import Actions from './actions'
import EditorActions from '../../components/editor/actions'

import { getSearchPayload } from './shared/utils'

import appConfig from 'config'

const filterStorageKey = "c6-horizon-store-filter";
const prevFilterStorageKey = "c6-horizon-store-filter-prev";

class HorizonStore {

	constructor() {
		
		this.excelLoading = false;
		
		this.list = {};
		this.initStore("services");

		this.init();

		const storedFilters = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(filterStorageKey)): null;
		this.filters = storedFilters ?? {
			searchText: "",
			filter: {
				filter: "current",
				status: "",
				deliveryStatus: "",
			},
			excludeStatus: "hidden,blocked",
			pageSize: 50,
		};
		this.filters.orderBy = getOrderBy(this.filters);

		const storedPrevFilter = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(prevFilterStorageKey)): null;
		this._prevFilter = storedPrevFilter ?? null;

		this.bindListeners({
			onRequestFailed: Actions.REQUEST_FAILED,

			// Filters
			onFilter: Actions.FILTER,
			onSearch: Actions.SEARCH,

			// Items & item
			onFetchItems: Actions.FETCH_ITEMS,
			onPageItems: Actions.PAGE_ITEMS,
			onItemsUpdated: Actions.ITEMS_UPDATED,
			onFetchItem: Actions.FETCH_ITEM,
			onItemLoaded: Actions.ITEM_LOADED,

			// Package operations
			onPackageAction: Actions.PACKAGE_ACTION,
			onPackageActionFinished: Actions.PACKAGE_ACTION_FINISHED,
			onToggleBatchOperations: Actions.TOGGLE_BATCH_OPERATIONS,
			onTogglePackageExpand: Actions.TOGGLE_PACKAGE_EXPAND,

			onExportExcel: Actions.EXPORT_EXCEL,
			onExportExcelFinish: Actions.EXCEL_FINISH,

			// Select
			onSelect: Actions.SELECT,
			onUnselect: Actions.UNSELECT,
			onToggleSelect: Actions.TOGGLE_SELECT,
			onToggleMultipleSelect: Actions.TOGGLE_MULTIPLE_SELECT,
			onClearSelected: Actions.CLEAR_SELECTED,
			onSelectAll: Actions.SELECT_ALL,

			onEditorSaved: EditorActions.MODEL_SAVED,

			onUnmount: Actions.UNMOUNT,
		});
	}

	init() {
		this.isLoading = false;
	
		this.initStore("packages");
		this.initStore("blockedPackages");
		this.initStore("deliveryList");
		this.initStore("selected");
		this.initStore("latePackages");

		this.actionLoading = false;
		this.isPerformingBatchOperations = false;
	}

	onRequestFailed() {
		this.isLoading = false;
	}

	/* Filters */
	onSearch({ searchText: text, targetStore }) {
		if (text === "") {
			this.filters.filter = this._prevFilter;
		} else {
			if (!this._prevFilter) {
				this._prevFilter = cloneDeep(this.filters.filter);
			}
			this.filters.filter = {};
			// this.filters.filter.orderBy = "title";
		}
		this.filters.searchText = text;
		this.filters.orderBy = getOrderBy(this.filters);
		this.list[targetStore || "packages"].items = [];

		this.saveFiltersToStorage();
	}

	onFilter(payload) {
		const filterParams = Object.keys(this.filters.filter).length
			? this.filters.filter
			: this._prevFilter;

		const { targetStore, ...filterPayload } = payload;

		this.filters.filter = {
			...filterParams,
			...filterPayload,
		};

		this.unselectedFilter = false;

		// Reset filters used by searchText
		this.searchText = ""; // TODO: Remove?
		this.filters.searchText = "";
		this.filters.orderBy = getOrderBy(this.filters);

		this._prevFilter = null;
		// this.isLoading = true;
		this.list[targetStore || "packages"].isLoading = true;
		this.list[targetStore || "packages"].items = [];

		this.saveFiltersToStorage();
	}


	/* Items */
	onFetchItems({ payload, store, requestTime }) {
		const l = this.list[store];
		l.isLoading = true;

		l.nextPageUrl = null;
		l.numberOfItems = 0;
		l.latestRequestTime = requestTime;
		l.latestSearchPayload = payload;
	}

	onPageItems({ entity, store }) {
		const l = this.list[store || entity];
		l.isLoading = true;
		l.nextPageUrl = null;
	}

	onItemsUpdated({ store, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
		const l = this.list[store];

		if (l.latestRequestTime > requestTime) {
			console.log("[%s] Ignoring result with %s items since there have been newer requests.", store, numberOfItems);
		} else {
			l.items = appendToExistingItems ? l.items.concat(items) : items;
			l.nextPageUrl = nextPageUrl;
			l.numberOfItems = numberOfItems;

			l.isLoading = false;
			this.errorMessage = null;
		}
	}

	/* Item */
	onFetchItem(entity) {
		this.item[entity] = {};
		this.item.isLoading = true;
	}

	onItemLoaded({ entity, model }) {
		this.item[entity] = model;
		this.item.isLoading = false;
	}

	// TODO: Remove datastore, we're using entities
	// TODO!: Keep item index when rollbacking
	onItemSaved({ datastore, entity, model, originalId, targetStore = null }) {
		if (datastore) {
			console.warn("DATASTORE PROPERTY DEPRECATED: %s. Please use ENTITY: %s instead.", datastore, entity);
		}
		const store = targetStore || `${entity}s`;

		// const hasUpdatedStore = this.persistToStore(store, model); // UPDATES OR ADDS
		const hasUpdatedStore = this.updateStore(store, model); // ONLY UPDATES

		// We might have several editor stores listening for the same Editor Component
		// action so we need to bail out if this regards another entity.
		if (!hasUpdatedStore) {
			return false;
		}

		this.isLoading = false;
	}


	/* Custom */
	onPackageAction() {
		this.actionLoading = true;
	}

	onPackageActionFinished({ items, serviceId, ...rest }) {
		const updatedItems = Array.isArray(items) ? items : [rest];
		updatedItems.forEach(item => {
			const index = this.list.packages.items.findIndex(p => p.id === item.id);
			if (index >= 0) {
				this.list.packages.items[index] = item;
			}
		});
		this.actionLoading = false;

		// If we were listing blocked packages and there are no more blocked packages after the action finished, (or same for delivery list)
		// clear the deliveryStatus filter and reload the list
		const deliveryStatus = this.filters.filter.deliveryStatus;
		const existsOtherPackagesWithSameDeliveryStatus = this.list.packages.items.some(item => {
			const itemWasUpdated = updatedItems.some(i => i.id === item.id);
			return item.deliveryStatus === deliveryStatus && !itemWasUpdated;
		});
		if (deliveryStatus?.length && !existsOtherPackagesWithSameDeliveryStatus) {
			this.onFilter({ deliveryStatus: "" });
			Actions.fetchItems.defer("packages", getSearchPayload({ filters: this.filters }, serviceId));
		}
	}

	onToggleBatchOperations() {
		this.isPerformingBatchOperations = !this.isPerformingBatchOperations;
		this.list.selected.items = [];
	}
	onSelect(item) {
		const { items } = this.list.selected;
		const index = items.findIndex(i => i.id === item.id);
		if( index < 0) {
			items.push(item);
		}

		this.list.selected.lastActionItemId = item.id;
	}
	onUnselect(item) {
		const { items } = this.list.selected;
		this.list.selected.items = items.filter(i => i.id !== item.id);

		this.list.selected.lastActionItemId = null;
	}
	onToggleSelect(item) {
		const { items } = this.list.selected;
		const index = items.findIndex(i => i.id === item.id);

		if (index >= 0) {
			this.onUnselect(item);
		}
		else {
			this.onSelect(item);
		}
	}
	onClearSelected() {
		this.list.selected.items = [];
		this.list.selected.lastActionItemId = null;
	}
	onToggleMultipleSelect({ item, storeName }) {
		const { items } = this.list[storeName];

		const start = Math.max(items.findIndex(i => i.id === this.list.selected.lastActionItemId), 0);
		const end = items.findIndex(i => i.id === item.id);
		const itemRange = items.slice(Math.min(start, end), Math.max(start, end) + 1);

		itemRange.forEach(i => this.onSelect(i));
	}
	onSelectAll(storeName) {
		const { items } = this.list[storeName];
		items.forEach(i => this.onSelect(i));
	}

	onTogglePackageExpand(item) {
		this.list.packages.items.forEach(i => {
			if (i.id === item.id) {
				i.expand = !i.expand;
			}
		});
	}

	onEditorSaved({ datastore, entity, model, originalId }) {
		if (entity === "service") {
			const index = this.list.services.items.findIndex(s => s.id === model.id);
			this.list.services.items[index] = model;
		}
	}

	onUnmount() {
		this.init();
	}

	// HELPERS
	persistToStore(store, model) {
		const storeItems = this.list[store].items;
		if (!storeItems) {
			return false;
		}

		const index = storeItems.findIndex(m => (m.id === model.id));

		if (index >= 0) {
			storeItems[index] = model;
		} else {
			storeItems.push(model);
		}

		return true;
	}

	updateStore(store, model) {
		const storeItems = this.list[store] ? this.list[store].items : this[store];

		if (!storeItems) {
			return false;
		}

		let didUpdate = false;
		storeItems.forEach((item, index) => {
			if (item.id === model.id) {
				storeItems[index] = model;
				didUpdate = true;
			}
		});

		return didUpdate;
	}

	initStore(store, filters, items = []) {
		this.list[store] = {
			items,
			nextPageUrl: null,
			numberOfItems: 0,
			latestRequestTime: null,
			latestSearchPayload: null,
			filters,
			isLoading: false,
		};
	}
	
	onExportExcel() {
		this.excelLoading = true;
	}
	
	onExportExcelFinish() {
		this.excelLoading = false
	}

	saveFiltersToStorage() {
		sessionStorage.setItem(filterStorageKey, JSON.stringify(this.filters));
		sessionStorage.setItem(prevFilterStorageKey, JSON.stringify(this._prevFilter));
	}
}
export default alt.createStore(HorizonStore, '[Horizon]Store');

// HELPLERS
function getOrderBy(filters) {
	if (filters.searchText.length > 0) {
		return "search";
	}
	
	const filter = filters.filter.filter;
	if (/^\d{4}-\d{2}-\d{2}$/.test(filter)) {
		return "premiere";
	}

	return ["upcoming", "current", "lastExported", "name"].includes(filter) ? filter : "premiere";
}