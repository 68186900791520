import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'

import { ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'
import { decoratorUIActions } from '../../../core/decorators/uiActions'

import Actions from '../actions'
import { getSearchPayload } from '../shared/utils'
import Item from './listItem'
import itemActions from './itemActions'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;

@decoratorUIActions(itemActions)
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		textEmpty: PropTypes.string,
	}

	constructor(props) {
		super(props);
	}

	onPackageAction = (action, clickedPackage) => {
		const p = clickedPackage;
		Actions.packageAction(p.service.id, [p.id], action, false, null, getSearchPayload({ filters: this.props.filters }, this.props.service.id));
	}

	render() {
		const {
			items,
			isLoading,
			searchText = "",
			textEmpty = "Sorry, could not find any items.",
			hasMore,
			loadMore,
			service,
			emptyLink,
		} = this.props;

		const noResult = !(items && items.length);

		if (noResult) {
			const textTooFewCharacters = searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST
				? `Please type ${ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST} characters or more to search.`
				: "";
			return (
				<Empty v2={true} key="empty" isLoading={isLoading}>
					{textTooFewCharacters || textEmpty} {!textTooFewCharacters && emptyLink}
				</Empty>
			);
		}

		const list = service && (
			<ItemGroup>
				{this.renderItems(this.props)}
			</ItemGroup>
		);

		return (
			<InfiniteScroll
				loadMore={loadMore}
				hasMore={hasMore}
				loader={<div key="infinite-loader" className="infinite-loader">Loading ...</div>}
				useWindow={false}
				threshold={700}
				initialLoad={false}
			>
				{list}
			</InfiniteScroll>
		);
	}

	renderItems({ items, isPerformingBatchOperations, selected, service, isEditor, filters }) {
		return items.map(item => (
			<Item
				key={item.id}
				item={item}
				isPerformingBatchOperations={isPerformingBatchOperations}
				isSelected={isSelected(item, selected)}
				onPackageAction={this.onPackageAction}
				service={service}
				isEditor={isEditor}
				isQueueList={filters.filter.status && filters.filter.status.startsWith("queued")}
			/>
		));
	}
}

function isSelected({ id }, selected) {
	return selected ? selected.items.some(i => i.id === id) : false;
}
