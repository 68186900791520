import alt from '../../../core/services/alt'
import { handleRequestFailed } from '../../../core/services/errorhandling'

import * as MetadataData from '../../../apis/metadata'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;

class Actions {

	/* **************** Actions **************** */
	search(searchText) {
		return searchText;
	}

	// filter(filterValue, filterName) {
	// 	return dispatch => {
	// 		dispatch({ [filterName]: filterValue });
	// 	};
	// }

	clearSearch() {
		return true;
	}

	/* **************** Programs **************** */
	fetchPrograms(payload) {
		return dispatch => {
			dispatch(payload);
			const requestTime = Date.now();

			// HACK?: Return nothing when searchtext is less than 2 chars
			const { searchText } = payload;
			if (searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST) {
				this.programsUpdated([], false, 0, null, requestTime);
			}
			else {
				MetadataData.fetchPrograms(payload)
					.then(response => {
						const { pageIndex, numberOfItems, items, links } = response;
						const appendToExistingItems = pageIndex > 0;

						const nextPageUrl = getNextPageUrl(links);
						this.programsUpdated(items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime);

					}, this.requestFailed);
			}
		};
	}
	pagePrograms(url) {
		return dispatch => {
			dispatch(url);

			MetadataData.fetchUrl(url)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.programsUpdated(items, appendToExistingItems, numberOfItems, nextPageUrl);

				}, this.requestFailed);
		};
	}
	programsUpdated(items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime) {
		return {
			items,
			appendToExistingItems,
			numberOfItems,
			nextPageUrl,
			requestTime,
		};
	}

	/* **************** Family **************** */
	fetchFamily(id, programSearchPayload) {
		return dispatch => {
			dispatch();

			MetadataData.fetchProgram({ id })
				.then(model => {
					this.familyLoaded({ model });

					this.initSearchText(model.displayName);
					programSearchPayload.searchText = model.displayName;
					this.fetchPrograms(programSearchPayload);
				}, this.requestFailed);
		};
	}
	familyLoaded(payload) { return payload; }
	initSearchText(searchText) { return searchText; }


	/* ********** Family members *********** */
	fetchFamilyMembers(id) {
		return dispatch => {
			dispatch();

			MetadataData.fetchLinkedPrograms(id)
				.then(response => {
					this.familyMembersLoaded(response.items);
				}, this.requestFailed);
		}
	}

	addFamilyMember(payload) {
		return dispatch => {
			dispatch(payload);
			const { familyId, ...requestPayload } = payload.requestPayload;
			MetadataData.linkProgram(familyId, requestPayload)
				.then(response => {
					this.fetchFamilyMembers(familyId);
				}, error => {
					this.rollbackAddFamilyMember(payload);
					this.requestFailed(error);
				});
		};
	}
	rollbackAddFamilyMember(payload) { return payload; }

	removeFamilyMember(data, payload) {
		return dispatch => {
			dispatch(payload);

			MetadataData.unlinkProgram(data.id, payload)
				.then(response => {
					this.fetchFamilyMembers(data.id);
				}, error => {
					this.rollbackRemoveFamilyMember(payload);
					this.requestFailed(error);
				});
		};
	}
	rollbackRemoveFamilyMember(payload) { return payload; }

	moveFamilyMember(payload, originalItem) {
		return dispatch => {
			dispatch(payload);
			const { familyId, ...requestPayload } = payload;

			MetadataData.moveLinkedProgram(familyId, requestPayload)
				.then(response => {
					this.fetchFamilyMembers(familyId);
				}, error => {
					this.rollbackMoveFamilyMember(originalItem);
					this.requestFailed(error);
				});
		};
	}
	rollbackMoveFamilyMember(originalItem) {
		return originalItem;
	}

	familyMembersLoaded(items) { return items; }


	/* **************** Misc **************** */
	unmount() { return true; }

	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}
}

export default alt.createActions(Actions);

// Helpers
function getNextPageUrl(links = []) {
	const nextLink = links.find(l => l.rel === "next" ||  l.Rel === "next");

	return nextLink
		? nextLink.href ||  nextLink.Href
		: null;
}