import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragSource } from 'react-dnd'

import ItemTypes from '../../core/itemTypes'
import Actions, { ContentActionItem } from '../actions'

import styles from './list.css'
import dndStyles from './dnd.css'

const itemSource = {
	beginDrag(props) {
		const { id, referenceId, actionData = null } = props;
		return {
			id,
			referenceId,
			originalIndex: -1,
			originalData: Object.assign({}, actionData), // Clone, so we have the original item state for rollbacks
			data: actionData, // TODO!!!!!!: LEGACY, can be removed when all DnD uses the SortableList HOC
		};
	},

	// TODO: Maybe even dragging outside the list should remove the item?
	// Handle cases when we drop outside a target
	endDrag(props, monitor) {
		const { id: droppedId, originalIndex } = monitor.getItem();
		const didDrop = monitor.didDrop();

		if (!didDrop && typeof(props.onItemDropOutside) === "function") {
			props.onItemDropOutside(droppedId, originalIndex);
		}
	}
};

@DragSource(ItemTypes.ITEM, itemSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging()
}))
export default class DraggableItem extends Component {
	static displayName = "DraggableItem"; // Needed to support UI-actions since babel is minifying the class name (this.constructor.name) when we build to production

	static propTypes = {
		actionData: PropTypes.object,
		flex: PropTypes.string,
		sel: PropTypes.bool,
		status: PropTypes.string,

		// DnD
		connectDragSource: PropTypes.func.isRequired,
		connectDragPreview: PropTypes.func.isRequired,
		isDragging: PropTypes.bool.isRequired,
		id: PropTypes.oneOfType([
			PropTypes.string.isRequired,
			PropTypes.number.isRequired
		]),
		isPlaceholder: PropTypes.bool
	}

	render() {
		const {
			handleType,
			actionData,
			sel,
			isDragging,
			isPlaceholder,
			connectDragSource,
			connectDragPreview,
		} = this.props;

		const style = {
			// cursor: 'move',
			opacity: isDragging || isPlaceholder ? 0.5 : 1
		};

		const flex = this.props.flex ? `flex flex-${this.props.flex}` : "";
		const selected = sel ? " sel" : "";
		const classNames = `c6-item ${flex}${selected}`;
		const handleClassNames = handleType ? `c6-handle width-${handleType}`: "c6-handle";
		const actions = actionData ? <Actions data={actionData} target={this.constructor.displayName} /> : null;

	    return connectDragPreview(
			<div className={classNames} style={style}>
				<ContentActionItem data={actionData}>
					{connectDragSource(<div className={handleClassNames} />)}
					{this.props.children}
					{actions}
				</ContentActionItem>
			</div>
	    );
  	}
}