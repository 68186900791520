import { get, put, post, patch, del } from '../core/c6'
import appConfig from 'config'
import { localeCompareSortNumeric } from '../core/services/sorting'
import { GetRegionById } from '../core/constants'

import moment from 'moment'

const sortById = localeCompareSortNumeric(item => item.id?.toString() ?? "");

const API = appConfig.features.metadataC80 ? "metadatac8" : "metadata";

const regionalRightDates = (program) => {
	program.rights?.map(r => {
		const region = GetRegionById(r.versionId);
		r.regionStart = moment(r.start).tz(region?.momentTimezone || "UTC").format("YYYY-MM-DD HH:mm:ss");
		r.regionEnd = moment(r.end).tz(region?.momentTimezone || "UTC").format("YYYY-MM-DD HH:mm:ss");
	})
}

/* **************** Program Library ***************** */
export const fetchPrograms = filters => {
	// In C70, we must fetch programs at /programs with the "accept" query parameter
	if (appConfig.features.metadataC70 || appConfig.features.metadataC80) {
		filters.accept = "dynamic";
		return get(API, "programs", filters).then((res) => {
			res.items?.map(regionalRightDates);
			return res;
		});
	}
	return get(API, "programs/dynamic", filters).then((res) => {
		res.items?.map(regionalRightDates);
		return res;
	});
}
export const fetchProgram  			=          ({id}) =>   get(API, `programs/${id}/dynamic`)
export const fetchProgramEmbargo  	=          ({id}) =>   get(API, `programs/${id}/dynamic?includeEmbargo=true`);
export const createProgram 			=         payload =>  post(API, "programs/dynamic", payload);
export const updateProgram 			= ({id}, payload) =>   put(API, `programs/${id}/dynamic`, payload);
export const updateProgramEmbargo 	= ({id}, payload) =>   put(API, `programs/${id}/dynamic?embargoIncluded=true`, payload);
export const patchProgram  = ({id}, payload, filters) => {
	const qParams = new URLSearchParams(filters);
	return patch(API, `programs/${id}/dynamic?${qParams.toString()}`, payload);
};
export const patchProgramEmbargo  = ({id}, payload, filters) => {
	const qParams = new URLSearchParams(filters);
	qParams.set("embargoIncluded", true);
	return patch(API, `programs/${id}/dynamic?${qParams.toString()}`, payload);
};
export const mergePrograms 			= ({id}, payload) =>   put(API, `programs/${id}/merge`, payload);
// export const deleteProgram =          ({id}) =>   del(API, `programs/${id}/dynamic`);

/* **************** Catalogues ***************** */
export const fetchCatalogues = filters => get(API, "catalogues", filters);

/* **************** Schedule Metadata ***************** */
export const fetchSchedule = filters => get(API, "schedules", filters);
export const fetchChannelGroups = filters => get(API, "schedules/channelgroups", filters);

/* **************** Episode templates  ***************** */
export const setAsDefaultTemplate   = id => put(API, `programs/${id}/defaultmaster`);
export const releaseDefaultTemplate = id =>  del(API, `programs/${id}/defaultmaster`);

export const useTemplate    = (id, masterId) => put(API, `programs/${id}/master?masterProgramId=${masterId}`);
export const ignoreTemplate =             id => del(API, `programs/${id}/master`);

/* **************** Tags / Crew ***************** */
export const fetchGenres    =                  	() => get(API, "items?itemTypes=Genre");
export const fetchRatings   =                  	() => {
	// Transform data here because muiSelect does not support dataSourceConfig and we didn't have time to fix that
	return new Promise((resolve, reject) => {
		get(API, "items?itemTypes=Rating").then(ratings => {
			const fixedItems = ratings.items?.sort(sortById).map(r => ({
				...r,
				id: r.name,
				name: r.displayName,
			})) ?? [];
			resolve({ ...ratings, items: fixedItems });
		});
	});
};
export const fetchContentOriginals  =                  	() => {
	// Transform data here because muiSelect does not support dataSourceConfig and we didn't have time to fix that
	return new Promise((resolve, reject) => {
		get(API, "items?itemTypes=ContentOriginal").then(contentOriginals => {
			const fixedItems = contentOriginals.items?.map(r => ({
				...r,
				id: r.name,
				name: r.displayName,
			})) ?? [];
			resolve({ ...contentOriginals, items: fixedItems });
		});
	});
};
export const fetchCountries =                  	() => get(API, "items?itemTypes=Country");
export const fetchLanguages =                  	() => get(API, "items?itemTypes=Language");
export const fetchCredits   =  (types, searchText) => get(API, `items?itemTypes=${types}&searchText=${searchText}`);
export const fetchSymbols	=					() => {
	const symbolsType = appConfig.features.metadataC70 || appConfig.features.metadataC80
		? "LocalRatingSymbols"
		: "FinnishRatingSymbols";
	return get(API, `items?itemTypes=${symbolsType}`);
};

/* **************** Program families ***************** */
export const fetchFamilies 	= filters =>  get(API, "programs/dynamic", filters);
export const fetchFamily 	=  ({id}) =>  get(API, `programs/${id}/dynamic`);
export const createFamily 	= payload => post(API, "programs/dynamic", payload);
// export const updateFamily 	= ({id}, payload) =>   put(API, `programs/${id}/dynamic`, payload);
export const patchFamily 	= ({id}, payload) => patch(API, `programs/${id}/dynamic`, payload);

export const fetchLinkedPrograms =            id =>  get(API, `programs/${id}/linked/dynamic`);
export const linkProgram 		 = (id, payload) => post(API, `programs/${id}/link`, payload);
export const unlinkProgram 		 = (id, payload) =>  del(API, `programs/${id}/link`, null, payload);
export const moveLinkedProgram 	 = (id, payload) => post(API, `programs/${id}/link`, payload);

/* **************** Attributes ***************** */
export const fetchEmbargoAttributes = () => get(API, "attributes?embargo=true");

/* **************** AI translations (cool stuff) *********** */
export const translateTexts = (payload) => post(API, "translations/TranslateTexts", payload); // proxy URL for the Azure function
export const fetchAIGenericMetadata = (payload) => post("cometchatgpt", "chatgpt/generic", payload, true); // TODO: use metadata API
export const fetchAISynopsis = (payload) => post("cometchatgpt", "chatgpt/synopsis", payload, true); // TODO: use metadata API

/* **************** Generic ***************** */
export const fetchUrl = url => get(API, url);