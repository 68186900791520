import React from 'react'
import PropTypes from 'prop-types'
import Flatpickr from 'react-flatpickr'
import '../../../components/ui/controls/pickers/picker.css'
import Moment from 'moment'
import { keydownScoped } from 'react-keydown'

import { Filter, Left, Right } from '../../../components/filter'
import Button from '../../../components/ui/controls/button'
import Pick from '../../../components/ui/controls/pick'
import Switch from '../../../components/ui/controls/switch'

import Actions from '../actions'

import Const from '../../../core/constants'
import Styles from './header.css'

import appConfig from 'config'

export default class Header extends React.Component {

	static propTypes = {
		title: PropTypes.string,
		date: PropTypes.string,
		channels: PropTypes.array.isRequired,
		selectedChannels: PropTypes.string.isRequired,
		approvalStatus: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		channelGroup: PropTypes.string.isRequired,
		handleFilter: PropTypes.func.isRequired,
		useChannelGroupsFallback: PropTypes.bool,
	}

	constructor(props) {
		super(props);

		this.handleNavPrev = this.handleNavPrev.bind(this);
		this.handleNavNext = this.handleNavNext.bind(this);
		this.handleNavToday = this.handleNavToday.bind(this);
		this.handleNavDate = this.handleNavDate.bind(this);
		this.handleChannelPick = this.handleChannelPick.bind(this);

		this.listId = this.props.params && this.props.params.id;
	}

	@keydownScoped("left")
	handleNavPrev() {
		const { selectedChannels, date } = this.props;
		Actions.navPrev(date, selectedChannels);
	}

	@keydownScoped("right")
	handleNavNext() {
		const { selectedChannels, date } = this.props;
		Actions.navNext(date, selectedChannels);
	}

	@keydownScoped("ctrl+t")
	handleNavToday() {
		const { selectedChannels } = this.props;
		Actions.navToday(selectedChannels);
	}

	handleNavDate(date) {
		const { selectedChannels } = this.props;
		Actions.navDate(date[0], selectedChannels);
	}

	handleChannelPick(e, pickedChannels) {
		const channel = pickedChannels || e.target.value;
		const {selectedChannels, date} = this.props;

		if(channel) {
			if (!e.target.checked || e.target.checked && e.target.value === "-") {
				Actions.unselectChannel(channel, selectedChannels, date);
			}
			else {
				Actions.selectChannel(channel, selectedChannels, date);
			}
		}
	}

	render() {
		let channelPickers = null;
		if (!appConfig.features.metadataScheduleSkipChannels) {
			const { channels, selectedChannels } = this.props;
			const tv4Channels = channels.filter(c => c.group === "tv4");
			const cmoreChannels = channels.filter(c => c.group === "cmore");
			const currentState = selectedChannels && selectedChannels.length ? selectedChannels.split(",") : [];

			channelPickers = [
				<div key="a">
					<Pick
						pickAll={true}
						pickAllLabels={["All", "None"]}
						onChange={this.handleChannelPick}
						states={tv4Channels}
						currentState={currentState}
					/>
				</div>,
				<div key="b">
					<Pick
						pickAll={true}
						pickAllLabels={["All", "None"]}
						onChange={this.handleChannelPick}
						states={cmoreChannels}
						currentState={currentState}
					/>
				</div>
			];
		}

		return (
			<Filter>
				<Left>
					<Button
						type=""
						title="Today"
						hoverTitle="Go to todays date [CTRL-T]"
						onClick={this.handleNavToday} />
					<Button
						type="prev"
						hoverTitle="Go to previous day [ARROW LEFT]"
						onClick={this.handleNavPrev} />
					<Button
						type="next"
						hoverTitle="Go to next day [ARROW RIGHT]"
						onClick={this.handleNavNext} />
					<Flatpickr
						options={{
							altInput: true,
							// altInputClass: "icon-schedule",
							altFormat: "j M Y",
							locale: {
								firstDayOfWeek: 1
							}
						}}
						value={this.props.date}
						onChange={this.handleNavDate}
					/>
					{channelPickers}
				</Left>
				<Right>
					<Switch
						name="_channelGroup"
						title="Channels"
						onChange={this.props.handleFilter.bind(this, "filter")}
						states={getGroup(this.props.channelGroups, this.props.useChannelGroupsFallback)}
						currentState={this.props.channelGroup}
					/>
					<Switch
						name="_type"
						title="Type"
						onChange={this.props.handleFilter.bind(this, "filter")}
						states={getType()}
						currentState={this.props.type}
					/>
					<Switch
						name="_approvalStatus"
						title="Approval"
						onChange={this.props.handleFilter.bind(this, "filter")}
						states={getApprovalStatus()}
						currentState={this.props.approvalStatus}
					/>
				</Right>
			</Filter>
		);
  	}
}

function getApprovalStatus() {
	return [
		{
			key: "",
			text: "Any",
			description: "Programs which either are approved or are missing approval.",
		},
		{
			key: "missing",
			text: "Needs attention",
			description: "Program is not fully approved.",
		},
		{
			key: "images",
			text: "Images",
			description: "Program needs approved images.",
		},
	];
}

function getType() {
	return [
		{
			key: "",
			text: "Any",
			description: "Display all broadcasts.",
		},
		{
			key: "sports",
			text: "Sports",
			description: "Only display sports broadcasts",
		},
	];
}

function getGroup(channelGroups, useChannelGroupsFallback) {
	if (!useChannelGroupsFallback && (appConfig.features?.metadataC70 || appConfig.features?.metadataC80)) {
		return channelGroups;
	}
	
	const groups = [];
	if (!appConfig.features.hideTV4Channels) {
		groups.push({
			key: "tv4",
			text: "TV4",
			description: "Only display TV4 channels",
		});
	}
	if (!appConfig.features.hideCMoreChannels) {
		groups.push(
			{
				key: "cmore",
				text: "C More",
				description: "Only display C More channels",
			},
			{
				key: "won",
				text: "C More Finland",
				description: "Only display C More Finland channels",
			}
		);
	}
	if (!appConfig.features.hideMTVChannels) {
		groups.push({
			key: "mtv",
			text: "MTV",
			description: "Only display MTV channels",
		});
	}
	// if (!appConfig.features.hideTV4Channels && !groups.find(g => g.key === "cmore")) {
	// 	groups.push({
	// 		key: "cmore",
	// 		text: "C More",
	// 		description: "Only display C More channels",
	// 	});
	// }
	return groups;
		
}