import React from 'react'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll'
import { browserHistory } from 'browserHistory'

import { isLoggedIn, hasAccessToPath } from '../../../core/services/auth'
import App from '../../../components/app'
import Main from '../../../components/ui/main'
import { Left } from '../../../components/filter'

import Header from './header'
import List from './list'

import Actions from '../actions'
import Store from '../store'

import { getSearchPayload } from '../shared/utils'

import './packages.css'

const MODULE = "horizon";
const DATASTORE = "packages";
// const TEXT_HEADING = "Packages";

export default class PackagesApp extends React.Component {


	constructor(props) {
		super(props);

		this.debouncedFilter = debounce(this.handleFilter, 500);

		this.scroll = null;
		this.serviceId = props.params.serviceId;


		const route = [{ module: "horizon", access: "editor" }];

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
			isEditor: hasAccessToPath(route),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);

		const loggedIn = isLoggedIn();
		if (!loggedIn) {
			browserHistory.replace("/login");
			return;
		}

		Actions.fetchItems("services", {}, null, () => {
			if (!this.props.params.serviceId) {
				this.serviceId = getServiceId(this.state.list.services.items);
				browserHistory.replace(`/horizon/packages/${this.serviceId}`);
			}

			this.fetchData();
		});
	}

	componentDidUpdate() {
		if (this.props.params.serviceId != this.serviceId) {
			this.serviceId = this.props.params.serviceId;

			if (!this.props.params.serviceId) {
				this.serviceId = getServiceId(this.state.list.services.items);
				browserHistory.replace(`/horizon/packages/${this.serviceId}`);
			}

			Actions.unmount();
			this.fetchData();
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	fetchData = () => {
		const payload = getSearchPayload(this.state, this.serviceId);
		Actions.fetchItems(DATASTORE, payload);
		// getBlockedPackages(payload);
		// getDeliveryList(payload);
	}

	onFilter = (type, e) => {
		if (type === "search") {
			e.persist();
			this.debouncedFilter(type, e);
		} else {
			this.handleFilter(type, e);
		}
	}

	handleFilter = (type, event) => {
		if (this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions[type](value, name, DATASTORE);
		const payload = getSearchPayload(this.state, this.props.params.serviceId);
		Actions.fetchItems(DATASTORE, payload);

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Also check if there are blocked packages
		// if (!(name === "status" && value.startsWith("queued")) && (type !== "search" || type === "search" && value === "")) {
		// 	getBlockedPackages(payload);
		// 	getDeliveryList(payload);
		// }
	}

	loadMore = () => {
		const packages = this.state.list[DATASTORE];

		if (packages.nextPageUrl) {
			Actions.pageItems(DATASTORE, packages.nextPageUrl);
		}
	}

	shouldUpdateScroll = (prevRouterProps, currentRouterProps) => {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const { isLoading, searchText: componentSearchText, filters, nextPageUrl, unselectedFilter, list, actionLoading } = this.state;
		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

		const packages = list[DATASTORE];
		const services = list["services"].items;
		const serviceId = this.props.params.serviceId;
		const service = serviceId && services.find(s => s.id == serviceId);

		const blockedPackages = list.blockedPackages;
		const deliveryList = list.deliveryList;

		const type = service && service.configuration.deliveryMode || "blocked";

		return (
			<App
				name={`c6-${MODULE}-${DATASTORE} c6-list`}
				layout="grid"
				isLoading={isLoading || packages.isLoading || actionLoading}
			>
				<Header
					searchText={searchText}
					filters={filters}
					unselectedFilters={unselectedFilter || !!searchText.length}
					handleFilter={this.onFilter}
					services={services}
					serviceId={this.props.params.serviceId}
					isEditor={this.state.isEditor}
					isLoading={this.state.excelLoading}
				/>
				{renderListActions(searchText, service, packages, blockedPackages, deliveryList, filters, this.onFilter, this.state.isEditor)}
				<ScrollContainer scrollKey={`c6-${MODULE}-${DATASTORE}`} shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el}>
						<List
							items={packages.items}
							isLoading={packages.isLoading}
							searchText={searchText}
							hasMore={!!packages.nextPageUrl}
							loadMore={this.loadMore}
							service={service}
							blockedPackages={blockedPackages}
							deliveryList={deliveryList}
							filters={filters}
							handleFilter={this.onFilter}
							selected={list.selected}
							emptyLink={getLink(type === "Blocked" ? blockedPackages : deliveryList, filters, this.onFilter, this.state.isEditor, type)}
							isEditor={this.state.isEditor}
						/>
					</Main>
				</ScrollContainer>
			</App>
		);
	}
}

// HELPERS
function renderListActions(searchText, service, packages, blockedPackages, deliveryList, filters, handleFilter, isEditor) {
	let leftContent;
	if (searchText) {
		leftContent = <span>Items matching &quot;{searchText}&quot;</span>;
	} else if (service && service.configuration.deliveryMode !== "DeliveryList" && blockedPackages.numberOfItems > 0) {
		leftContent = getLink(blockedPackages, filters, handleFilter, isEditor, "Blocked");
	} else if (service && service.configuration.deliveryMode === "DeliveryList" && deliveryList.numberOfItems > 0) {
		leftContent = getLink(deliveryList, filters, handleFilter, isEditor, "DeliveryList");
	}

	if (!leftContent) {
		return null;
	}

	return (
		<div style={{ display: "flex", marginBottom: "5px", height: "20px", alignItems: "center" }}>
			<Left>
				{leftContent}
			</Left>
		</div>
	);
}

function getLink(list, filters, handleFilter, isEditor, type) {
	if (filters && filters.filter.deliveryStatus === type) {
		return <a key="a" className="c6-link" onClick={handleFilter.bind(this, "filter", { target: { name: "deliveryStatus", value: "" }})}>Show all packages</a>;
	}

	return list.numberOfItems > 0 && (
		<a
			key="b"
			className="c6-link"
			onClick={handleFilter.bind(this, "filter", { target: { name: "deliveryStatus", value: type } })}
			title={type === "DeliveryList" ? "Show your delivery list containing all the packages you have selected to be included in future exports." : "Show packages that you have selected to be excluded from future exports."}
		>
			Show {type === "DeliveryList" ? "delivery list" : "excluded packages"}
		</a>
	);
}

function getServiceId(services) {
	return services.length ? services[0].id : 1;
}

function getBlockedPackages(payload) {
	if (payload) {
		Actions.fetchItems(DATASTORE, {
			...payload,
			serviceId: payload.serviceId,
			pageSize: 1,
			deliveryStatus: "Blocked",
		}, "blockedPackages");
	}
}

function getDeliveryList(payload) {
	if (payload) {
		Actions.fetchItems(DATASTORE, {
			...payload,
			serviceId: payload.serviceId,
			pageSize: 1,
			deliveryStatus: "DeliveryList",
		}, "deliveryList");
	}
}