import * as MetadataAPI from '../../../apis/metadata'
import { useQuery } from '@tanstack/react-query'

const useMetadataLanguages = (enabled: boolean = true) => {
    return useQuery(
        ["metadata", "languages"],
        () => MetadataAPI.fetchLanguages(),
        { enabled }
    );
};

export default useMetadataLanguages;