import alt from '../../core/services/alt'
import Actions from './actions'

class ProgramFamilyDialogStore {
	constructor() {

		this.isLoading = false;
		this.status = null;
		
		this.selectedItem = null;

		this.families = [
		];

		this.filters = {
			searchText: "",
			programType: 5, // 5 = container/family
			filter: {
			},
			pageSize: 50,
		};

		this.bindListeners({
			onRequestFailed: Actions.REQUEST_FAILED,

			onSearch: Actions.SEARCH,
			onFilter: Actions.FILTER,

			onFetchFamilies: Actions.FETCH_FAMILIES,
			onPageFamilies: Actions.PAGE_FAMILIES,
			onFamiliesUpdated: Actions.FAMILIES_UPDATED,

			createAndAddToFamily: Actions.CREATE_AND_ADD_TO_FAMILY,
			familyCreated: Actions.FAMILY_CREATED,
			onFamilyCreatedAndMemberAdded: Actions.FAMILY_CREATED_AND_MEMBER_ADDED,
			onSelectItem: Actions.SELECT_ITEM,

			onUnmount: Actions.UNMOUNT,
		});
	}

	onRequestFailed() {
		this.isLoading = false;
	}

	/* Filters */
	onSearch(searchText) {
		if (searchText === '') {
			// this.filters.filter = this._prevFilter;
			// this.filters.orderBy = "premiere";
			// this.filters.programTypes = "single|episode";
			// this.filters.hideWithParent = "";
		} else {
			// if (!this._prevFilter) {
			// 	this._prevFilter = cloneDeep(this.filters.filter);
			// }
			// this.filters.filter = {};
			// this.filters.orderBy = 'title';
			// this.filters.programTypes = "single|season|series";
			// this.filters.hideWithParent = "season";
		}
		this.filters.searchText = searchText;
		this.families = [];
		this.selectedItem = null;
	}

	onFilter(payload) {
		// const filterParams = Object.keys(this.filters.filter).length
		// 	? this.filters.filter
		// 	: this._prevFilter;

		// this.filters.filter = {
		// 	...filterParams,
		// 	...payload,
		// };

		// this.filters.searchText = "";
		// this.filters.orderBy = "premiere";
		// this.filters.programTypes = "single|episode";
		// this.filters.hideWithParent = "";
		// this._prevFilter = null;
		this.families = [];
		this.selectedItem = null;
	}


	onFetchFamilies(payload) {
		this.selectedItem = null;
		this.isLoading = true;
		this.nextPageUrl = null;
		this.numberOfItems = 0;
		this.latestRequestTime = Date.now();
	}

	onPageFamilies(url) {
		this.selectedItem = null;
		this.isLoading = true;
		this.nextPageUrl = null;
	}

	onFamiliesUpdated({ items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
		if (this.latestRequestTime > requestTime) {
			console.log("Ignoring result with %s items since there have been newer requests.", numberOfItems);
		}
		else {
			this.families = appendToExistingItems ? this.families.concat(items) : items;
			this.nextPageUrl = nextPageUrl;
			this.numberOfItems = numberOfItems;
			this.isLoading = false;
		}
	}


	createAndAddToFamily() {
		this.status = "Creating family...";
	}
	familyCreated() {
		this.status = "Adding program to family...";
	}
	onFamilyCreatedAndMemberAdded() {
		this.status = "Created family and added program!";
	}
	onSelectItem(item) {
		this.selectedItem = item;
	}

	onUnmount() {
		this.isLoading = false;
		this.status = null;
		this.selectedItem = null;
	}

}

export default alt.createStore(ProgramFamilyDialogStore, '[ProgramFamilyDialog]Store');