import React from 'react'
import { browserHistory } from 'browserHistory'
// import keydown from 'react-keydown'
// import debounce from 'lodash/debounce'

import { getRouteName } from '../../../components/comet'
import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'

import Header from './header'
import List from './list'
import { QCWithoutLink } from '../shared/qcLink'

import EditorNavigationController from '../../../core/ui/editorNavigationController'
import Actions from '../actions'
import Store from '../store'
import UserStore from '../../../core/authentication/store'
import { getRoleVersionLanguage } from '../library/app'
import MetadataModule from '../module'


import '../library/app.css'

const ENTITY = "program"
const TEXT_EMPTY = "No programs? There must be a problem."

export default class SeasonApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.onSelectSeason = this.onSelectSeason.bind(this);

		this.episodeId = parseInt(this.props.params.episodeId || 0);
		this.seasonId = parseInt(this.props.params.seasonId || 0);
		this.seriesId = parseInt(this.props.params.seriesId || 0);
		this.programId = parseInt(this.props.params.programId || 0);

		this.state = {
			...Store.getState(),
			qcDialogOpen: false,
			qcProgram: {},
		};
	}

	async componentDidMount() {
		await MetadataModule.initialSetup();

		Store.listen(this.onChange);

		this.catalogue = getCatalogue(this.props);

		this.isTranslationList = this.props.location.pathname.includes("metadata/translation");
		this.translationFilterFromRoute = this.props.routes.find(r => r.languageFilter)?.languageFilter;
		this.user = UserStore.getState();
		this.translationLanguage = this.translationFilterFromRoute || this.isTranslationList && getRoleVersionLanguage(this.user);

		if(this.programId) {
			Actions.fetchItem(ENTITY, { id: this.programId });
		}
		else {
			const type = this.seriesId ? "series" : "season";
			const { filters, items } = this.state.list.seriesSeasonPrograms;
			const templateRendered = !!items.filter(i => i.type === "Template").length;

			Actions.fetchSeriesSeasons(this.seriesId || this.seasonId, filters, type, this.episodeId, templateRendered);
		}
	}

	// componentWillReceiveProps({ keydown }) {
	// 	if (keydown.event) {
	// 		console.log( keydown.event.which );
	// 		this.onSearch(keydown.event);
	// 	}
	// }

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { params } = nextProps;
		const nextCatalogue = getCatalogue(nextProps);
		if (nextCatalogue !== this.catalogue) {
			this.catalogue = nextCatalogue;
		}

		const differentSeason = parseInt(params.seasonId || 0) !== this.seasonId;
		const differentSeries = parseInt(params.seriesId || 0) !== this.seriesId;
		const { filters, items } = this.state.list.seriesSeasonPrograms;
		const templateRendered = !!items.filter(i => i.type === "Template").length;

		if(differentSeason || this.episodeId && !params.episodeId) {
			this.seasonId = parseInt(params.seasonId);
			this.seriesId = 0;
			this.episodeId = 0;
			Actions.fetchSeasonEpisodes(this.seasonId, filters, templateRendered);
		}
		else if(differentSeries) {
			this.seriesId = parseInt(params.seriesId);
			this.seasonId = 0;
			this.episodeId = 0;
			Actions.fetchSeriesSeasons(this.seriesId, filters, "series", null, templateRendered);
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);

		const l = state.list.seriesSeasonPrograms;
		const items = this.programId ? [state.item.program] : l.items;
		const episodes = items.filter(i => i.type === "Episode");
		EditorNavigationController.setItems(episodes);
	}

	onSelectSeason(seasonId) {
		const catalogue = this.catalogue || getCatalogue(this.props);
		const cataloguePath = catalogue ? `${catalogue}/` : "";
		browserHistory.replace(`/metadata/programs/${cataloguePath}season/${seasonId}`);
	}

	handleFilter(type, event) {
		const { value: approval } = event.target;
		Actions.filterSeriesSeasons(approval);
		const { filters, items } = this.state.list.seriesSeasonPrograms;
		const selectedSeasonId = this.seasonId || filters._selectedSeasonId;
		const templateRendered = !!items.filter(i => i.type === "Template").length;

		Actions.fetchSeasonEpisodes(selectedSeasonId, filters, templateRendered);
	}

	render() {
		const {
			isLoading,
			isLoadingNumber,
			item,
			list,
			masterTemplateModifications,
		} = this.state;

		const { location } = this.props;

		// Are we listing a single program or a season?
		const l = list.seriesSeasonPrograms;
		const items = this.programId ? [item.program] : l.items;
		const seriesSeasons = items.filter(s => s.type === "Season");
		const hasSeason = seriesSeasons.filter(s => s.id > 0).length;
		const series = items.find(item => item.type === "Series");

		const catalogue = this.catalogue || getCatalogue(this.props);
		const selectedSeasonId = this.seasonId || l.filters._selectedSeasonId;

		return (
			<App
				name={`c6-metadata-programs c6-metadata-programs-${catalogue} c6-list`}
				layout="grid"
				isLoading={isLoading || isLoadingNumber > 0}
			>
				<Header
					location={location}
					title={getSeriesTitle(items)}
					seriesSeasonApprovalFilter={l.filters.approval}
					handleFilter={this.handleFilter}
					goBackInsteadOfLink={!!list.programs.items.length}
					hideFilter={!hasSeason}
					catalogue={catalogue}
				/>
				<Main padding={true} scrollBehavior="smooth">
					<List
						items={items}
						selectedSeasonId={selectedSeasonId}
						masterTemplateModifications={masterTemplateModifications}
						isLoading={isLoading || isLoadingNumber > 0}
						textEmpty={TEXT_EMPTY}
						catalogue={catalogue}
						handleSelectSeason={this.onSelectSeason}
						seasonId={selectedSeasonId}
						seriesSeasons={seriesSeasons}
						series={series}
						onVideoClick={(qcProgram) => this.setState({ qcProgram, qcDialogOpen: true })}
						isTranslationList={this.isTranslationList}
						translationLanguage={this.translationLanguage}
					/>
				</Main>
				<Info>{getInfo(location, this.state.list.seriesSeasonPrograms, seriesSeasons.length)}</Info>
				<QCWithoutLink
					program={this.state.qcProgram}
					open={this.state.qcDialogOpen}
					onClose={() => {
						// TODO: update video approval in list
						this.setState({
							qcDialogOpen: false,
							qcProgram: null,
						});
					}}
				/>
			</App>
		);
	}
}

// HELPERS
function getInfo(location, { numberOfItems, items: ds }, numberOfSeasons) {
	const noItems = ds.length && ds.length - numberOfSeasons + 1;
	const items = ds.length ? `- displaying ${noItems} item${noItems > 1 ? "s" : ""}` : "";

	return `${getRouteName(location)} ${items}`;
}

function getSeriesTitle(items) {
	if(items && items.length) {
		const series = items.filter(item => {
			if(!item.type) {
				console.error("getSeriesTitle: Item missing type property: %o Items array: %o", item, items);
				return false;
			}
			else {
				return item.type === "Series";
			}
		});

		const seriesItem = series.length ? series[0] : null;
		if(seriesItem && seriesItem.id === -100) { // Series missing
			return " / "
		}
		return series.length && series[0].originalTitle ? ` / ${series[0].originalTitle}` : "";
	}

	return  "";
}

function getCatalogue({ params }) {
	return params.catalogue;
}