import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragSource } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import ItemTypes from '../../core/itemTypes'
import ContentItem from './contentItem'

import './list.css'
import './dnd.css'

const itemSource = {
	beginDrag(props) {
		const { data, selectedItems } = props;
		return {
			id: data.id,
			// originalData: Object.assign({}, data), // Clone, so we have the original item state for rollbacks. (For example if the item position is changing during drag and we want to revert it)
			data,
			selectedItems,
		};
	},

	canDrag(props, monitor) {
		return !props.data || !props.data.disableDrag;
	},

	// TODO: Maybe even dragging outside the list should remove the item?
	// Handle cases when we drop outside a target
	endDrag(props, monitor) {
		const { id: droppedId, originalIndex } = monitor.getItem();
		const didDrop = monitor.didDrop();

		if (!didDrop && typeof(props.onItemDropOutside) === "function") {
			props.onItemDropOutside(droppedId, originalIndex);
		}
	}
};

@DragSource(ItemTypes.ITEM, itemSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging()
}))
export default class DraggableItem extends Component {
	static displayName = "DraggableItem"; // Needed to support UI-actions since babel is minifying the class name (this.constructor.name) when we build to production

	static propTypes = {
		data: PropTypes.object,
		flex: PropTypes.string,
		selected: PropTypes.bool,
		disabled: PropTypes.bool,
		selectedItems: PropTypes.array,

		// DnD
		connectDragSource: PropTypes.func.isRequired,
		connectDragPreview: PropTypes.func.isRequired,
		isDragging: PropTypes.bool.isRequired,
		id: PropTypes.oneOfType([
			PropTypes.string.isRequired,
			PropTypes.number.isRequired
		]),
		isPlaceholder: PropTypes.bool,
		handleType: PropTypes.string,
	}

	componentDidMount() {
		if(this.props.connectDragPreview) {
			this.props.connectDragPreview(getEmptyImage());
		}
	}

	render() {
		const {
			handleType, // "icon" || "thumbnail"
			isDragging,
			isPlaceholder,
			noHandle = false,
			connectDragSource,
			connectDragPreview,
			children,
			style: extraStyles = {},
			...props
		} = this.props;

		// Dim the item we're dragging from
		const style = {
			...extraStyles,
			opacity: isDragging || isPlaceholder ? 0.5 : 1,
		};

		// We can drag by part of the item; handle (default), icon or thumbnail... or just drag the whole item.
		let handle = null;
		if(handleType !== "item") {
			const handleClassNames = handleType ? `c6-handle width-${handleType}`: "c6-handle";
			handle = connectDragSource(<div className={handleClassNames} />);
		}

		const item = (
			<div style={style}>
				<ContentItem {...props}>
					{handle}
					{children}
				</ContentItem>
			</div>
		);

		// We have a custom drag preview so we MUST NOT wrap the draggable item with the connectDragPreview
		// method or we'll render both the browser ghost preview as well as our custom preview.
		return handleType === "item"
			? connectDragSource(item)
			: item;

		// return handleType === "item"
		// 	? connectDragPreview(connectDragSource(item))
		// 	: connectDragPreview(item);
  	}
}