import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'

import { CONTENT } from '../../../components/actions'
import { decoratorUIActions } from '../../../core/decorators/uiActions'
import { Item, ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'

import Content from './listItem'
import Actions from '../actions'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;

const uiActions = {
	targetComponent: "Item",
	actions: [
		{
			title: "View family",
			method: (data, sourceProps, location) => {
				Actions.edit({
					modal: false,
					pathname: `/metadata/families/${data.id}`,
				});
			},
			type: CONTENT,
		},
	]
};

@decoratorUIActions(uiActions)
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		textEmpty: PropTypes.string,
	}

	constructor(props) {
		super(props);
	}

	render() {
		const {
			items,
			isLoading,
			searchText = "",
			textEmpty = "Sorry, could not find any items.",
			hasMore,
			loadMore,
		} = this.props;

		const textTooFewCharacters = searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST ? `Please type ${ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST} characters or more to search.` : "";
		const noResult = !(items && items.length);

		return noResult
			? <Empty key="empty" v2={true} isLoading={isLoading}>{textTooFewCharacters ||  textEmpty}</Empty>
			: (
				<InfiniteScroll
					loadMore={loadMore}
					hasMore={hasMore}
					loader={<div className="infinite-loader">Loading ...</div>}
					useWindow={false}
					threshold={700}
					initialLoad={false}>
					{this.renderItems(this.props)}
				</InfiniteScroll>
			);
	}

	renderItems({ items }) {
		return items.map(item => (
			<Item
				key={item.id}
				id={item.id}
				actionData={item}>
				<Content
					{...item}
				/>
			</Item>
		));
	}
}