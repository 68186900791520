import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'
import { withRouter } from 'react-router'

import Dropzone from 'react-fine-uploader/dropzone'
import FileInput from 'react-fine-uploader/file-input'

import ItemTypes from '../../core/itemTypes'
import StarConstants from '../../core/constants/star'
import * as Alert from '../../core/services/alert'
import { isModalRoute } from '../../core'

import Actions from './actions'
import { createUploader } from './utils'

import './upload.css'
import appConfig from 'config'

@withRouter
class C6Dropzone extends Component {
	static propTypes = {
		categoryName: PropTypes.string,
		update: PropTypes.func,
		canDrop: PropTypes.bool,
		multipleUploads: PropTypes.bool,
		autoUpload: PropTypes.bool,
		disabled: PropTypes.bool,
		assetType: PropTypes.string,
		uploaderInformationText: PropTypes.string,
	};

	static defaultProps = {
		autoUpload: true
	};

	constructor(props) {
		super(props);

		this.uploader = null;
		this.uploaderId = null;
		this._isMounted = false;

		this.state = {
			initialized: true,
		};
	}

	async componentDidMount() {
		this._isMounted = true;
		await this.initializeUploader();
	}

	async componentDidUpdate(prevProps) {
		if (this.props.uploaderId !== prevProps.uploaderId) {
			await this.initializeUploader();
		}
	}

	componentWillUnmount() {
		Actions.unregisterUploaderIfUnused.defer(this.uploaderId);
		this._isMounted = false;
	}

	initializeUploader = async () => {
		this.uploaderId = this.props.uploaderId ?? Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
		Actions.unregisterUploaderIfUnused.defer(this.uploaderId);

		this.uploader = await createUploader(this.uploaderId, this.props);
		const pathname = this.props.location?.pathname ?? window.location.pathname;
		const modal = isModalRoute(this.props.routes);
		Actions.registerUploader.defer(this.uploaderId, this.uploader, this.props.uploaderInformationText, pathname, modal);
		if (this._isMounted) {
			this.setState({ initialized: true });
		}
	}

	render() {
		const {
			multipleUploads,
			disabled,
			connectDropTarget,
			indexDisplayName,
			required,
			assetType,
		} = this.props;

		const { initialized } = this.state;

		let instructionsType, accept;
		if (["Trailer", "Clip", "Video"].includes(assetType)) {
			instructionsType = "a video";
			accept = "video/*";
		} else if (assetType === "Image") {
			instructionsType = "an image";
			accept = "image/*";
		}

		return connectDropTarget(
			<div className="uploader">
				{required && <div className="status-ribbon required"><span>REQUIRED</span></div>}
				{indexDisplayName && <div className="index-name">{indexDisplayName}</div>}
				<Dropzone
					className="drop-zone"
					uploader={this.uploader || {}}
					multiple={multipleUploads}
					disabled={disabled || !initialized}
				>
					<FileInput
						className="drop-zone"
						uploader={this.uploader || {}}
						multiple={multipleUploads}
						disabled={disabled || !initialized}
						accept={accept}
					>
						{renderUploadInstructions(initialized, instructionsType)}
					</FileInput>
				</Dropzone>
			</div>
		);
	}
}

/* REACT DND */
const targetSpec = {
	drop: (props, monitor) => {
		if (props.addAssetsToContainer) {
			const updateType = 'parent';
			const containerId = props.containerId;
			const categoryName = props.categoryName;
			const categoryIndex = props.categoryIndex;
			const item = monitor.getItem();
			props.addAssetsToContainer(updateType, containerId, [item.data.id], categoryName, categoryIndex);
		}
	},
	canDrop: (props, monitor) => !props.disabled,
};

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	};
}

export default DropTarget([ItemTypes.ASSET, ItemTypes.ITEM], targetSpec, collect)(C6Dropzone);

// HELPERS
function renderUploadInstructions(initialized, instructionsType = "a file") {
	if (!initialized) {
		return <div className="upload-instructions">Initializing...</div>
	}

	return (
		<div className="upload-instructions icon-cloud_upload">
			<div>Drop {instructionsType} <br />or click to upload</div>
		</div>
	);
}