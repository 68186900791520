import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Item, ItemGroup } from '../../components/list/listItems'
import Empty from '../../components/list/empty'

import Content from './listItem'
// import contentActions from '../shared/listItemActions'
// @decoratorUIActions(contentActions)

export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		textEmpty: PropTypes.string,
	}

	constructor(props) {
		super(props);

		this.state = {
			selectedTypeId: "Main"
		};
	}

	render() {
		const {
			items,
			isLoading,
			searchText = "",
			textEmpty = "Sorry, could not find any items.",
			hasMore,
			loadMore,
		} = this.props;

		const { selectedTypeId } = this.state;

		const textTooFewCharacters = searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST ? `Please type ${ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST} characters or more to search.` : "";
		const noResult = !(items && items.length);

		return noResult
			? <Empty key="empty" v2={true} isLoading={isLoading}>{textTooFewCharacters ||  textEmpty}</Empty>
			: (
				<div>
					{this.renderItems(this.props, selectedTypeId)}
				</div>
			);
	}

	renderItems({ items, selectedItem, onSelectItem, onSetFamily, linkedPrograms = [] }, selectedTypeId) {
		return items.map(item => {
			const alreadyLinked = linkedPrograms.some(linkedProgram => linkedProgram.programId === item.id);
			return (
				<Item
					key={item.id}
					id={item.id}
					actionData={item}
					sel={selectedItem && selectedItem.id == item.id}
					onSelect={() => onSelectItem(item)}
					disabled={alreadyLinked}
				>
					<Content
						{...item}
						selected={selectedItem && selectedItem.id == item.id}
						selectedTypeId={selectedTypeId}
						onSelectType={val => this.setState({ selectedTypeId: val })}
						onAdd={() => onSetFamily(item, selectedTypeId)}
						alreadyLinked={alreadyLinked}
					/>
				</Item>
			);
		});
	}
}