import { browserHistory } from 'browserHistory';
import alt from '../../../core/services/alt';
import * as StarData from '../../../apis/star';
import * as Alert from '../../../core/services/alert';

class Actions {
	search(filters, searchText) {
		return (dispatch) => {
			dispatch(searchText);
			this.fetchContainers(filters);
		};
	}

	fetchContainers(filters, append = false) {
		return (dispatch) => {
			dispatch();

			StarData.fetchContainers(filters)
				.then((response) => {
					const { items, pageSize, pageIndex, numberOfItems } = response;
					if (append) {
						this.appendContainers(items);
					} else {
						this.updateContainers(items);
					}

					const hasMore = pageIndex * pageSize < numberOfItems;
					this.updateContainersInfo(hasMore, numberOfItems);
				}, (error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert('error', error.exceptionMessage);
				});
		};
	}

	fetchChildContainers(parentId) {
		return (dispatch) => {
			dispatch();
			StarData.fetchChildContainers(parentId)
				.then((response) => {
					if (response.items.length) {
						this.updateContainers(response.items);
					} else {
						Alert.displayAlert('error', 'This container has no child containers. If you want to place the assets in this container, click on "Move assets here".');
					}
				}, (error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert('error', error.exceptionMessage);
				});
		};
	}

	moveAssetsToContainer(assetIds, container, sourceContainerId, keepAssets) {
		const payload = {
			assetIds,
		};
		return (dispatch) => {
			dispatch();
			StarData.connectAssetsToContainer(container.id, payload)
				.then((response) => {
					const route = {
						pathname: `/star-legacy/container/${response.id}`,
						state: {
							modal: false,
							update: true,
						},
					};
					if (!keepAssets) {
						this.removeAssetFromContainer(sourceContainerId, response, payload, route);
					} else {
						Alert.displayAlert(
							'success',
							`Added ${assetIds.length} new asset(s) to ${container.displayName}.`,
							null,
							{ title: 'Take me there!', onClick: () => browserHistory.push(route) },
						);
					}
					this.updateContainer(response);
				}, (error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert('error', error.exceptionMessage);
				});
		};
	}

	removeAssetFromContainer(sourceContainerId, container, payload, route) {
		return (dispatch) => {
			dispatch();
			StarData.removeAssetsFromContainer(sourceContainerId, payload)
				.then((response) => {
					this.triggerStarContainerDetailUpdate(response);
					this.updateParentContainer(response);
					Alert.displayAlert(
						'success',
						`Moved ${payload.assetIds.length} asset(s) from ${response.displayName} to ${container.displayName}.`,
						null,
						{ title: 'Take me there!', onClick: () => browserHistory.push(route) },
					);
				}, (error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert('error', error.exceptionMessage);
				});
		};
	}

	// Custom event because it's very confusing and difficult to understand which event the ContainerDetail app should listen to :/
	triggerStarContainerDetailUpdate(container) { return container; }

	updateContainer(container) { return container; }
	updateParentContainer(container) { return container; }
	updateContainers(containers) { return containers; }
	updateContainersInfo(hasMore, numberOfItems) { return { hasMore, numberOfItems }; }
	appendContainers(containers) { return containers; }
	requestFailed(errorPayload) { return errorPayload; }
}

export default alt.createActions(Actions);
