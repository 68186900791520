import React from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'

import App from '../app'
import { StandardInfo } from '../comet'
import { Filter, Left, Right } from '../filter'
import Button from '../ui/controls/button'

import { Filters } from './filters'

import { getReturnTo } from '../../core'

import './app.css'

export default function GenericApp(props) {
	const {
		module,
		datastore,
		state,
		filterConfig,
		pathnameCreate,
		onCreateClick,
		collapseFiltersDefault,
		className = "",
		rightHeaderExtra,
		leftHeaderExtra,
	} = props;
	
	const {
		isLoading,
		filters,
	} = state;

	const handleCreateNew = () => {
		if (onCreateClick) {
			onCreateClick();
		} else {
			browserHistory.push({
				pathname: pathnameCreate ?? `/${module}/${datastore}/create`,
				state: {
					modal: true,
					returnTo: props.location.pathname,
				},
			});
		}
	};

	const backButton = renderBackButton(props);
	const heading = props.textHeading ?? "";
		
	const appKey = `c6-${module}-${datastore}`;
	return (
		<App
			name={`${appKey} ${className} c6-generic-app`}
			layout="grid"
			isLoading={isLoading}
		>
			<Filter>
				<Left>
					{heading && (
						<h1>
							{backButton}{backButton ? ` / ${heading}` : heading}
						</h1>
					)}
					<Filters
						filterConfig={filterConfig}
						filters={filters}
						collapseFiltersDefault={collapseFiltersDefault}
						appKey={appKey}
					/>
					{leftHeaderExtra}
				</Left>
				<Right>
					{rightHeaderExtra}
					{(onCreateClick || pathnameCreate) && (
						<Button
							type={props.iconCreate ?? "add"}
							title={props.textCreate ?? "Create"}
							onClick={handleCreateNew}
						/>
					)}
				</Right>
			</Filter>
			{props.children}
			{/* <StandardInfo location={props.location} /> */}
		</App>
	);
}


function renderBackButton({ location = {}, routes = [], params = {}, textBackButton }) {
	const returnTo = getReturnTo(location, routes, params);
	if (!returnTo || returnTo === "/" || !textBackButton?.length) {
		return null;
	}

	function handleClick(e) {
		e.preventDefault();
		browserHistory.replace(returnTo);
	}

	return (
		<Link to={returnTo} onClick={handleClick}>
			{textBackButton}
		</Link>
	);
}