import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import groupBy from 'lodash/groupBy'
import forEach from 'lodash/forEach'
//import throttle from 'lodash/throttle'
import InfiniteScroll from 'react-infinite-scroller'

import { decoratorUIActions } from '../../../../core/decorators/uiActions'
import { MENU } from '../../../../components/actions'
import Empty from '../../../../components/list/empty'

import Item from '../../shared/listItem'
import FamilyItem from '../../families/listItem'
import Actions from '../../actions'

const listItemActions = {
	targetComponent: "Content",
	actions: [
		{
			title: "Remove",
			icon: "remove_circle",
			method: (data, props) => {
				Actions.removeProgramFromTag(data.tagItemId, props.tagId, data.id);
			},
			type: MENU,
			confirm: true,
			dangerous: true,
		},
	],
};
@decoratorUIActions(listItemActions)
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		textEmpty: PropTypes.string,
	}

	constructor(props) {
		super(props);

		// this.throttledLoadMore = throttle(props.loadMore, 300); TODO!: Why thottled?
	}

	render() {
		const {
			items,
			isLoading,
			searchText = "",
			filters,
			textEmpty = "Sorry, could not find any items.",
			hasMore,
			loadMore,
		} = this.props;

		const noResult = !(items && items.length);

		return noResult
			? <Empty v2={true} key="empty" isLoading={isLoading}>{textEmpty}</Empty>
			: (
				<InfiniteScroll
					loadMore={loadMore}
					hasMore={hasMore}
					loader={<div key="infinite-loader" className="infinite-loader">Loading ...</div>}
					useWindow={false}
					threshold={700}
					initialLoad={false}>
					{this.renderItems(items)}
				</InfiniteScroll>
			);
	}

	renderItems(items) {
		return items.map(item => {
			if (item.type === "Family") {
				return (
					<FamilyItem
						{...item}
						key={item.id}
						id={item.id}
					/>
				);
			}

			return (
				<Item
					{...item}
					key={item.id}
					id={item.id}
				/>
			);
		});
	}
}