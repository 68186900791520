import React from 'react'

import ContentItem, { Content } from '../../../components/list/contentItem'

const PlaceholderItem = () => (
	<ContentItem data={{}}>
		<div className="c6-preview is-loading" />
		<Content>
			<h1 className="disabled">Loading...</h1>
		</Content>
	</ContentItem>
);

export default PlaceholderItem;