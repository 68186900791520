import React from 'react'

import './approveWarning.css'

const ApproveWarning = ({ model }) => {
	const warning = getWarning(model);

	if (warning) {
		return (
			<div className="icon-error c6-metadata c6-metadata-approveWarning">
				Hey, are you <strong>really sure</strong> you don't want to {warning}?
			</div>
		);
	}

	return null;
};

export default ApproveWarning;

function getWarning({
	distributor,
	approval,
	nid,
	type,
	productionYear,
	productionCountries,
	seasonNumber,
	spokenLanguages,
	defaultGenres,
	imdbId,
}) {
	const approved = approval?.status?.toLowerCase() === "approved";
	if (approved && ["Series", "Single"].includes(type) && !nid) {
		return "add a NID";
	}

	if (distributor === "Britbox") {
		if (approved && ["Season", "Single"].includes(type) && !productionYear) {
			return "add a production year";
		}
		if (approved && ["Season"].includes(type) && (!seasonNumber || seasonNumber === 0)) {
			return "add a season number";
		}
		if (approved && ["Season", "Single"].includes(type) && !productionCountries?.length) {
			return "add a production country";
		}
		if (approved && ["Series", "Single"].includes(type) && !spokenLanguages?.length) {
			return "add a spoken language";
		}
		if (approved && ["Series", "Single"].includes(type) && !defaultGenres?.length) {
			return "add a genre";
		}
		if (approved && ["Series", "Single"].includes(type) && !imdbId?.length) {
			return "add an IMDb ID";
		}
	}

	return null;
}