import { browserHistory } from 'browserHistory'

import { hasAccessToPath } from '../../../core/services/auth'
import { AllRegions } from '../../../core/constants'
import appConfig from 'config'
import MetadataModule from '../module'
import { setWideModal } from '../../../components/editor/index'

export function getTranslateSchema(schema, params, model) {
	const tempFields = {};
	const properties = schema.properties.versions.items.properties;
	Object.keys(properties).forEach(key => {
		tempFields[`${key}-0`] = { ...properties[key], maxLength: undefined, minLength: undefined, pattern: undefined }; // Left/original version
		tempFields[`${key}-1`] = { ...properties[key] }; // Right/active version
	});
	// Remove original locale approval toggler
	delete tempFields["approval-0"];
	delete tempFields["pressSynopsis-0"];

	// Populate dropdown based on languages found in current catalogue config
	const catalogue = MetadataModule.catalogueMap[params.catalogue];
	if (appConfig.features.metadataLocaleEditorSourceLanguageSmartness && catalogue) {
		const versionIds = catalogue.uiData.localeEditorVersions?.map(v => v.targetVersionId) ?? AllRegions.map(r => r.id);
		tempFields["versionName-0"].oneOf = versionIds.map(versionId => {
			const region = AllRegions.find(r => r.id === versionId);
			return {
				const: region.languageDisplayName,
				title: region.languageDisplayName,
				disabled: parseInt(params.versionId) === versionId
			};
		}) ?? [];
		
		// Had to pass this function to the widget through schema instead of uiSchema
		// because it seems like RJSF removes any functions passed through uiSchema ??
		tempFields["versionName-0"].onDropdownChange = (value) => {
			const location = browserHistory.getCurrentLocation();
			browserHistory.replace({
				...location,
				query: {
					...location.query,
					translateFrom: value,
				},
			});
			const languageId = AllRegions.find(r => r.languageDisplayName === value).id;
			saveLanguageIdForProgram(model, languageId);
		};
	}

	const translateSchema = {
		type: "object",
		properties: tempFields,
	};

	return translateSchema;
}

export function getTranslateUiSchema(uiSchema, model, routes, versionId, params) {
	const catalogue = MetadataModule.catalogueMap[params.catalogue];
	const localeIsApproved = model["approval-1"] && model["approval-1"].status && model["approval-1"].status.toLowerCase() === "approved";

	const tempFields = {};
	const items = uiSchema.versions.items;
	Object.keys(items).forEach(key => {
		for (let i = 0; i < 2; i++) {
			const merged = Object.assign({}, items[key]);
			if (!key.includes("approval")) {
				merged["ui:classNames"] += " grid-50";
				merged["ui:classNames"] += i === 0 ? " grid-left" : " grid-right";
			}
			merged["ui:readonly"] = i === 0 || merged["ui:readonly"] || localeIsApproved || !hasAccessToPath(routes, "editor", versionId);
			merged["ui:options"] = {
				...merged["ui:options"],
				floatingLabelFixed: true,
			};
			tempFields[`${key}-${i}`] = merged;
		}
	});

	// Don't show right epgTitle (Press title) when translating
	if (tempFields["epgTitle-1"]) {
		tempFields["epgTitle-1"]["ui:widget"] = "hidden";
	}

	if (appConfig.features.metadataLocaleEditorSourceLanguageSmartness && catalogue) {
		tempFields["versionName-0"]["ui:widget"] = "headerWithDropdown";
	}

	return {
		...tempFields,
		"ui:classNames": "side-by-side",
		"ui:readonly": localeIsApproved,
	};
}

export function getTranslatePatchData(patchData, versionId) {
	const properties = {};
	Object.keys(patchData).forEach(key => {
		// Find patchData["someproperty-1"] and assign it to properties["someproperty"]
		const [property, index] = key.split("-");
		if (!index || parseInt(index) === 1) {
			properties[property] = patchData[key];
		}
	});

	return {
		versions: [{
			versionId,
			versionName: AllRegions.find(region => region.id === versionId)?.languageDisplayName ?? "",
			...properties,
		}]
	};
}

export function getTranslateModel(filteredVersions, model, changeWasSourceLanguage = false) {
	const tempFields = {};

	Object.keys(filteredVersions[0]).forEach(key => {
		tempFields[`${key}-0`] = filteredVersions[0][key];
	});
	// Don't update target version if the user changed source language
	if (!changeWasSourceLanguage) {
		Object.keys(filteredVersions[1]).forEach(key => {
			tempFields[`${key}-1`] = filteredVersions[1][key];
		});
	}

	const payload = { ...model };
	Object.keys(payload).forEach(key => {
		if (
			key.endsWith("-0")
			|| !changeWasSourceLanguage && key.endsWith("-1") // Don't update target version if the user changed source language
		) {
			delete payload[key];
		}
	});
	Object.keys(tempFields).forEach(key => payload[key] = tempFields[key]);
	return payload;
}

export function getOriginalFieldsFromTranslateModel(translateModel) {
	const originalFields = [];
	Object.entries(translateModel).forEach(([key, value]) => {
		if (key.endsWith("-0")) {
			originalFields.push({
				key: key.replace("-0", ""),
				value,
			});
		}
	});
	return originalFields;
}

export function getTranslateFieldsFromTranslateModel(translateModel) {
	const translateFields = [];
	Object.entries(translateModel).forEach(([key, value]) => {
		if (key.endsWith("-1")) {
			translateFields.push({
				key: key.replace("-1", ""),
				value,
			});
		}
	});
	return translateFields;
}

export function getSourceLanguageFromTranslateModel(translateModel) {
	const originalVersionId = translateModel["versionId-0"];
	const region = AllRegions.find(r => r.id === originalVersionId);
	return region.language;
}

export function getTargetLanguageFromTranslateModel(translateModel) {
	const targetVersionId = translateModel["versionId-1"];
	const region = AllRegions.find(r => r.id === targetVersionId);
	return region.language;
}

// Filter the schema so we have the selected locale version + the original locale (for translation) at the start of the version list
export function getFilteredVersions(model, params, location) {
	// Find the version that we are translating to
	const versionId = parseInt(params.versionId);
	const targetVersion = model.versions?.find(v => v.versionId === versionId) ?? {};

	const catalogue = MetadataModule.catalogueMap[params.catalogue];
	if (!catalogue) {
		// If there is no matching catalogue, this is probably a translation list and we should check the metadataTranslationSourceLang config
		const translationSourceVersion = model.versions?.find(v => v.versionName?.toLowerCase() === appConfig.features.metadataTranslationSourceLang?.toLowerCase());
		return translationSourceVersion?.versionId === targetVersion.versionId
			? [translationSourceVersion]
			: [translationSourceVersion, targetVersion];
	}
	
	// Find the version that we are translating from
	const localeEditorVersion = catalogue.uiData.localeEditorVersions.find(v => v.targetVersionId === versionId);
	const originalVersionId = localeEditorVersion?.originalVersionId ?? versionId;
	if (originalVersionId === versionId) {
		return [targetVersion];
	}
	let originalVersion = model.versions?.find(v => v.versionId === originalVersionId);

	if (appConfig.features.metadataLocaleEditorSourceLanguageSmartness) {
		// If there is a version with more available texts than the current originalVersion, use that version instead
		const originalVersionNumberOfAvailableTexts = getNumberOfAvailableTextsInVersion(originalVersion);
		model.versions?.forEach(version => {
			const numberOfAvailableTextsInVersion = getNumberOfAvailableTextsInVersion(version);
			if (version.versionId !== versionId && numberOfAvailableTextsInVersion > originalVersionNumberOfAvailableTexts) {
				originalVersion = version;
			}
		});

		// If there is a saved language for this program/season, use that version instead
		const savedLangId = getSavedLanguageIdForProgram(model);
		if (savedLangId && savedLangId !== versionId) {
			originalVersion = model.versions?.find(v => v.versionId === savedLangId);
		}

		// If there is a query param for a language, use that version instead
		if (location.query?.translateFrom) {
			const overrideOriginalVersionId = AllRegions.find(r => r.languageDisplayName === location.query.translateFrom).id;
			originalVersion = model.versions?.find(v => v.versionId === overrideOriginalVersionId);
		}
	}

	const filteredVersions = [originalVersion, targetVersion];
	if (appConfig.features.metadataLocaleEditorSourceLanguageSmartness) {
		const otherVersions = (model.versions || []).filter(v => v.versionId !== originalVersion?.versionId && v.versionId !== targetVersion?.versionId);
		filteredVersions.push(...otherVersions);
	}
	// Remove duplicates so that we don't translate to the same language we are translating from
	return filteredVersions.filter((v, i, arr) => !!v && arr.indexOf(v) === i);
}

function getNumberOfAvailableTextsInVersion(version = {}) {
	return Object.keys(version)
		.filter(key => key.toLowerCase().includes("title") || key.toLowerCase().includes("synopsis"))
		.filter(key => typeof version[key] === "string" && version[key].length > 0)
		.length;
}

const languageChoiceLocalStorageKey = "c6-translate-from-language";
function getSavedLanguageIdForProgram(program) {
	let id = program.guid;
	if (program.type === "Episode") {
		id = program.grandParentGuid;
	} else if (program.type === "Season") {
		id = program.parentGuid;
	}
	const savedChoices = JSON.parse(localStorage.getItem(languageChoiceLocalStorageKey)) ?? {};
	return savedChoices[id];
}

function saveLanguageIdForProgram(program, languageId) {
	let id = program.guid;
	if (program.type === "Episode") {
		id = program.grandParentGuid;
	} else if (program.type === "Season") {
		id = program.parentGuid;
	}
	const savedChoices = JSON.parse(localStorage.getItem(languageChoiceLocalStorageKey)) ?? {};
	savedChoices[id] = languageId;
	localStorage.setItem(languageChoiceLocalStorageKey, JSON.stringify(savedChoices));
}

export function setWideModalForTranslationEditors(params) {
	const catalogue = MetadataModule.catalogueMap[params.catalogue];
	const versionId = parseInt(params.versionId);
	const version = catalogue?.uiData?.localeEditorVersions.find(v => v.targetVersionId === versionId);
	const isWideModal = version?.targetVersionId !== version?.originalVersionId;
	if (isWideModal) {
		setWideModal();
	}
}