import { get } from '../core/c6'

const API_EPG = "internalepg";
const API_METADATA = "internalmetadata";
const API_VOD = "internalvod";

// #TempHackForTv4InternalSchedule
let API_EPG_TV4 = "internalepg_tv4";
let API_METADATA_TV4 = "internalmetadata_tv4";
let API_VOD_TV4 = "internalvod_tv4";
// eslint-disable-next-line no-undef
const isAuthVersion = __BUILD_PRODUCT__ === "internal-schedule-auth";
if (isAuthVersion) {
	API_EPG_TV4 = "internalepg";
	API_METADATA_TV4 = "internalmetadata";
	API_VOD_TV4 = "internalvod";
}

export const fetchEpgSchedules          = filters               => get(API_EPG, "schedules", filters);
export const fetchEpgBroadcasts         = filters               => get(API_EPG, "broadcasts", filters);

export const fetchMetadataProgram       = (id, filters)         => get(API_METADATA, `programs/${id}`, filters);
export const fetchMetadataSeasons       = (seriesId, filters)   => get(API_METADATA, `programs/${seriesId}/seasons`, filters);
export const fetchMetadataEpisodes      = (seasonId, filters)   => get(API_METADATA, `programs/${seasonId}/episodes`, filters);
export const fetchMetadataFamily        = (id, filters)         => get(API_METADATA, `families/${id}`, filters);
export const search                     = filters               => get(API_METADATA, "search", filters);

export const fetchPackages              = filters               => get(API_VOD, "packages", filters);
export const fetchPackageSchedules      = filters               => get(API_VOD, "packages/premiere", filters);
export const fetchPackageSchedulesExcel = filters               => get(API_VOD, "excel", filters);

// #TempHackForTv4InternalSchedule
export const fetchEpgSchedulesTv4          = filters               => get(API_EPG_TV4, "schedules", filters);
export const fetchEpgBroadcastsTv4         = filters               => get(API_EPG_TV4, "broadcasts", filters);

export const fetchMetadataProgramTv4       = (id, filters)         => get(API_METADATA_TV4, `programs/${id}`, filters);
export const fetchMetadataSeasonsTv4       = (seriesId, filters)   => get(API_METADATA_TV4, `programs/${seriesId}/seasons`, filters);
export const fetchMetadataEpisodesTv4      = (seasonId, filters)   => get(API_METADATA_TV4, `programs/${seasonId}/episodes`, filters);
export const fetchMetadataFamilyTv4        = (id, filters)         => get(API_METADATA_TV4, `families/${id}`, filters);
export const searchTv4                     = filters               => get(API_METADATA_TV4, "search", filters);

export const fetchPackagesTv4              = filters               => get(API_VOD_TV4, "packages", filters);
export const fetchPackageSchedulesTv4      = filters               => get(API_VOD_TV4, "packages/premiere", filters);
export const fetchPackageSchedulesExcelTv4 = filters               => get(API_VOD_TV4, "excel", filters);


/* **************** Generic ***************** */
//export const fetchUrl = url => get(API, url);