import moment from 'moment'

export function getSearchPayload({ filters = {} }, serviceId) {
	let { filter, status, deliveredSince, ...rest } = filters.filter;
	
	// If filter is a date (2018-01-01), remove "filter" and add "premiere"
	let [newFilter, premiere] = /^\d{4}-\d{2}-\d{2}$/.test(filter)
		? [null, filter]
		: [filter, null];

	let excludeStatusObj = {
		hidden: true,
		blocked: true,
		cancelled: true
	}

	if (status?.startsWith("queued")) {
		premiere = null;
		newFilter = status === "queued-unsent" ? "unsent" : "updates";
	} else if (status === "new") {
		premiere = null;
		newFilter = "currentAndUpcoming";
	} else if (status === "_removed") {
		deliveredSince = "1970-01-01";
		status = "unpublished,cancelled,blocked";
		excludeStatusObj.blocked = false;
		excludeStatusObj.cancelled = false;
	}

	if(filters.searchText) {
		excludeStatusObj.cancelled = false;
	}

	// Included cancelled packages if there is a search text
	let excludeStatus = Object.keys(excludeStatusObj).filter(key => excludeStatusObj[key]).join(",");

	return {
		...filters,
		...rest,
		filter: newFilter,
		status,
		premiere,
		serviceId,
		deliveredSince,
		excludeStatus,
		// This filter doesnt work
		// isReady: status === "new" ? true : undefined,
	};
}

export function getStatus() {
	return [
		{
			key: "",
			text: "Any",
			description: "All packages",
		},
		{
			key: "new",
			text: "Scheduled",
			description: "Packages that are ready to be sent when their time comes.",
		},
		// {
		// 	key: "queued-unsent",
		// 	text: "Queued",
		// 	description: "New packages waiting to be sent.",
		// },
		// {
		// 	key: "queued-updates",
		// 	text: "Pending updates",
		// 	description: "Metadata updates waiting to be sent.",
		// },
		{
			key: "published",
			text: "Delivered",
			description: "Packages that have been successfully delivered.",
		},
		{
			key: "_removed",
			text: "Removed",
			description: "Packages that have been successfully delivered and then later removed.",
		}
	];
}

export function getPublishFilters() {
	const filters = [
		{
			key: "current",
			text: "Current",
			description: "Packages with a currently active publish window.",
		},
		{
			key: "upcoming",
			text: "Upcoming",
			description: "Packages with an upcoming publish window.",
		},
	];

	// add the previous month
	const prevMonth = moment().subtract(1, "months").startOf("month");
	filters.push({
		key: prevMonth.format("YYYY-MM-DD"),
		text: prevMonth.format("MMMM"),
		description: `Packages with a publish window starting in ${prevMonth.format("MMMM")}.`,
	});

	let month = moment().startOf("month");
	for (let i = 0; i <= 2; i++) {
		filters.push({
			key: month.format("YYYY-MM-DD"),
			text: month.format("MMMM"),
			description: `Packages with a publish window starting in ${month.format("MMMM")}.`,
		});
		month = month.add(1, "months").startOf("month");
	}

	filters.push({
		key: "lastExported",
		text: "Recently distributed",
		description: "The most recently distributed packages.",
	})

	return filters;
}

export function getDeliveryStatus() {
	return [
		{
			key: "",
			text: "All",
			description: "",
		},
		{
			key: "deliveryList",
			text: "Delivery list",
			description: "",
		},
	];
}