import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import './metadata.css'
import { getChannelVersion } from '../shared/utils';

export default class Metadata extends React.PureComponent {

	static propTypes = {
		program: PropTypes.object.isRequired,
	}

	render() {
		const { program, showRights, language } = this.props;

		return (
			<section className="metadata">
				<p>{getFacts(program, language)}</p>
				{program.credits.length ? <hr /> : null}
				{getCredits(program, "actor")}
				{getCredits(program, "host")}
				{getCredits(program, "director")}
				{getCredits(program, "producer")}
				{getCredits(program, "scriptWriter")}
				{showRights && getRights(program)}
			</section>
		)
	}
}

// HELPERS
function getFacts({ genres, minutes, productionCountries, productionYear, versions }, language) {
	const version = getChannelVersion(versions, language);
	let facts = `${version.genre || ""}${productionYear ? ` ${productionYear}.` : ""}${getList(productionCountries)}`.trim();
	facts = facts ? `${facts}. `: "";
	facts += minutes ? `${minutes} minutes.`: "";
	return facts;
}

function getList(items) {
	return items ? " " + items.join(", ") : "";
}

function getCredits(program, type) {
	const { credits } = program;
	const persons = credits.filter(c => c.type === type);

	return persons.length
		? (
			<div className="program-credits">{getTypeHeading(type)}{persons.map(p => {
				const character = p.character ? <span>{p.character}</span> : null;
				return <div key={p.type + p.name}><span>{p.name}</span>{character}</div>
			})}
			</div>
		)
		: null;
}

function getTypeHeading(type) {
	switch(type) {
		case "actor":
			return <h1>Actors</h1>;
		case "host":
			return <h1>Hosts</h1>;
		case "director":
			return <h1>Directors</h1>;
		case "producer":
			return <h1>Producers</h1>;
		case "scriptWriter":
			return <h1>Writers</h1>;
		default:
			return null;
	}
}


const pendingTitle = "Missing metadata, video or subs.";
function getRights(program) {
	if (!program?.rights?.length) {
		return null;
	}

	const filteredRights = program.rights.filter(r => r.status === "ready" || r.status === "pending").sort((a,b) => {
		if (a.platform === b.platform) {
			return a.type?.localeCompare(b.type);
		}
		return a.platform.localeCompare(b.platform);
	
	});
	console.log(filteredRights);
	return (
		<div className="rights">
			<h1>Platforms</h1>
			{filteredRights.map(right => {
				const pending = right.status === "pending";
				return (
					<div key={`${right.platform}-${right.type}`} className={pending ? "pending" : ""} title={pending ? pendingTitle : null}>
						<div className="platform">{right.platform}{right.type ? ` (${right.type})`:''}</div>
						<div className="from">{moment(right.from).format("YYYY-MM-DD")}</div>
						<div>-</div>
						<div className="until">{moment(right.until).format("YYYY-MM-DD")}</div>
					</div>
				);
			})}
		</div>
	);
}