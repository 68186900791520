import alt from '../../../core/services/alt'
import * as StarData from '../../../apis/star'
import * as MetadataData from '../../../apis/metadata'
import * as Alert from '../../../core/services/alert'
import { handleRequestFailed } from '../../../core/services/errorhandling'
import { GUID_REGEXP } from '../../../core/constants'

import appConfig from 'config'

// HELPERS
const isGuid = str => (GUID_REGEXP.test(str));

const exposeBlockedContainerAssets = appConfig.features.metadataEnableEmbargo;

class Actions {

	setSearching(val) { return val }

	search(searchText, name) {
		return { searchText, name };
	}

	filter(filterValue, filterName) {
		return (dispatch) => {
			dispatch({ [filterName]: filterValue });
		};
	}

	fetchSearchItems(payload) {
		return dispatch => {
			dispatch();

			const requestTime = Date.now();
			const { type, searchText } = payload;

			const requests = [];
			if (!type.length || type.includes("image") || type.includes("screengrab")) {
				let category = "",
					excludeCategory = "";
				if (type.includes("image") && !type.includes("screengrab")) {
					excludeCategory = "screengrab";
				} else if (!type.includes("image") && type.includes("screengrab")) {
					category = "screengrab";
				}
				const imageRequest = StarData.fetchSearchItems({
					type: "image",
					category,
					excludeCategory,
					searchText,
					pageSize: 100,
				});
				requests.push(imageRequest);
			}

			if (!type.length || type.includes("document")) {
				const documentRequest = StarData.fetchSearchItems({
					type: "document",
					searchText,
					pageSize: 100,
				});
				requests.push(documentRequest);
			}

			if (!type.length || type.includes("trailer,clip")) {
				const trailerRequest = StarData.fetchSearchItems({
					type: "trailer,clip",
					searchText,
					pageSize: 100,
				});
				requests.push(trailerRequest);
			}

			const folderRequest = StarData.fetchSearchItems({
				type: "container",
				searchText,
				pageSize: 100,
			});
			requests.push(folderRequest);
			
			Promise.all(requests).then(responses => {
				const items = responses.reduce((prev, current) => [...prev, ...current.items], []);
				this.searchItemsLoaded(items, requestTime);
			});
		};
	}
	searchItemsLoaded(searchItems, requestTime) { return { searchItems, requestTime } };

	selectContainer(container) {
		return dispatch => {
			dispatch();
			StarData.fetchContainer(container.id)
				.then(response => {
					this.selectedContainerLoaded(response);
				}, this.requestFailed);
		}
	}
	selectedContainerLoaded(container) { return container }
	clearSelectedContainer() { return true }


	mergeContainer(containerId, newContainerId, callback) {
		return dispatch => {
			dispatch();

			StarData.mergeContainer(containerId, newContainerId)
				.then(response => {
					this.containerMerged(response);
					Alert.displayAlert("success", "Successfully merged container!");
					if (typeof callback === "function") {
						callback();
					}
				}, this.requestFailed);
		};
	}

	containerMerged(newContainer) {
		return newContainer;
	}

	/* Container */
	fetchContainer(_reference, _providerId, containerType, metadataProgramId) {
		return (dispatch) => {
			dispatch();

			let metadataProgramPromise = null;
			if (metadataProgramId) {
				metadataProgramPromise = MetadataData.fetchProgram({ id: metadataProgramId });
			} else {
				metadataProgramPromise = Promise.resolve(null);
			}

			metadataProgramPromise.then(metadataProgram => {
				const providerId = metadataProgram?.provider?.id ?? _providerId;
				const reference = metadataProgram?.guid ?? _reference;
				
				const payload = {
					name: 'Awaiting sync...',
					type: containerType,
				};

				if (isGuid(reference)) {
					payload.programGuid = reference;
					StarData.fetchContainerByGuid(reference, exposeBlockedContainerAssets)
						.then(response => this.handleContainerReferenceResponse(response, payload), this.handleContainerReferenceError);
				} else {
					payload.reference = reference;
					StarData.fetchContainerByReference(reference, providerId, exposeBlockedContainerAssets)
						.then(response => this.handleContainerReferenceResponse(response, payload), this.handleContainerReferenceError);
				}
			});
		};
	}
	
	handleContainerReferenceResponse(response, payload) {
		return (dispatch) => {
			dispatch();

			if (response) {
				this.updateContainer(response);
			} else {
				StarData.createContainer(payload).then((createResponse) => {
					this.updateContainer(createResponse);
				}, this.requestFailed);
			}
		};
	}

	fetchParentContainer(parentId) {
		return dispatch => {
			dispatch();
			StarData.fetchContainer(parentId)
				.then(this.parentContainerLoaded, this.requestFailed);
		}
	}

	parentContainerLoaded(parentContainer) { return parentContainer }

	updateTitle(containerId, newTitle, container) {
		return (dispatch) => {
			dispatch();

			const payload = {
				...container,
				displayName: newTitle,
			};

			StarData.updateContainer(containerId, payload, exposeBlockedContainerAssets)
				.then((response) => {
					this.updateContainer(response);
				}, this.requestFailed);
		};
	}

	approveContainer(container, isApproved, targetStore) {
		return (dispatch) => {
			dispatch({ container, isApproved, targetStore });

			StarData.approveContainer(container.id, isApproved, exposeBlockedContainerAssets)
				.then((response) => {
					this.updateContainer(response);
				}, this.requestFailed);
		};
	}

	updateContainer(container) {
		return container;
	}
	updateContainerAndKeepUnsavedEmbargo(container, embargoAssetId) {
		return { container, embargoAssetId };
	}

	uploadUpdate(file) {
		return file;
	}

	removeAssetFromContainer(containerId, assetIds, category, categoryIndex) {
		return (dispatch) => {
			dispatch();

			const payload = {
				assetIds,
				category,
				index: categoryIndex,
				containerId,
			};

			if (typeof assetIds[0] === "string" && assetIds[0].includes("new_embargo")) {
				this.removeUnsavedEmbargo(assetIds[0]);
			} else {
				StarData.removeAssetsFromContainer(containerId, payload, exposeBlockedContainerAssets)
					.then((response) => {
						this.updateContainer(response);
						if (category === 'Main') {
							this.mainUpdated(response);
						}
					}, this.requestFailed);
			}
		};
	}

	addAssetsToContainer(updateType, containerId, assetIds, category, categoryIndex, publishFrom) {
		const index = categoryIndex || 0;
		const payload = {
			assetIds,
			category,
			index,
			containerId,
			publishFrom,
		};
		return (dispatch) => {
			dispatch();
			StarData.connectAssetsToContainer(containerId, payload, exposeBlockedContainerAssets)
				.then((response) => {
					console.log("response", response);
					if (updateType === 'parent') {
						this.updateContainer(response);
						if (category === 'Main') {
							this.mainUpdated(response);
						}
					} else if (updateType === 'child') {
						this.fetchChildContainers(response.parentId);
						Alert.displayAlert('success', `Added asset${assetIds.length > 1 ? 's' : ''} to container (ID: ${containerId}).`);
					} else {
						Alert.displayAlert('error', 'No updateType defined in addAssetsToContainer');
					}
				}, this.requestFailed);
		};
	}

	updateAssetPublishFrom(containerId, assetId, categoryId, categoryIndex, publishFrom) {
		const payload = {
			assetId,
			categoryId,
			index: categoryIndex,
			containerId,
			publishFrom,
		};
		return (dispatch) => {
			dispatch();

			if (typeof assetId === "string" && assetId.includes("new_embargo")) {
				this.updateUnsavedEmbargo(assetId, publishFrom);
			} else {
				StarData.updateAssetPublishFrom(containerId, payload, exposeBlockedContainerAssets)
					.then((response) => {
						this.updateContainer(response);
					}, this.requestFailed);
			}
		};
	}

	mainUpdated(response) {
		return response;
	}

	addEmbargo(asset) {
		return dispatch => dispatch(asset);
	}

	removeUnsavedEmbargo(id) {
		return id;
	}
	updateUnsavedEmbargo(id, publishFrom) {
		return { id, publishFrom };
	}

	/* Child containers */
	fetchChildContainers(parentId) {
		return (dispatch) => {
			dispatch();
			StarData.fetchContainers({ parent: parentId, excludeAssets: true })
				.then((response) => {
					this.updateChildContainers(response.items);
				}, this.requestFailed);
		};
	}

	updateChildContainers(childContainers) {
		return childContainers;
	}

	/* Container Types */
	fetchTypes() {
		return dispatch => {
			dispatch();
			StarData.fetchTypes()
				.then(response => {
					const types = response.items;
					this.typesLoaded(types);
				}, this.requestFailed);
		};
	}

	typesLoaded(types) {
		return types;
	}



	/* Helpers */
	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}
}

export default alt.createActions(Actions);
