import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'
import upperFirst from 'lodash/upperFirst'

import {Filter, Left, Right} from '../../../components/filter'
import Preview from '../../../components/assets/preview'
import Label from '../../../components/ui/label'

import './preview.css'

import appConfig from 'config'
import QCLink, { getQCUrl, useStarToken } from './qcLink'
import { copyTextToClipboard } from '../../../utils/misc'

const Metadata = ({ model, withQCLinkOnImage = false, leftContentExtra }) => {
	const {
		cometId,
		displayName,
		originalTitle,
		name,
		credits = {},
		duration,
		// premieres,
		reference,
		externalMediaRef,
		guid,
		type,
		category,
		links,
		// master,
		// slaves,
		provider,
		productCode,
	} = model;

	// Add special keyboard shortcut for Axel so that he can copy program GUIDs... :)
	const copyGuidToClipboard = React.useCallback(
		(e) => {
			if (e.ctrlKey && e.shiftKey && e.code === "KeyU") {
				copyTextToClipboard(guid, "Program GUID copied to clipboard.");
			}
		},
		[guid]
	);
	React.useEffect(
		() => {
			document.removeEventListener("keypress", copyGuidToClipboard)
			document.addEventListener("keypress", copyGuidToClipboard);
			return () => {
				document.removeEventListener("keypress", copyGuidToClipboard)
			};
		},
		[copyGuidToClipboard]
	);

	const contentApiId = cometId ? [<dt key="a">Content API</dt>,<dd key="b">{cometId}</dd>] : null;
	const referenceName = provider && `${(provider.name === "Won" ? "WHATS'ON" : provider.name) || ""} ref` || "";
	const wonProductCode = provider?.name === "Won" && <><dt>Product code</dt><dd>{productCode}</dd></>;
	const externalMediaReference = externalMediaRef && <><dt>Ext. media ref</dt><dd>{externalMediaRef}</dd></>;

	const hasVideo = (type === "Episode" || type === "Single");
	const showQCLinkOnImage = withQCLinkOnImage && hasVideo && appConfig.features.metadataNewActionsLayout && appConfig.features.metadataQCBaseUrl;
	const starToken = useStarToken(!showQCLinkOnImage);

	return (
		<Filter className={`c6-metadata c6-metadata-preview ${showQCLinkOnImage ? "has-image-qc-link" : ""}`}>
			<Left>
				<Preview
					size="medium"
					programId={guid}
					onClick={showQCLinkOnImage ? () => openQCInNewTab(model, starToken) : null}
				/>
				{showQCLinkOnImage && (
					<div className="image-qc-link icon-play_circle_outline"></div>
				)}
				<div>
					<h1>{displayName || originalTitle || name}</h1>
					{getTypeLabel(type)}
					<section>{getProgramInfo(model)}{duration > 0 && duration +  " min."}</section>
					{getCrewMetadata(credits, type, category)}
					{getFamilyInfo(links)}
					{leftContentExtra}
					{/*getMasterSlaveStatus(master, slaves)*/}
				</div>
			</Left>
			<Right>
				<dl>
					{contentApiId}
					<dt>{referenceName}</dt>
					<dd>{reference}</dd>
					{externalMediaReference}
					{wonProductCode}
					{getImdbLink(model)}
				</dl>
				{(appConfig.features.metadataQCLink && !appConfig.features.metadataNewActionsLayout && type === "Episode") && (
					<QCLink program={model} />
				)}
			</Right>
		</Filter>
	);
};

{/* <dt>Premiere</dt>
<dd>{premieres && premieres[0].on}</dd> */}

export default Metadata;

// HELPERS
function getProgramInfo({ type, defaultGenres = [], productionCountries = [], productionYear }) {

	if(defaultGenres.length || productionCountries.length || productionYear) {
		return (
			<ul>
				{(type === "Series" || type === "Single") && getProperty(defaultGenres, "name")}
				{getProperty(productionCountries, "name")}
				{ productionYear ? <li>{ productionYear}</li> : null }
			</ul>
		);
	}

	return null;
}

function getProperty(arrayEl, prop) {
	if(arrayEl && arrayEl.length) {
		return <li>{upperFirst(arrayEl[0][prop])}</li>;
	}

	return null;
}

function getImdbLink(model) {
	const imdbId = model.imdbId; // || model.seriesImdbId;
	const imdbLink = imdbId
		? <a href={`http://imdb.com/title/${imdbId}`} className="c6-link" target="_blank">{imdbId}</a>
		: <a href={`http://imdb.com/find?q=${model.originalTitle}&s=all`} className="c6-link" target="_blank">Search</a> ;
	
	// For now, only show IMDB link for series
	if (model.type === "Series" || model.type === "Single") {
		return [
			<dt key="label">IMDb</dt>,
			<dd key="value">{imdbLink}</dd>
		];
	}

	return null;
}

function getCrewMetadata(credits, type, category) {

	let crewTypes = [];
	switch(type) {
		case "News":
			crewTypes = ["host"];
			break;
		case "Sport":
			crewTypes = ["commentators", "experts", "guests"];
			break;
		default:
			crewTypes = ["actors", "directors"];
	}

	return Object.keys(credits).length
		? crewTypes.map(ct => {
			const persons = credits[ct] && credits[ct].length ? credits[ct].slice(0,3) : null; // Only show the first three persons
			return persons
				? (
					<div key={ct}>
						{getCrewHeading(ct)} <ul className="and">
							{persons.map(c => c.name ? <li key={c.id ?? c.reactKey ?? c.name}>{c.name}</li>: null)}
						</ul>
					</div>
				)
			: null;
		})
		: null ;
}

function getTypeLabel(type) {
	const typeName = type === "Single" ? "Single program" : type;
	return type ? <Label status="active" size="large">{typeName}</Label> : null;
}

function getCrewHeading(type = "") {
	switch(type) {
		case "actors":
			return "";
			break;
		case "directors":
			return "Directed by:";
			break;
		default:
			return type.toUppercase();
	}
}

function getFamilyInfo(links = []) {
	if (!links.length) {
		return null;
	}

	return (
		<div>
			Families: <ul className="and">
				{links.map(family => (
					<li key={family.programId}>
						<Link to={`/metadata/families/${family.programId}`} className="c6-link">{family.name}</Link>
					</li>
				))}
			</ul>
		</div>
	);
}

const tempFakeMaster = {
	id: 1,
	displayName: "Top Gun 1",
};
const tempFakeSlaves = [
	{ id: 1, displayName: "Top Gun 1" },
	{ id: 2, displayName: "Top Gun 2" },
	{ id: 3, displayName: "Top Gun 3" },
];
function getMasterSlaveStatus(master = tempFakeMaster, slaves = tempFakeSlaves) {
	const handleLinkClick = (e, id) => {
		e.preventDefault();
		browserHistory.replace({ //replace because it breaks if we use push ?
			pathname: `/metadata/programs/${id}/edit`,
		});
	};

	if (slaves.length) {
		return (
			<div>
				Slaves: <ul className="and">
					{slaves.map(slave => (
						<li key={slave.id}>
							<Link to={`/metadata/programs/${slave.id}/edit`} onClick={e => handleLinkClick(e, slave.id)} className="c6-link">{slave.displayName}</Link>
						</li>
					))}
				</ul>
			</div>
		);
	}

	if (master.id) {
		return (
			<div>
				This program has a master: <Link to={`/metadata/programs/${master.id}/edit`} onClick={e => handleLinkClick(e, master.id)} className="c6-link">{master.displayName}</Link>
			</div>
		);
	}
}


function openQCInNewTab(program, starToken) {
	const light = true;
	const url = getQCUrl(program, starToken, light);
	window.open(url, "_blank");
}