import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Preview from '../assets/preview'

import './dragPreview.css'

const SIZE = {
	name: "starAsset",
	width: "min-content",
	height: "75px",
}

export default class DragPreview extends PureComponent {
	static propTypes = {
		item: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)
	}

	render() {
		const { data, selectedItems = [] } = this.props.item;

		// Make sure the item we're dragging comes first and the rest of the selected items comes after
		let previewImages = selectedItems.filter(i => i.assetGuid !== data.assetGuid).map(i => i.assetGuid);
		if (data?.assetGuid) {
			previewImages.unshift(data.assetGuid);
		}

		const visibleCount = previewImages.length > 1 ? previewImages.length : "";
		const count = visibleCount ? { ["data-visibleCount"]: visibleCount } : null;

		return (
			<div className="c6-drag-preview" {...count}>
				{previewImages.slice(0, 3).map(guid => <Preview key={guid} imageGuid={guid} size={SIZE} />)}
			</div>
		)
	}
}