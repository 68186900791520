import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router'

import { hasAccessToPath } from '../../../core/services/auth'
import { MetadataContainerTypes } from '../../../core/constants'
import Preview from '../../../components/assets/preview'
import Actions from '../actions'

const ENTITY = "program";
const SWEDISH = 1;
const NORWEGIAN = 2;
const DANISH = 3;
const FINNISH = 4;
const VERSIONS = [SWEDISH]; // Only show Swedish for TV4

import styles from '../../../components/listItems/standard.css'

@withRouter
export default class ProgramContent extends PureComponent {

	static propTypes = {
		id: PropTypes.number,
		reference: PropTypes.string,
		provider: PropTypes.object,
		approval: PropTypes.object,
		seasonApproval: PropTypes.object,
		seriesApproval: PropTypes.object,
		imageApproval: PropTypes.object,
		versions: PropTypes.array,

		displayName: PropTypes.string,

		parentId: PropTypes.number,
		numberOfEpisodes: PropTypes.number,

		className: PropTypes.string,
		noLink: PropTypes.bool,

		location: PropTypes.object, // From @withRouter
	}

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(id, pathname, wideModal, typeId, query, e) {
		e.preventDefault();
		e.stopPropagation();
		const { location } = this.props;

		Actions.edit({
			id,
			pathname,
			query,
			returnTo: `${location.pathname}${location.search}`,
			wideModal,
			typeId
		});
	};

	render() {
		const {
			id,
			guid,
			displayName,
			reference,
			provider,
			approval,
			imageApproval,
			tagsApproval,
			name,
			versions: originalVersions,
			type,
			className = "",
		} = this.props;

		const versions = originalVersions && originalVersions.filter(v => VERSIONS.includes(v.versionId));
		const tagsAccess = { module: "metadata", app: "tags", access: "reader" };

	    return (
			<div className={`flex flex-dyn-1 ${className}`}>
				<Preview programId={guid} />
				<div className="c6-content">
					<h1>{name}</h1>
				</div>
				<div className="c6-content-actions">
					{getAction({
						hasAccessToPath,
						access: tagsAccess,
						id,
						title: "Tags",
						approval: tagsApproval,
						onClick: this.handleClick,
						name: displayName,
						guid,
					})}
					{versions && versions.map(v => getAction({id, title: v.versionName, approval: v.approval, onClick: this.handleClick, versionId: v.versionId}))}
					{getAction({id, title: "Images", approval: imageApproval, onClick: this.handleClick, reference, guid, providerId: provider.id, hasAccessToPath, type})}
				</div>
			</div>
		);
  	}
}

function getAction({ id, title = "Missing type", approval, onClick, versionId, reference, guid, providerId, hasAccessToPath = () => true, type, name }) {

	const classNames = approval && approval.status && approval.status.toLowerCase() === "approved"
		? "fg-green icon-check"
		: "fg-red icon-close";

	// HACK: We should find a way to validate router navigation routes when rendering them
	// The problem with using the react-router match util is that it's async so we need to
	// store the state and update it when the match resolves, which is not ideal
	const route = [{ module: "metadata", app: "images", access: "reader" }];

	const typeId = type ? MetadataContainerTypes.find(item => item.name === type).id : null;

	if (hasAccessToPath(route)) {
		const { pathname, query } = getPath(title, id, versionId, providerId, reference, guid, name);
		const wideModal = pathname.indexOf('images') >= 0;

		return (
			<button
				key={title}
				className={classNames}
				onClick={onClick.bind(this, id, pathname, wideModal, typeId, query)}>
				<span>{title}</span>
			</button>
		);
	}

	return (
		<button disabled className={classNames}>
			<span>{title}</span>
		</button>
	);
}

function getPath(title, id, versionId, providerId, reference, guid, name) {
	const locale = versionId ? `/locale/${versionId}` : '';
	const referenceVal = reference || guid;

	if (title === "Images") {
		return { pathname: `/metadata/images/${ENTITY}/${id}/provider/${providerId}/reference/${referenceVal}` };
	}
	
	if (title === "Tags") {
		return {
			pathname: `/metadata/${ENTITY}s/${id}/tags/edit`,
			query: {
				name,
				referenceId: id,
			}
		};
	}

	return { pathname: `/metadata/families/${id}/edit` };
}