import React, { Component } from 'react'
import debounce from 'lodash/debounce'

import { MuiText, MuiSelect } from '../../components/ui/editorWidgets'
import Button from '../../components/ui/controls/button'
import Scroll from '../../components/scroll'
import Empty from '../../components/list/empty'

import Header from './header'
import List from './list'
import Store from './store'
import Actions from './actions'

const TEXT_HEADING = "Add program to existing family";

export default class ExistingFamily extends Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.debouncedFilter = debounce(this.handleFilter, 500);

		this.state = {
			...Store.getState()
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);

		Actions.fetchFamilies({ filters: this.state.filters });
	}

	onChange(state) {
		this.setState(state);
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onFilter(type, e) {
		if (type === "search") {
			e.persist();
			this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
	}

	handleFilter(type, event) {
		// if(this.scroll) {
		// 	this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		// }

		const { name, value } = event.target;
		Actions[type](value, name);
		Actions.fetchFamilies({ filters: this.state.filters });

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		// window.dispatchEvent(window.customLazyEvent);
	}

	render() {
		const {
			isLoading,
			searchText: componentSearchText,
			filters,
			unselectedFilter,

			families,
			selectedItem,
		} = this.state;

		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

		const {
			data
		} = this.props;

		return (
			<div>
				<Header
					title={TEXT_HEADING}
					showSearch={true}
					searchText={searchText}
					filters={filters}
					unselectedFilters={unselectedFilter || !!searchText.length}
					handleFilter={this.onFilter}
				/>
				<Scroll nopadding={true} inDialog={true}>
					<List
						isLoading={isLoading}
						items={families}
						conditions={filters}
						onSetFamily={(family, typeId) => Actions.addProgramToFamily(data, family, typeId)}
						selectedItem={selectedItem}
						onSelectItem={item => Actions.selectItem(item)}
						linkedPrograms={data.links}
					/>
				</Scroll>
			</div>
		);
	}
}
