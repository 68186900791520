import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router'

import Actions from './actions'

import ContentItem, { Content } from '../../../components/list/contentItem'

@withRouter
export default class ProgramContent extends PureComponent {

	static propTypes = {
		id: PropTypes.number,

		displayName: PropTypes.string,
		tagType: PropTypes.object,

		className: PropTypes.string,
	}

	render() {
		const {
			id,
			displayName,
			tagType,
			totalItems = 0,
			items,
			className = "",
			disabled,
		} = this.props;

		const allowEditing = tagType.id <= 3 || tagType.id >= 6; // Don't allow editing for tagType.id 4 & 5 (Ad tag & Category tag)
		const tagTypeText = allowEditing && tagType.name;
		const programsTextOrLink = allowEditing && getProgramsTextOrLink(id, totalItems);

		const disabledText = disabled
			? <span style={{ color: "var(--attention-color)", marginLeft: "10px" }}>(Disabled)</span>
			: null;

		return (
			<ContentItem data={this.props}>
				<Content>
					<h1>{displayName}{disabledText}</h1>
					<p>{/*tagTypeText*/}</p>
					<p>{programsTextOrLink}</p>
				</Content>
			</ContentItem>
		);
	}
}


function getProgramsTextOrLink(id, totalItems) {
	if (totalItems <= 0) {
		return "Programs: 0";
	}

	return (<Link to={`/metadata/tags/${id}/programs`} className="c6-link">Programs: {totalItems}</Link>);
}
