import React from 'react'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

import './synopsisEmbargoController.css'

import { ConfirmPlugin } from '../../../components/cms/components/datetimepicker'
import Button from '../../../components/ui/controls/button'
import EditorActions from '../../../components/editor/actions'

import { localeCompareSortNumeric } from '../../../core/services/sorting'

const SynopsisEmbargoController = ({ formData, onChange, readonly }) => {
    let current = formData.current ?? moment().startOf("minute").format();
    if (formData.current === "now") {
        const now = moment();
        const sortedDesc = formData.all.sort(sortDates).reverse();
        const activeDate = sortedDesc.find(d => {
            return d === "default" || moment(d).isSameOrBefore(now);
        });
        current = activeDate;
    }

    let embargoDate;
    if (current === "now") {
        embargoDate = moment();
    } else if (current === "default") {
        embargoDate = null;
    } else {
        embargoDate = moment(current);
    }
    
    const onDateChange = React.useCallback(
        (value) => {
            const newValue = {
                ...formData,
                reschedule: moment(value).startOf("minute").format(),
            };
            onChange(newValue);

            EditorActions.setDirty();
        },
        [current, formData, onChange]
    );

    const changeInstance = (direction = "next") => {
        const sortedDates = formData.all.sort(sortDates);
        const currentIndex = Math.max(sortedDates.indexOf(current), 0);
        let newIndex = currentIndex + (direction === "next" ? 1 : -1);
        newIndex = Math.min(Math.max(newIndex, 0), formData.all.length - 1);
        const newValue = {
            current: sortedDates[newIndex],
            all: sortedDates,
        };
        onChange(newValue);
    };

    const goToPrevInstance = (e) => {
        e.preventDefault();
        changeInstance("prev");
    };

    const goToNextInstance = (e) => {
        e.preventDefault();
        changeInstance("next");
    };

    const {
        hasNext,
        hasPrevious,
        // currentFrom,
        currentTo,
    } = getInstanceInfo(formData.all, embargoDate?.format());

	return (
        <React.Fragment>
            {formData.all?.length >= 2 && (
                <div className="c6-metadata-synopsis-embargo">
                    <Button
                        type="prev"
                        onClick={goToPrevInstance}
                        className="instance-button"
                        disabled={!hasPrevious}
                    />
                    <div className="current-date-info">
                        {!embargoDate && (
                            <div className="current-from">Default/original version</div>
                        )}
                        {embargoDate && (
                            <Flatpickr
                                // HACK: Add a key to our Flatpickr so that we get a new instance(?) every time our formData changes.
                                // Otherwise, the ConfirmPlugin will be using an old version of the onDateChange function.
                                key={formData.current}
                                options={{
                                    altInput: true,
                                    altFormat: "D j M H:i",
                                    locale: {
                                        firstDayOfWeek: 1
                                    },
                                    enableTime: true,
                                    // TODO: mindate
                                    // minDate: "2023-01-01"
                                    time_24hr: true,
                                    plugins: [new ConfirmPlugin({ onChange: onDateChange })],
                                }}
                                value={embargoDate?.format()}
                                onChange={() => {}}
                                onReady={onFpReady}
                                placeholder="Default/original"
                            />
                        )}
                        <div className="large-icon icon-arrow_right_alt"></div>
                        <div className="current-to">{currentTo ? moment(currentTo).format("ddd D MMM HH:mm") : (<span className="large-icon icon-all-inclusive"></span>)}</div>
                    </div>
                    
                    <Button
                        type="next"
                        onClick={goToNextInstance}
                        className="instance-button"
                        disabled={!hasNext}
                    />
                </div>
            )}
            {!readonly && (
                <div className="c6-metadata-synopsis-embargo-buttons">
                    {current !== undefined && current !== "default" && (
                        <Button
                            className="c6-metadata-synopsis-embargo-delete"
                            type="delete"
                            title="Delete this version"
                            dangerous
                            confirm="Really delete this version?"
                            onClick={() => {
                                onChange({
                                    ...formData,
                                    delete: true,
                                });

                                EditorActions.setDirty();
                            }}
                            noBackground
                        />
                    )}
                    <Button
                        className="c6-metadata-synopsis-embargo-activate"
                        type="schedule"
                        title="Schedule future version"
                        onClick={() => {
                            const sorted = formData.all.sort(sortDates);
                            const lastDate = sorted.pop();
                            
                            const newVersionStart = lastDate.includes("-")
                                ? moment.max(moment(lastDate), moment()) // Use new moment() if lastDate is in the past
                                    .add(1, "week")
                                    .startOf("hour")
                                    .format()
                                : moment() // default/current
                                    .add(1, "week")
                                    .startOf("hour")
                                    .format();

                            onChange({
                                all: [...formData.all, newVersionStart],
                                current: newVersionStart,
                            });
                        }}
                        noBackground
                    />
                </div>
            )}
        </React.Fragment>
	);
};

function onFpReady(_, __, fp) {
	fp.calendarContainer?.classList.add("c6-cms-datetimepicker");
}

export default SynopsisEmbargoController;

const sortDates = localeCompareSortNumeric(d => d === "default" ? "1970-01-01" : d);

function getInstanceInfo(all, current) {
    const sortedDates = all.sort(sortDates);
    let currentIndex = sortedDates.findIndex(d => {
        return d === current
            || (d !== "now" && d !== "default" && current !== "now" && current !== "default" && moment(d).isSame(current));
    });
    currentIndex = Math.max(currentIndex, 0);

    return {
        hasNext: currentIndex < sortedDates.length - 1,
        hasPrevious: currentIndex > 0,
        currentFrom: sortedDates[currentIndex],
        currentTo: sortedDates[currentIndex + 1],
    };
}
