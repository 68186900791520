import React from 'react'

import './textbox_new.css'

const TextboxNew = ({
	name,
	value,
	displayName,
	onChange,
	className,
	readOnly,
	style,
	options,
	getTooltipEl = () => null,
	tooltip,
	onBlur,
	autoFocus = false,
	dataType,
}) => {

	let defaultValue;
	let resizeHeight;
	if (dataType === "integer") {
		resizeHeight = false;
		defaultValue = isNaN(value) ? 0 : value;
	} else {
		resizeHeight = true;
		defaultValue = value?.length ? value : "";
	}

	const slim = style?.format?.includes("slim");
	const multiline = !slim;

	const inputRef = React.useRef();
	React.useEffect(
		() => resizeToContent(inputRef.current, slim, resizeHeight),
		[slim]
	);

	const onInputChange = React.useCallback(
		(e) => resizeToContent(e.target, slim, resizeHeight),
		[slim]
	);

	// Update textarea when value is changed somewhere else
	React.useEffect(
		() => {
			if (inputRef.current) {
				inputRef.current.value = defaultValue;
				resizeToContent(inputRef.current, slim, resizeHeight);
			}
		},
		[defaultValue, slim]
	);

	const onKeyDown = React.useCallback(
		async (e) => {
			switch (e.key) {
				case "Esc":
				case "Escape":
					e.preventDefault();
					e.target.value = defaultValue;
					e.target.blur();
					resizeToContent(e.target, slim, resizeHeight);
					break;
				case "Enter":
					if (!e.shiftKey) { // shift + enter should still add newline
						e.preventDefault();
						const el = e.target;
						await onChange(el.value);
						el.blur();
					}
					break;
			}
		},
		[defaultValue, onChange, slim]
	);

	const onTextareaBlur = React.useCallback(
		async (e) => {
			let newValue;
			if (dataType === "integer") {
				newValue = Number(e.target.value);
			} else {
				newValue = e.target.value?.length ? e.target.value : "";
			}
			if (newValue !== defaultValue) {
				e.preventDefault();
				
				const save = window.confirm("Unsaved changes in textbox. Save them using Enter/OK or discard using Escape/Cancel");
				if (save) {
					await onChange(newValue);
				} else {
					e.target.value = defaultValue;
					resizeToContent(e.target, slim, resizeHeight);
				}
			}

			if (typeof onBlur === "function") {
				onBlur();
			}
		},
		[defaultValue, slim, dataType]
	);

	const onTextareaClick = React.useCallback(
		(e) => {
			e.stopPropagation();
		},
		[]
	);

	return (
		<div className={`c6-cms-textbox ${className} ${slim ? "slim" : ""} ${multiline ? "multiline" : ""}`}>
			{!slim && <label>{displayName ?? name}{getTooltipEl(tooltip)}</label>}
			{dataType === "integer" && (
				<input
					type="number"
					ref={inputRef}
					onChange={onInputChange}
					defaultValue={defaultValue}
					readOnly={readOnly}
					onKeyDown={onKeyDown}
					onBlur={onTextareaBlur}
					onClick={onTextareaClick}
					spellCheck={false}
					tabIndex={0}
					autoFocus={autoFocus}
				/>
			)}
			{dataType !== "integer" && (
				<textarea
					ref={inputRef}
					onChange={onInputChange}
					defaultValue={defaultValue}
					readOnly={readOnly}
					maxLength={options?.maxLength}
					onKeyDown={onKeyDown}
					onBlur={onTextareaBlur}
					onClick={onTextareaClick}
					spellCheck={false}
					tabIndex={0}
					autoFocus={autoFocus}
				/>
			)}
			<span className="hidden-width-trick"></span>
		</div>
	);
}

export default TextboxNew;


function resizeToContent(element, slim, resizeHeight) {
	const spanEl = element.nextElementSibling;
	if (spanEl && slim) {
		spanEl.textContent = element.value;
		element.style.width = `${spanEl.offsetWidth + 10}px`;
	}

	if (resizeHeight) {
		element.style.height = "1px";
		element.style.height = `${element.scrollHeight + 2}px`;
	}
}