import React, { Component } from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import forEach from 'lodash/forEach'
import InfiniteScroll from 'react-infinite-scroller'

import { decoratorUIActions } from '../../../core/decorators/uiActions'
import { MENU } from '../../../components/actions'
import { ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'

import Item from './listItem'
import Actions from './actions'
import { hasAccessToPath } from '../../../core/services/auth'

const listItemActions = {
	targetComponent: "Content",
	actions: [
		{
			title: "Edit",
			icon: "create",
			method: (data) => {
				const { id } = data;
				const { pathname, query } = getPath(id);
				Actions.edit({
					id,
					pathname,
					query,
					returnTo: `${window.location.pathname}${window.location.search}`,
				});
			},
			type: MENU,
			hidden: (data) => {
				return !hasAccessToPath([{ module: "metadata", app: "library", access: "editor" }]);
			},
		},
		{
			title: "Enable",
			icon: "add_circle",
			method: data => {
				Actions.enableTag(data.id)
			},
			type: MENU,
			// confirm: true,
			hidden: data => !data.disabled
		},
		{
			title: "Disable",
			icon: "remove_circle",
			method: data => {
				Actions.disableTag(data.id)
			},
			type: MENU,
			// confirm: true,
			dangerous: true,
			hidden: data => data.disabled
		},
	]
};
@decoratorUIActions(listItemActions)
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		textEmpty: PropTypes.string,
	}

	constructor(props) {
		super(props);
	}

	render() {
		const {
			items,
			isLoading,
			searchText = "",
			filters,
			textEmpty = "Sorry, could not find any items.",
			hasMore,
			loadMore,
		} = this.props;

		const noResult = !(items && items.length);

		return noResult
			? <Empty key="empty" v2={true} isLoading={isLoading}>{textEmpty}</Empty>
			: (
				<InfiniteScroll
					loadMore={loadMore}
					hasMore={hasMore}
					loader={<div className="infinite-loader" key="infinite-loader">Loading ...</div>}
					useWindow={false}
					threshold={700}
					initialLoad={false}>
					{this.renderGroups(items, searchText)}
				</InfiniteScroll>
			);
	}

	renderGroups(items, searchText) {
		if (searchText) {
			return (
				<ItemGroup title={`Items matching "${searchText}"`}>
					{this.renderItems(items)}
				</ItemGroup>
			);
		}

		const groupedItems = groupItems(items);
		let keys = Object.keys(groupedItems);

		// Move group 0 (Content discovery tags) to end of keys array
		const index = keys.findIndex(i => i == "0");
		if (index >= 0) {
			const first = keys.splice(index, 1);
			keys = [...keys, ...first];
		}

		keys = keys.filter(key => key != 6 && key != 7); // hide anno + aman
		return keys.map(groupKey => (
			<ItemGroup
				key={groupKey}
				title={getGroupTitle(groupKey)}
				collapsible={true}
				initiallyCollapsed={groupKey > 3}
			>
				{this.renderItems(groupedItems[groupKey], groupKey)}
			</ItemGroup>
		));
	}

	renderItems(items, groupKey) {
		return items.map(item => (
			<Item
				{...item}
				key={item.id}
				id={item.id}
			/>
		));
	}
}

// HELPERS
function groupItems(items) {
	return groupBy(items, item => item.tagType.id > 3 ? item.tagType.id : 0);
}

function getGroupTitle(group) {
	switch (parseInt(group)) {
		case 0:
			return "Content discovery tags";
		case 4:
			return "Ad tags";
		case 5:
			return "Category tags";
	}
}

function getPath(id) {
	return { pathname: `/metadata/tags/${id}/edit` };
}