import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { decoratorUIActions } from '../../../core/decorators/uiActions'

import { ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'
import Switch from '../../../components/ui/controls/switch'

import Placeholder    	from '../shared/placeholderItem'
import MissingSeries  	from '../shared/missingSeriesItem'
import Item          	from '../shared/listItem'
import contentActions 	from '../shared/listItemActions'

import { getItemTemplateModification } from '../shared/utils'
import appConfig from 'config'

@decoratorUIActions(contentActions)
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		selectedSeasonId: PropTypes.number,
		masterTemplateModifications: PropTypes.object,
		textEmpty: PropTypes.string,
		catalogue: PropTypes.string,
	}

	render() {
		const {
			items,
			isLoading,
			textEmpty = "Sorry, could not find any items.",
			onVideoClick,
			isTranslationList,
			translationLanguage,
		} = this.props;

		const noResult = !(items && items.length && items[0].id);

		if (noResult) {
			return <div><Empty key="empty" v2={true} isLoading={isLoading}>{textEmpty}</Empty></div>;
		}

		return (
			<div>
				{["Series", "Season", "Template", "Episode", "Single"].map(type => this.renderGroup(type, onVideoClick, isTranslationList, translationLanguage, isLoading))}
			</div>
		);
  	}

	renderGroup = (type, onVideoClick, isTranslationList, translationLanguage, isLoading) => {
		const { seriesSeasons, selectedSeasonId, items, series } = this.props;
		let groupItems = items.filter(i => i.type === type),
			groupTitle = getGroupTitle(type);

		if (type === "Episode" && !isLoading && groupItems[0]?.id === -3) { // id = -3 is the placeholder
			return <p key={type + series?.id}>This season has no episodes</p>;
		}

		if (type === "Season") {
			const seasonGroups = getSeasonGroups(seriesSeasons, series);
			const selectedGroup = seasonGroups.find(g => g.items.some(i => i.id === selectedSeasonId));
			groupItems = selectedGroup?.items ?? groupItems;
			groupTitle = this.renderSeasonGroupTitle(seasonGroups, selectedGroup);
		}

		if (groupItems.length) {
			return (
				<ItemGroup
					key={type + series?.id}
					title={groupTitle}
				>
					{this.renderItems(groupItems, onVideoClick, isTranslationList, translationLanguage)}
				</ItemGroup>
			);
		}
	}

	renderItems = (items, onVideoClick, isTranslationList, translationLanguage) => {
		const { masterTemplateModifications, catalogue, selectedSeasonId } = this.props;
		return items.map(item => {
			if (item.id === -100 && item.seasonId) {
				return <MissingSeries key="missing-series" seasonId={parseInt(item.seasonId)} />;
			}

			const itemTemplateModification = getItemTemplateModification(item, masterTemplateModifications);
			const actionData = itemTemplateModification ? null : item; // HACK: Hide list item actions during episode template addition or removal (which is done by an API job)

			const slimmedSeasonItem = item.type === "Season" && item.id !== selectedSeasonId;
			const expandedSeasonItem = item.type === "Season" && item.id === selectedSeasonId;
			const className = `${slimmedSeasonItem ? "slimmed-season" : ""} ${expandedSeasonItem ? "expanded-season" : ""}`;

			if (item.id > 0) {
				return (
					<Item
						{...item}
						key={`${item.type || "unknown-type"}-${item.id}`}
						id={item.id}
						actionData={actionData}
						onSelect={item.type === "Season" ? () => this.selectSeason(item.id) : null}
						isSeasonList={true}
						itemTemplateModification={itemTemplateModification}
						catalogue={catalogue}
						className={className}
						onVideoClick={onVideoClick}
						renderMenuWhenNoActions={true}
						isTranslationList={isTranslationList}
						translationLanguage={translationLanguage}
					/>
				);
			}

			return (
				<Placeholder key={`${item.type || "unknown-type"}-${item.id}`} />
			);
		});
	}

	renderSeasonGroupTitle = (seasonGroups, selectedGroup) => {
		const {
			seriesSeasons,
		} = this.props;

		const selectedGroupKey = selectedGroup?.key;

		return (
			<div className="custom-season-title">
				<span>Season</span>
				{seriesSeasons.length > 1 && (
					<Switch
						name="seasonGroups"
						onChange={e => {
							const group = seasonGroups.find(g => g.key === e.target.value);
							const firstActiveSeason = group.items.find(s => s.hasActiveRight);
							const newSeasonId = (firstActiveSeason ?? group.items[0]).id;
							this.selectSeason(newSeasonId);
						}}
						states={seasonGroups}
						currentState={selectedGroupKey}
					/>
				)}
			</div>
		);
	}

	selectSeason = (newSeasonId) => {
		const {
			seriesSeasons,
			handleSelectSeason,
			catalogue,
			selectedSeasonId,
		} = this.props;

		// HACK: When "leaving" a season with a large number of episodes, reload Comet instead
		// Otherwise Comet will freeze while React unloads all the episodes since we are not using a virtual list
		const currentSeason = seriesSeasons.find(s => s.id === selectedSeasonId);
		if (currentSeason?.numberOfEpisodes > 100) {
			const base = appConfig.app?.basePath?.length
				? `/${appConfig.app.basePath}`
				: "";
			window.location.pathname = `${base}/metadata/programs/${catalogue}/season/${newSeasonId}`;
		} else {
			handleSelectSeason(newSeasonId);
		}
	}
}

// HELPERS
function getGroupTitle(group) {
	switch(group) {
		case "Episode":
			return "Episodes";
		case "Template":
			return "Episode metadata template";
		default:
			return group;
	}
}


function getSeasonGroups(seriesSeasons, series) {
	console.log("series?.category", series?.category);
	const [groupByAttribute, groupByAttributeFallback] = series?.category === "Scripted"
		? ["seasonNumber", "productionYear"]
		: ["productionYear", "seasonNumber"];
	const uniqueKeys = seriesSeasons
		.filter(s => s.id !== -2) // id -2 is the season placeholder
		.map(s => `${s[groupByAttribute] ?? s[groupByAttributeFallback]}`)
		.filter((key, i, list) => list.indexOf(key) === i)
		.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

	return uniqueKeys.map(key => {
		const children = seriesSeasons.filter(s => `${s[groupByAttribute] ?? s[groupByAttributeFallback]}` === key);
		const items = children.map((season, index) => {
			// const text = index + 1;
			const text = season.name;
			return {
				...season,
				key: season.id,
				text: text,
				hasActiveRight: hasActiveRight(season),
				className: !hasActiveRight ? "inactive" : "",
			};
		});

		const hasSeasonWithActiveRight = items.some(i => i.hasActiveRight);
		return {
			key: key,
			text: key,
			description: `Display season ${key}`,
			items: items,
			hasSeasonWithActiveRight,
			className: !hasSeasonWithActiveRight ? "inactive" : "",
		};
	});
}

function hasActiveRight(program) {
	const now = moment();
	return program.rights?.some(r => r.start && r.end && now.isBetween(moment(r.start).subtract(14, 'days'), r.end, "day", "[]"));
}