import React from 'react'
import { ScrollContainer } from 'react-router-scroll'

import Main from '../../../components/ui/main'
import List from './list'

import './items.css'

export default class StarItems extends React.Component {

	constructor(props) {
		super(props);
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const {
			list,
			selected,
			searchText,
			loadMore,
			setInputRef,
			uploadUrl = null,
			onUploadComplete = model => { console.error("Upload completed, but no onUploadComplete prop was provided! %o", model)},

			modal = false,
			disableDrag = false,
			masonry,
		} = this.props;

		const { items, isLoading, nextPageUrl } = list;

		const main = (
			<Main
				className="c6-star-items"
				inputRef={setInputRef}
				padding={true}
				modal={modal}
			>
				<List
					{...this.props}
					items={items}
					selected={selected}
					isLoading={isLoading}
					searchText={searchText}
					hasMore={!!nextPageUrl}
					loadMore={loadMore}
					uploadUrl={uploadUrl}
					onUploadComplete={onUploadComplete}
					draggableItems={!disableDrag && modal}
					masonry={masonry}
				/>
			</Main>
		);

		if(modal) {
			return main;
		}

	    return (
			<ScrollContainer scrollKey={`c6-star-items`} shouldUpdateScroll={this.shouldUpdateScroll}>
				{main}
			</ScrollContainer>
		);
	}
}