import cloneDeep from 'lodash/cloneDeep'

import alt from '../../../core/services/alt'
import EditorActions from '../../../components/editor/actions'

import Actions from './actions'

class FamilyStore {

	constructor() {
		this.programsLoading = false;
		this.familyLoading = false;
		this.familyMembersLoading = false;

		this.nextPageUrl = null;
		this.numberOfItems = 0;
		this.latestRequestTime = null;

		this.family = {};
		this.familyMembers = [];
		this.programs = [];

		this.filters = {
			searchText: "",
			pageSize: 50,
			programTypes: "single|series",
			hideWithParent: "",
			orderBy: "title",
			excludeattributes: "synopses,credits",
		};
		this._prevFilter = null;

		this.bindListeners({
			onRequestFailed: Actions.REQUEST_FAILED,

			// onFilter: Actions.FILTER,
			onSearch: Actions.SEARCH,
			onInitSearchText: Actions.INIT_SEARCH_TEXT,
			onClearSearch: Actions.CLEAR_SEARCH,

			// Programs
			onFetchPrograms: Actions.FETCH_PROGRAMS,
			onPagePrograms: Actions.PAGE_PROGRAMS,
			onProgramsUpdated: Actions.PROGRAMS_UPDATED,

			// Family
			onFetchFamily: Actions.FETCH_FAMILY,
			onFamilyLoaded: Actions.FAMILY_LOADED,

			// Family members
			onFetchFamilyMembers: Actions.FETCH_FAMILY_MEMBERS,
			onFamilyMembersLoaded: Actions.FAMILY_MEMBERS_LOADED,
			onAddFamilyMember: [Actions.ADD_FAMILY_MEMBER, Actions.ROLLBACK_REMOVE_FAMILY_MEMBER],
			onRemoveFamilyMember: [Actions.REMOVE_FAMILY_MEMBER, Actions.ROLLBACK_ADD_FAMILY_MEMBER],

			onMoveFamilyMember: Actions.MOVE_FAMILY_MEMBER,
			onRollbackMoveFamilyMember: Actions.ROLLBACK_MOVE_FAMILY_MEMBER,

			// External (family editor)
			onEditorSaved: EditorActions.MODEL_SAVED,

			// onRemoveItem: Actions.REMOVE_ITEM,
			// onItemRemoved: Actions.ITEM_REMOVED,
			// onRollbackRemoveItem: Actions.ROLLBACK_REMOVE_ITEM,

			onUnmount: Actions.UNMOUNT,
		});
	}

	onRequestFailed() {
		// this.isLoading = false;
		this.programsLoading = this.familyLoading = this.familyMembersLoading = false;
	}

	/* Filters */
	onSearch(searchText) {
		if (searchText === '') {
			this.filters.filter = this._prevFilter;
			this.filters.orderBy = "premiere";
			this.filters.hideWithParent = "";
		} else {
			if (!this._prevFilter) {
				this._prevFilter = cloneDeep(this.filters.filter);
			}
			this.filters.filter = {};
			this.filters.orderBy = 'title';
			this.filters.hideWithParent = "season";
		}
		this.filters.searchText = searchText;
		this.programs = [];
	}

	// onFilter(payload) {
	// 	const filterParams = Object.keys(this.filters.filter).length
	// 		? this.filters.filter
	// 		: this._prevFilter;

	// 	this.filters.filter = {
	// 		...filterParams,
	// 		...payload,
	// 	};

	// 	this.filters.searchText = "";
	// 	this.filters.orderBy = "premiere";
	// 	this.filters.programTypes = "single|episode";
	// 	this.filters.hideWithParent = "";
	// 	this._prevFilter = null;
	// 	this.programs = [];
	// }

	onInitSearchText(searchText) {
		this.filters.searchText = searchText;
	}

	onClearSearch() {
		this.programs = [];
	}

	/* Programs */
	onFetchPrograms(payload) {
		this.programsLoading = true;
		this.nextPageUrl = null;
		this.numberOfItems = 0;
		this.latestRequestTime = Date.now();
	}

	onPagePrograms(url) {
		this.programsLoading = true;
		this.nextPageUrl = null;
	}

	onProgramsUpdated({ items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
		if (this.latestRequestTime > requestTime) {
			console.log("Ignoring result with %s items since there have been newer requests.", numberOfItems);
		}
		else {
			this.programs = appendToExistingItems ? this.programs.concat(items) : items;
			this.nextPageUrl = nextPageUrl;
			this.numberOfItems = numberOfItems;
			this.programsLoading = false;
		}
	}

	/* Family */
	onFetchFamily() {
		this.familyLoading = true;
	}

	onFamilyLoaded({ model }) {
		this.family = model;
		this.familyLoading = false;
	}


	/* Family members */
	onFetchFamilyMembers() {
		this.familyMembersLoading = true;
	}

	onFamilyMembersLoaded(items) {
		this.familyMembers = items;
		this.familyMembersLoading = false;
	}

	onAddFamilyMember({ requestPayload, temporaryItem }) {
		this.familyMembersLoading = true;

		const { linkRelation, ordinal } = requestPayload;

		const group = this.familyMembers.find(group => group.name === linkRelation);
		if (!group) {
			this.familyMembers.push({
				name: linkRelation,
				items: [temporaryItem]
			});
		}
		else if (!group.items) {
			group.items = [temporaryItem];
		}
		else {
			group.items.splice(ordinal, 0, temporaryItem);
		}
	}

	onRemoveFamilyMember(payload) {
		this.familyMembersLoading = true;
		this.familyMembers.forEach(group => {
			const itemIndex = (group.items || []).findIndex(item => item.id === payload.id);
			if (itemIndex >= 0) {
				group.items.splice(itemIndex, 1);
			}
		});
	}

	onMoveFamilyMember(payload) {
		this.familyMembersLoading = true;

		const newIndex = payload.ordinal;

		// Remove the item from it's old position and insert the updated item
		this.familyMembers.forEach(group => {
			const itemIndex = (group.items || []).findIndex(item => item.id === payload.programId);
			if (itemIndex >= 0) {
				const item = group.items.splice(itemIndex, 1)[0];
				group.items.splice(newIndex, 0, item);
			}
		});
	}
	onRollbackMoveFamilyMember(originalItem) {
		this.familyMembersLoading = false;
		this.familyMembers.forEach(group => {
			const itemIndex = (group.items || []).findIndex(item => item.id === originalItem.id);
			const originalRelation = originalItem.links.find(l => l.linkRelation === group.name);
			const oldIndex = originalRelation ? originalRelation.ordinal : null;

			if(itemIndex >= 0 && oldIndex !== itemIndex) {
				// Remove the item from it's new position and insert it in the old position
				const item = group.items.splice(itemIndex, 1)[0];
				group.items.splice(oldIndex, 0, item);
			}
		});
	}

	/* External */
	onEditorSaved(payload) {
		if (payload.entity === "family" && payload.model.id === this.family.id) {
			this.family = payload.model;
		}

		if (payload.entity === "program") {
			this.familyMembers.forEach(group => {
				const familyMemberIndex = (group.items || []).findIndex(fm => fm.id === payload.model.id);
				if (familyMemberIndex >= 0) {
					group.items[familyMemberIndex] = payload.model;
				}
			});
		}

		if (payload.entity === "item") {
			const programId = parseInt(payload.model.referenceId);
			if (this.family.id === programId) {
				this.family.tagsApproval = payload.model.approval;
			}
			this.familyMembers.forEach(group => {
				const program = (group.items || []).find(p => p.id === programId);
				if (program) {
					program.tagsApproval = payload.model.approval;
				}
			});
		}
	}


	onUnmount() {
		this.family = {};
		this.familyMembers = [];
		this.programs = [];

		this.programsLoading = false;
		this.familyLoading = false;
		this.familyMembersLoading = false;

		this.filters.searchText = "";
		//this.nextPageUrl = null;
		//this.numberOfItems = 0;
	}

	// HELPERS
	// getItem(entity, id) {
	// 	const store = this[`${entity}s`];
	// 	return store.find(e => e.id === id);
	// }

	// removeItem(entity, id) {
	// 	const store = this[`${entity}s`];
	// 	const index = store.findIndex(e => e.id === id);
	// 	store.splice(index, 1);
	// }

}
export default alt.createStore(FamilyStore, '[Metadata]FamilyStore');