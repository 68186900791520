module.exports = {
	product: "Comet",
	env: "Production",
	app: {
		basePath: null, // Don't prepend or append slashes
		resourcePath: null,
		availableRegions: null,
	},
	// API keys need to match the service key in Shield
	api: {
		acq: null,
		acqc8: null,
		acquisitions: null,
		cmore: null,
		cms: null,
		comet: null,
		content: null,
		contentExternal: null,
		contentKey: null,
		discovery: null,
		diva: null,
		eventplanner: null,
		exchange: null,
		horizon: null,
		horizonc8: null,
		internalepg: null,
		internalmetadata: null,
		internalvod: null,
		internalepg_tv4: null,
		internalmetadata_tv4: null,
		internalvod_tv4: null,
		main: null,
		metadata: null,
		metadatac8: null,
		planner: null,
		plannerc8: null,
		press: null,
		pressBlobStorage: "https://mtvcometstorage.z6.web.core.windows.net/json/fi/",
		reviews: null,
		reviewsScraping: "https://discovery-scraper.junecomet.com/",
		schedules: null,
		scheduleChanges: null,
		selections: null,
		shield: null,
		sport: null,
		star: null,
		star_c70: null,
		tags: null,
		tagsc8: null,
		traffic: null,
		trafficc8: null,
		vod: null,
		vodcore: null,
	},
	media: {
		image: null,
		star: null,
		prodimage: null,
		uploadURL: null,
		playVideoURL: null,
		brandedImageProxy: null,
	},
	features: {
		// Media assets
		assetEditorWithTabs: false,
		assetEditorHideRestrictions: false,
		starDAMFeatures: false,
		starDocumentAddonEnabled: false,
		starUseMasonryLayout: false,

		// Security
		skipApiTokens: false, // Do not try to look up auth tokens for use against the C60 API:s
		shieldTimerInterval: 60, // Time in seconds. -1 = No timer
		moduleNameForShieldReset: null,
		enableModuleWideAccess: null, // IN PROGRESS: Enables users with only module.access (ex shield.admin) to see and navigate to all configured apps for the module
		setCookieOnShieldLogin: false,
		extraLoginPassword: false,
		clearUserTokenCookies: false,

		// Tracking
		enableSentryTracking: false, // Track errors with Sentry
		sentryUrl: null,
		trackUserDisplayAlertsInSentry: true, // Also track the alerts we display to the user
		enableHeapTracking: false, // Track user actions with Heap
		heapId: null,
		adobeAnalyticsURL: null,

		// Support
		enableSupportBeacon: null, // Add a support beacon which lets customers create Help Scout issues directly
		supportBeaconId: null,

		// Misc
		alertOnDirty: true, // Should the user be alerted when navigating away from a dirty editor. Remove when #stableOnleave
		acqLight: true,
		acqC70: false,
		acqC80: false,
		acqDownloadRights: true,
		acqAdsAllowed: true,
		checkVPNUrl: null,
		showProgramFamilies: false,
		metadataChatGPT: false,
		metadataCognitiveServicesTranslation: false,
		metadataEventPlannerFilter: false,
		metadataLocaleEditorSourceLanguageSmartness: false,
		metadataScheduleSkipChannels: true, // Don't render the channel picker
		metadataTranslationContentOwner: "cmore", // ContentOwner used to fetch programs that need translation
		metadataTranslationSourceLang: "swedish",
		metadataTranslationNewFilters: false,
		metadataRightsInList: true,
		metadataQCLink: null, // Link to QC player from metadata editor
		metadataQCBaseUrl: "http://testplayer.june.se:8080",
		metadataQCConfigName: "hayu",
		metadataEditorWarningMessageOnOpen: null,
		metadataEnableEmbargo: false,
		metadataEnableSynopsisEmbargo: false,
		metadataFinnishRatings: null,
		metadataOtherCountriesRatings: false,
		metadataNewActionsLayout: false,
		metadataNewDefaultSearchBehaviour: false,
		metadataC70: false,
		metadataC80: false,
		metadataLinearDefaultChannelGroup: null,
		metadataPressSynopsisWYSIWYG: false,
		metadataProviderInGenericEditor: false,
		metadataSeriesAndEpisodesSeparateFilters: false,
		metadataVodHubProgramListFilter: false,
		metadataUseCatalogues: false,
		metadataUseRightTypeDisplayName: false,
		tagsC70: null,
		tagsC80: null,
		tagsShowBrandTags: false,
		newVODFeatures: null,
		newCometNavigation: false,
		enableModuleSideDrawer: false,
		enableDarkModeSwitch: false,
		metadataUrl: null,
		hideAppBar: false,
		hideCMoreChannels: null,
		hideTV4Channels: null,
		hideMTVChannels: null,
		hideFinnishChannels: null,
		useWindowForLazyloadCalculations: false,
		topWelcomeTitle: null,
		topWelcomeText: null,
		bottomWelcomeTitle: null,
		bottomWelcomeText: null,
		defaultFontFamily: null,
		internalScheduleSearch: null, // Render program search (discovery) in Internal Schedule
		internalScheduleVodViewsEnabled: false,
		internalScheduleL2VEnabledServices: "tv4play,cmore-se",
		plannerEnableAddonViews: false,
		plannerHVOD: false,
		plannerC80: false,
		osdExtendedEditorForContentTodos: false,
		localRatingSource: null,
		osdEnableExcel: false,
		osdProductionCompanyRestrictions: false,
		useMinimalImageEditor: false,
		useContentSourceMetadata: false,
		eventplannerDefaultCatchupAvailabilityPeriod: null,
		eventplannerDefaultHighlightCatchupAvailabilityPeriod: null,
		eventplannerDefaultPublishBeforeEventStart: null,
		eventplannerDefaultPublishTimeOfDay: null,
		eventplannerScheduleChannels: null,
		eventplannerDisabledTempForHERO: false,
		eventplannerContentConsumers: false,
		horizonExternalScheduleAssetUrlTemplate: "https://cmore.incomet.io/ui-epg-schedules/schedule-vod/asset/${assetId}",
		horizonPublishMetadataMaxDays: 14,
		faviconPath: "",
		headerHighlightedTextBackgroundColor: "#ef5350",
		extendedMezzSupport: false,
		playVideoFromAzureMediaServices: false,
		videoSourcesLookupAssetType: null,
		enableEditorNavigation: true,
		saveListFiltersInStorage: false,
		sanitizeTextInputs: false,
		scheduleReleasesHideCreate: false,

		// Press
		pressOneTrustCookiesConsentId: null,
		pressBlobStorageLocale: "fi",
		pressLocale: "fi",
		pressRecaptchaSiteKey: "6LdouKoUAAAAACjov5RwS8VK5fz341GqrZjIejX9",
		pressTimezone: "Europe/Helsinki",
		pressLogoPath: "client/img/MTV_logo_red_RGB.svg",
		pressSiteTitle: "Pressi",
		discoveryPlatform: null,
		pressSiteStructureFilename: "mtv-press.json",
		pressEnableProgramReviews: false,
		pressAddVideoWatermark: false,
		pressDisableVideoPlayerOnProgramPage: false,
		pressUnauthorizedAssetMessage: null,
		pressRenderPressSynopsis: null,
		pressEnableFooter: true,
		pressClassName: "",
		pressHeaderScrollToLinks: "",
		pressSubscriptionsProviderId: 1,
		pressRequireLogin: false,
		pressHidePasswordAndRegisterLinks: false,
		registrationPrivacyPolicyAgreementLink: null,

		// Selections
		useNewSelections: false,
		selectionsItemEditorSTARAddon: false,
		selectionsUseTemplate: false,
		selectionsMovedToHeroComet: false,

		// VOD 
		defaultVodServiceId: 1,
		vodServiceName: null,

		// Code path selection
		useFetchRequestRetries: false, // Actually tries a request three times before throwing an error
		hideTokenErrors: true, // Don't display red alerts or track to Sentry when the user recieves Token Errors (since they're not real errors)
		checkForUpdates: true, // Periodically checks for new UI releases by loading index.html and comparing the version numbers
	},
	acq: {
		internalWindowTypes: null,
	},
	metadata: {
		providerName: null,
	},
};