import React from 'react'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'

import update from 'immutability-helper'
import clone from 'lodash/clone'

import ItemTypes from '../../core/itemTypes'

const USE_SCHEDULED_DRAGS = true;

const itemTarget = {
	drop(props, monitor, component) {
		if (!monitor.didDrop()) {
			const droppedItem = monitor.getItem();
			component.handleItemDrop(droppedItem);
		}
	},
	// hover(props, monitor, component) {
	// 	console.log("Hovering over sortable list");
	// }
};

const SortableList = actions => {
	return ComposedComponent => {
		return (
			@DropTarget(ItemTypes.ITEM, itemTarget, (connect, monitor) => ({
				connectDropTarget: connect.dropTarget(),

				// Expose if we're hovering over our wrapped component so we can disable list item actions.
				// Also magically seems to handle removing dragged elements when leaving with a new item, not in the list before? #magic
				isOver: monitor.isOver(),
			}))
			class extends React.Component {
				constructor(props) {
					super();

					this.handleTargetHover = this.handleTargetHover.bind(this);

					this.handleItemDrop = this.handleItemDrop.bind(this);
					this.handleItemInsert = this.handleItemInsert.bind(this);
					this.handleItemManipulation = this.handleItemManipulation.bind(this);
					this.handleOutsideDrop = this.handleOutsideDrop.bind(this);

					this.findItem = this.findItem.bind(this);
					this.drawFrame = this.drawFrame.bind(this);
					this.moveItem = this.moveItem.bind(this);
					this.moveItemToIndex = this.moveItemToIndex.bind(this);
					this.removeItem = this.removeItem.bind(this);
					this.replaceItem = this.replaceItem.bind(this);

					const { itemsById, itemsByIndex } = this.getItemsByIdAndByIndex(props.items);
					this.state = {
						itemsById,
						itemsByIndex,
						overTarget: null,
					};
				}

				componentWillReceiveProps(nextProps) {
					// TODO!: Can we optimize this a bit so we don't do the items dance everytime some prop updates?

					// TODO: In case we want control over the list enter and leave events
					// if(nextProps.isOver !== this.props.isOver) {
					// 	if(!nextProps.isOver) {
					// 		console.log("Remove the temp hover item from the list");
					// 	}
					// 	else {
					// 		console.log("We're over the list, do nothing!");
					// 	}
					// }

					// HMM: Makes the remove item action not update
					// (since the main list prop is not touched?)
					//if(nextProps.items !== this.props.items) {

						// let itemsById = {}, itemsByIndex = [];

						// // Used to speed up sortable lists
						// nextProps.items && nextProps.items.forEach((item, index) => {
						// 	itemsById[item.id] = item;
						// 	itemsByIndex[index] = item;
						// });

						// console.log("itemsByIndex", itemsByIndex);
						const { itemsById, itemsByIndex } = this.getItemsByIdAndByIndex(nextProps.items);

						this.setState({
							itemsById,
							itemsByIndex,
						});

					//}
				}

				componentWillUnmount() {
					this.setState({
						itemsById: {},
						itemsByIndex: [],
					});

					cancelAnimationFrame(this.requestedFrame);
				}

				// Used to speed up sortable lists
				getItemsByIdAndByIndex = (items) => {
					let itemsById = {}, itemsByIndex = [];

					items?.forEach((item, index) => {
						itemsById[item.id] = item;
						itemsByIndex[index] = item;
					});

					return {
						itemsById,
						itemsByIndex,
					};
				}

				findItem(id) {
					const { itemsById, itemsByIndex } = this.state;
					const item = itemsById[id];

					let index = itemsByIndex.indexOf(item);
					// if(index === -1) {
					// 	index = itemsByIndex.findIndex(i => i.id === id);
					// }

					return {
						item,
						index,
					};
				}

				moveItem(id, afterId) {
					const { itemsById, itemsByIndex, overTarget } = this.state;

					const item = itemsById[id];
					const itemIndex = itemsByIndex.indexOf(item);

					const afterItem = itemsById[afterId];
					const afterIndex = itemsByIndex.indexOf(afterItem);

					// We need to set the list target the item is hovering over so we can drag between list groups
					item.isOver = overTarget;

					const moveOperation = {
						itemsByIndex: {
							$splice: [
								[itemIndex, 1],
								[afterIndex, 0, item]
							]
						}
					};

					if(USE_SCHEDULED_DRAGS) {
						this.scheduleUpdate(moveOperation);
					}
					else {
						const nextState = update(this.state, moveOperation);
						this.setState(nextState);
					}
				}

				// TODO: Make sure removes also update the items Optimistically
				removeItem(id) {
					const { itemsById, itemsByIndex } = this.state;
					const item = itemsById[id];

					if(item) {
						const itemIndex = itemsByIndex.indexOf(item);

						const removeOperation = {
							itemsByIndex: {
								$splice: [
									[itemIndex, 1]
								]
							}
						};

						this.setState(update(this.state, removeOperation));

						// HACK: Remove our placeholder from itemsById (until our update helper has a $delete method
						// (https://github.com/facebook/react/issues/1856#issuecomment-52627020) I guess... or we switch to immutable-js)
						const nextItemsById = clone(this.state.itemsById);
						delete nextItemsById[id];

						this.setState({ itemsById: nextItemsById });
					}
				}

				moveItemToIndex(id, index) {
					const { itemsById, itemsByIndex } = this.state;

					const item = itemsById[id];
					const itemIndex = itemsByIndex.indexOf(item);

					delete item.isOver;

					const moveOperation = {
						itemsByIndex: {
							$splice: [
								[itemIndex, 1],
								[index, 0, item]
							]
						},
					};

					if(USE_SCHEDULED_DRAGS) {
						this.scheduleUpdate(moveOperation);
					}
					else {
						const nextState = update(this.state, moveOperation);
						this.setState(nextState);
					}
				}

				replaceItem(id, newItem) {

					const { itemsById, itemsByIndex } = this.state;
					const item = itemsById[id];

					if(item) {
						const itemIndex = itemsByIndex.indexOf(item);

						// TODO!!!!!: Make sure itemsById is updated too?
						// https://3.basecamp.com/3091592/buckets/1615235/todos/259070710

						const replaceOperation = {
							itemsByIndex: {
								$splice: [
									[itemIndex, 1, newItem],
								]
							}
						};

						const nextState = update(this.state, replaceOperation)
						this.setState(nextState);

						// HACK: Remove our placeholder from itemsById
						//const nextItemsById = clone(this.state.itemsById);
						//delete nextItemsById[id];

						//this.setState({ itemsById: nextItemsById });
					}
				}

				scheduleUpdate(updateFn) {
					this.pendingUpdateFn = updateFn;

					if (!this.requestedFrame) {
						this.requestedFrame = requestAnimationFrame(this.drawFrame);
					}
				}

				drawFrame() {
					const nextState = update(this.state, this.pendingUpdateFn);
					this.setState(nextState);

					this.pendingUpdateFn = null;
					this.requestedFrame = null;
				}

				// Remember which target we're hovering over to be able to set active/inactive status for example.
				handleTargetHover(targetType) {
					if(targetType !== this.state.overTarget) {
						this.setState({ overTarget: targetType });
					}
				}

				// Moves or inserts an item in the list during drag
				handleItemManipulation(id, afterId) {
					if(this.findItem(id).item) {
						this.moveItem(id, afterId);
					}
					else {
						this.handleItemInsert(id, afterId);
					}
				}

				// Adds a placeholder to the list items when we drag an external item to the list
				// It will be replaced with the real dragged item when dropping it.
				handleItemInsert(id, afterId) {
					const { itemsById, itemsByIndex, overTarget } = this.state;

					const afterCard = itemsById[afterId];
					const afterIndex = itemsByIndex.indexOf(afterCard);

					const placeholder = {
						isPlaceholder: true,
						isOver: overTarget,
						id,
						referenceId: id,
						displayName: "Placeholder",
						// description: "",
						// assets: [],
						ordinal: afterIndex + 1, // TODO: Try removing ordinals everywhere, we're not using them for anything
					};

					const insertOperation = {
						itemsById: {
							$merge: {
								[id]: placeholder
							}
						},
						itemsByIndex: {
							$splice: [
								[afterIndex, 0, placeholder]
							]
						},
					};

					if(USE_SCHEDULED_DRAGS) {
						this.scheduleUpdate(insertOperation);
					}
					else {
						const nextState = update(this.state, insertOperation);
						this.setState(nextState);
					}
				}

				handleItemDrop(droppedItem, targetType) {
					const { itemsById, itemsByIndex } = this.state;
					const { id, originalData: originalItem } = droppedItem;
					const item = itemsById[id];
					const itemIndex = itemsByIndex.indexOf(item);

					const payload = {
						id,
						ordinal: itemIndex + 1 || 1, // 1 is the minimum ordinal
					};

					const movingExistingItem = item && !item.isPlaceholder;
					if(movingExistingItem) {

						// HACK: Set active state + remove the temporary isOver property
						const nextItemsByIndex = clone(this.state.itemsByIndex);
						nextItemsByIndex[itemIndex].active = targetType !== ItemTypes.LIST_INACTIVE;
						delete nextItemsByIndex[itemIndex].isOver;

						this.setState({ itemsByIndex: nextItemsByIndex });

						//this.replaceItem(id, newItem); // TODO: This breaks dropping between groups (loses the title prop?)

						actions.onItemMoved({
							payload,
							originalItem,
							targetType,
							sourceProps: this.props,
						});
					}
					else {
						const newItem = actions.generateNewItem({
							payload,
							droppedItem,
							targetType,
							sourceProps: this.props,
						});

						// TODO!!!!!!: itemsById will never have a length so this part will never execute
						// Optimistically replace the placeholder with the dragged item
						if(itemsById.length) {
							this.replaceItem(item.id, {
								...newItem,
								id: `temporary-${id}`, // The temporary ID will be replaced with the real one from the action below
							});
						}

						actions.onItemAdded({
							payload: newItem,
							sourceProps: this.props,
							targetType,
						});
					}

					this.setState({ overTarget: null });
				}

				handleOutsideDrop(id, originalIndex) {

					// Item does not originate from our list => remove placeholder item
					if(originalIndex === -1) {
						this.removeItem(id);
					}
					// Item is from our list => move item back to it's original index
					else {
						this.moveItemToIndex(id, originalIndex);
					}
				}

				render() {
					return (
						<ComposedComponent
							{...this.props}
							items={this.state.itemsByIndex}
							findItem={this.findItem}
							moveItem={this.moveItem}
							handleItemDrop={this.handleItemDrop}
							handleOutsideDrop={this.handleOutsideDrop}
							handleItemManipulation={this.handleItemManipulation}
							handleTargetHover={this.handleTargetHover}
						/>
					);
				}
			}
		)
	}
}

export default SortableList;