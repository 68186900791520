import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import Section from '../../../components/ui/section'
import { Filter, Left, Right } from '../../../components/filter'
// import Switch from '../../../components/ui/controls/switch'
import Search from '../../../components/ui/controls/search'
import Main from '../../../components/ui/main'
import DraggableItem from '../../../components/list/draggableItem'
import Empty from '../../../components/list/empty'

import Content from './listItem'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;

export default class ProgramSearch extends Component {
	render() {
		const {
			items,
			isLoading,
			hasMore,
			loadMore,
			searchText,
			handleFilter,
			onItemDropOutside,
			inputRef,
			familyMembers,
		} = this.props;

		const textTooFewCharacters = searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST ? `Please type ${ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST} characters or more to search.` : "";
		const textNoSearchText = !searchText || !searchText.length ? "Type something in the field above to search." : "";
		const noResult = !(items && items.length);

		const list = noResult
			? <Empty key="empty" v2={true} isLoading={isLoading} title="No items">{textTooFewCharacters || textNoSearchText || "Sorry, could not find any items."}</Empty>
			: (
				<InfiniteScroll
					loadMore={loadMore}
					hasMore={hasMore}
					loader={<div className="infinite-loader" key="infinite-loader">Loading ...</div>}
					useWindow={false}
					threshold={700}
					initialLoad={false}>
					{renderItems(items, onItemDropOutside, familyMembers)}
				</InfiniteScroll>
			);

		return (
			<Section flex="1">
				<Filter>
					<Left>
						<Search
							onChange={handleFilter.bind(this, "search")}
							placeholder="Search"
							searchText={searchText}
						/>
					</Left>
				</Filter>
				<Main inputRef={inputRef} padding={true}>
					{list}
				</Main>
			</Section>
		);
	}
}

function renderItems(items, onItemDropOutside, familyMembers) {
	return items.map(item => (
		<DraggableItem
			key={item.id}
			id={item.id}
			handleType="thumbnail"
			actionData={item}
			onItemDropOutside={onItemDropOutside}
		>
			<Content
				{...item}
				title={item.displayName}
				isPartOfCurrentProgramFamily={isPartOfCurrentProgramFamily(item, familyMembers)}
			/>
		</DraggableItem>
	));
}

function isPartOfCurrentProgramFamily(item, familyMembers) {
	return familyMembers.some(group => {
		return group.items?.some(i => i.id === item.id);
	});
}