import React from 'react'

import Button from '../../../components/ui/controls/button'

import * as MetadataAPI from '../../../apis/metadata'
import { displayAlert } from '../../../core/services/alert'
import EditorActions from '../../../components/editor/actions'

import './chatGPTController.css'
import useMetadataLanguages from '../../../core/queries/metadata/useMetadataLanguages'
import useMetadataCountries from '../../../core/queries/metadata/useMetadataCountries'
import useMetadataGenres from '../../../core/queries/metadata/useMetadataGenres'

export const ChatGPTGenericMetadataController = ({
    formContext,
    onChange,
    readonly,
}) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const { data: languagesFromAPI } = useMetadataLanguages();
    const { data: countriesFromAPI } = useMetadataCountries();
    const { data: genresFromAPI } = useMetadataGenres();

    const getMetadata = React.useCallback(
        async (e) => {
            const modalBody = document.querySelector(".c6-modal-body");
            try {
                modalBody.classList.remove("magic-effect-done");
                modalBody.classList.add("magic-effect");
                e.preventDefault();
                setIsLoading(true);

                const { seriesName, seasonNumber, productionYear } = await getSeriesAndSeasonInfo(formContext);
                const result = await MetadataAPI.fetchAIGenericMetadata({
                    productionYear: Number(productionYear),
                    name: seriesName ?? formContext.model.originalTitle,
                    type: formContext.model.type.toLowerCase(),
                    seasonNumber: seasonNumber ?? formContext.model.seasonNumber,
                    episodeNumber: formContext.model.episodeNumber,
                });

                const content = JSON.parse(result.choices[0].message.content);
                console.log("content", content);

                const fieldsToUpdate = {};
                fieldsToUpdate.credits = formContext.model.credits ?? {};

                // IMDB
                if (content.imdb_id) {
                    const imdbId = content.imdb_id.startsWith("tt")
                        ? content.imdb_id
                        : `tt${content.imdb_id}`;
                    fieldsToUpdate.imdbId = formContext.model.imdbId ?? imdbId;
                }

                // DURATION
                if (content.len_min) {
                    fieldsToUpdate.duration = formContext.model.imdbId || content.len_min;
                }

                // ACTORS
                const actors = typeof content.actors === "string"
                    ? [{ name: content.actors }]
                    : content.actors;
                if (Array.isArray(actors) && !formContext.model.credits?.actors?.length) {
                    fieldsToUpdate.credits.actors = actors.map(a => ({
                        type: "actor",
                        name: a.name ?? a.actor ?? a,
                        role: a.role
                    }));
                }

                // DIRECTORS
                const directors = typeof content.directors === "string"
                    ? [{ name: content.directors }]
                    : content.directors;
                if (Array.isArray(directors) && !formContext.model.credits?.directors?.length) {
                    fieldsToUpdate.credits.directors = directors.map(d => ({ type: "director", name: d.name ?? d }));
                }

                // PRODUCERS
                const producers = typeof content.producers === "string"
                    ? [{ name: content.producers }]
                    : content.producers;
                if (Array.isArray(producers) && !formContext.model.credits?.producers?.length) {
                    fieldsToUpdate.credits.producers = producers.map(p => ({ type: "producer", name: p.name ?? p }));
                }

                // WRITERS
                const writers = typeof content.writers === "string"
                    ? [{ name: content.writers }]
                    : content.writers;
                if (Array.isArray(writers) && !formContext.model.credits?.scriptWriters?.length) {
                    fieldsToUpdate.credits.scriptWriters = writers.map(w => ({ type: "writer", name: w.name ?? w }));
                }

                // COUNTRIES
                const productionCountries = typeof content.countries === "string"
                    ? [{ name: content.countries }]
                    : content.countries;
                if (Array.isArray(productionCountries) && !formContext.model.productionCountries?.length) {
                    fieldsToUpdate.productionCountries = productionCountries.map(pc => {
                        const name = iso_3166_1_a3_map[pc.iso_3166_1_a3?.toLowerCase()];
                        const countryFromAPI = countriesFromAPI?.items.find(c => c.name.toLowerCase() === name);
                        return countryFromAPI;
                    }).filter(pc => !!pc);
                }

                // LANGUAGES
                const spokenLanguages = typeof content.lang === "string"
                    ? [{ name: content.lang }]
                    : content.lang;
                if (Array.isArray(spokenLanguages) && !formContext.model.spokenLanguages?.length) {
                    fieldsToUpdate.spokenLanguages = spokenLanguages.map(sl => {
                        const name = iso_639_2_b_map[sl.iso_639_2_b?.toLowerCase()];
                        const langFromAPI = languagesFromAPI?.items.find(l => l.name.toLowerCase() === name);
                        return langFromAPI;
                    }).filter(sl => !!sl);
                }

                // GENRES
                const genres = typeof content.genres_swe === "string"
                    ? [content.genres_swe]
                    : content.genres_swe;
                if (Array.isArray(genres) && !formContext.model.defaultGenres?.length) {
                    fieldsToUpdate.defaultGenres = genres.map(g1 => {
                        const genreFromAPI = genresFromAPI?.items.find(g2 => g2.displayName.toLowerCase() === g1?.toLowerCase?.());
                        return genreFromAPI;
                    }).filter(g => !!g);
                }

                console.log("fieldsToUpdate", fieldsToUpdate);

                modalBody.classList.add("magic-effect-done");

                setTimeout(() => {
                    onChange({ fieldsToUpdate });
                    setIsLoading(false);
                    EditorActions.setDirty();
                }, 300);

                setTimeout(() => {
                    modalBody.classList.remove("magic-effect");
                }, 600);
            } catch (e) {
                setIsLoading(false);
                displayAlert("error", "Something went wrong when fetching the magic metadata. Please contact support@junefirst.tv if the problem persists.");
                console.error(e);
                modalBody.classList.remove("magic-effect");
            }
        },
        [formContext.model, onChange, languagesFromAPI, countriesFromAPI, genresFromAPI]
    );

    return (
        <div>
            <Button
                noBackground
                title={isLoading ? "Please wait while I work my AI magic" : "Use AI"}
                hoverTitle="Fill empty fields with AI (requires title and production year)"
                type="auto-fix"
                className="get-metadata"
                onClick={getMetadata}
                disabled={isLoading || readonly}
            />
        </div>
    );
};

export const ChatGPTSynopsisMetadataController = ({
    formContext,
    onChange,
    readonly,
}) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const getMetadata = React.useCallback(
        async (e) => {
            const modalBody = document.querySelector(".c6-modal-body");
            try {
                modalBody.classList.remove("magic-effect-done");
                modalBody.classList.add("magic-effect");
                e.preventDefault();
                setIsLoading(true);
                
                const { seriesName, seasonNumber, productionYear } = await getSeriesAndSeasonInfo(formContext);
                const result = await MetadataAPI.fetchAISynopsis({
                    productionYear: Number(productionYear),
                    name: seriesName ?? formContext.model.originalTitle,
                    type: formContext.model.type.toLowerCase(),
                    seasonNumber: seasonNumber ?? formContext.model.seasonNumber,
                    episodeNumber: formContext.model.episodeNumber,
                });

                const content = JSON.parse(result.choices[0].message.content);
                console.log("content", content);

                const fieldsToUpdate = {};

                if (content.short_synopsis) {
                    fieldsToUpdate.shortSynopsis = formContext.model.versions[0].shortSynopsis || content.short_synopsis;
                }
                if (content.brief_synopsis) {
                    fieldsToUpdate.briefSynopsis = formContext.model.versions[0].briefSynopsis || content.brief_synopsis;
                }
                if (content.medium_synopsis) {
                    fieldsToUpdate.mediumSynopsis = formContext.model.versions[0].mediumSynopsis || content.medium_synopsis;
                }
                if (content.long_synopsis) {
                    fieldsToUpdate.longSynopsis = formContext.model.versions[0].longSynopsis || content.long_synopsis;
                }

                console.log("fieldsToUpdate", fieldsToUpdate);

                modalBody.classList.add("magic-effect-done");

                setTimeout(() => {
                    onChange({ fieldsToUpdate });
                    setIsLoading(false);
                    EditorActions.setDirty();
                }, 300);

                setTimeout(() => {
                    modalBody.classList.remove("magic-effect");
                }, 600);
            } catch (e) {
                setIsLoading(false);
                displayAlert("error", "Something went wrong when fetching the magic synopsis. Please contact support@junefirst.tv if the problem persists.");
                console.error(e);
                modalBody.classList.remove("magic-effect");
            }
        },
        [formContext.model, onChange]
    );

    return (
        <div>
            <Button
                noBackground
                title={isLoading ? "Please wait while I work my AI magic" : "Use AI"}
                hoverTitle="Fill empty fields with AI"
                type="auto-fix"
                className="get-metadata"
                onClick={getMetadata}
                disabled={isLoading || readonly}
            />
        </div>
    );
};

async function getSeriesAndSeasonInfo(formContext) {
    let season;
    let seasonNumber = formContext.model.seasonNumber;
    let productionYear = formContext.model.productionYear;
    let seriesName;
    if (formContext.model.type === "Episode") {
        season = await MetadataAPI.fetchProgram({ id: formContext.model.parentId });
        seasonNumber = season.seasonNumber;
        productionYear = season.productionYear;
    }
    if (formContext.model.type === "Episode" || formContext.model.type === "Season") {
        const series = await MetadataAPI.fetchProgram({ id: season?.parentId ?? formContext.model.parentId });
        seriesName = series.originalTitle;
    }

    return { seriesName, seasonNumber, productionYear };
}

const iso_639_2_b_map = {
    "aar": "afar",
    "abk": "abkhazian",
    "ace": "achinese",
    "ach": "acoli",
    "ada": "adangme",
    "afa": "afro-asiatic",
    "afh": "afrihili",
    "afr": "afrikaans",
    "aka": "akan",
    "akk": "akkadian",
    "ale": "aleut",
    "alg": "algonquian languages",
    "amh": "amharic",
    "apa": "apache languages",
    "ara": "arabic",
    "arc": "aramaic",
    "arn": "araucanian",
    "arp": "arapaho",
    "art": "artificial",
    "arw": "arawak",
    "asm": "assamese",
    "ath": "athapascan languages",
    "aus": "australian languages",
    "ava": "avaric",
    "ave": "avestan",
    "awa": "awadhi",
    "aym": "aymara",
    "aze": "azerbaijani",
    "bad": "banda",
    "bai": "bamileke languages",
    "bak": "bashkir",
    "bal": "baluchi",
    "bam": "bambara",
    "ban": "balinese",
    "bas": "basa",
    "bat": "baltic",
    "bej": "beja",
    "bel": "belarussian",
    "bem": "bemba",
    "ben": "bengali",
    "ber": "berber",
    "bho": "bhojpuri",
    "bih": "bihari",
    "bik": "bikol",
    "bin": "bini",
    "bis": "bislama",
    "bla": "siksika",
    "bnt": "bantu",
    "tib": "tibetan",
    "bos": "bosnian",
    "bra": "braj",
    "bre": "breton",
    "btk": "batak",
    "bua": "buriat",
    "bug": "buginese",
    "bul": "bulgarian",
    "cad": "caddo",
    "cai": "central american indian",
    "car": "carib",
    "cat": "catalan",
    "cau": "caucasian",
    "ceb": "cebuano",
    "cel": "celtic",
    "cze": "czech",
    "cha": "chamorro",
    "chb": "chibcha",
    "che": "chechen",
    "chg": "chagatai",
    "chk": "chuukese",
    "chm": "mari",
    "chn": "chinook jargon",
    "cho": "choctaw",
    "chp": "chipewyan",
    "chr": "cherokee",
    "chu": "church slavic",
    "chv": "chuvash",
    "chy": "cheyenne",
    "cmc": "chamic languages",
    "cop": "coptic",
    "cor": "cornish",
    "cos": "corsican",
    "cpe": "creoles and pidgins",
    "cpf": "creoles and pidgins",
    "cpp": "creoles and pidgins",
    "cre": "cree",
    "crp": "creoles and pidgins",
    "cus": "cushitic",
    "wel": "welsh",
    "dak": "dakota",
    "dan": "danish",
    "day": "dayak",
    "del": "delaware",
    "ger": "german",
    "dgr": "dogrib",
    "din": "dinka",
    "div": "divehi",
    "doi": "dogri",
    "dra": "dravidian",
    "dua": "duala",
    "dum": "dutch",
    "dyu": "dyula",
    "dzo": "dzongkha",
    "efi": "efik",
    "egy": "egyptian",
    "eka": "ekajuk",
    "gre": "greek",
    "elx": "elamite",
    "eng": "english",
    "epo": "esperanto",
    "est": "estonian",
    "baq": "basque",
    "ewe": "ewe",
    "ewo": "ewondo",
    "fan": "fang",
    "fao": "faroese",
    "per": "persian",
    "fat": "fanti",
    "fij": "fijian",
    "fin": "finnish",
    "fiu": "finno-ugrian",
    "fon": "fon",
    "fre": "french",
    "frm": "french",
    "fro": "french",
    "fry": "frisian",
    "ful": "fulah",
    "fur": "friulian",
    "gaa": "ga",
    "gay": "gayo",
    "gba": "gbaya",
    "gem": "germanic",
    "gez": "geez",
    "gil": "gilbertese",
    "gla": "gaelic",
    "gle": "irish",
    "glg": "gallegan",
    "glv": "manx",
    "gmh": "german",
    "goh": "german",
    "gon": "gondi",
    "gor": "gorontalo",
    "got": "gothic",
    "grb": "grebo",
    "grc": "greek",
    "grn": "guarani",
    "guj": "gujarati",
    "gwi": "gwich'in",
    "hai": "haida",
    "hau": "hausa",
    "haw": "hawaiian",
    "her": "herero",
    "hil": "hiligaynon",
    "him": "himachali",
    "hin": "hindi",
    "hit": "hittite",
    "hmn": "hmong",
    "hmo": "hiri motu",
    "scr": "croatian",
    "hun": "hungarian",
    "hup": "hupa",
    "arm": "armenian",
    "iba": "iban",
    "ibo": "igbo",
    "ijo": "ijo",
    "iku": "inuktitut",
    "ile": "interlingue",
    "ilo": "iloko",
    "ina": "interlingua",
    "inc": "indic",
    "ind": "ind	indonesian",
    "ine": "indo-european",
    "ipk": "inupiaq",
    "ira": "iranian",
    "iro": "iroquoian languages",
    "ice": "icelandic",
    "ita": "italian",
    "jav": "javanese",
    "jpn": "japanese",
    "jpr": "judeo-persian",
    "jrb": "judeo-arabic",
    "kaa": "kara-kalpak",
    "kab": "kabyle",
    "kac": "kachin",
    "kal": "kalaallisut",
    "kam": "kamba",
    "kan": "kannada",
    "kar": "karen",
    "kas": "kashmiri",
    "geo": "georgian",
    "kau": "kanuri",
    "kaw": "kawi",
    "kaz": "kazakh",
    "kha": "khasi",
    "khi": "khoisan",
    "khm": "khmer",
    "kho": "khotanese",
    "kik": "kikuyu",
    "kin": "kinyarwanda",
    "kir": "kirghiz",
    "kmb": "kimbundu",
    "kok": "konkani",
    "kom": "komi",
    "kon": "kongo",
    "kor": "korean",
    "kos": "kosraean",
    "kpe": "kpelle",
    "kro": "kru",
    "kru": "kurukh",
    "kua": "kuanyama",
    "kum": "kumyk",
    "kur": "kurdish",
    "kut": "kutenai",
    "lad": "ladino",
    "lah": "lahnda",
    "lam": "lamba",
    "lao": "lao",
    "lat": "latin",
    "lav": "latvian",
    "lez": "lezghian",
    "lin": "lingala",
    "lit": "lithuanian",
    "lol": "mongo",
    "loz": "lozi",
    "ltz": "letzeburgesch",
    "lua": "luba-lulua",
    "lub": "luba-katanga",
    "lug": "ganda",
    "lui": "luiseno",
    "lun": "lunda",
    "luo": "luo",
    "lus": "lushai",
    "mad": "madurese",
    "mag": "magahi",
    "mah": "marshall",
    "mai": "maithili",
    "mak": "makasar",
    "mal": "malayalam",
    "man": "mandingo",
    "map": "austronesian",
    "mar": "marathi",
    "mas": "masai",
    "mdr": "mandar",
    "men": "mende",
    "mga": "irish",
    "mic": "micmac",
    "min": "minangkabau",
    "mis": "miscellaneous languages",
    "mac": "macedonian",
    "mkh": "mon-khmer",
    "mlg": "malagasy",
    "mlt": "maltese",
    "mnc": "manchu",
    "mni": "manipuri",
    "mno": "manobo languages",
    "moh": "mohawk",
    "mol": "moldavian",
    "mon": "mongolian",
    "mos": "mossi",
    "mao": "maori",
    "may": "malay",
    "mul": "multiple languages",
    "mun": "munda languages",
    "mus": "creek",
    "mwr": "marwari",
    "bur": "burmese",
    "myn": "mayan languages",
    "nah": "nahuatl",
    "nai": "north american indian",
    "nau": "nauru",
    "nav": "navajo",
    "nbl": "ndebele, south",
    "nde": "ndebele, north",
    "ndo": "ndonga",
    "nep": "nepali",
    "new": "newari",
    "nia": "nias",
    "nic": "niger-kordofanian",
    "niu": "niuean",
    "dut": "dutch",
    "nno": "norwegian nynorsk",
    "nob": "norwegian bokmål",
    "non": "norse, old",
    "nor": "norwegian",
    "nso": "sotho, northern",
    "nub": "nubian languages",
    "nya": "chichewa; nyanja",
    "nym": "nyamwezi",
    "nyn": "nyankole",
    "nyo": "nyoro",
    "nzi": "nzima",
    "oci": "occitan",
    "oji": "ojibwa",
    "ori": "oriya",
    "orm": "oromo",
    "osa": "osage",
    "oss": "ossetian; ossetic",
    "ota": "turkish, ottoman",
    "oto": "otomian languages",
    "paa": "papuan",
    "pag": "pangasinan",
    "pal": "pahlavi",
    "pam": "pampanga",
    "pan": "panjabi",
    "pap": "papiamento",
    "pau": "palauan",
    "peo": "persian",
    "phi": "philippine",
    "phn": "phoenician",
    "pli": "pali",
    "pol": "polish",
    "pon": "pohnpeian",
    "por": "portuguese",
    "pra": "prakrit languages",
    "pus": "pushto",
    "que": "quechua",
    "raj": "rajasthani",
    "rap": "rapanui",
    "rar": "rarotongan",
    "roa": "romance",
    "roh": "raeto-romance",
    "rom": "romany",
    "rum": "romanian",
    "run": "rundi",
    "rus": "russian",
    "sad": "sandawe",
    "sag": "sango",
    "sah": "yakut",
    "sai": "south american indian",
    "sal": "salishan languages",
    "sam": "samaritan aramaic",
    "san": "sanskrit",
    "sas": "sasak",
    "sat": "santali",
    "sco": "scots",
    "sel": "selkup",
    "sem": "semitic",
    "sgn": "sign languages",
    "shn": "shan",
    "sid": "sidamo",
    "sin": "sinhalese",
    "sio": "siouan languages",
    "sit": "sino-tibetan",
    "sla": "slavic",
    "slo": "slovak",
    "slv": "slovenian",
    "sme": "northern sami",
    "smi": "sami languages other",
    "smo": "samoan",
    "sna": "shona",
    "snd": "sindhi",
    "snk": "soninke",
    "sog": "sogdian",
    "som": "somali",
    "son": "songhai",
    "sot": "sotho, southern",
    "spa": "spanish",
    "alb": "albanian",
    "srd": "sardinian",
    "scc": "serbian",
    "srr": "serer",
    "ssa": "nilo-saharan",
    "ssw": "swati",
    "suk": "sukuma",
    "sun": "sundanese",
    "sus": "susu",
    "sux": "sumerian",
    "swa": "swahili",
    "swe": "swedish",
    "syr": "syriac",
    "tah": "tahitian",
    "tai": "tai",
    "tam": "tamil",
    "tat": "tatar",
    "tel": "telugu",
    "tem": "timne",
    "ter": "tereno",
    "tet": "tetum",
    "tgk": "tajik",
    "tgl": "tagalog",
    "tha": "thai",
    "tig": "tigre",
    "tir": "tigrinya",
    "tiv": "tiv",
    "tkl": "tokelau",
    "tli": "tlingit",
    "tmh": "tamashek",
    "tog": "tonga (nyasa)",
    "ton": "tonga (tonga islands)",
    "tpi": "tok pisin",
    "tsi": "tsimshian",
    "tsn": "tswana",
    "tso": "tsonga",
    "tuk": "turkmen",
    "tum": "tumbuka",
    "tur": "turkish",
    "tut": "altaic",
    "tvl": "tuvalu",
    "twi": "twi",
    "tyv": "tuvinian",
    "uga": "ugaritic",
    "uig": "uighur",
    "ukr": "ukrainian",
    "umb": "umbundu",
    "und": "undetermined",
    "urd": "urdu",
    "uzb": "uzbek",
    "vai": "vai",
    "ven": "venda",
    "vie": "vietnamese",
    "vol": "volapuk",
    "vot": "votic",
    "wak": "wakashan languages",
    "wal": "walamo",
    "war": "waray",
    "was": "washo",
    "wen": "sorbian languages",
    "wol": "wolof",
    "xho": "xhosa",
    "yao": "yao",
    "yap": "yapese",
    "yid": "yiddish",
    "yor": "yoruba",
    "ypk": "yupik languages",
    "zap": "zapotec",
    "zen": "zenaga",
    "zha": "zhuang",
    "chi": "chinese",
    "znd": "zande",
    "zul": "zulu",
    "zun": "zuni",
};




const iso_3166_1_a3_map = {
    "afg": "afghanistan",
    "ala": "åland islands",
    "alb": "albania",
    "dza": "algeria",
    "asm": "american samoa",
    "and": "andorra",
    "ago": "angola",
    "aia": "anguilla",
    "ata": "antarctica",
    "atg": "antigua and barbuda",
    "arg": "argentina",
    "arm": "armenia",
    "abw": "aruba",
    "aus": "australia",
    "aut": "austria",
    "aze": "azerbaijan",
    "bhs": "bahamas",
    "bhr": "bahrain",
    "bgd": "bangladesh",
    "brb": "barbados",
    "blr": "belarus",
    "bel": "belgium",
    "blz": "belize",
    "ben": "benin",
    "bmu": "bermuda",
    "btn": "bhutan",
    "bol": "bolivia (plurinational state of)",
    "bes": "bonaire, sint eustatius and saba",
    "bih": "bosnia and herzegovina",
    "bwa": "botswana",
    "bvt": "bouvet island",
    "bra": "brazil",
    "iot": "british indian ocean territory",
    "brn": "brunei darussalam",
    "bgr": "bulgaria",
    "bfa": "burkina faso",
    "bdi": "burundi",
    "cpv": "cabo verde",
    "khm": "cambodia",
    "cmr": "cameroon",
    "can": "canada",
    "cym": "cayman islands",
    "caf": "central african republic",
    "tcd": "chad",
    "chl": "chile",
    "chn": "china",
    "cxr": "christmas island",
    "cck": "cocos (keeling) islands",
    "col": "colombia",
    "com": "comoros",
    "cod": "congo (democratic republic of the)",
    "cog": "congo",
    "cok": "cook islands",
    "cri": "costa rica",
    "civ": "côte d'ivoire",
    "hrv": "croatia",
    "cub": "cuba",
    "cuw": "curaçao",
    "cyp": "cyprus",
    "cze": "czech republic",
    "dnk": "denmark",
    "dji": "djibouti",
    "dma": "dominica",
    "dom": "dominican republic",
    "ecu": "ecuador",
    "egy": "egypt",
    "slv": "el salvador",
    "gnq": "equatorial guinea",
    "eri": "eritrea",
    "est": "estonia",
    "eth": "ethiopia",
    "flk": "falkland islands (malvinas)",
    "fro": "faroe islands",
    "fji": "fiji",
    "fin": "finland",
    "fra": "france",
    "guf": "french guiana",
    "pyf": "french polynesia",
    "atf": "french southern territories",
    "gab": "gabon",
    "gmb": "gambia",
    "geo": "georgia",
    "deu": "germany",
    "gha": "ghana",
    "gib": "gibraltar",
    "grc": "greece",
    "grl": "greenland",
    "grd": "grenada",
    "glp": "guadeloupe",
    "gum": "guam",
    "gtm": "guatemala",
    "ggy": "guernsey",
    "gin": "guinea",
    "gnb": "guinea-bissau",
    "guy": "guyana",
    "hti": "haiti",
    "hmd": "heard island and mcdonald islands",
    "vat": "holy see",
    "hnd": "honduras",
    "hkg": "hong kong",
    "hun": "hungary",
    "isl": "iceland",
    "ind": "india",
    "idn": "indonesia",
    "irn": "iran (islamic republic of)",
    "irq": "iraq",
    "irl": "ireland",
    "imn": "isle of man",
    "isr": "israel",
    "ita": "italy",
    "jam": "jamaica",
    "jpn": "japan",
    "jey": "jersey",
    "jor": "jordan",
    "kaz": "kazakhstan",
    "ken": "kenya",
    "kir": "kiribati",
    "prk": "korea (democratic people's republic of)",
    "kor": "korea (republic of)",
    "kwt": "kuwait",
    "kgz": "kyrgyzstan",
    "lao": "lao people's democratic republic",
    "lva": "latvia",
    "lbn": "lebanon",
    "lso": "lesotho",
    "lbr": "liberia",
    "lby": "libya",
    "lie": "liechtenstein",
    "ltu": "lithuania",
    "lux": "luxembourg",
    "mac": "macao",
    "mkd": "north macedonia",
    "mdg": "madagascar",
    "mwi": "malawi",
    "mys": "malaysia",
    "mdv": "maldives",
    "mli": "mali",
    "mlt": "malta",
    "mhl": "marshall islands",
    "mtq": "martinique",
    "mrt": "mauritania",
    "mus": "mauritius",
    "myt": "mayotte",
    "mex": "mexico",
    "fsm": "micronesia (federated states of)",
    "mda": "moldova (republic of)",
    "mco": "monaco",
    "mng": "mongolia",
    "mne": "montenegro",
    "msr": "montserrat",
    "mar": "morocco",
    "moz": "mozambique",
    "mmr": "myanmar",
    "nam": "namibia",
    "nru": "nauru",
    "npl": "nepal",
    "nld": "netherlands",
    "ncl": "new caledonia",
    "nzl": "new zealand",
    "nic": "nicaragua",
    "ner": "niger",
    "nga": "nigeria",
    "niu": "niue",
    "nfk": "norfolk island",
    "mnp": "northern mariana islands",
    "nor": "norway",
    "omn": "oman",
    "pak": "pakistan",
    "plw": "palau",
    "pse": "palestine, state of",
    "pan": "panama",
    "png": "papua new guinea",
    "pry": "paraguay",
    "per": "peru",
    "phl": "philippines",
    "pcn": "pitcairn",
    "pol": "poland",
    "prt": "portugal",
    "pri": "puerto rico",
    "qat": "qatar",
    "reu": "réunion",
    "rou": "romania",
    "rus": "russian federation",
    "rwa": "rwanda",
    "blm": "saint barthélemy",
    "shn": "saint helena, ascension and tristan da cunha",
    "kna": "saint kitts and nevis",
    "lca": "saint lucia",
    "maf": "saint martin (french part)",
    "spm": "saint pierre and miquelon",
    "vct": "saint vincent and the grenadines",
    "wsm": "samoa",
    "smr": "san marino",
    "stp": "sao tome and principe",
    "sau": "saudi arabia",
    "sen": "senegal",
    "srb": "serbia",
    "syc": "seychelles",
    "sle": "sierra leone",
    "sgp": "singapore",
    "sxm": "sint maarten (dutch part)",
    "svk": "slovakia",
    "svn": "slovenia",
    "slb": "solomon islands",
    "som": "somalia",
    "zaf": "south africa",
    "sgs": "south georgia and the south sandwich islands",
    "ssd": "south sudan",
    "esp": "spain",
    "lka": "sri lanka",
    "sdn": "sudan",
    "sur": "suriname",
    "sjm": "svalbard and jan mayen",
    "swz": "eswatini",
    "swe": "sweden",
    "che": "switzerland",
    "syr": "syrian arab republic",
    "twn": "taiwan, province of china",
    "tjk": "tajikistan",
    "tza": "tanzania, united republic of",
    "tha": "thailand",
    "tls": "timor-leste",
    "tgo": "togo",
    "tkl": "tokelau",
    "ton": "tonga",
    "tto": "trinidad and tobago",
    "tun": "tunisia",
    "tur": "turkey",
    "tkm": "turkmenistan",
    "tca": "turks and caicos islands",
    "tca": "turks and caicos islands",
    "tcd": "chad",
    "tha": "thailand",
    "tls": "timor-leste",
    "ton": "tonga",
    "tto": "trinidad and tobago",
    "tun": "tunisia",
    "tur": "turkey",
    "tuv": "tuvalu",
    "tjk": "tajikistan",
    "tkl": "tokelau",
    "tkm": "turkmenistan",
    "tgo": "togo",
    "uga": "uganda",
    "ukr": "ukraine",
    "umi": "united states minor outlying islands",
    "ury": "uruguay",
    "usa": "united states",
    "uzb": "uzbekistan",
    "vat": "vatican city",
    "vct": "saint vincent and the grenadines",
    "ven": "venezuela",
    "vgb": "british virgin islands",
    "vir": "u.s. virgin islands",
    "vnm": "vietnam",
    "vut": "vanuatu",
    "wlf": "wallis and futuna",
    "wsm": "samoa",
    "yem": "yemen",
    "zaf": "south africa",
    "zmb": "zambia",
    "zwe": "zimbabwe"
}