import React from 'react'
import moment from 'moment'
import Popover from '@mui/material/Popover'
import upperFirst from 'lodash/upperFirst'

import Status from '../../../components/ui/status'
import InlineHeading from '../../../components/ui/inlineHeading'
import Period from '../../../components/ui/period'
import ContentItem, { Content } from '../../../components/list/contentItem'
import Button from '../../../components/ui/controls/button'
import Program from '../../external-schedule/program/program'
import DropDown from '../../../components/ui/controls/dropdown'

import { copyTextToClipboard } from '../../../utils/misc'
import { getTitle, useLanguage } from '../../external-schedule/shared/utils'
import Actions from '../actions'
import Const from '../../../core/constants'
import useHorizonProgramMetadata from '../../../core/queries/horizon/useHorizonProgramMetadata'

import appConfig from 'config'
import '../../../components/listItems/standard.css'

const PackageContent = props => {
	const { item, service = {} } = props;
	const { deliveryStatus, expand } = item;

	const { publishWindows, hasLateChange } = getPublishWindows(item.created, item.packagePublishWindows, service.defaultCountry, service.countries);
	const isExcluded = service
		&& service.configuration.deliveryMode !== "DeliveryList" && deliveryStatus === "Blocked"
		|| service.configuration.deliveryMode === "DeliveryList" && deliveryStatus !== "DeliveryList";

	console.log(item);

	return (
		<React.Fragment>
			<ContentItem
				id={item.id}
				data={item}
				extraClasses={`${hasLateChange ? "late-change " : ""}`}
			>
				<Content>
					<h1>{item.name}</h1>
					<p>
						ID: 
						{item.externalReference?.length && (
							<span className="reference" onClick={(e) => copyTextToClipboard(item.externalReference, "Copied reference to your clipboard!", e)} title="Click here to copy reference to your clipboard">
								{item.externalReference}<span className="icon-content_copy"></span>
							</span>
						)}
						{!item.externalReference?.length && "N/A"}
						{item.isDubbed && <span> (DUBBED)</span>}
					</p>
				</Content>
				<Content className="publish-windows" size="">
					{publishWindows}
				</Content>
				<Content className="package-status">
					{getStatus(item.inQueue || props.isQueueList, item.packageStatus, item.firstExportDate, item.lastExportDate, item.isReady, isExcluded, item.statusDescription)}
					{expand && (
						<Button
							type="close"
							title="Close"
							onClick={(e) => {
								e.stopPropagation();
								Actions.togglePackageExpand(props.item);
							}}
						/>
					)}
				</Content>
			</ContentItem>
			{expand && (
				<PackageMetadata {...props} />
			)}
		</React.Fragment>
	);
}

export default PackageContent;

function getPublishWindows(created, packagePublishWindows, serviceCountry = {}, serviceCountries) {
	const packageCreated = moment(created);
	const now = moment();
	const lateChangeDays = appConfig.features.horizonLateChangeDays || 7;
	let hasLateChange = false;
	packagePublishWindows = packagePublishWindows.filter(pw => !!pw.site.country.iso2);
	if (!packagePublishWindows.length) {
		return { publishWindows: <div></div>, hasLateChange: false };
	}

	const publishWindows = packagePublishWindows.map(pw => {
		const isLateChange = packageCreated.isBefore(moment(pw.start))
			&& moment(pw.start).isAfter(now.clone().subtract(14, "days"))
			&& Math.abs(packageCreated.diff(pw.start, "days")) <= lateChangeDays;
		hasLateChange = hasLateChange || isLateChange;
		const useIso2Title = serviceCountries.length > 1
			|| (packagePublishWindows.some(w => w.site.country.iso2 !== pw.site.country.iso2)
			|| pw.site.country.iso2 !== serviceCountry.iso2)
		const iso2title = useIso2Title ? pw.site.country.iso2 : "";
		return (
			<InlineHeading key={pw.id} title={iso2title}>
				<span className={`icon-new_releases ${isLateChange ? "visible" : ""}`} title={`Added less than ${lateChangeDays} days before license start.`}></span>
				<Period start={pw.start} end={pw.end} format={Const.PERIOD_FORMAT} />
			</InlineHeading>
		);
	});
	
	return { publishWindows, hasLateChange };
}

function getStatus(inQueue, _packageStatus, firstExportDate, lastExportDate, isReady, isExcluded, statusDescription) {
	if (isExcluded) {
		return <Status status="blocked">Excluded</Status>
	}

	const packageStatus = _packageStatus?.toLowerCase() ?? "";
	let status = packageStatus,
		statusText = upperFirst(packageStatus),
		title = statusDescription;
	if (inQueue) {
		status = "queued";
		statusText = lastExportDate ? "Pending update" : "In queue for distribution";
	} else if ((packageStatus === "undefined" || packageStatus === "new") && !isReady) {
		status = "disabled";
		statusText = "Not ready for distribution";
	} else if ((packageStatus === "undefined" || packageStatus === "new") && isReady) {
		status = "queued";
		statusText = "To be distributed";
	} else if (packageStatus === "delivered" || packageStatus === "published") {
		statusText = packageStatus === "published" ? "Delivered" : "Partly delivered";
	}
	
	let dateNode;
	if ((packageStatus === "delivered" || packageStatus === "published") && !inQueue && (/*firstExportDate || */lastExportDate)) {
		// Only use lastExportDate for now since there is no firstExportDate
		title = `Last distributed: ${moment(lastExportDate).format("YYYY-MM-DD HH:mm:ss")}`;
		// title = `${firstExportDate && `Distributed: ${moment(firstExportDate).format("YYYY-MM-DD HH:mm:ss")}`}
		// 	${firstExportDate !== lastExportDate ? `(Updated: ${moment(lastExportDate).format("YYYY-MM-DD HH:mm:ss")})` : ""}`
		// 	.trim().replace(/\t/g, "")
	}

	if(title) {
		dateNode = <span className="icon-info" style={{ marginLeft: "5px" }} />;
	}
	
	return (
		<Status status={status} title={title}>
			{statusText}
			{dateNode}
		</Status>
	);
}

function PackageMetadata(props) {
	const { data, isLoading } = useHorizonProgramMetadata(props.item?.program?.reference);
	const program = data?.data ?? {};
	const { languages, language, setLanguage } = useLanguage(program);
	const programTitle = getTitle(program, language);

	return (
		<div className="c6-internal-schedule package-metadata">
			<div className="package-metadata-header">
				<PackageActions {...props} />
				{program?.id && (
					<div className="package-metadata-lang">
						<DropDown
							title="Language"
							name="language"
							currentState={language}
							states={languages}
							onChange={(e) => setLanguage(e.target.value)}
						/>
					</div>
				)}
			</div>
			{isLoading && (
				<div className="package-metadata-loading">
					Loading metadata...
				</div>
			)}
			{program?.id && (
				<Program
					program={program}
					title={programTitle}
					isLoading={isLoading}
					language={language}
				/>
			)}
		</div>
	);
}

function PackageActions({
	item: packageData,
	service,
	onPackageAction,
}) {
	const [historyPopoverOpen, setHistoryPopoverOpen] = React.useState(false);
	const historyButtonRef = React.useRef();

	const isCmoreFI = service?.system === "Comet 7" && service?.reference === "11";
	const upcoming = isInactiveAndHasUpcomingWindow(packageData);
	const canPrepublish = isCmoreFI && upcoming && packageData.packageStatus === "New";
	const isExported = !!packageData.lastExportDate || packageData.packageStatus == "delivered" || packageData.packageStatus == "published";
	const canResendMetadata = service?.updatesEnabled;
	const isEpisode = packageData?.metadata?.type === "Episode";
	const disableSendMetadata = isCmoreFI && !isActiveOrHasPremiereSoon(packageData);
	const sendMetadataDisabledExplanation = disableSendMetadata ? "(disabled because premiere is too far in the future)" : "";
	return (
		<div className="package-metadata-actions">
			{service?.replacementPolicy !== "NotSupported" && (
				<Button
					title="Resend"
					hoverTitle="Resend complete package including video and subtitle files"
					onClick={() => onPackageAction("resendAll", packageData)}
					disabled={!isExported}
				/>
			)}
			{canPrepublish && (
				<Button
					title="Send metadata"
					hoverTitle={`Prepublish metadata for this package ${sendMetadataDisabledExplanation}`}
					onClick={() => onPackageAction("publishMetadata", packageData)}
					disabled={disableSendMetadata}
				/>
			)}
			{!canPrepublish && (
				<Button
					title="Update metadata"
					hoverTitle="Update metadata for this package"
					onClick={() => onPackageAction("resendMetadata", packageData)}
					disabled={!canResendMetadata || !isExported}
				/>
			)}
			{isEpisode && (
				<Button
					title="Resend full season"
					hoverTitle="Resend all packages in this season"
					onClick={() => onPackageAction("resendSeason", packageData)}
					disabled={!isExported}
				/>
			)}
			<Button
				title="History log"
				hoverTitle="Show a list of events with information about what and when something happened and who carried out the action."
				onClick={() => setHistoryPopoverOpen(true)}
				buttonRef={historyButtonRef}
			/>
			<Popover
				open={historyPopoverOpen}
				anchorEl={historyButtonRef?.current}
				onClose={() => setHistoryPopoverOpen(false)}
			>
				{renderPackageLog(packageData)}
			</Popover>
		</div>
	);
}

function isInactiveAndHasUpcomingWindow(packageData) {
	const now = moment();
	const hasActiveWindow = packageData?.packagePublishWindows?.find(pw => {
		return moment(pw.start).isBefore(now) && moment(pw.end).isAfter(now);
	});
	const hasUpcomingWindow = packageData?.packagePublishWindows?.find(pw => {
		return moment(pw.start).isAfter(now);
	});
	return !hasActiveWindow && hasUpcomingWindow;
}

function isActiveOrHasPremiereSoon(packageData) {
	const now = moment();
	return packageData.packagePublishWindows?.some(pw => {
		const isActive = moment(pw.start).isBefore(now) && moment(pw.end).isAfter(now);
		const soon = moment().add(parseInt(appConfig.features.horizonPublishMetadataMaxDays), "days");
		const hasPremiereSoon = moment(pw.start).isBefore(soon);
		return isActive || hasPremiereSoon;
	}) ?? false;
}

function renderPackageLog({ packageEventLog = [] }) {
	return (
		<div className="package-log">
			<table>
				<thead>
					<tr>
						<th>When</th>
						<th>Who</th>
						<th>What</th>
					</tr>
				</thead>
				<tbody>
					{packageEventLog.map(({ description, occuredOn, triggeredBy }, i) => (
						<tr key={`${description}-${occuredOn}-${triggeredBy}-${i}`}>
							<td className="occured-on">{moment(occuredOn).format("YYYY-MM-DD HH:mm:ss")}</td>
							<td className="triggered-by">{triggeredBy}</td>
							<td className="description">{description}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
