import React from 'react'
import { Link, IndexLink } from 'react-router'
import { browserHistory } from 'browserHistory'
import debounce from 'lodash/debounce'
import { ThemeProvider, StyledEngineProvider } from '@mui/system'
import Dialog from '@mui/material/Dialog'

import { Comet, Module } from '../components/comet'

import AlertContainer from '../components/ui/alertContainer'
import ModuleChooser from '../components/module-chooser/'
import UserControlsOld from '../components/users/usercontrols'
import GlobalUploader from '../components/upload/globalUploader'
import SideNavigation from './sideNavigation'

import Actions from './authentication/actions'
import UserStore from './authentication/store'

import Login from '../core/authentication/login'
import UserControlsNew from '../core/authentication/userControls'
import * as Auth from './services/auth'

import { getModuleIcon } from './constants'
import { darkTheme, lightTheme } from './muiTheme'
import appConfig from 'config'
import { getCometTheme, listenForThemeChange } from './hooks/useDarkMode'

const REFRESH_INTERVAL = appConfig.features?.shieldTimerInterval ? parseInt(appConfig.features.shieldTimerInterval) : -1;
const AUTHENTICATION = "ShieldAuthentication";
const PRODUCT = appConfig.product ?? "Comet";
export const HIDE_GLOBAL_UPLOADER = "hide-global-uploader";
export const NEW_NAVIGATION = "new-navigation";

export default class App extends React.Component {
	constructor(props) {
		super(props);

		this.renderContent = _renderContent.bind(this);

		this.userDataExpiryTimerID = null;
		const hideGlobalUploader = localStorage.getItem(HIDE_GLOBAL_UPLOADER) === "yes";
		const newNavigation = localStorage.getItem(NEW_NAVIGATION) === "yes";
		if (appConfig.features.newCometNavigation && newNavigation) {
			document.querySelector("html").classList.add("hide-version");
		} else {
			document.querySelector("html").classList.remove("hide-version");
		}

		listenForThemeChange(() => {
			this.setState({ theme: getCometTheme() });
		});

		this.state = {
			...UserStore.getState(),
			hideGlobalUploader,
			newNavigation,
			userSettingsOpen: false,
			theme: getCometTheme(),
		};
	}

	componentDidMount() {
		UserStore.listen(this.onChange);

		// Start interval check for expired userData when the route is under Shield Authentication
		if (requiresAuthentication(this.props.routes) && REFRESH_INTERVAL > 0) {
			this.startIntervalCheck();
		}

		// Set favicon
		if (appConfig.features.faviconPath?.length) {
			Array.from(document.querySelectorAll("link[rel*='icon']")).forEach(el => el.href = appConfig.features.faviconPath);
		}
	}

	componentWillUnmount() {
		UserStore.unlisten(this.onChange);

		if (REFRESH_INTERVAL > 0) {
			this.endIntervalCheck();
		}
	}

	onChange = (state) => {
		this.setState(state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { location, children } = this.props;

		// if we changed routes...
		if ((
			nextProps.location.key !== location.key
			&& nextProps.location.state
			&& nextProps.location.state.modal
			&& !(location.state && location.state.modal) // If we're already in a modal, don't save the previous children for rendering since it will render editor modal on editor background
		)) {
			this.previousChildren = children; // remember the old children
			checkNavWidthDebounced();
		}
	}

	startIntervalCheck = () => {
		const { loggedIn, user } = this.state;

		if (loggedIn) {
			this.endIntervalCheck();
			this.userDataExpiryTimerID = setInterval(() => {
				const prematureExpirySeconds = REFRESH_INTERVAL ||  60; // Expire one refresh interval (or 60 seconds) before the real expiry, so we don't risk overshooting the next check
				if (Auth.isExpired(user, prematureExpirySeconds)) {
					const stayOnPage = true;
					Actions.logout(user, stayOnPage);
				}
			}, REFRESH_INTERVAL * 1000);
		}
	}

	endIntervalCheck = () => {
		clearInterval(this.userDataExpiryTimerID);
	}

	// ESC key or MUI close button
	handleClose = () => {
		const { location, routes, params } = this.props;
		// TODO!!!!: goBack() logic should not be needed since browserHistory.replace "Replaces the current entry on the history stack"
		// The problem is that goBack does not give us control of the URL, so we can't remove URL hashes for example :(

		// If location.state.modal is true, we use goBack() instead of replace()
		// Otherwise the user can end up with maaaannyyyyy history entries if they edit a lot of items in a list
		if (location.state?.modal) {
			browserHistory.goBack();
		} else {
			const returnTo = getReturnTo(location, routes, params);
			browserHistory.replace(returnTo);
		}
	}

	handleLogOut = () => {
		this.setState({ userSettingsOpen: false });
		const { user } = this.state;
		Auth.logoutUser(user);
		clearCookies();
	}

	toggleGlobalUploader = (override) => {
		this.setState((state) => {
			const newState = override !== undefined ? override : !state.hideGlobalUploader;
			if (newState) {
				localStorage.setItem(HIDE_GLOBAL_UPLOADER, "yes");
			} else {
				localStorage.removeItem(HIDE_GLOBAL_UPLOADER);
			}
			return { hideGlobalUploader: newState };
		});
	}

	toggleNewNavigation = (override) => {
		this.setState((state) => {
			const newState = override !== undefined ? override : !state.newNavigation;
			if (newState) {
				localStorage.setItem(NEW_NAVIGATION, "yes");
				document.querySelector("html").classList.add("hide-version");
			} else {
				localStorage.removeItem(NEW_NAVIGATION);
				document.querySelector("html").classList.remove("hide-version");				
			}
			return { newNavigation: newState };
		});
	}

	toggleUserSettingsOpen = () => {
		this.setState(state => ({ ...state, userSettingsOpen: !state.userSettingsOpen }));
	}

	renderUserControlsDialog = () => {
		return (
			<Dialog
				open={this.state.userSettingsOpen}
				onClose={() => {
					this.setState({ userSettingsOpen: false });
				}}
				className="c6-modal"
				maxWidth={false}
				classes={{
					container: "c6-modal-content",
					paper: "c6-modal-body",
				}}
                
			>
				<UserControlsNew
					user={this.state.user}
					onLogOut={this.handleLogOut}
					hideGlobalUploader={this.state.hideGlobalUploader}
					onToggleGlobalUploader={this.toggleGlobalUploader}
					// darkMode={props.darkMode}
					// onToggleDarkMode={props.onToggleDarkMode}
					newNavigation={this.state.newNavigation}
					onToggleNewNavigation={this.toggleNewNavigation}
				/>
			</Dialog>
		);
	}

	render() {
		let loginDialog = null;
		const muiTheme = this.state.theme === "dark" ? darkTheme : lightTheme;

		if (REFRESH_INTERVAL > 0) {
			if (!this.state.loggedIn && requiresAuthentication(this.props.routes)) {
				this.endIntervalCheck();
				loginDialog = (
					<Dialog
						open={true}
						PaperProps={{
							sx: { width: "310px" }
						}}
					>
						{/* Need to add another ThemeProvider here for some reason... */}
						<ThemeProvider theme={muiTheme}>
							<Login modal={true} />
						</ThemeProvider>
					</Dialog>
				);
			}
		}

		return (
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={muiTheme}>
					<Comet>
						<AlertContainer />
						{loginDialog}
						{this.renderContent(this.previousChildren)}
					</Comet>
					{!this.state.hideGlobalUploader && <GlobalUploader onHide={() => this.toggleGlobalUploader(true)} />}
				</ThemeProvider>
			</StyledEngineProvider>
		);
	}
}

// HELPERS
function _renderContent(previousChildren) {
	const { location, children, routes, params } = this.props;
	const { loggedIn, modules, user } = this.state;


	if (shouldRenderInAuthForm(location, routes)) {
		if (REFRESH_INTERVAL > 0) {
			this.endIntervalCheck();
		}

		return (
			<div className="c6-authentication">
				{renderWelcomeTexts()}
				<div>{children}</div>
			</div>
		);
	}

	if (requiresAuthentication(routes) && REFRESH_INTERVAL > 0) {
		this.startIntervalCheck();
	}

	const isModal = location.state?.modal;
	const isWideModal = location.state?.wideModal || routes && isWideModalRoute(routes, params) || isWideModalLocation(location);
	const dialogMaxHeightNone = routes?.some(r => r.dialogMaxHeightNone) ? "c6-modal-body-max-height-none" : "";

	const muiTheme = this.state.theme === "dark" ? darkTheme : lightTheme;

	// We arrived here with a direct URL link (without navigating in the app first)
	const cameFromDirectLink = !isModal && (location.query.directlink || routes && isModalRoute(routes));
	if (cameFromDirectLink) {
		return renderInDialog(children, this.handleClose, isWideModal, dialogMaxHeightNone, muiTheme);
	}

	// This check prevents modal content from rendering behind the dialog too
	const skipPreviousChildren = !!previousChildren?.props?.routes?.find(r => r.modal) && !previousChildren?.props?.route?.modal;

	const newCometNavigation = appConfig.features.newCometNavigation && this.state.newNavigation;
	return (
		<div>
			{PRODUCT === "Interntablå" && <div className="newVersionAvailable hide"><div>A new version is available! Please <a className="c6-link" onClick={reloadBrowser}>reload your browser</a>.</div></div>}
			<Module>
				{!appConfig.features.hideAppBar && !newCometNavigation && (
					<div className="c6-topbar">
						<div className="topbar-wrapper">
							{loggedIn && (
								<ModuleChooser
									location={location}
									modules={modules}
									canOpen={loggedIn}
									product={PRODUCT}
								/>
							)}
							<Apps location={location} modules={modules} />
							{loggedIn && (
								<UserControlsOld
									username={user.name}
									onLogOut={this.handleLogOut}
									hideGlobalUploader={this.state.hideGlobalUploader}
									onToggleGlobalUploader={this.toggleGlobalUploader}
									onToggleUserSettingsOpen={this.toggleUserSettingsOpen}
								/>
							)}
						</div>
					</div>
				)}
				{newCometNavigation && (
					<SideNavigation
						user={user}
						onToggleUserSettingsOpen={this.toggleUserSettingsOpen}
					/>
				)}
				{this.renderUserControlsDialog()}
				{isModal ? (!skipPreviousChildren && previousChildren) : children}
				{isModal && renderInDialog(children, this.handleClose, isWideModal, dialogMaxHeightNone, muiTheme)}
			</Module>
		</div>
	);
}

// HELPERS
function reloadBrowser(e) {
	e.preventDefault();
	e.stopPropagation();
	window.location.reload(true);
}

function shouldRenderInAuthForm(location, routes) {
	return routes.some(r => r.renderInAuthForm === true);
	// const routeName = getRouteName(location);
	// return routeName === "login" || routeName === "logout" || routeName === "forgotpassword" || routeName.includes("resetpassword/");
}

function requiresAuthentication(routes) {
	const ShieldComponent = routes.find(i => i.component && i.component.name === AUTHENTICATION);
	const isPublicRoute = routes.find(r => r.public === true);
	return ShieldComponent != null && !isPublicRoute;
}

export function isModalRoute(routes) {
	return routes.some(route => route.modal);
}

function isWideModalRoute(routes, params) {
	return routes.some(route => {
		if (typeof(route.wideModal) === "function") {
			return route.wideModal(params);
		}

		return route.wideModal;
	});
}

function isWideModalLocation(location) {
	return location?.query?.widemodal;
}

export function getReturnTo(location, routes, params) {
	if (location?.state?.returnTo) {
		return location.state.returnTo;
	}

	// First reverse list of routes because we want deeper routes to have higher priority
	// Then look for the first route with the "returnTo" or "returnHere" prop
	const route = routes.reverse().find(r => r.returnTo || r.returnHere);
	if (route?.returnTo) {
		return route.returnTo;
	}
	
	if (typeof route?.returnHere === "function") {
		return route.returnHere(params);
	}
	
	if (route?.returnHere) {
		const returnTo = routes
			.slice(1, routes.length)
			.reverse()
			.filter(r => r.path && r.path !== "/")
			.map(r => r.path)
			.join("/");
		return `/${returnTo}`;
	}

	return "/";
}

function Apps({ location, modules }) {
	setTimeout(checkNavWidthDebounced);
	window.removeEventListener("resize", checkNavWidthDebounced);
	window.addEventListener("resize", checkNavWidthDebounced);

	const module = location.pathname.split("/")[0] || location.pathname.split("/")[1];
	const currentModule = modules.find(m => m.key === module);
	return (
		<React.Fragment>
			<nav className="full-size">
				<div className="module-apps">{getModuleApps(currentModule)}</div>
			</nav>
			<nav className="slim hidden">
				<div className="module-apps">{getModuleApps(currentModule)}</div>
			</nav>
		</React.Fragment>
	);
}

function renderInDialog(children, handleClose, isWideModal, dialogMaxHeightNone = "", muiTheme) {
	return (
		<Dialog
			open={true}
			onClose={handleClose}
			className="c6-modal"
			maxWidth={isWideModal ? "xl" : "md"}
			fullWidth={true}
			classes={{
				container: "c6-modal-content",
				paper: `c6-modal-body ${dialogMaxHeightNone}`
			}}
		>
			{/* Need to add another ThemeProvider here for some reason... */}
			<ThemeProvider theme={muiTheme}>
				{children}
			</ThemeProvider>
		</Dialog>
	)
}

function getModuleApps(currentModule) {
	if (currentModule?.apps) {
		const apps = currentModule.apps
			.filter(a => !a.hidden)
			.map(({ url, key, displayName }, index) => {
				const linkProps = {
					key: `${index}${key}`,
					to: url,
					className: getIcon(key),
					activeClassName: "active",
				};

				return url === currentModule.url
					? <IndexLink {...linkProps}><span className="name">{displayName}</span></IndexLink>
					: <Link {...linkProps}><span className="name">{displayName}</span></Link>;
			});

		return apps;
	}

	return null;
}

function getIcon(key) {
	return getModuleIcon(key) || "";
}

export function clearCookies() {
	const cookies = document.cookie.split(";");

	cookies.forEach(c => {
		document.cookie = c
			.replace(/^ +/, "") // remove whitespace
			.replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
	});
}

function renderWelcomeTexts() {
	return (
		<>
			<header key="header">
				{appConfig.features.topWelcomeTitle && <h1>{appConfig.features.topWelcomeTitle}</h1>}
				{appConfig.features.topWelcomeTitle && <p dangerouslySetInnerHTML={{ __html: insertEmailLinks(appConfig.features.topWelcomeText) }}></p>}
			</header>
			<footer key="footer">
				{appConfig.features.bottomWelcomeTitle && <h1>{appConfig.features.bottomWelcomeTitle}</h1>}
				{appConfig.features.bottomWelcomeText && <p dangerouslySetInnerHTML={{ __html: insertEmailLinks(appConfig.features.bottomWelcomeText) }}></p>}
			</footer>
		</>
	);
}

function insertEmailLinks(text) {
	const urlRegex = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+~#?&//=]*))/gi;
	const emailRegex = /([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)/gi;
	return text
		.replace(urlRegex, `<a class="c6-link" target="_blank" href="$1">$1</a>`)
		.replace(emailRegex, `<a class="c6-link" href="mailto:$1">$1</a>`);
}

// Compares the width of the nav element and the app links
// If the app links are too wide for the nav menu, display the slim version instead
function checkNavWidth() {
	const slimNav = document.querySelector(".c6-topbar nav.slim");
	const fullSizeNav = document.querySelector(".c6-topbar nav.full-size");
	const apps = fullSizeNav?.querySelectorAll(".module-apps > a");
	if (!apps?.length) {
		return;
	}

	const navWidth = getElementContentWidth(fullSizeNav);
	const appsWidth = [...apps].reduce((totalWidth, app) => totalWidth + app.clientWidth, 0);

	if ((appsWidth + 50) >= navWidth) {
		fullSizeNav.classList.add("hidden");
		slimNav.classList.remove("hidden");
	} else {
		fullSizeNav.classList.remove("hidden");
		slimNav.classList.add("hidden");
	}
}

const checkNavWidthDebounced = debounce(checkNavWidth, 500);

function getElementContentWidth(element) {
	const styles = window.getComputedStyle(element);
	const padding = parseFloat(styles.paddingLeft)
		+ parseFloat(styles.paddingRight);
  
	return parseFloat(element.clientWidth - padding);
}