import React from 'react'

import Editor, { parseUi } from '../../../components/editor/'
import Approval from '../../../components/ui/editorFields/approval'

import { Regions } from '../../../core/constants'
import { hasAccessToPath } from '../../../core/services/auth'
import { displayAlert } from '../../../core/services/alert'

import schema from './schema'
import uiSchema from './uiSchema'

import appConfig from 'config'

export default props => (
	<Editor
		layout="grid"
		api="tags"
		entity="tag"
		getSchema={getSchema}
		getUiSchema={getUiSchema}
		customFields={{ approval: Approval }}
		loadPayloadTransform={loadPayloadTransform}
		hasEditAccess={hasAccessToPath(props.routes, "editor")}
		{...props}
	/>
);

const getSchema = () => schema;
const getUiSchema = (model, isNew, location, route, params) => {
	uiSchema.tagType.name["ui:disabled"] = !isNew;
	return uiSchema;
};

function loadPayloadTransform({ model, entity, location, route, params }) {
	const { versions, ...payload } = model;

	if (appConfig.features.metadataEditorWarningMessageOnOpen?.length) {
		displayAlert("warning", null, null, null, null, appConfig.features.metadataEditorWarningMessageOnOpen);
	}

	// Fill in missing versions
	const languages = Regions.filter(region => region.id <= 4).map(region => region.languageDisplayName);
	languages.forEach((lang, i) => {
		if (versions.some(version => version.version == lang))
			return;
		
		versions.splice(i, 0, { version: lang });
	});

	return {
		versions,
		...payload
	};
}