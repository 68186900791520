import React from 'react'
import PropTypes from 'prop-types'

import Main from '../../../components/ui/main'
import ResponsiveImage from '../../../components/assets/responsiveImage'

import { getSynopsisExternal } from '../shared/utils'

import Metadata from './metadata'
import PressImages from './pressImages'

import './program.css'

export default class Program extends React.PureComponent {

	static propTypes = {
		title: PropTypes.string,
		program: PropTypes.object,
		isLoading: PropTypes.bool,
	}

	render() {
		const { title, program, isLoading, showRights, language } = this.props;

		if (isLoading || !program) {
			return null;
		}

		const { type, versions, assets, season } = program;

		const firstArea = season
			? <SeasonPart season={season} versions={versions} assets={assets} language={language} />
			: <ProgramPart versions={versions} assets={assets} language={language} />;

		let secondArea = null;
		if (type === "episode") {
			secondArea = <EpisodePart program={program} versions={versions} assets={assets} showRights={showRights} language={language} />;
		}
		else if (type === "single") {
			secondArea = <Metadata program={program} showRights={showRights} language={language} />;
		}

		const classNames = season && season.id ? "c6-program program-season" : "c6-program";
		return (
			<div className={classNames}>
				<Main>{firstArea}</Main>
				<Main>{secondArea}</Main>
				<Main><PressImages programTitle={title} assets={assets} /></Main>
			</div>
	    );
  	}
}

// When rendering single programs
const ProgramPart = ({ versions, assets, language }) => (
	<section>
		{getMainResponsiveImage(assets, "single")}
		{getSynopsisExternal(versions, "single", null, language)}
	</section>
);


// When rendering episodes
const EpisodePart = ({ program, versions, assets, showRights, language }) => {

	const { episode } = program;

	const episodeNumber = episode.episodeNumber
		? <h2>Episode {episode.episodeNumber}</h2>
		: null;

	return (
		<section>
			{getMainResponsiveImage(assets, "episode")}
			{episodeNumber}
			{getSynopsisExternal(versions, "episode", null, language)}
			<Metadata program={program} showRights={showRights} language={language} />
		</section>
	);
};

// When rendering seasons
const SeasonPart = ({ season, versions, assets, language }) => (
	<section>
		{getMainResponsiveImage(assets, "season")}
		<h2>Season {season.seasonNumber} ({season.numberOfEpisodes} episodes)</h2>
		{getSynopsisExternal(versions, "season", null, language)}
	</section>
);

// HELPERS
function getMainResponsiveImage(assets = [], contentType) {

	const image = assets.find(a => a.category === "main" && a.type === "image" && a.contentType === contentType);

	return image
		? <ResponsiveImage id={image.id} sizes="(min-width: 985px) 33vw, (min-width: 665px) 50vw, 100vw" />
		: null;
}