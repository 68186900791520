import React from 'react'
import PropTypes from 'prop-types'
import styles from './section.css'

const Section = ({flex = 1, background = false, children}) => {

	const style = { flex };
	const classNames = background ? "c6-section-background" : null ;

	return (
		<section className={classNames} style={style}>
			{children}
		</section>
	);
};
Section.propTypes = {
	flex: PropTypes.string,
	background: PropTypes.bool,
}
export default Section;