import React from 'react'
import Editor, { parseUi } from '../../../components/editor/'

import Approval from '../../../components/ui/editorFields/approval'
import MuiSlug from '../../../components/ui/editorWidgets/muiSlug'
import ProgramPreview from '../shared/preview'

import * as API from '../../../apis/'
import { displayAlert } from '../../../core/services/alert'

import schema from './schema.json'
import ui from './ui.json'

import appConfig from 'config'

const VERSION_ID = 1;
const VERSION_NAME = "Swedish";

export default props => (
	<Editor
		layout="grid"
		submitAsPatch={true}
		api="metadata"
		entity="family"
		getSchema={getSchema}
		getUiSchema={getUiSchema}
		loadPayloadTransform={loadPayloadTransform}
		savePayloadTransform={savePayloadTransform}
		customFields={{ approval: Approval }}
		customWidgets={{ slug: MuiSlug }}
		{...props}
	>
		<ProgramPreview />
	</Editor>
);

function getSchema(model, isNew, location, route, params) {
	if (!model.versions) {
		model.versions = [
			{ versionId: VERSION_ID, versionName: VERSION_NAME }
		]
	}
	return schema;
}

function getUiSchema(model, isNew, location, route, params) {
	return {
		...parseUi(ui, API),
	};
}

function loadPayloadTransform({ model, entity, location, route, params }) {
	if (appConfig.features.metadataEditorWarningMessageOnOpen?.length) {
		displayAlert("warning", null, null, null, null, appConfig.features.metadataEditorWarningMessageOnOpen);
	}

	// Filter the model so we only have the selected locale version
	return {
		...model,
		versions: model.versions.filter(v => v.versionId === parseInt(VERSION_ID))
	};
}

function savePayloadTransform({ formData, patchData, entity, location, route, params, ...rest }) {
	// The fields in this editor are on a locale/version but we also need a name for the program object
	const version = formData.versions.find(v => v.versionId === parseInt(VERSION_ID)) || formData.versions[0];
	const name = version.title;

	return {
		...rest,
		formData: {
			...formData,
			name,
			displayName: name,
			provider: {
				name: "Comet",
			},
			type: 5, // 5 = Container
		},
		patchData: patchData && {
			...patchData,
			name,
			displayName: name,
		},
		entity,
		location
	};
}