import React, { Component } from 'react'

import { MuiText, MuiTextArea, MuiSelect } from '../../components/ui/editorWidgets'
import Button from '../../components/ui/controls/button'

import Header from './header'
import Store from './store'
import Actions from './actions'
import { LinkRelationTypes } from '../../apps/metadata/shared/utils'

const TEXT_HEADING = "Add program to new family";

export default class NewFamily extends Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);

		const { originalTitle } = this.props.data;

		this.state = {
			...Store.getState(),
			familyName: originalTitle,
			familyDescription: "",
			familyMemberType: "Main",
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	render() {
		const {
			data
		} = this.props;

		const {
			isLoading,
			status,

			familyName,
			familyDescription,
			familyMemberType,
		} = this.state;


		const disabled = isLoading || status != null;
		const buttonDisabled = disabled
			|| !(familyName && familyName.length)
			// || !(familyDescription && familyDescription.length)
			|| !familyMemberType;

		return (
			<div>
				<Header
					title={TEXT_HEADING}
					showSearch={false}
				/>
				<div style={{ paddingLeft: "40px" }}>
					<MuiText
						onChange={ val => this.setState({ familyName: val }) }
						label="Family name"
						value={familyName}
						readonly={disabled}
						options={{ maxLength: 100 }}
					/>
					<MuiTextArea
						onChange={ val => this.setState({ familyDescription: val }) }
						label="Family description"
						value={familyDescription}
						readonly={disabled}
						options={{ maxLength: 400 }}
					/>
					<MuiSelect
						value={familyMemberType}
						label="Type"
						options={{ dataSource: LinkRelationTypes }}
						onChange={ val => this.setState({ familyMemberType: val }) }
						disabled={disabled}
					/>
					<br />
					<Button
						title={"Create new family"}
						onClick={ () => Actions.createAndAddToFamily(data, this.state) }
						disabled={buttonDisabled}
						className="create-family"
					/>
				</div>
			</div>
		);
	}
}
