import React from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import { QueryClientProvider } from '@tanstack/react-query'

import EditorCore from '../../core/ui/editor'
import { DEFAULT_QUERY_CLIENT } from '../../core/constants'
import { isModalRoute } from '../../core'
import { displayAlert } from '../../core/services/alert'

import Store from './store'
import Actions from './actions'

import './index.css'

export default class Editor extends React.Component {

	static propTypes = {
		api: PropTypes.string.isRequired,
		entity: PropTypes.string.isRequired,
		getSchema: PropTypes.func.isRequired,
		getUiSchema: PropTypes.func.isRequired,
		location: PropTypes.object.isRequired,
		route: PropTypes.object.isRequired,

		getValidation: PropTypes.func,
		customTransformErrors: PropTypes.func,
		getCommands: PropTypes.func,
		layout: PropTypes.string,
		submitAsPatch: PropTypes.bool,
		rfc6902: PropTypes.bool,
		loadPayloadTransform: PropTypes.func,
		savePayloadTransform: PropTypes.func,
		customFields: PropTypes.object,
		customWidgets: PropTypes.object,
		hasEditAccess: PropTypes.bool,
		getCreateAlertAction: PropTypes.func,
		disabled: PropTypes.bool,
		enableEditorNavigation: PropTypes.bool,
	}

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.handleModelChange = this.handleModelChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleError = this.handleError.bind(this);

		this.id = props.params.id;
		this.pristineModel = null;

		this.state = {
			...Store.getState(),
			validationResult: {},
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		if (this.id && !this.props.disabled) {
			init(this.id, this.props);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextState) {
		if (
			this.props.disabled && !nextProps.disabled
			|| this.props.params.id !== nextProps.params.id
		) {
			this.id = nextProps.params.id;
			this.pristineModel = null;
			init(this.id, nextProps);
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		if (
			(this.props.submitAsPatch || this.props.rfc6902)
			&& state.model.id
			&& this.pristineModel?.id !== state.model.id
		) {
			this.pristineModel = cloneDeep(state.model);
		}
		this.setState(state);
	}

	handleModelChange(data, patchData = null, changeFieldId) {
		const { formData } = data;
		let newData = formData;
		if (typeof this.props.changePayloadTransform === "function") {
			const payload = {
				formData,
				patchData,
				changeFieldId,
				entity: this.props.entity,
				location: this.props.location,
				routes: this.props.routes,
				params: this.props.params,
			};
			newData = this.props.changePayloadTransform(payload);
		}
		Actions.modelChanged.defer(newData, changeFieldId);
	}

	handleSubmit(data, patchData = null) {
		const { errors, formData } = data;
		const {
			location,
			route,
			params,
			routes,
			api,
			entity,
			savePayloadTransform = null,
			loadPayloadTransform = null,
			submitAsPatch = false,
			rfc6902 = false,
			getCommands = null,
			getCreateAlertAction = null,
		} = this.props;

		if(errors.length === 0) {
			let payload = {
				formData,
				patchData,
				entity,
				location,
				routes,
				params,
			};

			if(typeof(savePayloadTransform) === "function") {
				payload = savePayloadTransform({ ...payload, route });
			}

			const commands = typeof(getCommands) === "function"
				? getCommands({ entity, location, route, params, formData, patchData })
				: null;

			const hasLoadTransform = typeof(loadPayloadTransform) === "function";

			if(hasLoadTransform || submitAsPatch || rfc6902) {

				const transformer = model => {
					const updatedModel = hasLoadTransform
						? loadPayloadTransform({ model, entity, location, route, params })
						: model ;

					if(submitAsPatch || rfc6902) {
						this.pristineModel = cloneDeep(updatedModel);
					}

					return updatedModel;
				};

				Actions.saveItem({ ...payload, api, transformer, commands, getCreateAlertAction });
			}
			else {
				Actions.saveItem({ ...payload, api, commands, getCreateAlertAction });
			}
		}
	}

	handleError(data) {
		console.error(data);
		displayAlert("error", "There was a validation error in the editor. Please check the fields and try again.");
		const firstErrorNode = document.querySelector(".has-error .error-detail");
		firstErrorNode && firstErrorNode.scrollIntoView({ behavior: "smooth", block: "start" });
	}

	render() {
		const {
			getSchema,
			getUiSchema,
			getValidation,
			customTransformErrors,
			location,
			route,
			routes,
			layout,
			rfc6902,
			customFields = {},
			customWidgets = {},
			params,
			children = [],
			hasEditAccess,
			canApprove,
			className: extraClassNames = "",
			context = {},
			enableEditorNavigation,
		} = this.props;

		const {
			isDirty,
			isLoading,
			model,
			validationResult,
		} = this.state;

		const isNew = !this.id && !(model && model.id);
		const modelLoaded = model && model.id;
		const className = `${layout ? `rjsf-${layout}` : ""} ${extraClassNames}`;

		// HACK: Editors using the new grid layout also get live validation (just to
		// constrain it to metadata and acq for the moment) This simplified editor
		// will default to grid + live validation in the future.
		const liveValidate = layout === "grid";

		// If the user doesn't have the required access level, set the editor to read-only (if it's not already set to read only)
		const uiSchema = getUiSchema(model, isNew, location, route, params, routes);
		if(hasEditAccess !== undefined && !uiSchema["ui:readonly"]) {
			uiSchema["ui:readonly"] = !hasEditAccess;
		}

		const schema = getSchema(model, isNew, location, route, params, routes);

		// We only need to override the fieldset elements with divs in the new flexbox grid layout
		const objectFieldTemplate = layout === "grid" ? getObjectFieldTemplate : null;

		const validate = getValidation ? getValidation.bind(this, params, location) : null;

		const formContext = {
			model, // Expose the complete model for use in widgets and fields.
			editorActions: Actions, // Expose the editor actions so components can call setDirty on the editor while editing (before the model is updated)
			hasEditAccess,
			canApprove,
			...context,
		};

		return (
			<EditorCore
				liveValidate={liveValidate}
				isDirty={isDirty}
				formContext={formContext}
				route={route}
				isModal={location.state?.modal || isModalRoute(routes)}
				renderForm={isNew || modelLoaded}
				enableEditorNavigation={enableEditorNavigation}

				model={model}
				pristineModel={this.pristineModel}
				rfc6902={rfc6902}

				schema={schema}
				uiSchema={uiSchema}
				validate={validate}
				customTransformErrors={customTransformErrors}

				validationResult={validationResult}
				onChange={this.handleModelChange}
				onSubmit={this.handleSubmit}
				onError={this.handleError}
				disableActions={isLoading}
				className={className}
				customWidgets={customWidgets}
				customFields={customFields}
				objectFieldTemplate={objectFieldTemplate}
			>
				{React.Children.map(children, child => React.cloneElement(child, { model, schema, uiSchema, params, location, formContext }))}
			</EditorCore>
		);
	}
}

// This would allow the JSON Schema required property to have property:whenApproved logic, but
//  I'm not sure it's the right way to go so I'm using custom validation in the app.jsx for now
//
// export function parseSchema(schema, model, options) {
// 	return JSON.parse(JSON.stringify(schema), (key, value) => {
// 		switch(key) {
// 			case "required":
// 				let returnValue = [];
// 				value.forEach(v => {
// 					const [property, condition] = v.split(":");
// 					if(condition === "whenApproved" && options.isApproved) {
// 						returnValue.push(property);
// 					}
// 				});
// 				return returnValue;
// 			default:
// 				return value;
// 		}
// 	});
// }

// Replaces api+command datasource ui:options with the real API-function
// Also replaces action strings with real Actions
export function parseUi(ui, API, Actions) {
	return JSON.parse(JSON.stringify(ui), (key, value) => {

		switch(key) {
			case "dataSource":
				const { api, command } = value;
				return API && api && command ? API[api][command] : value;
			case "actionFetch":
			case "actionSelect":
			case "actionRemove":
				return Actions ? Actions[value] : value;
			default:
				return value;
		}
	});
}

export const withQueryClient = (WrappedComponent) => {
	return (props) => (
		<QueryClientProvider client={DEFAULT_QUERY_CLIENT}>
			<WrappedComponent {...props} />
		</QueryClientProvider>
	);
};


// TODO: Until https://bugs.chromium.org/p/chromium/issues/detail?id=375693 is fixed
// We have to use a custom objectFieldTemplate with div:s instead of fieldset
function getObjectFieldTemplate(props) {
	const { title, description, properties } = props;
	return (
		<div className="grid-object">
			{title && <h1>{title}</h1>}
			{description && <p>{description}</p>}
			{properties.map(el => el.content)}
		</div>
	);
}

function init(id, props) {
	const { api, entity, loadPayloadTransform = null, location, route, params, getCommands = null } = props;

	const transform = typeof(loadPayloadTransform) === "function"
		? model => loadPayloadTransform({ model, entity, location, route, params })
		: null;

	const commands = typeof(getCommands) === "function"
		? getCommands({ entity, location, route, params })
		: null;

	Actions.fetchItem(id, api, entity, transform, commands);
}

/**
 * HACK: a hacky way to make the MUI dialogs wide.
 * Can be used instead of the wideModal route prop when the setting needs to be set dynamically based on data in the editor.
 */
export function setWideModal() {
	const dialogEl = document.querySelector(".c6-modal-body");
	if (dialogEl) {
		dialogEl.style.transition = "max-width 0.2s ease-in-out";
		dialogEl.style.maxWidth = "1536px";
	}
}