import React from 'react'
import { Filter, Left, Right } from '../../../components/filter'
import Switch from '../../../components/ui/controls/switch'
import Search from '../../../components/ui/controls/search'
import Button from '../../../components/ui/controls/button'

export default ({
	title,
	searchText,
	filters,
	unselectedFilters,
	handleFilter,
	onCreateNew,
	iconCreate,
	textCreate,
}) => (
	<Filter>
		<Left>
			<h1>{title}</h1>
			{/*<Search
				onChange={handleFilter.bind(this, "search")}
				placeholder="Search"
				searchText={searchText} />*/}
		</Left>
		<Right>
			<Button					
				type={iconCreate}
				title={textCreate}
				onClick={onCreateNew}
			/>
		</Right>
	</Filter>
);