import React from 'react'
import PropTypes from 'prop-types'

import List from '@mui/material/List'
import MenuItems from './containerNavigationItem'

import AlertDialog from '../shared/alertDialog'

import ContainerActions from './actions'
import ContainerStore from './store'

import './containerNavigation.css'

export default class ContainerNavigation extends React.Component {
	static propTypes = {
		container: PropTypes.object,
		childContainers: PropTypes.array,
		loadingContainerId: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.bool,
		]),
		hasEditAccess: PropTypes.bool,
		initiallyOpen: PropTypes.bool,
		alwaysShowEpisodesAndSeasons: PropTypes.bool,
	}

	constructor(props) {
		super(props);

		this.state = {
			...ContainerStore.getState(),
		};
	}

	componentDidMount() {
		ContainerStore.listen(this.onChange);
	}

	componentWillUnmount() {
		ContainerStore.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	handleDrop = ({ droppedItem, dropTarget }) => {
		// console.log("Dropped %o on %o", droppedItem, dropTarget);

		if (droppedItem.draggedItemType === "container") {
			const isSameContainer = droppedItem.id === dropTarget.id;
			const isChildOfTargetFolder = droppedItem.parentId === dropTarget.id;
			if (!isSameContainer && !isChildOfTargetFolder && !isParentOfDropTarget(droppedItem, dropTarget)) {
				// This will display an "Are you sure?" dialog
				ContainerActions.containerDrop({
					droppedContainer: droppedItem,
					dropTarget,
				});
			}
		} else {
			// This will display an "Are you sure?" dialog
			ContainerActions.assetDrop({
				droppedAssets: [droppedItem],
				dropTarget,
				selected: this.props.selected,
			});
		}
	}

	handleDropAssetsAction = (e) => {
		const { container } = this.props;
		const { droppedAssets, dropTarget: targetContainer } = this.state;

		switch(e.target.innerText) {
			case "COPY":
				ContainerActions.moveAssetsToContainer(droppedAssets.map(a => a.id), targetContainer, container.id, true);
				break;
			case "MOVE":
				ContainerActions.moveAssetsToContainer(droppedAssets.map(a => a.id), targetContainer, container.id, false);
				break;
		}

		ContainerActions.clearAssetDrop.defer(); // Removes the dialog
	}

	handleDropContainerAction = (e) => {
		const { container } = this.props;
		const { droppedContainer, dropTarget: targetContainer } = this.state;

		if (e.target.innerText === "MOVE") {
			ContainerActions.moveContainersToChildContainers(targetContainer.id, [droppedContainer]);
		}

		ContainerActions.clearContainerDrop.defer(); // Removes the dialog
	}

	render() {
		const {
			loadingContainerId,
			container,
			childContainers = [],
			hasEditAccess,
			onContainerClick,
			initiallyOpen = true,
			alwaysShowEpisodesAndSeasons = false,
			hideAssetCountOnSelectedContainer = false,
		} = this.props;

		const { droppedContainer, droppedAssets, dropTarget } = this.state;
		const { id: selectedContainerId } = container;
		const numberOfItemsText = droppedAssets.length === 1 ? "the item" : `${droppedAssets.length} items`;

		return (
			<div className="c6-folder-navigation">
				<List disablePadding>
					<MenuItems
						loadingContainerId={loadingContainerId}
						items={childContainers}
						selectedContainerId={selectedContainerId}
						onDrop={this.handleDrop}
						hasEditAccess={hasEditAccess}
						onContainerClick={onContainerClick}
						initiallyOpen={initiallyOpen}
						alwaysShowEpisodesAndSeasons={alwaysShowEpisodesAndSeasons}
						hideAssetCountOnSelectedContainer={hideAssetCountOnSelectedContainer}
					/>
				</List>
				<AlertDialog
					open={droppedAssets.length > 0}
					primary="Copy,Move"
					secondary="Cancel"
					onAction={this.handleDropAssetsAction}
				>
					Do you want to copy or move {numberOfItemsText} to <strong>{dropTarget.displayName}</strong>?
				</AlertDialog>
				<AlertDialog
					open={!!droppedContainer.id}
					primary="Move"
					secondary="Cancel"
					onAction={this.handleDropContainerAction}
				>
					Do you want to move the folder "{droppedContainer.displayName}" and it's subfolders to <strong>{dropTarget.displayName}</strong>?
				</AlertDialog>
			</div>
		);
	}
};


function isParentOfDropTarget(droppedItem, dropTarget) {
	if (dropTarget.parentContainer && dropTarget.parentContainer.id === droppedItem.id) {
		return true;
	}

	return !!dropTarget.parentContainer && !!isParentOfDropTarget(droppedItem, dropTarget.parentContainer);
}