import { browserHistory } from 'browserHistory'

import alt from '../../core/services/alt'
import * as MetadataData from '../../apis/metadata'
import * as StarData from '../../apis/star'
import { handleRequestFailed } from '../../core/services/errorhandling'
import * as Alert from '../../core/services/alert'

class Actions {

	search(searchText) {
		return searchText;
	}

	filter(filterValue, filterName) {
		return (dispatch) => {
			dispatch({ [filterName]: filterValue });
		};
	}

	fetchFamilies({ filters }) {
		return dispatch => {
			dispatch();

			const requestTime = Date.now();

			MetadataData.fetchFamilies(filters)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.familiesUpdated(items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime);
				}, this.requestFailed);
		};
	}

	pageFamilies(url) {
		return (dispatch) => {
			dispatch(url);

			MetadataData.fetchUrl(url)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.familiesUpdated(items, appendToExistingItems, numberOfItems, nextPageUrl);

				}, this.requestFailed);
		};
	}
	familiesUpdated(items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime) {
		return {
			items,
			appendToExistingItems,
			numberOfItems,
			nextPageUrl,
			requestTime,
		};
	}

	createAndAddToFamily(programData, { familyName, familyDescription, familyMemberType }) {
		return dispatch => {
			dispatch();

			const newFamilyPayload = {
				name: familyName,
				displayName: familyName,
				provider: {
					name: "Comet",
				},
				type: 5, // 5 = Container
				versions: [
					{
						versionId: 1,
						versionName: "Swedish",
						title: familyName,
						mediumSynopsis: familyDescription,
					}
				]
			};

			// 1: Create new Metadata program/family
			let newFamily;
			MetadataData.createFamily(newFamilyPayload)
				.then(response => {
					newFamily = response;
					const containerPayload = {
						containerTypeId: 9,
						name: "Awaiting sync...",
						programGuid: response.guid,
					};

					this.familyCreated();

					// 2: Create new Star container using the new program/family guid
					return StarData.createContainer(containerPayload);
				}, this.requestFailed)
				.then(response => {
					// 3: Copy assets from (old) program container to (new) family container
					return StarData.copyAssetsFromContainer(response.id, programData.guid);
				}, this.requestFailed)
				.then(() => {
					const linkPayload = {
						programId: programData.id,
						linkRelation: familyMemberType,
						name: newFamily.name,
					};
					// 4: Link program to new family
					return MetadataData.linkProgram(newFamily.id, linkPayload);
				}, this.requestFailed)
				.then(() => {
					this.familyCreatedAndMemberAdded();
					Alert.displayAlert("success", "New family created and program added to it!");
					this.familyMemberAdded({
						programId: programData.id,
						familyId: newFamily.id,
						name: newFamily.name,
					});

					// 5: Go to family page
					browserHistory.push(`/metadata/families/${newFamily.id}`);
				}, this.requestFailed);
		};
	}

	addProgramToFamily(programData, family, linkType) {
		return dispatch => {
			dispatch();
			const { id: programId } = programData;
			const { id: familyId, name } = family;

			const payload = {
				programId,
				linkRelation: linkType,
				name,
			};

			MetadataData.linkProgram(familyId, payload)
				.then(response => {
					this.familyMemberAdded({
						programId,
						familyId,
						name,
					});
					Alert.displayAlert("success", "Program added to family!");
				}, this.requestFailed);
		};
	}

	familyCreated() { return true; }
	familyCreatedAndMemberAdded() { return true; }
	familyMemberAdded(payload) { return payload; }
	selectItem(item) { return item; }

	unmount() { return true; }

	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}
}

export default alt.createActions(Actions);

function getNextPageUrl(links = []) {
	const nextLink = links.find(l => l.rel === "next" || l.Rel === "next");

	return nextLink
		? nextLink.href || nextLink.Href
		: null;
}