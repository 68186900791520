import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import MuiSelect from '../../components/ui/editorWidgets/muiSelect'
import Button from '../../components/ui/controls/button'
import Preview from '../../components/assets/preview'

import Actions from './actions'
import { LinkRelationTypes } from '../../apps/metadata/shared/utils'

import '../../components/listItems/standard.css'

export default class FamilyContent extends PureComponent {

	static propTypes = {
		id: PropTypes.number,
		reference: PropTypes.string,
		provider: PropTypes.object,
		approval: PropTypes.object,
		seasonApproval: PropTypes.object,
		seriesApproval: PropTypes.object,
		imageApproval: PropTypes.object,
		versions: PropTypes.array,
		defaultGenres: PropTypes.array,
		countries: PropTypes.array,
		duration: PropTypes.number,
		productionYear: PropTypes.string,

		displayName: PropTypes.string,

		parentId: PropTypes.number,
		numberOfEpisodes: PropTypes.number,

		className: PropTypes.string,
		noLink: PropTypes.bool,
	}

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(id, pathname, wideModal, typeId, query, e) {
		const { location } = this.props;

		Actions.edit({
			id,
			pathname,
			query,
			returnTo: `${location.pathname}${location.search}`,
			wideModal,
			typeId
		});
	};

	render() {
		const {
			id,
			guid,
			displayName,
			selected,
			onSetFamily,
			selectedTypeId,
			onSelectType,
			onAdd,
			alreadyLinked,
			// rights,
			className = "",
		} = this.props;

		const familyMemberActions = selected ? getFamilyMemberActions(id, onSetFamily, selectedTypeId, onSelectType, onAdd) : null;

	    return (
			<div className={`flex flex-dyn-1 ${className}`}>
				<Preview programId={guid} size={selected ? "medium" : "small"} />
				<div className="c6-content">
					<h1>{displayName}{alreadyLinked ? " (Program is already a member of this family)" : ""}</h1>
				</div>
				<div className="c6-content">
					{familyMemberActions}
				</div>
			</div>
		);
  	}
}

function getFamilyMemberActions(id, onSetFamily, selectedTypeId, onSelectType, onAdd) {
	const options = {
		dataSource: LinkRelationTypes
	};

	return [
		<MuiSelect
			key="select"
			value={selectedTypeId}
			label="Type"
			options={options}
			onChange={val => onSelectType(val)}
		/>,
		<Button
			key="button"
			title="Add"
			onClick={onAdd}
		/>
	];
}