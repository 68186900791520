import FineUploader from 'fine-uploader-wrappers'
import appConfig from 'config'
import Qs from 'qs'

import Actions from './actions'
import Store from './store'
import StarConstants from '../../core/constants/star'
import * as Alert from '../../core/services/alert'
import { getServiceToken } from '../../core/services/auth'

export async function createUploader(uploaderId, { skipAuth, autoUpload, assetType, categoryIndex, categoryName, onComplete, getEmbargoDate, exposeBlockedContainerAssets, ...rest }) {
	let uploader;
	
	const onSubmitCallback = (id, name) => {
		let embargoDate = undefined;
		if (typeof getEmbargoDate === "function") {
			embargoDate = getEmbargoDate();
		}

		uploader.methods.setParams({
			assetType: assetType && assetType !== "Undefined" ? assetType : decideAssetType(getExtension(name)),
			containerAssetIndex: categoryIndex,
			category: categoryName,
			publishFrom: embargoDate,
			exposeBlockedContainerAssets,
		}, id);
	};

	const onCompleteCallback = (id, name, response) => {
		if (response.success) {
			onComplete(response);
		}
	};

	const onErrorCallback = (id, name, errorReason, xhr) => {
		Alert.displayAlert("error", `Failed to upload file [${name}], ${errorReason}`);
	};

	const onStatusChangeCallback = (id, oldStatus, status) => {
		Actions.fileStatusChange({ uploaderId: uploaderId, id, status });
	};

	uploader = Store.getState().uploaders[uploaderId];
	if (uploader) {
		// console.log("Using existing uploader!");
		return uploader;
	}
	// console.log("Creating new uploader!");

	const token = skipAuth ? null : await getServiceToken("star");
	uploader = new FineUploader({
		options: {
			autoUpload,
			chunking: {
				enabled: true,
			},
			request: {
				endpoint: getEndpoint(rest),
				paramsInBody: false,
				customHeaders: token ? { authorization: `Bearer ${token}` } : null,
			},
			callbacks: {
				onSubmit: onSubmitCallback,
				onComplete: onCompleteCallback,
				onError: onErrorCallback,
				onStatusChange: onStatusChangeCallback,
			},
		},
	});

	return uploader;
}

export function updateUploaderEndpoint(uploader, props) {
	uploader.methods.setEndpoint(getEndpoint(props));
}

function getEndpoint({ audience, endpoint, containerId, contentSource }) {
	if (endpoint) {
		return endpoint;
	}

	if (containerId) {
		const queryParams = Qs.stringify({
			audience,
			contentSource,
		}, { skipNulls: false });
		return `${appConfig.api.star}containers/${containerId}/uploadFile?${queryParams}`;
	}

	console.error("No endpoint or containerId prop provided to the Upload component.");
	return undefined;
}


function decideAssetType(extension) {
	const extensionInLower = extension.toLowerCase();
	if (StarConstants.IMAGE_FORMATS.includes(extensionInLower)) return "Image"; // assetTypeId = image (1)
	if (StarConstants.DOCUMENT_FORMATS.includes(extensionInLower)) return "Document"; // assetTypeId = document (3)
	if (StarConstants.CLIP_FORMATS.includes(extensionInLower)) return "Clip"; // assetTypeId = clip (4)
	return "Undefined"; // assetTypeId = undefined (0)
}

function getExtension(filename) {
	const extIndex = filename.lastIndexOf(".") + 1;
	if (extIndex > 0) {
		return filename.substr(extIndex, filename.length - extIndex);
	}

	return false;
}