import moment from 'moment'

export function renderTodayOrDate(date, withDayName) {
	const now = moment().format("YYYYMMDD");
	const prem = moment(date);
	const day = prem.format("YYYYMMDD");

	if(day === moment(now).subtract(1, "d").format("YYYYMMDD")) {
		return "Yesterday";
	}
	else if(day === now) {
		return "Today";
	}
	else if(day === moment(now).add(1, "d").format("YYYYMMDD")) {
		return "Tomorrow";
	}
	else if(now.substr(0, 4) !== day.substr(0, 4)) {
		return prem.format(withDayName ? "dddd D MMMM YYYY" : "D MMMM YYYY");
	}
	else {
		return prem.format(withDayName ? "dddd D MMMM" : "D MMMM");
	}
}