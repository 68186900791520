import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router'

import ResponsiveImage from '../../../components/assets/responsiveImage'

import './pressImages.css'

import appConfig from 'config'

const DOWNLOAD_FORMAT = "Original";

@withRouter
export default class PressImages extends React.PureComponent {

	static propTypes = {
		assets: PropTypes.array.isRequired,
		programTitle: PropTypes.string.isRequired,
		location: PropTypes.object.isRequired, // From @withRouter
	}

	render() {
		const { assets, programTitle, location } = this.props;

		return (
			<section className="press-image-area">
				{getPressImages(assets, "single", location, programTitle)}
				{getPressImages(assets, "episode", location, programTitle)}
				{getPressImages(assets, "season", location, programTitle)}
				{getPressImages(assets, "series", location, programTitle)}
			</section>
		)
	}
}

// HELPERS

function getPressImages(assets = [], contentType, location, programTitle) {
	const pressImages = assets.filter(a => a.category === "press" && a.type === "image" && a.contentType === contentType) || [];
	if(pressImages.length) {
		const heading = getPressImageHeading(contentType);
		const images = pressImages.map((a, i) => (
			<Link
				key={a.id}
				to={generateLinkAction(a.id, location)}
				className="icon-search">
				<ResponsiveImage
					id={a.id}
					format="freeheight"
					sizes="(min-width: 1901px) 33.33vw, (min-width: 1200px) 50vw, (min-width: 786px) 25vw, (min-width: 666px) 33.33vw, 50vw" />
				<button onClick={handleClick.bind(this, a, programTitle, i)} className="icon-cloud_download"><span>Download</span></button>
			</Link>
		));

		return <section className="press-images">{heading}{images}</section>
	}

	return null;
}

function getPressImageHeading(type) {
	switch(type) {
		case "series":
			return <h2 key="a">Series images</h2>
		case "season":
			return <h2 key="a">Season images</h2>
		case "episode":
			return <h2 key="b">Episode images</h2>
		default:
			return null
	}
}

function generateFileName(programTitle, index) {
	return `${programTitle.replace(/ /g, "_")}-${index + 1}.jpg`;
}

function generateLinkAction(id, location) {
	return {
		pathname: `/schedule/image/${id}`,
		state: {
			modal: true,
			wideModal: true,
			returnTo: `${location.pathname}${location.search}`,
		}
	};
}

function handleClick(asset, programTitle, index, e) {
	e.preventDefault();
	e.stopPropagation();
	document.location.href = `${appConfig.media.star}${asset.id}/${DOWNLOAD_FORMAT}.jpg?dl=true&fileName=${generateFileName(programTitle, index)}`;
}