import React from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'react-router'
import moment from 'moment'

// import { getProgramLink } from '../shared/utils'

import '../../internal-schedule/schedule/broadcast.css'
import './broadcast.css'
// import { Regions } from '../../../core/constants'

const TV4_VERSION_ID = 1;

// Broadcast prop is imperatively added in broadcasts.jsx
export default ({ broadcast, group, location, selectedGroup, contentOwner }) => {

	const { start, title: broadcastTitle, type: broadcastType, program } = broadcast;
	const {
		displayName,
		type,
		versions,

		approval = {},
		seasonApproval = {},
		seriesApproval = {},
		tagsSeriesApproval = {},
		imageApproval = {},
		tagsApproval = {},
	} = program;

	const programTitle = broadcastTitle || displayName || <span className="attention">Program title not available!</span>;
	const activeVersions = group !== "tv4" ? versions : versions.filter(v => v.versionId === TV4_VERSION_ID);
	const approvalVersions = activeVersions.map(v => ({
		type: v.versionName,
		approval: v.approval,
	}));

	const typeSpecificApprovals = type === "Episode"
		? [{
			type: "Season",
			approval: seasonApproval,
		},
		{
			type: "Series",
			approval: seriesApproval,
		},
		{
			type: "Series tags",
			approval: tagsSeriesApproval,
		},] : [{
			type: "Tags",
			approval: tagsApproval,
		}];

	const approvals = [
		{
			type: "Generic",
			approval: approval,
		},
		{
			type: "Images",
			approval: imageApproval,
		},
		...approvalVersions,
		...typeSpecificApprovals,
	];

	const { status, description } = approvalStage(approvals);
	const time = getTimeZoneIndependentStartTime(start, program, selectedGroup);

	return (
		<Link title={description} to={getMetadataLink(program, group, location, selectedGroup, contentOwner)} className={`c6-schedule-broadcast type-${broadcastType} c6-text-${status}`}>
			<time dateTime={start}>{time}</time>
			<h1>{programTitle}</h1>
		</Link>
	);
}

// HELPERS
function getMetadataLink({id, parentId, type }, group, location, selectedGroup, contentOwner) {
	group = selectedGroup === "won" || group === "mtv" ? "mtvfi" : group;
	const owner = contentOwner ?? group;
	const ownerPart = owner ? `${owner}/` : "";

	return {
		// pathname: type === "Episode" ? `/metadata/programs/${contentOwner}season/${parentId}#p${id}` : `/metadata/programs/${contentOwner}${id}`,
		pathname: type === "Episode" ? `/metadata/programs/${ownerPart}season/${parentId}/episode/${id}` : `/metadata/programs/${ownerPart}${id}`,
		state: {
			returnTo: location.pathname,
			returnToTitle: "Linear",
		}
	};
}

function approvalStage(approvals) {
	let itemsApproved = 0, totalItems = 0, description="";
	approvals.forEach(({ type, approval: a }, index) => {
		if(a && a.status && a.status.toLowerCase() === "approved") {
			itemsApproved++;
		}
		else {
			description += `${description ? ", " : ""}${type}`;
		}
		totalItems++;
	});
	const allApproved = itemsApproved === totalItems;

	if(!allApproved) {
		description = `${itemsApproved} of ${totalItems} approved. (Missing ${description} approval.)`
	}

	return {
		status: allApproved ? "approved" : "unapproved",
		description: allApproved ? "Everything is approved." : description,
	};
}

function getTimeZoneIndependentStartTime(start, program = {}, selectedGroup) {
	const isFinnishView = selectedGroup === "mtv" || selectedGroup === "mtvfi" || selectedGroup === "won";
	const isFinnishViewAndChannelIsScheduledInLouise = isFinnishView && program.provider && program.provider.name === "Louise";
	const isFinnishViewAndIsSwedishTimeZone = isFinnishView && moment.parseZone(start).utcOffset() === (moment(start).isDST() ? 120 : 60);

	// We need a diff to make sure we render the same time no matter in what time zone the client is located.
	const diffMinutes = moment(start).utcOffset() - moment.parseZone(start).utcOffset();
	const timeZoneIndependentStart = moment(start).subtract(diffMinutes, "m");

	// Pan-nordic channels are scheduled using swedish time so we need to add 1 hour if we're displaying them in the finnish view.
	return isFinnishViewAndChannelIsScheduledInLouise || isFinnishViewAndIsSwedishTimeZone
		? timeZoneIndependentStart.add(1, "h").format("HH:mm")
		: timeZoneIndependentStart.format("HH:mm");
}