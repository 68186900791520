import React from 'react'

import Editor, { parseUi, withQueryClient } from '../../../components/editor/'
import { hasAccessToPath } from '../../../core/services/auth'
import Approval from '../../../components/ui/editorFields/approval'
import Crew from '../../../components/ui/editorFields/crew'
import MuiSlug from '../../../components/ui/editorWidgets/muiSlug'

import ApproveWarning from '../shared/approveWarning'
import ProgramPreview from '../shared/preview'
import { ChatGPTGenericMetadataController } from '../shared/chatGPTController'

import * as API from '../../../apis/'
import appConfig from 'config'

import schemaSingle from './singleSchema'
import schemaEpisode from './episodeSchema'
import schemaSeason from './seasonSchema'
import schemaSeries from './seriesSchema'

import uiSingle from './singleUI'
import uiEpisode from './episodeUI'
import uiSeason from './seasonUI'
import uiSeries from './seriesUI'

import singleClassCategoryMap from './singleClassCategoryMap'
import seriesClassCategoryMap from './seriesClassCategoryMap'
import useShieldRoles from '../../../core/queries/shield/useShieldRoles'
import { displayAlert } from '../../../core/services/alert'

const GenericEditor = props => {
	const { data: roles, error: rolesError } = useShieldRoles({
		filter: { module: "onestopdrop" },
		enabled: appConfig.features.metadataProviderInGenericEditor,
	});

	return (
		<Editor
			layout="grid"
			submitAsPatch={true}
			api="metadata"
			entity="program"
			className="c6-metadata-genericeditor"
			getSchema={getSchema.bind(this, roles, rolesError)}
			getUiSchema={getUiSchema.bind(this, roles, rolesError)}
			customFields={{
				approval: Approval,
				crew: Crew,
				chatGPTGenericMetadataController: ChatGPTGenericMetadataController,
			}}
			customWidgets={{ slug: MuiSlug }}
			loadPayloadTransform={loadPayloadTransform}
			savePayloadTransform={savePayloadTransform}
			changePayloadTransform={changePayloadTransform}
			hasEditAccess={hasAccessToPath(props.routes, "editor")}
			enableEditorNavigation={true}
			{...props}
		>
			<ApproveWarning />
			<ProgramPreview />
		</Editor>
	);
};

export default withQueryClient(GenericEditor);

function getSchema(roles, rolesError, model, isNew, location, route, params, routes) {
	let schema;
	const type = (model.type || params.type || "").toLowerCase();
	switch(type) {
		case "series":
			schema = categoryOptionsBasedOnClass(schemaSeries, model, type);

			// Set correct provider on newly created items when configured
			// TODO!!! HACK!!!: Needs to come from Shield since there is more than one
			schema = setProviderFromConfigIfNew(schema, model, isNew);
			break;
		case "season":
			schema = schemaSeason;
			schema = setMetadataProviderOptions(schema, roles, rolesError, model?.provider);
			break;
		case "episode":
			schema = schemaEpisode;
			// Allow episodeNumber 0 for special episodes (i.e. anniversary special) in mediahub
			if (appConfig.features && appConfig.features.vodServiceName === "MTV") {
				schema.properties.episodeNumber.minimum = 0;
			}
			break;
		default:
			schema = categoryOptionsBasedOnClass(schemaSingle, model, type);
			schema = setMetadataProviderOptions(schema, roles, rolesError, model?.provider);
			break;
	}

	if (appConfig.features.metadataChatGPT) {
		schema.properties = {
			_chatGPTGenericMetadataController: {
				type: "object",
			},
			...schema.properties,
		};
	}

	return schema;
}

function getUiSchema(roles, rolesError, model, isNew, location, route, params, routes) {
	const type = (model.type || params.type || "").toLowerCase();
	const classCategory = model.class !== "Undefined" ? getClassCategory(type, model.class) : null;
	if (classCategory && !classCategory.oneOf.some(cc => cc.const === model.category)) {
		model.category = classCategory.default;
	}

	let ui;
	switch(type) {
		case "series":
			ui = uiSeries;
			ui.structure["ui:widget"] = model.class !== "Undefined" && model.category === "Scripted" ? "radio" : "hidden";
			ui.numberOfSeasons["ui:widget"] = model.class !== "Undefined" && model.category === "Scripted" ? undefined : "hidden";
			break;
		case "season":
			ui = uiSeason;
			showOrHideMetadataProvider(ui, roles, rolesError, model?.provider);
			break;
		case "episode":
			ui = uiEpisode;
			break;
		default:
			ui = uiSingle;
			ui.category["ui:readonly"] = model.class === "Sport";
			showOrHideMetadataProvider(ui, roles, rolesError, model?.provider);
			break;
	}

	// Hide category field when class = undefined
	if (ui.category) {
		ui.category["ui:widget"] = model.class === "Undefined" ? "hidden" : "select";
	}

	if (appConfig.features.metadataChatGPT) {
		ui._chatGPTGenericMetadataController = { "ui:field": "chatGPTGenericMetadataController", "ui:classNames": "chat-gpt" };
	}

	ui = editableInternalNameWhenCreatedInCometOrSeries(ui, isNew, model);

	// Lock approval if the season/series/single is missing age rating (and isn't already approved)
	const isUnapproved = model.approval?.status?.toLowerCase() === "unapproved" || typeof model.approval === 'undefined';
	const isMissingValidRating = model.rating === "Undefined" || typeof model.rating === 'undefined';
	const programTypeRequiresValidation = type === "series" || type === "season" || type === "single";
	const enforceMetadataRating = appConfig.features.enforceMetadataRatingBeforeApproval;
	ui.approval.overrideReadOnly = isUnapproved && isMissingValidRating && programTypeRequiresValidation && enforceMetadataRating;

	return {
		"ui:readonly": model.approval?.status?.toLowerCase() === "approved",
		...parseUi(ui, API),
	};
}

// HELPERS
function editableInternalNameWhenCreatedInCometOrSeries(ui, isNew, { provider, type }) {
	if(isNew || provider && provider.name === "Comet" || type === "Series") { // JOL 180423: Series programs should also have editable internal names forever.
		return {
			...ui,
			name: {
				...ui.name,
				"ui:readonly": false,
			}
		};
	}

	return ui;
}

function categoryOptionsBasedOnClass(schema, model, type) {
	const classCategory = model.class !== "Undefined" ? getClassCategory(type, model.class) : null;
	if (classCategory && !classCategory.oneOf.some(cc => cc.const === model.category)) {
		model.category = classCategory.default;
	}

	return {
		...schema,
		properties: {
			...schema.properties,
			category: {
				...schema.properties.category,
				...classCategory,
			},
		}
	};
}

function loadPayloadTransform({ model, entity, location, route, params }) {
	const newModel = { ...model };
	
	if (model?.oSD_MetadataProvider) {
		newModel.oSD_MetadataProvider = model.oSD_MetadataProvider.toLowerCase();
	}

	if (appConfig.features.metadataEditorWarningMessageOnOpen?.length) {
		displayAlert("warning", null, null, null, null, appConfig.features.metadataEditorWarningMessageOnOpen);
	}

	// Since we use enums for the structure in the json-schema we get validation errors from rjsf if the structure
	// doesn't match "Chronological" or "Topical". So we just remove the property if it's undefined.
	if (newModel?.structure === "Undefined") {
		const { structure, ...rest } = newModel;
		return rest;
	}

	return newModel;
}

function savePayloadTransform({ formData, patchData, ...rest }) {
	if (formData?.oSD_MetadataProvider) {
		formData = {
			...formData,
			oSD_MetadataProvider: formData.oSD_MetadataProvider === "none" ? null : formData.oSD_MetadataProvider,
		};
	}
	if (patchData?.oSD_MetadataProvider) {
		patchData = {
			...patchData,
			oSD_MetadataProvider: patchData.oSD_MetadataProvider === "none" ? null : patchData.oSD_MetadataProvider,
		};
	}

	if (patchData?.imdbId) {
		patchData.imdbId = patchData.imdbId.trim();
	}

	return {
		formData,
		patchData,
		...rest
	};
}

function changePayloadTransform({ formData, patchData, changeFieldId, entity, location, routes, params, context }) {
	const changeWasChatGPT = changeFieldId === "root__chatGPTGenericMetadataController";
	const _chatGPTGenericMetadataController = { ...formData._chatGPTGenericMetadataController ?? {} };
	if (appConfig.features.metadataChatGPT && changeWasChatGPT) {
		const fieldsToUpdate = _chatGPTGenericMetadataController.fieldsToUpdate;
		Object.entries(fieldsToUpdate).forEach(([key, value]) => {
			formData[key] = value;
		});
		delete _chatGPTGenericMetadataController.fieldsToUpdate;
	}

	return {
		...formData,
		_chatGPTGenericMetadataController,
	};
}

function setProviderFromConfigIfNew(schema, model, isNew) {
	if (isNew && appConfig.metadata && appConfig.metadata.providerName) {
		schema.properties.provider.properties.name.default = appConfig.metadata.providerName;

	}

	return schema;
}

function getClassCategory(type, programClass = "Regular") {
	const classCategoryMap = type === "single" ? singleClassCategoryMap : seriesClassCategoryMap;
	return classCategoryMap[programClass];
}

function showOrHideMetadataProvider(uiSchema, roles, rolesError, provider) {
	const filteredRoles = getFilteredRoles(roles, provider);
	if (appConfig.features.metadataProviderInGenericEditor && filteredRoles.length) {
		if (uiSchema.oSD_MetadataProvider) 	uiSchema.oSD_MetadataProvider["ui:widget"] = "select";
		if (uiSchema.class) 				uiSchema.class["ui:classNames"] = "grid-50 bg separator";
		if (uiSchema.category) 				uiSchema.category["ui:classNames"] = "grid-50 bg separator";
		if (uiSchema.seasonNumber) 			uiSchema.seasonNumber["ui:classNames"] = "grid-50 bg separator";
		if (uiSchema.numberOfEpisodes) 		uiSchema.numberOfEpisodes["ui:classNames"] = "grid-50 bg separator";
	} else {
		if (uiSchema.oSD_MetadataProvider) 	uiSchema.oSD_MetadataProvider["ui:widget"] = "hidden";
	}
}

function setMetadataProviderOptions(schema, roles, rolesError, provider) {
	if (!schema.properties.oSD_MetadataProvider || !appConfig.features.metadataProviderInGenericEditor) {
		return schema;
	}

	if (roles?.items?.length) {
		const filteredRoles = getFilteredRoles(roles, provider);
		if (filteredRoles.length) {
			schema.properties.oSD_MetadataProvider.oneOf = [
				{ const: "none", title: "None" },
				...filteredRoles.map(r => ({
					const: r.name,
					title: r.displayName,
				})),
			];
			schema.properties.oSD_MetadataProvider.default = "none";
		}
		return schema;
	}

	// Unless the roles request has failed, return empty schema since the roles dropdown won't update otherwise
	if (!rolesError) {
		return { properties: {}, type: "object" };
	}

	return schema;
}

function getFilteredRoles(roles, provider) {
	if (!provider || provider.name === "Won") {
		return [];
	}

	return (roles?.items ?? [])
		.filter(f => f.name.startsWith("ContentSource."))
		.filter(f => {
			if (provider?.name === "Louise") {
				return f.displayName.startsWith("Studio");
			}
			if (provider?.name === "Won") {
				return f.displayName.startsWith("MD Station");
			}

			return true;
		})
		.map(f => ({ ...f, name: f.name.split("ContentSource.")[1].toLowerCase() }));
}
