import React from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'

import { decoratorUIActions } from '../../../core/decorators/uiActions'

import { DroppableItemGroup } from '../../../components/list/listItems'
import SortableList from '../../../components/list/sortableList'
import SortableItem from '../../../components/list/sortableItem'

import Actions from './actions'
import Content from '../shared/listItem'
import { LinkRelationTypes } from '../shared/utils'
import itemActions from './listItemActions'

@SortableList({
	onItemMoved: ({ originalItem, ...rest }) => {
		const requestPayload = getRequestPayload(originalItem.id, rest);
		Actions.moveFamilyMember(requestPayload, originalItem);
	},
	generateNewItem: ({ droppedItem, ...rest }) => {
		const requestPayload = getRequestPayload(droppedItem.id, rest);
		return {
			requestPayload, // For creating the family relation
			temporaryItem: droppedItem.data, // So we can optimistically insert a temporary item before the list is refreshed
		};
	},
	onItemAdded: ({ payload }) => Actions.addFamilyMember(payload),
})
@decoratorUIActions(itemActions)
export default class MembersList extends React.Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
	};

	constructor(props) {
		super(props);

		this.handleDragStart = this.handleDragStart.bind(this);
		this.handleDragEnd = this.handleDragEnd.bind(this);
		this.renderGroups = this.renderGroups.bind(this);
		this.renderItems = this.renderItems.bind(this);

		this.state = {
			isDraggingItem: false,
		};
	}

	handleDragStart() {
		this.setState({ isDraggingItem: true });
	}

	handleDragEnd() {
		this.setState({ isDraggingItem: false });
	}

	render() {
		const isDraggingClass = this.state.isDraggingItem ? " is-dragging-item" : "";

		return (
			<div className={isDraggingClass}>
				{this.renderGroups(this.props)}
			</div>
		);
	}

	renderGroups({ items, handleTargetHover, handleItemDrop }) {
		const groupedItems = groupBy(items, item => item.linkRelationType || item.isOver);

		return LinkRelationTypes.map(linkRelationType => (
			<DroppableItemGroup
				key={linkRelationType.id}
				type={linkRelationType.id}
				title={linkRelationType.name}
				onTargetHover={handleTargetHover}
				onItemDrop={handleItemDrop}
			>
				{this.renderItems(groupedItems[linkRelationType.id])}
			</DroppableItemGroup>
		));
	}

	renderItems(items = [], noItemsFoundText = "Drag programs from the right to link them with this group.") {
		const {
			// Injected by the SortableList HOC
			findItem,
			moveItem,
			handleOutsideDrop,
			handleItemManipulation,
		} = this.props;


		if (!items.length) {
			return (
				<div className="c6-item group-empty">
					<div className="flex icon-add_circle_outline">{noItemsFoundText}</div>
				</div>
			);
		}

		return items.map((item, i) => (
			<SortableItem
				key={item.id}
				id={item.id}
				handleType="thumbnail"
				actionData={item}

				isPlaceholder={item.isPlaceholder || !!item.isOver}
				moveItem={moveItem}
				onItemDropOutside={handleOutsideDrop}
				findItem={findItem}
				insertCard={handleItemManipulation}

				onDragStart={this.handleDragStart}
				onDragEnd={this.handleDragEnd}
			>
				<Content
					{...item}
					catalogue="tv4"
					title={item.displayName}
					renderMenuWhenNoActions={true}
				/>
			</SortableItem>
		));
	}
}

// HELPERS
function getRequestPayload(programId, { payload, targetType, sourceProps }) {
	const { family, items } = sourceProps;
	const { id: familyId, name } = family;

	return {
		familyId,
		programId,
		linkRelation: targetType,
		ordinal: getGroupOrdinal(items, targetType, payload), // Get the ordinal in the specific item group target
		name,
	};
}

function getGroupOrdinal(items, targetType, payload) {
	const firstTargetGroupItemIndex = items.findIndex(i => i.links.find(l => l.linkRelation === targetType));
	return firstTargetGroupItemIndex !== undefined ? payload.ordinal-1 - firstTargetGroupItemIndex : 0;
}