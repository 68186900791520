import alt from '../../core/services/alt'
import * as Alert from '../../core/services/alert'
import * as HorizonData from '../../apis/horizon'
import { handleRequestFailed } from '../../core/services/errorhandling'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;

import { saveBlobToDisc } from '../../utils/save'

class Actions {

	/* **************** Actions **************** */
	search(searchText, filterName, targetStore) {
		return (dispatch) => {
			dispatch({
				searchText,
				targetStore,
			});
		}
	}

	filter(filterValue, filterName, targetStore) {
		return (dispatch) => {
			dispatch({
				[filterName]: filterValue,
				targetStore,
			});
		};
	}

	/* **************** Items **************** */
	fetchItems(entity, payload, targetStore = null, callback) {
		return (dispatch) => {
			const requestTime = Date.now();
			const multiple = true;
			const command = getCommand("fetch", entity, multiple);
			const store = targetStore || getStore(entity, multiple);

			dispatch({ payload, store, requestTime });

			// HACK?: Return nothing when searchtext is less than 2 chars
			const { searchText } = payload;
			if (searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST) {
				this.itemsUpdated({ store, items: [], numberOfItems: 0, requestTime });
			}
			else {
				HorizonData[command](payload)
					.then(response => {
						const { pageIndex, numberOfItems, items, links } = response;
						const appendToExistingItems = pageIndex > 0;

						const nextPageUrl = getNextPageUrl(links);
						this.itemsUpdated({ store, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime });
						if (callback && typeof callback === "function") {
							callback();
						}
					}, this.requestFailed);
			}
		};
	}
	pageItems(entity, url, targetStore = null) {
		return (dispatch) => {
			const multiple = true;
			const store = targetStore || getStore(entity, multiple);

			dispatch({ entity, url, store });

			HorizonData.fetchUrl(url)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.itemsUpdated({ store, items, appendToExistingItems, numberOfItems, nextPageUrl });

				}, this.requestFailed);
		};
	}
	itemsUpdated(payload) {
		return payload;
	}

	/* **************** Item **************** */
	fetchItem(entity, payload) {
		return (dispatch) => {
			dispatch();

			const command = getCommand("fetch", entity);
			HorizonData[command](payload)
				.then(model => {
					this.itemLoaded({ entity, model });
				}, this.requestFailed);
		};
	}
	itemLoaded(payload) { return payload; }

	updateItem(entity, data, payload, type = "update", targetStore) {
		return (dispatch) => {
			dispatch();

			const command = getCommand(type, entity);
			HorizonData[command](data, payload)
				.then(model => {
					this.itemUpdated({ entity, model, originalId: data.id, targetStore });
				}, this.requestFailed);
		}
	}
	itemUpdated(payload) { return payload; }


	/* **************** Custom **************** */
	packageAction(serviceId, packageIds, action, clearSelected, callback, filters) {
		return dispatch => {
			dispatch();

			const payload = ["publishMetadata", "resendSeason"].includes(action)
				? { id: packageIds[0] }
				: { serviceId, packageIds };

			HorizonData[action](payload)
				.then(response => {
					this.packageActionFinished({ ...response, serviceId });
					Alert.displayAlert("success", "Operation was successful!");
					if (clearSelected) {
						this.clearSelected();
						this.toggleBatchOperations();
					}
					this.fetchItems("packages", {
						...filters,
						pageSize: 1,
						deliveryStatus: "Blocked",
					}, "blockedPackages");
					this.fetchItems("packages", {
						...filters,
						pageSize: 1,
						deliveryStatus: "DeliveryList",
					}, "deliveryList")
					if (callback) {
						callback();
					}
				}, error => {
					this.requestFailed(error);
					this.packageActionFinished();
				});
		};
	}

	packageActionFinished(payload) { return payload }
	toggleBatchOperations() 	  { return true }
	select(item)                  { return item }
	unselect(item)                { return item }
	toggleSelect(item)            { return item }
	toggleMultipleSelect(payload) { return payload }
	clearSelected() 			  { return true }
	selectAll(storeName)		  { return storeName }
	togglePackageExpand(item)	  { return item }

	/* **************** Misc **************** */
	unmount() { return true; }

	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}

	/* **************** Excel **************** */
	ExportExcel(payload, serviceName) {
		return dispatch => {
			dispatch();
			HorizonData.fetchExcel(payload)
				.then(response => {	
					saveBlobToDisc(response, `Horizon_${serviceName.replaceAll(".", "")}_${payload.filter}_${payload.status}`);
					this.excelFinish()
				})
		}
	}
	excelFinish(excelLoading) {return !excelLoading}
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, entity, multiple = false) {
	if (typeof(entity) === "object") {
		const extra = multiple ? "s" : "";

		if (entity.parentEntity) {
			return command + entity.parentEntity.substr(0, 1).toUpperCase() + entity.parentEntity.substr(1) + entity.entity.substr(0, 1).toUpperCase() + entity.entity.substr(1) + extra;
		}
		entity = `${entity.entity}${extra}`;
	}
	return command + entity.substr(0, 1).toUpperCase() + entity.substr(1);
}

function getStore(entity, multiple = false) {
	if (typeof(entity) === "object") {
		const extra = multiple ? "s" : "";
		return `${entity.entity}${extra}`;
	}
	return entity;
}

function getNextPageUrl(links = []) {
	const nextLink = links.find(l => l.rel === "next" || l.Rel === "next");

	return nextLink
		? nextLink.href || nextLink.Href
		: null;
}