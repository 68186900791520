import moment from 'moment'

import alt from '../../../core/services/alt'
import Actions from './actions'

import MoveDialogActions from '../../../dialogs/moveDialog/star/actions'

class StarContainerNewStore {

	constructor() {
		this.containerIsLoading = false;
		this.container = {};
		this.parentContainer = {};

		this.childContainersIsLoading = false;
		this.childContainers = [];

		this.isSearching = false;
		this.searchText = "";
		this.searchItems = [];
		this.searchItemsIsLoading = false;
		this.selectedContainer = {};
		this.selectedContainerIsLoading = false;

		this.types = [];

		this.bindListeners({
			/* Search */
			onSetSearching: Actions.SET_SEARCHING,
			onFilter: Actions.FILTER,
			onSearch: Actions.SEARCH,
			onFetchSearchItems: Actions.FETCH_SEARCH_ITEMS,
			onSearchItemsLoaded: Actions.SEARCH_ITEMS_LOADED,
			/* Selected container */
			onSelectContainer: Actions.SELECT_CONTAINER,
			onSelectedContainerLoaded: Actions.SELECTED_CONTAINER_LOADED,
			onClearSelectedContainer: Actions.CLEAR_SELECTED_CONTAINER,
			onContainerMerged: Actions.CONTAINER_MERGED,
			/* Containers */
			onFetchContainer: Actions.FETCH_CONTAINER,
			onUpdateContainer: Actions.UPDATE_CONTAINER,
			onUpdateContainerAndKeepUnsavedEmbargo: Actions.UPDATE_CONTAINER_AND_KEEP_UNSAVED_EMBARGO,
			onUploadUpdate: Actions.UPLOAD_UPDATE,
			onFetchParentContainer: Actions.FETCH_PARENT_CONTAINER,
			onParentContainerLoaded: Actions.PARENT_CONTAINER_LOADED,
			onAddEmbargo: Actions.ADD_EMBARGO,
			onRemoveUnsavedEmbargo: Actions.REMOVE_UNSAVED_EMBARGO,
			onUpdateUnsavedEmbargo: Actions.UPDATE_UNSAVED_EMBARGO,
			/* Child containers */
			onFetchChildContainers: Actions.FETCH_CHILD_CONTAINERS,
			onUpdateChildContainers: Actions.UPDATE_CHILD_CONTAINERS,
			/* Container Types */
			onTypesLoaded: Actions.TYPES_LOADED,
			/* Move dialog */
			onMoveDialogUpdate: MoveDialogActions.TRIGGER_STAR_CONTAINER_DETAIL_UPDATE,
			/* Helpers */
			onRequestFailed: Actions.REQUEST_FAILED,
		});
	}

	
	/* Filters */
	onSetSearching(val) {
		this.isSearching = val;
	}
	
	onSearch({ searchText }) {
		this.searchText = searchText;
	}

	onFilter(payload) {
		this.searchText = "";
	}

	onFetchSearchItems() {
		this.searchItems = [];
		this.searchItemsIsLoading = true;
		this.latestRequestTime = Date.now();
	}

	onSearchItemsLoaded({ searchItems, requestTime }) {
		if (this.latestRequestTime > requestTime) {
			console.log("Ignoring result since there have been newer requests.");
			return;
		}
		this.searchItems = searchItems.map(si => ({
			...si,
			id: parseInt(si.reference),
			assetGuid: si.asset && si.asset.guid,
			assetData: si.asset,
			displayName: si.displayName,
			type: si.type === "Asset" ? si.subType : si.type,
			disableDrag: si.type !== "Asset",
		}));
		this.searchItemsIsLoading = false;
	}

	/* Selected container */
	onSelectContainer() {
		this.selectedContainerIsLoading = true;
	}

	onSelectedContainerLoaded(container) {
		this.selectedContainerIsLoading = false;
		this.selectedContainer = container;
	}

	onClearSelectedContainer() {
		this.selectedContainer = {};
	}

	onContainerMerged(newContainer) {
		this.container = newContainer;
		this.selectedContainer = {};
	}


	/* Containers */
	onFetchContainer() {
		this.containerIsLoading = true;
	}

	onUpdateContainer(container) {
		this.container = container;
		this.containerIsLoading = false;
		this.searchText = container.displayName;

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}
	
	onUpdateContainerAndKeepUnsavedEmbargo({ container, embargoAssetId }) {
		const assets = this.container.assets;
		const unsavedEmbargo = assets.filter(a => 
			typeof a.id === "string"
			&& a.id.includes("new_embargo")
			&& a.id !== embargoAssetId
		);
		this.container = container;
		this.container.assets = [
			...(this.container.assets ?? []),
			...unsavedEmbargo,
		];
		this.containerIsLoading = false;
		this.searchText = container.displayName;

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	onUploadUpdate(asset) {
		this.container.assets.unshift(asset);
	}

	onFetchParentContainer() {

	}

	onParentContainerLoaded(parentContainer) {
		this.parentContainer = parentContainer;
	}

	onAddEmbargo(asset) {
		this.container.assets?.push({
			id: `new_embargo_${Date.now()}`,
			publishFrom: moment().add(1, "week").format(),
			category: asset.category,
			categoryId: asset.categoryId,
			index: asset.index,
		});
	}

	onRemoveUnsavedEmbargo(id) {
		this.container.assets = this.container.assets.filter(a => a.id !== id);
	}

	onUpdateUnsavedEmbargo({ id, publishFrom }) {
		this.container.assets.forEach(a => {
			if (a.id === id) {
				a.publishFrom = publishFrom;
			}
		});
	}

	/* Child containers */
	onFetchChildContainers() {
		this.childContainersIsLoading = true;
	}

	onUpdateChildContainers(childContainers) {
		this.childContainers = childContainers;
		this.childContainersIsLoading = false;
	}

	/* Container Types */
	onTypesLoaded(types) {
		this.types = types;
	}

	/* Move dialog */
	onMoveDialogUpdate(container) {
		this.container.assets = container.assets;
	}


	/* Helpers */
	onRequestFailed() {
		this.isLoading = false;
	}
}

export default alt.createStore(StarContainerNewStore, '[Star]ContainerNewStore');
