import React, { PureComponent } from 'react'
import { browserHistory } from 'browserHistory'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'

import SideDrawer from '../../../components/module-chooser/sideDrawer'

import './servicePicker.css'

export default class ServicePicker extends PureComponent {

	constructor(props) {
		super(props);
	}

	handleMenuItemClick(service) {
		browserHistory.push(`/horizon/packages/${service.id}`);
	}

	render() {
		const { services = [] } = this.props;
		if (!services.length) {
			return null;
		}

		return (
			<SideDrawer icon="icon-menu" primaryText="Horizon">
				<List>
					{services.map(service => (
						<ListItem
							key={service.id}
							onClick={this.handleMenuItemClick.bind(this, service)}
							disablePadding
							style={{ color: "var(--accent-color)" }}
						>
							<ListItemButton>
								{service.displayName ?? service.name}
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</SideDrawer>
		);
	}
}