import cloneDeep from 'lodash/cloneDeep'
import alt from '../../../core/services/alt'
import EditorActions from '../../../components/editor/actions'

import Actions from './actions'
import appConfig from 'config'

const TAG_TYPES_FILTER = "0|1|2|3|6|7";

const filterStorageKey = "c6-metadata-tags-store-filter";
const prevFilterStorageKey = "c6-metadata-tags-store-filter-prev";

class TagsStore {

	constructor() {
		this.isLoading = false;
		this.isLoadingNumber = 0;

		this.item = {
			tag: {},
		};

		this.list = {};
		this.initStore("tags");

		const storedFilters = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(filterStorageKey)) : null;
		this.filters = storedFilters ?? {
			searchText: "",
			filter: {
				tagTypes: TAG_TYPES_FILTER,
				translation: "",
				excludeItems: true
			},
			pageSize: 50,
			orderBy: "displayName"
		};
		const storedPrevFilters = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(prevFilterStorageKey)) : null;
		this._prevFilter = storedPrevFilters ?? null;

		this.bindListeners({
			onRequestFailed: Actions.REQUEST_FAILED,

			onFilter: Actions.FILTER,
			onSearch: Actions.SEARCH,

			onFetchItems: Actions.FETCH_ITEMS,
			onPageItems: Actions.PAGE_ITEMS,
			onItemsUpdated: Actions.ITEMS_UPDATED,
			onFetchItem: Actions.FETCH_ITEM,
			onItemLoaded: Actions.ITEM_LOADED,
			// onItemSaved: [Actions.ITEM_UPDATED,EditorActions.MODEL_SAVED],
			onItemSaved: Actions.ITEM_UPDATED,
			onEditorSaved: EditorActions.MODEL_SAVED,

			onRemoveItem: Actions.REMOVE_ITEM,
			onItemRemoved: Actions.ITEM_REMOVED,
			onRollbackRemoveItem: Actions.ROLLBACK_REMOVE_ITEM,

			onAddItemEntity: Actions.ADD_ITEM_ENTITY,
			onRemoveItemEntity: Actions.REMOVE_ITEM_ENTITY,

			onSetItemEntityProperty: Actions.SET_ITEM_ENTITY_PROPERTY,

			onUnmount: Actions.UNMOUNT,
		});
	}

	onRequestFailed() {
		this.isLoading = false;
		this.isLoadingNumber = 0;
	}

	/* Filters */
	onSearch(searchText) {
		if (searchText === '') {
			this.filters.filter = this._prevFilter;
			this.filters.orderBy = "displayName";
		} else {
			if (!this._prevFilter) {
				this._prevFilter = cloneDeep(this.filters.filter);
			}
			this.filters.filter = {
				tagTypes: TAG_TYPES_FILTER,
				excludeItems: true
			};
			this.filters.orderBy = "displayName";
		}
		this.filters.searchText = searchText;
		this.saveFiltersToStorage();
	}

	onFilter(payload) {
		const filterParams = Object.keys(this.filters.filter).length
			? this.filters.filter
			: this._prevFilter;

		this.filters.filter = {
			...filterParams,
			...payload,
		};

		this.filters.searchText = "";
		// this.filters.orderBy = "premiere";
		// this.filters.programTypes = "single|episode";
		this._prevFilter = null;
		this.saveFiltersToStorage();
	}


	/* Items */
	onFetchItems({ payload, store, requestTime }) {
		this.isLoading = true;
		const l = this.list[store];

		l.nextPageUrl = null;
		l.numberOfItems = 0;
		l.latestRequestTime = requestTime;
	}

	onPageItems({ entity, url, store }) {
		this.isLoading = true;
		const l = this.list[store || entity];
		l.nextPageUrl = null;
	}

	onItemsUpdated({ datastore, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
		const l = this.list[datastore];
		if(l.latestRequestTime > requestTime) {
			console.log("[%s] Ignoring result with %s items since there have been newer requests.", datastore, numberOfItems);
		}
		else {
			l.items = appendToExistingItems ? l.items.concat(items) : items;
			l.nextPageUrl = nextPageUrl;
			l.numberOfItems = numberOfItems;
			this.isLoading = false;
		}
	}

	/* Item */
	onFetchItem() {
		this.isLoading = true;
	}

	onItemLoaded({ entity, model }) {
		this.item[entity] = model;
		this.isLoading = false;
	}

	// TODO: Remove datastore, we're using entities
	// TODO!: Keep item index when rollbacking
	onItemSaved({ datastore, entity, model, originalId }) {
		if (datastore) {
			console.warn("DATASTORE PROPERTY DEPRECATED: %s. Please use ENTITY: %s instead.", datastore, entity);
		}
		const store = entity ? `${entity}s` : datastore;

		const hasUpdatedStore = this.persistToStore(store, model);

		// We might have several editor stores listening for the same Editor Component
		// action so we need to bail out if this regards another entity.
		if (!hasUpdatedStore) {
			return false;
		}

		this.isLoading = false;
	}

	onEditorSaved({ datastore, entity, model, originalId }) {

		// HACK: Since the model from the editor contains all Rights, and the list model filters the rights
		// from the API we also need to apply filtering to the model we're about to insert in the list.

		// Filter rights from the model (if we have any)
		// const { rights, ...rest } = model;

		// if (!(rights && rights.length)) {
		if (entity === "tag") {
			this.onItemSaved({ datastore, entity, model, originalId });
		}
		// }
		// else {
		// 	const { premiere, right } = this.filters.filter;

		// 	// Get the date we should compare with, depending on the premiere filter
		// 	const baseTime = premiere !== "upcoming"
		// 		? new Date(premiere).getTime()
		// 		: new Date().getTime();

		// 	const newModel = {
		// 		...rest,
		// 		rights: rights.filter(r => (
		// 			new Date(r.start).getTime() >= baseTime && // Right date filtering
		// 			(!right ||  r.rightTypeClass.toLowerCase() === right || r.rightTypeClass === `S${right}`)) // Right class filtering (HACK: vod => Svod)
		// 		),
		// 	}

		// 	this.onItemSaved({ datastore, entity, model: newModel, originalId });
		// }
	}

	onRemoveItem({ entity, id }) {
		this.isLoading = true;

		const item = this.getItem(entity, id);
		item._isHidden = true;
	}
	onItemRemoved({ entity, id }) {
		this.isLoading = false;

		this.removeItem(entity, id);
	}
	onRollbackRemoveItem({ entity, id }) {
		this.isLoading = false;

		const item = this.getItem(entity, id);
		delete item._isHidden;
		// item._isHidden = false;
	}

	/* Item entity */
	onAddItemEntity({ entities, payload }) {
		this.isLoading = true;

		const { parentEntity, entity } = entities;
		const store = this.item[parentEntity][`${entity}s`];
		store.push(payload.data);

		this.item[parentEntity] = {
			...this.item[parentEntity],
		};
	}

	onRemoveItemEntity({ entities, payload }) {
		this.isLoading = true;

		const { parentEntity, entity } = entities;
		const store = this.item[parentEntity][`${entity}s`];
		const index = store.findIndex(e => e.id === payload.data.id);
		if (index >= 0) {
			store.splice(index, 1);
		}

		this.item[parentEntity] = {
			...this.item[parentEntity],
		};
	}

	onSetItemEntityProperty() {
		this.isLoading = true;
	}

	onUnmount() {
		this.item = {
			tag: {},
		};

		this.tags = [];

		this.isLoading = false;
		this.nextPageUrl = null;
		this.numberOfItems = 0;
	}

	// HELPERS
	getItem(entity, id) {
		const store = this[`${entity}s`];
		return store.find(e => e.id === id);
	}

	removeItem(entity, id) {
		const store = this[`${entity}s`];
		const index = store.findIndex(e => e.id === id);
		store.splice(index, 1);
	}

	persistToStore(store, model) {
		const storeItems = this.list[store].items;
		if (!storeItems) {
			return false;
		}

		const index = storeItems.findIndex(m => (m.id === model.id));

		if (index >= 0) {
			storeItems[index] = model;
		} else {
			storeItems.push(model);
		}

		return true;
	}

	updateStore(store, model) {
		if (!this[store]) {
			return false;
		}

		const index = this[store].findIndex(m => (m.id === model.id));

		if (index < 0) {
			return false;
		}

		this[store][index] = model;
		return true;
	}

	initStore(store, filters = null, items = []) {
		this.list[store] = {
			items,
			nextPageUrl: null,
			numberOfItems: 0,
			latestRequestTime: null,
			filters,
		};
	}

	saveFiltersToStorage() {
		sessionStorage.setItem(filterStorageKey, JSON.stringify(this.filters));
		sessionStorage.setItem(prevFilterStorageKey, JSON.stringify(this._prevFilter));
	}
}
export default alt.createStore(TagsStore, '[Metadata]TagsStore');