import React from 'react'
import Moment from 'moment'

import Constants from '../../core/constants'

import './period.css'

export default ({ start, end, format = Constants.PERIOD_FORMAT, renderSingleDateIfSame = false, className = "" }) => {
	const startText = start ? Moment(start).format(format) : null;
	const endText = end ? Moment(end).format(format) : null;
	const renderUntil = !renderSingleDateIfSame || startText !== endText;

	const untilTime = end && renderUntil
		? <time dateTime={end} title={end}>{endText}</time>
		: null ;

	const fromTime = start
		? <time dateTime={start} title={start}>{startText}</time>
		: null;

	return fromTime && (
		<div className={`c6-period ${className}`}>
			{fromTime}
			{untilTime}
		</div>
	);
};