import React, { Component } from 'react'

import Dropzone from '../../../components/upload/dropzone'
import CategoryAsset, { renderEmbargoDatePicker, renderEmbargoLink } from '../../../components/assets/asset'
import { localeCompareSortNumeric } from '../../../core/services/sorting'
import Button from '../../../components/ui/controls/button'

import ContainerActions from './actions'
import appConfig from 'config'
import moment from 'moment'

const Category = props => {
	const {
		displayName,
		group,
		assetLimit,
		indexes,
	} = props.category;

	let assets = props.category.assets?.sort(sortAssetsByPublishFrom) ?? [];
	assets = filterOldAssets(assets, props.showPastVersions);

	if (props.disabled && !assets.length) {
		return null;
	}

	const ownRow = indexes.length > 1
		|| assetLimit != 1
		|| assets.length > 1
		|| assets.some(a => a.publishFrom || typeof a.id === "string" && a.id.includes("new_embargo"));

	let classNames = "category";
	classNames += ownRow ? " own-row" : "";

	let content = [];
	if (!indexes.length) {
		content.push(renderUpload(props));
		content.push(renderAssets({
			...props,
			assets,
		}));
	}
	indexes.forEach(index => content.push(renderIndex({
		...props,
		index,
	})));

	return (
		<div className={classNames}>
			<div className="category-header">
				<h3>{displayName}</h3>
				{group !== "Undefined" ? <span>{group}</span> : null}
			</div>
			{ownRow ? <div className="content">{content}</div> : content}
		</div>
	);
};

const renderIndex = ({ category, container, disabled, onAssetClick, onRemoveAsset, onEmbargoChange, onAddEmbargo, index, showPastVersions }) => {
	if (category.name === "Clip" && index.name === "Trailer") {
		return null;
	}
	let indexAssets = category.assets.filter(asset => asset.categoryId === category.id && asset.index && asset.index.id === index.id);
	indexAssets = filterOldAssets(indexAssets, showPastVersions);

	if (indexAssets?.length) {
		return renderAssets({
			container,
			category,
			assets: indexAssets,
			onAssetClick,
			onRemoveAsset,
			onEmbargoChange,
			onAddEmbargo,
			disabled,
			index,
		});
	}
	
	return renderUpload({ category, container, disabled, index, onAddEmbargo });
};

const renderUpload = ({ category, container, disabled, index, onAddEmbargo }) => {
	const assets = category.assets.filter(a => 
		(a.index ? a.index.id : 0) === (index ? index.id : 0)
		&& !(
			a.publishFrom && moment(a.publishFrom).isAfter(moment())
			|| typeof a.id === "string" && a.id.includes("new_embargo")
		)
	);
	const tooManyAssets = category.assetLimit && assets.length >= category.assetLimit;
	if (tooManyAssets || disabled) {
		return null;
	}

	return (
		<div className="file-wrapper" key={`upload-${category.id}-${index ? index.id : 0}`}>
			<Dropzone
				categoryName={category.name}
				containerId={container.id}
				categoryIndex={index ? index.id : 0}
				onComplete={updatedContainer => {
					if (updatedContainer?.id === container.id) {
						ContainerActions.updateContainerAndKeepUnsavedEmbargo(updatedContainer, null);
					}
				}}
				addAssetsToContainer={ContainerActions.addAssetsToContainer}
				multipleUploads={index ? false : true}
				assetType={category.assetType}
				exposeBlockedContainerAssets={appConfig.features.metadataEnableEmbargo}
				indexDisplayName={index ? index.displayName : null}
				uploaderInformationText={`Folder: "${container.displayName ?? container.name}" (${index ? index.displayName : category.name})`}
				uploaderId={`container-${container.containerId ?? container.id}-${category.name}-${index?.id}`}
			/>
			{renderEmbargoLink({ category: category.name, categoryId: category.id, index }, onAddEmbargo)}
		</div>
	);
};

const renderAssets = ({ container, category, assets, onAssetClick, onRemoveAsset, onEmbargoChange, onAddEmbargo, disabled, index }) => {
	return assets.map((asset, i) => {
		if (typeof asset.id === "string" && asset.id.includes("new_embargo")) {
			return (
				<div className="file-wrapper" key={`upload-${category.id}-${index ? index.id : 0}-${asset.id}`}>
					<Dropzone
						categoryName={category.name}
						containerId={container.id}
						categoryIndex={index ? index.id : 0}
						onComplete={updatedContainer => {
							if (updatedContainer?.id === container.id) {
								ContainerActions.updateContainerAndKeepUnsavedEmbargo(updatedContainer, asset.id);
							}
						}}
						addAssetsToContainer={(...args) => ContainerActions.addAssetsToContainer(...args, asset.publishFrom)}
						multipleUploads={index ? false : true}
						assetType={category.assetType}
						getEmbargoDate={() => asset.publishFrom} // Pass function instead of value since the uploader is otherwise initialized with the original value and can't change
						exposeBlockedContainerAssets={true}
						indexDisplayName={index ? index.displayName : null}
						uploaderInformationText={`Folder: "${container.displayName ?? container.name}" (${index ? index.displayName : category.name})`}
						uploaderId={`container-${container.containerId ?? container.id}-${category.name}-${index?.id}-${asset.id}`}
					/>
					{renderEmbargoDatePicker(asset, onEmbargoChange)}
					<Button type="cancel" onClick={() => onRemoveAsset(asset)} />
				</div>
			);
		}

		const isEmbargo = !!asset.publishFrom;
		const embargoDateHasPassed = isEmbargo && moment(asset.publishFrom).isBefore(moment());
		const isFirstInCategory = !assets[i-1] || assets[i-1].isOld;
		const hideEmbargoDatePicker = embargoDateHasPassed && isFirstInCategory;
		return (
			<div className="file-wrapper" key={`${asset.id}-${index?.name ?? asset.index?.name ?? "no-index"}`}>
				<CategoryAsset
					asset={asset}
					onClick={onAssetClick}
					onRemove={!disabled && onRemoveAsset}
					index={asset.index}
					enableEmbargo={appConfig.features.metadataEnableEmbargo && !asset.isOld}
					hideEmbargoDatePicker={hideEmbargoDatePicker}
					onEmbargoChange={!disabled && onEmbargoChange}
					onAddEmbargo={!disabled && onAddEmbargo}
				/>
			</div>
		);
	});
};

const sortAssetsByPublishFrom = localeCompareSortNumeric("publishFrom");
function filterOldAssets(assets, markAsOldInsteadOfRemoving) {
	const filteredAssets = [];
	const now = moment();
	assets.forEach((asset, i) => {
		const nextAsset = assets[i+1];
		const isOld = !(typeof nextAsset?.id === "string" && nextAsset.id.includes("new_embargo"))
			&& nextAsset?.publishFrom
			&& moment(nextAsset.publishFrom).isBefore(now);
		
		if (!isOld) {
			filteredAssets.push(asset);
		} else if (markAsOldInsteadOfRemoving) {
			filteredAssets.push({
				...asset,
				isOld,
			});
		}
	});
	return filteredAssets;
}

export default Category;