import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router'

import { hasAccessToPath } from '../../../core/services/auth'
import * as MetadataData from '../../../apis/metadata'
import { handleRequestFailed } from '../../../core/services/errorhandling'

import InfoBar from '../../../components/ui/infoBar'
import MuiAutoComplete from '../../../components/ui/editorWidgets/muiAutoComplete'

import Actions from '../actions'

import './missingSeriesItem.css'

@withRouter
export default class MissingSeriesItem extends Component {

	static propTypes = {
		seasonId: PropTypes.number.isRequired,
	}

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);

		this.state = {
			series: [],
		}
	}

	// Connects the chosen series to the season and updates the series item if successful
	onChange(seriesId) {
		const seriesData = this.state.series.filter(s => s.id === seriesId)[0];
		seriesData && Actions.connectSeasonToSeries(this.props.seasonId, seriesData);
	}

	render() {
		const {
			seasonId,
			children,
			location,
		} = this.props;

		// Check if the user has access, if not return a "sorry" text
		if(!hasAccessToPath([{ module: "metadata", app: "library", access: "editor" }])) {
			return (
				<InfoBar className="c6-metadata-missingSeriesItem" separate={true}>
					<div className="large-icon icon-error">
						This season is not connected to a series. Since you don't have the access rights to connect it, please let someone else know that it should be connected.
					</div>
				</InfoBar>
			);
		}

		const options = {
			openOnFocus: true,
			dataSource: createSearchMenu(this.state.series),
			dataSourceConfig: { text: "displayName", value: "id" },
			fetch: searchText => {
				if (searchText && searchText.length > 0) {
					MetadataData.fetchPrograms({ searchText, programType: "series" })
						.then(result => {
							this.setState({	series: result.items });
						}, handleRequestFailed);
				} else {
					this.setState({ series: [] });
				}
			},
			remove: () => {},
		};

		const linkTo = {
			pathname: "/metadata/programs/create/series",
			state: {
				modal: true,
				returnTo: `${location.pathname}${location.search}`,
				targetStore: "seriesSeasonPrograms",
			}
		};

		const createNewSeries = <Link className="c6-action" to={linkTo}>create a new series</Link>;

		const searchForSeries = (
			<MuiAutoComplete
				filter={() => true}
				placeholder="Please search for an existing series..."
				options={options}
				onChange={this.onChange}
			/>
		);

		return (
			<InfoBar className="c6-metadata-missingSeriesItem" separate={true}>
				<div className="large-icon icon-error">
					This season is not connected to a series. {searchForSeries} or go ahead and {createNewSeries} if you can't find it.
				</div>
			</InfoBar>
		);
	}
}

// HELPERS
function createSearchMenu(items = []) {
	return items.map(({id, displayName, category}) => ({
		id,
		displayName: `${displayName} (${category !== "Undefined" ? category : ""} id: ${id})`
	}));
}