import React, { Component } from 'react'
import { KEYBOARD_SHORTCUTS } from '../../../core/constants'
import { MuiToggle } from '../editorWidgets'


const LightApproval = ({ isApproved, onChange, disabled }) => {

	const handleChange = (isApproved, event) => {
		// Check for disabled here instead of passing disabled to MuiToggle
		// because the styles are wrong if approved toggle is disabled
    	if (!disabled) {
			onChange(isApproved);
		}
  	};

	const label = isApproved ? "Approved" : "Not approved";
	const title = `${isApproved ? "Approved" : "Unapproved"} (${KEYBOARD_SHORTCUTS.approve})`;

 	return (
		<MuiToggle
			title={title}
			value={isApproved}
			label={label}
			onChange={handleChange}
			disabled={disabled}
			options={{
				color: "success"
			}}
			textStyle={{
				fontSize: "12px",
				color: "#fff"
			}}
			rootStyle={{
				cursor: disabled ? "not-allowed" : "pointer",
			}}
			
		/>
	);
};

export default LightApproval;
