import alt from '../../../core/services/alt'

import Actions from './actions'
import StarActions from '../actions'

import { findHighestNumberInSiblings } from '../shared/utils'

const SERIES = "SeriesProgram";
const SEASON = "SeasonProgram";
const EPISODE = "EpisodeProgram";
// import MoveDialogActions from '../../../dialogs/moveDialog/star/actions'

class Star2ContainerStore {

	constructor() {

		/* Selection */
		this.list = {};

		/* Drag and Drop */
		this.droppedAssets = [];
		this.droppedContainer = {};
		this.dropTarget = {};

		/* Assets */
		this.displayAssetId = null;

		/* Containers */
		this.containerIsLoading = false;
		this.container = {};

		/* Child containers */
		this.childContainersIsLoading = false;
		this.childContainers = [];

		this.types = [];

		this.bindListeners({
			/* Drag and Drop */
			onAssetDrop: Actions.ASSET_DROP,
			onClearAssetDrop: Actions.CLEAR_ASSET_DROP,
			onContainerDrop: Actions.CONTAINER_DROP,
			onClearContainerDrop: Actions.CLEAR_CONTAINER_DROP,

			/* Assets */
			onOpenAsset: Actions.OPEN_ASSET,
			onCloseAsset: Actions.CLOSE_ASSET,

			onItemRemovedFromContainer: StarActions.ITEM_REMOVED_FROM_CONTAINER,

			/* Containers */
			onFetchContainer: Actions.FETCH_CONTAINER,
			onUpdateContainer: Actions.UPDATE_CONTAINER,
			onCreateContainer: Actions.CREATE_CONTAINER,
			onUploadUpdate: Actions.UPLOAD_UPDATE,
			onContainerRemoved: Actions.CONTAINER_REMOVED,

			/* Child containers */
			onFetchChildContainers: Actions.FETCH_CHILD_CONTAINERS,
			onAddChildContainers: Actions.ADD_CHILD_CONTAINERS,
			onUpdateChildContainer: Actions.UPDATE_CHILD_CONTAINER,
			onCreateChildContainer: Actions.CREATE_CHILD_CONTAINER,
			onReplaceChildContainer: Actions.REPLACE_CHILD_CONTAINER,
			onRemoveChildContainer: Actions.REMOVE_CHILD_CONTAINER,
			onCreateSeasonsWithEpisodes: Actions.CREATE_SEASONS_WITH_EPISODES,

			/* Container Types */
			onTypesLoaded: Actions.TYPES_LOADED,

			/* Move dialog */
			// onMoveDialogUpdate: MoveDialogActions.TRIGGER_STAR_CONTAINER_DETAIL_UPDATE,

			/* Helpers */
			onRequestFailed: Actions.REQUEST_FAILED,
		});
	}

	/* Drag and Drop */
	onAssetDrop({ droppedAssets, dropTarget, selected }) {
		this.dropTarget = dropTarget;
		this.droppedAssets = droppedAssets.concat(selected.items.filter(i => i.id !== droppedAssets[0].id)); // Also add possible selected items so we get all "dragged" items
	}

	onClearAssetDrop() {
		this.dropTarget = {};
		this.droppedAssets = [];
	}

	onContainerDrop({ droppedContainer, dropTarget }) {
		this.dropTarget = dropTarget;
		this.droppedContainer = droppedContainer; //droppedAssets.concat(selected.items.filter(i => i.id !== droppedAssets[0].id)); // Also add possible selected items so we get all "dragged" items
	}

	onClearContainerDrop() {
		this.dropTarget = {};
		this.droppedContainer = {};
	}

	/* Assets */
	onOpenAsset({ id }) {
		this.displayAssetId = id;
	}

	onCloseAsset() {
		this.displayAssetId = null;
	}

	onItemRemovedFromContainer({ id }) {
		const index = this.container?.assets?.findIndex(a => a.id === id);
		if (index >= 0) {
			this.container.assets.splice(index, 1);
		}
	}

	/* Containers */
	onFetchContainer(id) {
		this.containerIsLoading = id || true;
	}

	onUpdateContainer(container) {
		this.container = container || { };

		// Start by adding the root container to childContainers so we can hook up child containers when they're ready
		if(container && !container.parentId && (!this.childContainers.length || this.childContainers[0].id !== container.id)) {
			container._level = 0;
			this.childContainers.unshift(container);
		}

		this.containerIsLoading = false;
	}

	onCreateContainer() {
		this.containerIsLoading = true;
	}

	onUploadUpdate(asset) {
		this.container.assets.unshift(asset);
	}

	onContainerRemoved(container) {
		// Currently selected container was removed, select a container from childContainers
		if (container.id === this.container.id) {
			this.container = this.childContainers[0] || {};
		}
	}

	/* Child containers */
	onFetchChildContainers(id) {
		this.childContainersIsLoading = id || true;
	}

	onAddChildContainers(childContainers) {
		const uniqueContainers = childContainers.filter(c => !this.childContainers.some(cc => cc.id === c.id));
		this.childContainers = this.childContainers.concat(this.addFolderLevel(uniqueContainers));
		this.childContainersIsLoading = false;
	}

	onUpdateChildContainer(container) {
		const index = this.childContainers.findIndex(cc => cc.id === container.id);
		if(index >= 0) {
			this.childContainers.splice(index, 1, this.addFolderLevel(container));
		}
	}

	onCreateChildContainer({ parentContainer, type }) {
		const hasNewContainer = this.childContainers.some(cc => cc.id === -1);
		const index = this.childContainers.findIndex(cc => cc.id === parentContainer.id);
		if(index >= 0 && !hasNewContainer) {
			const siblings = this.childContainers.filter(c => 
				c.parentId === parentContainer.id &&
				c.type === (parentContainer.type === SERIES ? SEASON : EPISODE)
			);
			const typeName = parentContainer.type === SERIES ? "Season" : "Episode";
			const highestNumberInSiblings = findHighestNumberInSiblings(siblings, typeName);
			const suggestedDisplayName = parentContainer.type === SERIES || parentContainer.type === SEASON
				? `${typeName} ${highestNumberInSiblings + 1}`
				: "";
			const tempContainer = {
				id: -1,
				parentId: parentContainer.id,
				parentContainerTypeId: parentContainer.containerTypeId,
				suggestedDisplayName,
				highestNumberInSiblings, 
				type,
			}
			this.childContainers.splice(index + 1, 0, tempContainer);
		}
	}

	onReplaceChildContainer({ id, container}) {
		const index = this.childContainers.findIndex(cc => cc.id === id);
		if(index >= 0) {
			this.childContainers.splice(index, 1, this.addFolderLevel(container));
		}
	}

	onRemoveChildContainer(container) {
		const index = this.childContainers.findIndex(cc => cc.id === container.id);
		if(index >= 0) {
			this.childContainers.splice(index, 1);
		}
	}

	onCreateSeasonsWithEpisodes(id) {
		this.childContainersIsLoading = id || true;
	}

	/* Container Types */
	onTypesLoaded(types) {
		this.types = types;
	}

	/* Helpers */
	onRequestFailed() {
		this.isLoading = false;
	}

	initStore(store, filters = null, items = []) {
		this.list[store] = {
            items,
			nextPageUrl: null,
			numberOfItems: 0,
            latestRequestTime: null,
            latestSearchPayload: null,
            filters,
            isLoading: false,
		};
	}

	addFolderLevel(container) {
		if (Array.isArray(container)) {
			return container.map(c => {
				const parentContainer = this.getParentContainer(c);
				return {
					...c,
					parentContainer,
					_level: parentContainer ? parentContainer._level + 1 : 0,
				};
			});
		}

		const parentContainer = this.getParentContainer(container);
		return {
			...container,
			parentContainer,
			_level: parentContainer ? parentContainer._level + 1 : 0,
		}
	}

	getParentContainer(container) {
		const parentContainer = this.childContainers.find(c => c.id === container.parentId);
		if (parentContainer) {
			parentContainer.parentContainer = this.getParentContainer(parentContainer);
		}
		return parentContainer;
	}
}

export default alt.createStore(Star2ContainerStore, '[Star2]ContainerStore');

// function getSmartContainerName2(parentContainer, containers) {
// 	if (parentContainer.containerTypeId === SERIES || parentContainer.containerTypeId === SEASON) {
// 		const siblings = container.filter(c => c.parentId === parentContainer.id);
// 		const typeName = parentContainer.containerTypeId === SERIES ? "Season": "Episode";
// 		const siblingsNumbers = siblings.map(s => {
// 			const regex = `/^${typeName}\\s(\\d+)$/i`;
// 			const regexMatch = s.displayName.match(new RegExp(regex));
// 			return regexMatch ? parseInt(regexMatch[1]) : null;
// 		}).filter(n => !!n).sort((a, b) => b - a);
// 		const highestNumber = siblingsNumbers.length ? siblingsNumbers[0] : 0;
// 		return `${typeName} ${highestNumber + 1}`;
// 	}

// 	return "";
// }
