import React, { Component } from 'react'

import appConfig from 'config'

import { getUser } from '../../core/services/auth'

import './usercontrols.css'

export default class UserControls extends Component {

	componentWillUnmount() {
		window.removeEventListener("click", this.toggleSettingsArea);
	}

	toggleBeacon = () => {
		if (window.Beacon) {
			window.Beacon("open");
		} else {
			console.warn("No window.Beacon :(");
		}
	}

	render() {
		const { username = "No user", onLogOut } = this.props;

		const user = getUser();

		return (
			<div className="c6-user-controls">
				<div className="wrapper">
					{appConfig.features.enableSupportBeacon && user && (
						<span className="icon-help beacon-toggle" onClick={this.toggleBeacon}>&nbsp;Help</span>
					)}
					<span className="icon-person settings-toggle" onClick={this.props.onToggleUserSettingsOpen}>&nbsp;{username}</span>
					<span className="icon-cancel" onClick={onLogOut}></span>
				</div>
			</div>
		);
	}
}
