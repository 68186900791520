import * as MetadataAPI from '../../../apis/metadata'
import { useQuery } from '@tanstack/react-query'

const useMetadataCountries = (enabled: boolean = true) => {
    return useQuery(
        ["metadata", "countries"],
        () => MetadataAPI.fetchCountries(),
        { enabled }
    );
};

export default useMetadataCountries;