import React from 'react'
import Moment from 'moment'
import debounce from 'lodash/debounce'

import App from '../../../../components/app'
import { Info, getRouteName } from '../../../../components/comet'
import Main from '../../../../components/ui/main'
import Button from '../../../../components/ui/controls/button'

import Header from './header'
import List from './list'

// Use Metadata actions+store instead of Tags
import Actions from '../../actions'
import Store from '../../store'

import { getSearchPayload } from '../../shared/utils'

import '../app.css'

const MODULE = "metadata"
const DATASTORE_LIST = "tagPrograms"
const DATASTORE_ITEM = "tag"
const TEXT_HEADING = "Tags"
const TEXT_EMPTY = "No items? There must be a problem."

export default class ProgramsApp extends React.Component {

	constructor(props) {
		super(props);

		this.debouncedFilter = debounce(this.handleFilter, 500);

		this.scroll = null;
		this.tagId = props.params.id;

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		if (this.tagId) {
			Actions.fetchTagPrograms({ id: this.tagId });
		}
	}

	// componentWillReceiveProps({ keydown }) {
	// 	if (keydown.event) {
	// 		console.log( keydown.event.which );
	// 		this.onSearch(keydown.event);
	// 	}
	// }

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	onFilter = (type, e) => {
		if (type === "search") {
    		e.persist();
    		this.debouncedFilter(type, e);
		} else {
			this.handleFilter(type, e);
		}
  	}

	handleFilter = (type, event) => {
		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions[type](value, name, DATASTORE_LIST);
		// const payload = getSearchPayload(this.state);
		Actions.fetchItems("programs", { tagReference: this.tagId, filter: value, pageSize: 50 }, DATASTORE_LIST);

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	loadMore = () => {
		if (this.state.list[DATASTORE_LIST].nextPageUrl) {
			Actions.pageItems(DATASTORE_LIST, this.state.list[DATASTORE_LIST].nextPageUrl);
		}
	}

	render() {
		const { isLoading, searchText: componentSearchText, filters, unselectedFilter, list } = this.state;
		const tagPrograms = list[DATASTORE_LIST];

		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

		return (
			<App
				name={`c6-${MODULE}-programs c6-list`}
				layout="grid"
				isLoading={isLoading}
			>
				<Header
					title={getTagTitle(this.state.item[DATASTORE_ITEM])}
					searchText={searchText}
					filters={filters}
					unselectedFilters={unselectedFilter || !!searchText.length}
					handleFilter={this.onFilter}
				/>
				<Main inputRef={el => this.scroll = el} padding={false}>
					<List
						items={tagPrograms.items}
						isLoading={isLoading}
						searchText={searchText}
						filters={filters}
						textEmpty={TEXT_EMPTY}
						hasMore={!!tagPrograms.nextPageUrl}
						loadMore={this.loadMore}
						tagId={this.tagId}
					/>
				</Main>
				<Info>{getInfo(this.props.location, tagPrograms)}</Info>
			</App>
		);
	}
}

// HELPERS
function getInfo(location, list) {
	const items = list.items.length ? `- displaying ${list.items.length} of ${list.numberOfItems}` : "";

	return `${getRouteName(location)} ${items}`;
}

function getTagTitle(tag) {
	if (tag && tag.displayName) {
		return ` / Programs with tag: ${tag.displayName}`;
	}

	return "";
}