import React from 'react'
import debounce from 'lodash/debounce'
import upperFirst from 'lodash/upperFirst'
import { ScrollContainer } from 'react-router-scroll'

import { getRouteName } from '../../../components/comet'
import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'
import ProgramFamilyDialog from '../../../dialogs/programFamilyDialog'
// import ProgramMergeDialog from '../../../dialogs/programMergeDialog'
import { QCWithoutLink } from '../shared/qcLink'

import Header from './header'
import List from './list'

import Actions from '../actions'
import Store from '../store'
import UserStore from '../../../core/authentication/store'

import { getSearchPayload } from '../shared/utils'
import './app.css'
import appConfig from 'config'
import EditorNavigationController from '../../../core/ui/editorNavigationController'
import MetadataModule from '../module'

const MODULE = "metadata"
const DATASTORE =  "programs"
const ENTITY =  "program"
const TEXT_HEADING = "Program library"
const TEXT_HEADING_TRANSLATION = "Translate to"
const TEXT_CREATE = "Create program"
const ICON_CREATE = "add"

export default class ListApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.debouncedFilter = debounce(this.handleFilter, 500);
		this.loadMore = this.loadMore.bind(this);
		this.handleDialogOpen = this.handleDialogOpen.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);

		this.scroll = null;

		this.isTranslationList = this.props.location.pathname.includes("metadata/translation");
		this.translationFilterFromRoute = this.props.routes.find(r => r.languageFilter)?.languageFilter;
		this.user = UserStore.getState();
		this.translationLanguage = this.translationFilterFromRoute || this.isTranslationList && getRoleVersionLanguage(this.user);

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
			qcDialogOpen: false,
			qcProgram: {},
		};
	}

	async componentDidMount() {
		await MetadataModule.initialSetup();
		
		Store.listen(this.onChange);
		this.catalogue = getCatalogue(this.props, this.isTranslationList);
		this.rightVersion = this.props.routes.find(r => r.rightVersion)?.rightVersion;

		this.catalogue = this.props.params?.catalogue;

		const { list } = this.state;
		const payload = getSearchPayload(this.state, this.catalogue, this.isTranslationList, this.translationLanguage, this.rightVersion);

		const targetStore = this.isTranslationList ? "translations" : null;

		if (!list[targetStore || DATASTORE].items.length) {
			Actions.fetchItems(DATASTORE, payload, targetStore);
		}

		// Also check if there is older stuff that needs some kind of attention
		getPastProgramsNeedingAttention(payload);
		if (payload.searchText) {
			getPastProgramsMatchingSearch(payload);
		}
	}

	// componentWillReceiveProps({ keydown }) {
	// 	if (keydown.event) {
	// 		console.log( keydown.event.which );
	// 		this.onSearch(keydown.event);
	// 	}
	// }

	UNSAFE_componentWillReceiveProps(nextProps) {
		const nextIsTranslationList = nextProps.location.pathname.includes("metadata/translation");
		const nextTranslationFilterFromRoute = nextProps.routes.find(r => r.languageFilter)?.languageFilter;
		this.translationLanguage = nextTranslationFilterFromRoute || nextIsTranslationList && getRoleVersionLanguage(this.user);
		const nextCatalogue = getCatalogue(nextProps, nextIsTranslationList);
		const nextRightVersion = nextProps.routes.find(r => r.rightVersion)?.rightVersion;
		const payload = getSearchPayload(this.state, nextCatalogue, nextIsTranslationList, this.translationLanguage, nextRightVersion);
		if (
			nextCatalogue !== this.catalogue
			|| nextRightVersion !== this.rightVersion
			|| nextIsTranslationList !== this.isTranslationList
			|| nextTranslationFilterFromRoute !== this.translationFilterFromRoute
			|| nextCatalogue !== this.catalogue
		) {
			this.catalogue = nextCatalogue;
			this.rightVersion = nextRightVersion;
			this.isTranslationList = nextIsTranslationList;
			this.translationFilterFromRoute = nextTranslationFilterFromRoute;
			// this.onViewChange(this.isTranslationList);
			const targetStore = this.isTranslationList ? "translations" : null;
			Actions.fetchItems(DATASTORE, payload, targetStore);
		}

		// Also check if there is older stuff that needs some kind of attention
		getPastProgramsNeedingAttention(payload);
		if (payload.searchText) {
			getPastProgramsMatchingSearch(payload);
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	// onViewChange(isTranslationList) {
	// 	console.log("New view");
	// 	Actions.viewChange(isTranslationList); // Resets some filters which might confuse the user

	// 	this.setState({
	// 		searchText: "",
	// 		unselectedFilter: false,
	// 	});
	// }

	onChange(state) {
		this.setState(state);

		const programs = state.list[this.isTranslationList ? "translations" : DATASTORE];
		EditorNavigationController.setItems(programs.items);
	}

	onFilter(type, e) {
		if(type === "search") {
    		e.persist();
    		this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
  	}

	handleFilter(type, event) {
		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		const targetStore = this.isTranslationList ? "translations" : null;
		Actions[type](value, name, targetStore);
		const payload = getSearchPayload(this.state, this.catalogue, this.isTranslationList, this.translationLanguage, this.rightVersion);
		Actions.fetchItems(DATASTORE, payload, targetStore);

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);

		// Also check if there is older stuff that needs some kind of attention
		if (type !== "search" && name !== "_searchPastPrograms" || type === "search" && value === "") {
			getPastProgramsNeedingAttention(payload);
		} else {
			getPastProgramsMatchingSearch(payload);
		}
	}

	loadMore() {
		const targetStore = this.isTranslationList ? "translations" : null;
		const programs = this.state.list[targetStore || DATASTORE];

		if(programs.nextPageUrl) {
			Actions.pageItems(DATASTORE, programs.nextPageUrl, targetStore);
		}
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	handleDialogOpen(dialogName, e, dialogData) {
		e.preventDefault();
		this.setState({
			dialogOpen: dialogName,
			dialogData,
		});
	}

	handleDialogClose() {
		this.setState({ dialogOpen: null });
	}

	render() {
		const {
			isLoading,
			searchText: componentSearchText,
			filters,
			unselectedFilter,
			dialogData,
			list,
			masterTemplateModifications,
		} = this.state;

		const programs = list[this.isTranslationList ? "translations" : DATASTORE];
		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

		const catalogue = this.catalogue || getCatalogue(this.props, this.isTranslationList);
		const catalogueTitle = MetadataModule.catalogueMap[catalogue]?.displayName;

		const pastProgramsNeedingAttention = filters.filter.filter !== "HasActiveRights" && filters.filter.approval !== "" ? list.pastProgramsNeedingAttention : null;
		const pastProgramsMatchingSearch = searchText?.length && filters.filter.approval !== "" ? list.pastProgramsMatchingSearch : null;

		const language = this.translationFilterFromRoute || getRoleVersionLanguage(this.user) || "any language";
		const title = this.isTranslationList ? `${TEXT_HEADING_TRANSLATION} ${language}` : catalogueTitle ?? TEXT_HEADING;

		const isSysadmin = this.user?.user?.username === "sysadmin";

	    return (
			<App
				name={`c6-${MODULE}-${DATASTORE} c6-${MODULE}-${DATASTORE}-${catalogue} c6-list`}
				layout="grid"
				isLoading={isLoading}
			>
				<Header
					title={title}
					searchText={searchText}
					filters={filters}
					unselectedFilters={unselectedFilter || !!searchText.length}
					handleFilter={this.onFilter}
					isTranslationList={this.isTranslationList}
					translationLanguage={language}
					catalogue={catalogue}
					rightVersion={this.rightVersion}
				/>
				<ScrollContainer scrollKey={`c6-${MODULE}-${DATASTORE}`} shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el} padding={true}>
						<List
							items={programs.items}
							numberOfItems={programs.numberOfItems}
							masterTemplateModifications={masterTemplateModifications}
							isLoading={this.state.isLoading}
							searchText={searchText}
							filters={filters}
							hasMore={!!programs.nextPageUrl}
							loadMore={this.loadMore}
							catalogue={catalogue}
							isTranslationList={this.isTranslationList}
							translationLanguage={language}
							isSysadmin={isSysadmin}

							pastNeedingAttention={pastProgramsNeedingAttention}
							pastMatchingSearch={pastProgramsMatchingSearch}
							handleFilter={this.onFilter}
							// selectedItems={this.state.selected[DATASTORE]}
							// onSelectItem={item => Actions.selectItem(DATASTORE, item)}
							onFamilyActionClick={(e, itemData) => this.handleDialogOpen("programFamilyDialog", e, itemData)}
							onVideoClick={(qcProgram) => this.setState({ qcProgram, qcDialogOpen: true })}
						/>
					</Main>
				</ScrollContainer>
				<ProgramFamilyDialog
					open={this.state.dialogOpen === "programFamilyDialog"}
					onClose={this.handleDialogClose}
					data={dialogData}
				/>
				{/* <ProgramMergeDialog
					open={this.state.dialogOpen === "programMergeDialog"}
					onClose={this.handleDialogClose}
					data={this.state.selected[DATASTORE]}
				/> */}
				<Info>
					{getInfo(this.props.location, programs)}
					{/*getMergeActions(DATASTORE, this.state, e => this.handleDialogOpen("programMergeDialog", e), () => Actions.clearSelected(DATASTORE))*/}
				</Info>
				<QCWithoutLink
					program={this.state.qcProgram}
					open={this.state.qcDialogOpen}
					onClose={() => {
						// TODO: update video approval in list
						this.setState({
							qcDialogOpen: false,
							qcProgram: null,
						});
					}}
				/>
			</App>
		);
	}
}

// HELPERS
function getInfo(location, list) {
	const items = list.items.length ? `- displaying ${list.items.length} of ${list.numberOfItems}` : "";

	return `${getRouteName(location)} ${items}`;
}

function getCatalogue({ params }, isTranslationList) {
	if (isTranslationList && appConfig.features.metadataTranslationContentOwner) {
		return appConfig.features.metadataTranslationContentOwner;
	}

	return params.catalogue;
}

function getPastProgramsNeedingAttention(payload) {
	if (payload && payload.approval !== "") {
		delete payload.premiere;
		// Don't display alert if request fails since it usually is a timeout error and it will confuse the user if they see and alert 30 seconds after they did something
		// Ideally we should probably display a "Loading past programs..." and automatically retry the request or allow the user to manually retry the request
		const noDisplayAlert = true;
		Actions.fetchItems(
			DATASTORE,
			{
				...payload,
				approval: "missing",
				pageSize: 1,
				filter: "HasActiveRights", // Returns programs with active rights which premiered before today
				orderBy: "premiereDescending",
			},
			"pastProgramsNeedingAttention",
			noDisplayAlert
		);
	}
}

function getPastProgramsMatchingSearch(payload) {
	if (payload.searchText?.length && appConfig.features.metadataNewDefaultSearchBehaviour) {
		delete payload.premiere;
		Actions.fetchItems(DATASTORE, {
			...payload,
			filter: null,
			pageSize: 1,
		}, "pastProgramsMatchingSearch");
	}
}

export function getRoleVersionLanguage(user) {
	const { accessroles } = user;
	const metadataRoles = accessroles.filter(r => r.includes(`${MODULE}.`));
	const role = metadataRoles[0];
	if (role === `${MODULE}.finnish-age-rating`) {
		return "Finnish";
	}
	return role && upperFirst(role.replace(`${MODULE}.`, ""));
}