import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'

import Editor from './editor'

import '../../../dialogs/dialogs.css'

const AssetDialog = props => {

	React.useEffect(
		() => {
			// HACK: Trigger dialog padding-top calculation to fix dialog position
			// https://github.com/mui-org/material-ui/issues/1676#issuecomment-143798818
			setTimeout(() => {
				window.dispatchEvent(new Event("resize"));
			}, 100);
		},
		[]
	);
	
	return (
		<Dialog
			open={props.open}
			onClose={() => props.onClose()}
			className="c6-modal"
			maxWidth={false}
			fullWidth={true}
			classes={{
				container: "c6-modal-content",
				paper: "c6-modal-body c6-dialog-paper-dark"
			}}
		>
			<Editor
				assetId={props.assetId}
				assetCategoryId={props.assetCategoryId}
				disabled={props.disabled}
				hideTabs={props.hideTabs}
			/>
		</Dialog>
	);
};

AssetDialog.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
	assetId: PropTypes.number,
	assetCategoryId: PropTypes.number,
	hideTabs: PropTypes.bool,
};

export default AssetDialog;