import React from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'

import { Filter } from '../../../components/filter'

export default ({
	title,
	// goBackInsteadOfLink,
}) => {

	// We need to go back instead of doing a normal navigation to allow for
	// react-router-scroll to restore the scroll position of the library list.
	// const handleClick = e => {
	// 	if (goBackInsteadOfLink) {
	// 		e.preventDefault();
	// 		browserHistory.goBack();
	// 	}
	// };

	return (
		<Filter>
			<h1>
				<Link to={"/metadata/families"} /*onClick={handleClick}*/>Program families</Link> / {title}
			</h1>
		</Filter>
	);
}