import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router'
import InfiniteScroll from 'react-infinite-scroller'
import groupBy from 'lodash/groupBy'

import { ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'
import Dropzone from '../../../components/upload/dropzone'

import Item from './listItem'

import { isThumbNailType, hasStarEditAccess, getAssetOrContainerId } from './utils'

import appConfig from 'config'
import './list.css'

const RECENT_KEY = "star-recentSearches";

@withRouter
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		selected: PropTypes.object,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		titleEmpty: PropTypes.string,
		textEmpty: PropTypes.string,
		uploadUrl: PropTypes.string,
		onUploadComplete: PropTypes.func,
		draggableItems: PropTypes.bool,

		location: PropTypes.object, // from @withRouter
	}

	constructor(props) {
		super(props);

		this.userCanEdit = hasStarEditAccess() || props.userCanEdit;

		this.state = {
			groupRefs: {},
		};
	}

	render() {
		const {
			items,
			isLoading,
			hasMore,
			loadMore,
			uploadUrl,
			searchText,
			location,
			hideRecentSearches = false,
			groupItems = true,
			selected,
			draggableItems,
			masonry = true,
		} = this.props;

		const titleEmpty = "No assets found!";
		const textEmpty = searchText ? `Sorry, I couldn't find any assets matching your search ${searchText}.` : "No assets have been added yet.";

		const noResult = !(items && items.length);

		if(noResult && !uploadUrl && !searchText && !hideRecentSearches) {
			return getRecentSearches(location);
		}

		if(noResult && (!uploadUrl || !this.userCanEdit)) {
			return <Empty title={titleEmpty} v2={true} isLoading={isLoading}>{textEmpty}</Empty>;
		}

		if(!loadMore) {
			// No InfiniteScroll if this is a list which cannot load more items
			return (
				<div>
					{groupItems
						? this.renderGroups(this.props)
						: <ItemGroup containerClassName={getThumbnailLayout(masonry)}>{this.renderItems(items, selected, draggableItems)}</ItemGroup>
					}
				</div>
			);
		}

		return (
			<InfiniteScroll
				loadMore={loadMore}
				hasMore={hasMore}
				loader={<div className="infinite-loader" key="infinite-loader">Loading...</div>}
				useWindow={false}
				threshold={1500}
				initialLoad={false}
			>
				{groupItems
					? this.renderGroups(this.props)
					: <ItemGroup containerClassName={getThumbnailLayout(masonry)}>{this.renderItems(items, selected, draggableItems)}</ItemGroup>
				}
			</InfiniteScroll>
		);
	}

	renderGroups({ items, searchText, selected, uploadUrl, onUploadComplete, draggableItems, skipUploadAuth, uploaderInformationText, uploaderIdPrefix, masonry = true }) {

		const renderUploader = this.userCanEdit && uploadUrl;
		if (renderUploader && items.length === 0) {
			return (
				<ItemGroup title="Upload assets" containerClassName={getThumbnailLayout(masonry)}>
					<Dropzone
						categoryName="Undefined"
						endpoint={uploadUrl}
						onComplete={onUploadComplete}
						multipleUploads={true}
						skipAuth={skipUploadAuth}
						uploaderId={`${uploaderIdPrefix}star-list-no-items`}
						uploaderInformationText={uploaderInformationText}
					/>
				</ItemGroup>
			);
		}

		const groupedItems = groupItems(items);
		return Object
			.keys(groupedItems)
			.sort((a, b) => getGroupOrder(a) - getGroupOrder(b)) // TODO: Maybe only use this when listing selected items
			.map((groupKey) => {
				const groupRef = this.state.groupRefs[groupKey];
				return (
					<ItemGroup
						key={groupKey}
						title={getTitle(groupKey, searchText)}
						containerClassName={getLayout(groupKey, masonry)}
						groupRef={ref => {
							// Sort of hacky but we need to trigger a state update after the container (grid) has rendered
							// so that we can calculate the masonry layout
							if (appConfig.features.starUseMasonryLayout && masonry && !this.state.groupRefs[groupKey]) {
								const refs = { ...this.state.groupRefs };
								refs[groupKey] = ref;
								this.setState({ groupRefs: refs });
							}
						}}
					>
						{renderUploader && (
							<Dropzone
								categoryName="Undefined"
								endpoint={uploadUrl}
								onComplete={onUploadComplete}
								multipleUploads={true}
								skipAuth={skipUploadAuth}
								uploaderId={`${uploaderIdPrefix}star-list-group-${groupKey}`}
								uploaderInformationText={uploaderInformationText}
							/>
						)}
						{this.renderItems(groupedItems[groupKey], selected, this.userCanEdit && draggableItems, groupRef)}
					</ItemGroup>
				);
			});
	}

	renderItems(items, selected, draggableItems, groupRef) {
		const visibleItems = items.filter(i => !i._isHidden);
		setMasonryItemSizes(visibleItems, groupRef);
		return visibleItems.map(item => (
			<Item
				key={item.id}
				id={item.id}
				item={item}
				selected={isSelected(item, selected)}
				draggable={draggableItems}
				selectedItems={selected ? selected.items : []}
				style={{ gridRowEnd: item.rowSpan ? "span " + item.rowSpan : undefined }}
			/>
		));
	}
}

// HELPERS
function groupItems(items) {
	return groupBy(items, item => {
		if (item.type === "Container")
			return "Folder";
		if (item.assetCategory === "ScreenGrab" || item.category === "ScreenGrab")
			return "Screengrab";
		
		return item.subType || item.type;
	});
}

function getTitle(type, searchText = "") {
	return searchText ? `${type}s matching "${searchText}"` : `${type}s`;
}

function getLayout(type, masonry) {
	return isThumbNailType(type) ? getThumbnailLayout(masonry) : "";
}

function isSelected(item, selected) {
	const id = getAssetOrContainerId(item);
	return selected ? selected.items.some(i => getAssetOrContainerId(i) == id) : false;
}

function getGroupOrder(group) {
	switch(group) {
		case "Folder": return 1;
		case "Image": return 2;
		case "Screengrab": return 3;
		case "Clip": return 4;
		case "Video": return 5;
		case "Sub": return 6;
		case "Document": return 7;
		default: return 8;
	}
}

function getRecentSearches({ search }) {
	const recentSearches = localStorage.getItem(RECENT_KEY) || "";
	const recent = recentSearches.split("|").filter(r => r !== "").map(r => decodeURIComponent(r));

	if (recent.length) {
		return (
			<div className="c6-star-recent-searches">
				<h3 className="icon-search">Your recent searches</h3>
				<ul>
					{recent.map(r => {
						const linkAction = { pathname: `/star/search/${r}`, search };
						return <li key={r}><Link to={linkAction} className="c6-link">{r}</Link></li>;
					})}
				</ul>
			</div>
		);
	}

	return (
		<div className="c6-star-search-welcome">
			<div>
				<h3>Welcome to STAR</h3>
				<p>Please search for assets above (filter by asset type if you wish).</p>
			</div>
		</div>
	);
}

function filterAsset(asset) {
	// if (appConfig.features.extendedMezzSupport) {

	// }

	// return HIDDEN_GROUPS.includes(type)
}

function setMasonryItemSizes(items, groupRef) {
	const grid = groupRef?.classList.contains("masonry")
		? groupRef.querySelector(".items")
		: null;
	
	if (!grid || !appConfig.features.starUseMasonryLayout) {
		return;
	}

	const columnWidth = parseFloat(window.getComputedStyle(grid).getPropertyValue("grid-template-columns").split(" ")[0]);
	const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue("grid-auto-rows"));
	if (!columnWidth || !rowHeight) {
		return;
	}
	
	const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue("grid-row-gap"));
	items.forEach(item => {
		const width = item.assetData?.width ?? item.asset?.width;
		const height = item.assetData?.height ?? item.asset?.height;
		if (width && height) {
			const itemHeight = (height / width) * columnWidth;
			item.rowSpan = Math.ceil((itemHeight + rowGap) / (rowHeight + rowGap));
		} else {
			item.rowSpan = 16;
		}
	});
}

function getThumbnailLayout(masonry) {
	if (appConfig.features.starUseMasonryLayout && masonry) {
		return "layout-thumbnails masonry";
	}

	return "layout-thumbnails";
}