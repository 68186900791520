import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

import Button from './button'

export default class EditableText extends React.Component {

	static propTypes = {
		onChange: PropTypes.func.isRequired,
		onCancelChange: PropTypes.func,
		hintText: PropTypes.string,
		isEditing: PropTypes.bool,
		disableEdit: PropTypes.bool,
		useApproveButton: PropTypes.bool,
		className: PropTypes.string,
		// underlineStyle: PropTypes.object,
		// inputStyle: PropTypes.object,
		// hintStyle: PropTypes.object,
		children: PropTypes.string,
	}

	constructor(props) {
		super(props);

		this.handleEditClick = this.handleEditClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.commitChange = this.commitChange.bind(this);
		this.cancelChange = this.cancelChange.bind(this);

		this.state = {
			isEditing: props.isEditing || false,
			value: "",
		}
	}

	handleEditClick(e) {
		e.preventDefault();

		this.setState({ isEditing: true });
	}

	handleChange(e) {
		if (this.props.useApproveButton && !this.props.commitOnEnter) {
			return false;
		}
		if(e.type === "blur" || e.which === 13 || e.keyCode === 13) { // Blur or Enter
			e.preventDefault();
			const { value } = e.target;

			if(value !== this.props.children) {
				this.props.onChange(value, e);
			}

			if(value !== "") {
				this.setState({ isEditing: false });
			}
		}
		else if (e.which === 27 || e.keyCode === 27) { // Escape (doesn't acually work since we're in a MUI modal which will close on escape)
			e.preventDefault();
			e.stopPropagation();
			this.setState({ isEditing: false });
		}
	}

	handleUpdate(e) {
		if(e.which === 13 || e.keyCode === 13 || e.which === 27 || e.keyCode === 27) { // Enter or Escape
			e.preventDefault();
		}
		else {
			const { value } = e.target;
			this.setState({ value });
		}
	}

	commitChange(e) {
		this.props.onChange(this.state.value);
		this.setState({ isEditing: false });
	}

	cancelChange(e) {
		e.preventDefault();
		this.setState({
			isEditing: false,
			value: "",
		}, this.props.onCancelChange);

	}

	UNSAFE_componentWillReceiveProps({ isEditing }) {
		if(isEditing !== this.props.isEditing) {
			this.setState({ isEditing });
		}
	}

	render() {
		const {
			hintText = null,
			disableEdit,
			children,
			useApproveButton = false,
			className = "",
		} = this.props;

		if (this.state.isEditing) {
			if (useApproveButton) {
				return (
					<span>
						<TextField
							variant="standard"
							ref={focusTextField}
							onChange={this.handleUpdate}
							onKeyDown={this.handleChange}
							defaultValue={children}
							className={`c6-text-editable ${className}`}
							placeholder={hintText}
							autoFocus={true}
						/>
						<Button
							type="approve"
							onClick={this.commitChange}
							noBackground />
						<Button
							type="cancel"
							onClick={this.cancelChange}
							noBackground />
					</span>
				);
			}

			return (
				<TextField
					variant="standard"
					ref={focusTextField}
					defaultValue={children}
					onChange={this.handleChange}
					onKeyDown={this.handleChange}
					onBlur={this.handleChange}
					className={`c6-text-editable ${className}`}
					placeholder={hintText}
					autoFocus={true}
				/>
			);
		}

		const editIcon = !disableEdit
			? <button onClick={this.handleEditClick} className="c6-button noBackground"><span className="icon-create" /></button>
			: null;

		return <span className={className}>{children}{editIcon}</span>;
	}
}

function focusTextField(field) {
	setTimeout(() => {
		if (field) {
			field.focus();
		}
	}, 500);
}