import React from 'react'
import {Filter, Left, Right} from '../../../components/filter'
import Switch from '../../../components/ui/controls/switch'
import Search from '../../../components/ui/controls/search'
import Button from '../../../components/ui/controls/button'
import { getTranslation } from '../shared/utils'

export default ({
	title,
	searchText,
	filters,
	unselectedFilters,
	handleFilter,
	handleCreateNew
}) => (
	<Filter>
		<Left>
			<h1>{title}</h1>
			<Search
				onChange={handleFilter.bind(this, "search")}
				placeholder="Search"
				searchText={searchText} />
		</Left>
		<Right>
			<Switch
				name="translation"
				title="Translation"
				unselected={unselectedFilters}
				onChange={handleFilter.bind(this, "filter")}
				states={getTranslation()}
				currentState={filters.filter.translation}
			/>
			<Button
				type="add"
				title="Add new tag"
				onClick={handleCreateNew}
			/>
		</Right>
	</Filter>
);