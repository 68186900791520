import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { DragSource, DropTarget } from 'react-dnd'

import ItemTypes from '../../core/itemTypes'
import Actions, { ContentActionItem } from '../actions'

import styles from './list.css'
import dndStyles from './dnd.css'

const itemSource = {
	beginDrag(props) {
		const { id, actionData = null, onDragStart, findItem } = props;

		// For hiding actions when dragging
		if(typeof(onDragStart) === "function") {
			onDragStart();
		}

		return {
			id,
			originalIndex: findItem(id).index,
			originalData: Object.assign({}, actionData), // Clone, so we have the original item state for rollbacks
		 };
	},

	// Possibly needed if we're dragging between list components
	// isDragging(props, monitor) {
    //     return props.actionData === monitor.getItem().actionData;
    // },

	endDrag(props, monitor) {
		// For hiding actions when dragging
		if(typeof(props.onDragEnd) === "function") {
			props.onDragEnd();
		}

	 	const { id: droppedId, originalIndex } = monitor.getItem();
	 	const didDrop = monitor.didDrop();

		// Set item original location if no real drop
	 	if (!didDrop && typeof(props.onItemDropOutside) === "function") {
			props.onItemDropOutside(droppedId, originalIndex); // Resets the dragged item to it's original location
		}
	}
};

const itemTarget = {
	canDrop() {
		return false;
	},

	hover(props, monitor) {
		const { id: overId, findItem, moveItem, insertCard} = props;
		const draggedId = monitor.getItem().id;

		if (draggedId !== overId) {
			const { index: draggedIndex } = findItem(draggedId);

			if(draggedIndex >= 0) {
				moveItem(draggedId, overId); // TODO: See if adding the overTarget prop here is faster than using state
			}
			else {
				insertCard(draggedId, overId);
			}
		}
	}
};

// https://github.com/gaearon/react-dnd/issues/229
// const dragSourceOptions = {
// 	arePropsEqual: (nextProps, props, component) => {
// 		return 	nextProps.id === props.id; // NOW: Needs more logic
// 	}
// };

@DropTarget(ItemTypes.ITEM, itemTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
}))
@DragSource(ItemTypes.ITEM, itemSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging()
}))
// @DragSource(ItemTypes.ITEM, itemSource, (connect, monitor) => ({
// 	connectDragSource: connect.dragSource(),
// 	isDragging: monitor.isDragging()
// }), dragSourceOptions)
export default class SortableItem extends PureComponent {
	static displayName = "SortableItem"; // Needed to support UI-actions since babel is minifying the class name (this.constructor.name) when we build to production

	static propTypes = {
		actionData: PropTypes.object,
		flex: PropTypes.string,
		sel: PropTypes.bool,

		// DnD
		connectDragSource: PropTypes.func.isRequired,
		connectDragPreview: PropTypes.func.isRequired,
		connectDropTarget: PropTypes.func.isRequired,
		isDragging: PropTypes.bool.isRequired,
		id: PropTypes.oneOfType([
			PropTypes.string.isRequired,
			PropTypes.number.isRequired
		]),
		moveItem: PropTypes.func.isRequired,
		findItem: PropTypes.func.isRequired,
		insertCard: PropTypes.func,
		isPlaceholder: PropTypes.bool,
		// isOverArea: PropTypes.bool,
	}

	render() {
		const {
			handleType,
			actionData,
			sel,
			isDragging,
			isPlaceholder,
			// isOverArea = true,
			connectDragSource,
			connectDragPreview,
			connectDropTarget
		} = this.props;

		// let opacity = 1;
		// const hide = isDragging || isPlaceholder;
		// if(!isOverArea && hide) {
		// 	opacity = 0.5;
		// }
		// else if(isOverArea && hide) {
		// 	opacity = 0;
		// }

		// const style = { opacity };

		const style = {
			opacity: isDragging || isPlaceholder ? 0 : 1
		};

		const flex = this.props.flex ? `flex flex-${this.props.flex}` : "";
		const selected = sel ? " sel" : "";
		// const dragging = isDragging ? " is-dragging-item" : "";
		// const classNames = `c6-item ${flex}${selected}${dragging}`;
		const classNames = `c6-item ${flex}${selected}`;
		const handleClassNames = handleType ? `c6-handle width-${handleType}`: "c6-handle";
		const actions = actionData
			? <Actions data={actionData} target={this.constructor.displayName} />
			: null;

	    return connectDragPreview(connectDropTarget(
			<div className={classNames} style={style}>
				<ContentActionItem data={actionData}>
					{connectDragSource(<div className={handleClassNames} />)}
					{this.props.children}
					{actions}
				</ContentActionItem>
			</div>
		));
  	}
}