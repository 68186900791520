import * as MetadataAPI from '../../../apis/metadata'
import { useQuery } from '@tanstack/react-query'

const useMetadataGenres = (enabled: boolean = true) => {
    return useQuery(
        ["metadata", "genres"],
        () => MetadataAPI.fetchGenres(),
        { enabled }
    );
};

export default useMetadataGenres;