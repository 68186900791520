import React from 'react'
import PropTypes from 'prop-types'
import './switch.css'

import debounce from 'lodash/debounce'

const Pick = (
	{
		states = [],
		onChange = () => { alert("onChange handler missing from parent component!")},
		currentState = [],
		currentStateKey = null,
		title = "",
		disabled = false,
		unselected = false,
		name = "status",
		value = "key",
		label = "text",
		group = "group",
		labelGenerator = (label, value) => <span>{label}</span>,
		pickAll = false,
		pickAllLabels = ["All", "None"],
	}
) => {

	const debounced = debounce(onChange, 200, { leading: true });

	const handleChange = (pickedStates, e) => {
		e.persist();
		debounced(e, pickedStates);
	};

	const labels = states.map((state, index) => {
		if(!state) {
			console.warn("Pick component got undefined State in %o", states);
			return null;
		}
		return (
			<label key={index} title={state.description} disabled={state.disabled}>
				<input
					disabled={state.disabled || disabled}
					type="checkbox"
					name={name}
					checked={currentState.filter(s => (
						currentStateKey
							? s[currentStateKey] === state[value]
							: s === state[value]
						)).length}
					value={state[value]}
					onChange={handleChange.bind(this, state[value])} />
				{labelGenerator(state[label], state[value], state[group])}
			</label>
		);
	});

	const allPicked = states.every(s => currentState.includes(s[value]));
	const allToggler = pickAll
		? (
			<label className="c6-pick-toggle">
				<input
					disabled={disabled}
					type="checkbox"
					name={name}
					checked={allPicked}
					value={allPicked ? "-" : "+"}
					onChange={handleChange.bind(this, states)} />
				<span>{pickAllLabels[allPicked | 0]}</span>
			</label>
		)
		: null;

	const classes = unselected ? "c6-switch isUnselected" : "c6-switch";
	const heading = title ? <span>{title}</span> : null;
	return states.length
		? (
			<div className={classes} disabled={disabled}>
				{heading}
				{allToggler}
				{labels}
			</div>
		)
		: null;
};

Pick.propTypes = {
	states: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	currentState: PropTypes.array,
	title: PropTypes.string,
	disabled: PropTypes.bool,
	unselected: PropTypes.bool,
	value: PropTypes.string,
	label: PropTypes.string,
	labelGenerator: PropTypes.func,
}
export default Pick;