import { get, put, post, patch, del } from '../core/c6'

const API = "contentExternal";
const skipToken = true;

export const fetchEpgSchedules = (filters, key) => {
	const headers = {
		"x-api-key": key,
	};

	return get(API, "epg/schedules", filters, skipToken, headers);
}


export const fetchVodAssets = (filters, key) => {
	const headers = {
		"x-api-key": key,
	};

	return get(API, "vod/assets", filters, skipToken, headers);
}

export const fetchVodAsset = (id, key) => {
	const headers = {
		"x-api-key": key,
	};

	return get(API, `vod/assets/${id}`, null, skipToken, headers);
}

export const fetchCometProgram = (id, key) => {
	const headers = {
		"x-api-key": key,
	};

	return get(API, `metadata/programs/comet/${id}`, null, skipToken, headers);
}