import React from 'react'
import PropTypes from 'prop-types'

import { Item } from '../../../components/list/listItems'

import MembersList from './membersList'
import FamilyContent from '../families/listItem'

export default class Members extends React.Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
	};

	// TODO!: Try to remove this
	// https://3.basecamp.com/3091592/buckets/1615235/todos/336613940
	handleOutsideDrop(id, originalIndex) {
		this.listRef.decoratedComponentInstance.handleOutsideDrop(id, originalIndex);
	}

	render() {
		const {
			isLoading,
			scrollHeight: height,
			family,
			items,
		} = this.props;
		
		const itemsReduced = items.reduce((acc, current) => {
			const currentItems = (current.items || []).map(item => ({
				...item,
				linkRelationType: current.name,
			}));
			return [...acc, ...currentItems];
		}, []);

		const familyItem = family && family.id && (
			<Item
				id={family.id}
				actionData={family}
			>
				<FamilyContent {...family} />
			</Item>
		);

		return (
			<div style={{ height }}>
				{familyItem}
				<MembersList
					ref={r => this.listRef = r}
					family={family}
					items={itemsReduced}
				/>
			</div>
		);
	}
}