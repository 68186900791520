import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'

export default class AlertDialog extends React.Component {
	static propTypes = {
		open: PropTypes.bool.isRequired,
		onAction: PropTypes.func.isRequired,
		primary: PropTypes.string,
		secondary: PropTypes.string,
	}

	constructor(props) {
		super(props);
	}

	render() {
		const {
			open = false,
			primary = null,
			secondary = null,
			onAction,
			children = "The developers forgot to tell me what to ask you. So how's the weather?",
		} = this.props;

		let actions = [];
		secondary && secondary.split(",").forEach(a => {
			actions.push(<Button key={a} color="error" onClick={onAction}>{a}</Button>);
		});
		primary && primary.split(",").forEach(a => {
			actions.push(<Button key={a} color="primary" onClick={onAction}>{a}</Button>);
		});

		return (
			<Dialog
				open={open}
				onClose={onAction}
			>
				<DialogContent>
					{children}
				</DialogContent>
				<DialogActions>
					{actions}
				</DialogActions>
			</Dialog>
		);
	}
};