import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragLayer } from 'react-dnd'

import DragPreview from './dragPreview'

@DragLayer(monitor => ({
	item: monitor.getItem(),
	// itemType: monitor.getItemType(),
	initialOffset: monitor.getInitialSourceClientOffset(),
	currentOffset: monitor.getSourceClientOffset(),
	currentPointerOffset: monitor.getClientOffset(),
	isDragging: monitor.isDragging(),
}))
export default class CustomDragLayer extends Component {
	static propTypes = {
		item: PropTypes.object,
		// itemType: PropTypes.string,
		initialOffset: PropTypes.shape({
			x: PropTypes.number.isRequired,
			y: PropTypes.number.isRequired,
		}),
		currentOffset: PropTypes.shape({
			x: PropTypes.number.isRequired,
			y: PropTypes.number.isRequired,
		}),
		currentPointerOffset: PropTypes.shape({
			x: PropTypes.number.isRequired,
			y: PropTypes.number.isRequired,
		}),
		isDragging: PropTypes.bool.isRequired,
	}

	render() {
		const { isDragging, item } = this.props;

		if(!isDragging) {
			return null;
		}

		return (
			<div style={dragLayerStyles}>
				<div style={getItemStyles(this.props)}>
					<DragPreview item={item} />
				</div>
			</div>
		)
	}
}

// HELPERS
const dragLayerStyles = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
}

function getItemStyles(props) {
	const { initialOffset, currentOffset, currentPointerOffset } = props;

	if(!initialOffset || !currentOffset) {
		return { display: 'none' };
	}

	const { x, y } = currentPointerOffset;

	return { transform: `translate(${x+20}px, ${y-45}px)` }
}